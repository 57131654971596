import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import RouterButton from '../Button/RouterButton';
import CoinContext from '../../contexts/CoinContext';
import { theme, mediaUp, mediaDown, psLetterSpacing, psLineHeight } from "../../globalStyle";
import star from '../../assets/images/star_yellow_small.png';

const StyledRatesCard = styled.div`
  background-color: ${theme.white};
  text-align: center;
  
  ${mediaUp.lg`
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 497px;
  `}
`;

const RatesCardHeader = styled.div`
  position: relative;
  min-height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${rem(35)};
`;

const RatesCardHeaderLogo = styled.img`
  max-width: 150px;
  height: auto;
`;

const RatesCardHeaderTitle = styled.h5`
  font-size: ${rem(17)};
  letter-spacing: ${psLetterSpacing(17, 40)};
  margin-bottom: 0;
  text-align: center;
  text-transform: capitalize;
`;

const RatesCardHeaderBanner = styled.div`
  position: absolute;
  top: 0;
  left: 12px;
  width: 52px;
  height: 53px;
  background-color: ${theme.primary};
  
  ${mediaUp.lg`
    left: 19px;
  `}
  
  &::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    width: 0; 
    height: 0; 
    border-left: 26px solid transparent;
    border-right: 26px solid transparent;
    border-top: 16px solid ${theme.primary};
  }
`;

const RatesCardHeaderBannerCopy = styled.h6`
  color: ${theme.white};
  font-size: ${rem(9)};
  font-family: ${theme.fontFamilyCircular};
  font-weight: ${theme.circularWeightBold};
  letter-spacing: ${psLetterSpacing(9, 180)};
  line-height: ${psLineHeight(9, 8)};
  margin: ${rem(12)} 0 ${rem(5)};
`;

const RatesCardHeaderBannerStar = styled.img`
  display: block;
  margin: 0 auto;
`;

const RatesCardBody = styled.div`
  padding: ${rem(42)} 0 0;
  
  ${mediaDown.lg`
    width: ${10/12 * 100}%;
    margin: 0 auto;
  `}   

  ${mediaUp.lg`
    padding: ${rem(62)} ${rem(50)} ${rem(42)};
  `}
`;

const RatesCardCurrencyAmount = styled.h2`
  font-family: ${theme.fontFamilyBase};
  font-size: ${rem(42)};
  font-weight: ${theme.avenirWeightMedium};
  letter-spacing: ${psLetterSpacing(42, -10)};
  margin-bottom: ${rem(5)};
  color: ${theme.gray900};
`;

const RatesCardCurrencySymbol = styled.p`
  color: ${theme.gray500};
  font-size: ${rem(12)};
  font-weight: ${theme.avenirWeightHeavy};
  letter-spacing: ${psLetterSpacing(12, 200)};
  margin-bottom: ${rem(30)};
  text-transform: uppercase;
  
  ${mediaUp.lg`
    margin-bottom: ${rem(40)};
  `}
`;

const RatesCardButton = styled(RouterButton)`
  flex-wrap: wrap;
  padding-top: ${rem(19)};
  padding-bottom: ${rem(19)};
  margin-bottom: ${props => props.hasSavings ? rem(15) : rem(40)};
  
  ${mediaUp.lg`
    margin-bottom: ${rem(15)};
  `}
  
  small {
    font-weight: ${theme.avenirWeightHeavy};
    font-size: ${rem(9)};
    margin-bottom: ${rem(3)};
    letter-spacing: ${psLetterSpacing(9, 120)};
  }
  
  span {
    font-size: ${rem(15)};
    letter-spacing: ${psLetterSpacing(15, 100)};
  }
  
  span,
  small {
    flex: 0 0 100%;
    line-height: normal;
  }
`;


const RatesCardFooter = styled.div`
  margin: auto ${rem(20)} 0;
  border-top: 1px solid ${theme.muted};
`;

const RatesCardFooterCopy = styled.p`
  font-family: ${theme.fontFamilyNunito};
  font-size: ${rem(11)};
  line-height: ${psLineHeight(11, 14)};
  letter-spacing: ${psLetterSpacing(11, 0)};
  margin: 0 auto;
  max-width: 242px;
  padding: ${rem(21)} 0 ${rem(24)};
  color: ${theme.gray800};
`;

function RatesCard(props) {
  return (
    <StyledRatesCard>
      <RatesCardHeader>
        {props.bestDeal &&
          <RatesCardHeaderBanner>
            <RatesCardHeaderBannerCopy>
              Top<br />
              Rate
            </RatesCardHeaderBannerCopy>

            <RatesCardHeaderBannerStar src={star} aria-hidden={true} />
          </RatesCardHeaderBanner>
        }

        {props.exchangeLogo
          ?
          <RatesCardHeaderLogo src={process.env.REACT_APP_API_URL + props.exchangeLogo} aria-hidden={true} />
          :
          <RatesCardHeaderTitle>{props.exchangeName}</RatesCardHeaderTitle>
        }
      </RatesCardHeader>

      <RatesCardBody>
        {props.currencyAmount &&
          <RatesCardCurrencyAmount>
            {(props.currencyAmount.toString().length > 8) ? parseFloat(props.currencyAmount.toFixed(8)) : props.currencyAmount}
          </RatesCardCurrencyAmount>
        }

        {props.currencySymbol &&
          <RatesCardCurrencySymbol>
            {props.currencySymbol}
          </RatesCardCurrencySymbol>
        }

        {props.exchangeName &&
          <CoinContext.Consumer>
            {({ setExchange }) => (
              <RatesCardButton secondary={true}
                               block={true}
                               to="/exchange"
                               onClick={() => { setExchange(props.exchangeName, props.exchangeLogo, parseFloat(props.currencyAmount.toFixed(8))) }}
              >
                <small>Swap with</small>
                <span>{props.exchangeName}</span>
              </RatesCardButton>
            )}
          </CoinContext.Consumer>
        }
      </RatesCardBody>

      <RatesCardFooter>
        <RatesCardFooterCopy>
          This rate is a spot rate. It may vary at the time of swapping due to market changes.<br />
          <b>You must log in to swap coins.</b>
        </RatesCardFooterCopy>
      </RatesCardFooter>
    </StyledRatesCard>
  )
}

export default RatesCard;
