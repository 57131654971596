import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, psLetterSpacing, psLineHeight } from "../../globalStyle";

const StyledCompleteStep = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
`;

const CompleteStepBody = styled.div`
  background-color: ${theme.white};
  padding: ${rem(32)} ${rem(27)} ${rem(57)};
  
  ${mediaUp.lg`
    padding: ${rem(54)} ${rem(54)} ${rem(95)};
  `}
`;

const CompleteStepContent = styled.div`
  margin-bottom: ${rem(40)};
`;

const CompleteStepTitle = styled.h2`
  color: ${theme.gray1000};
  margin-bottom: ${rem(10)};
  
  ${mediaUp.lg`
    font-size: ${rem(38)};
    letter-spacing: ${psLetterSpacing(38, -40)};
    line-height: 0.75;
    margin-bottom: ${rem(20)};
  `}
`;

const CompleteStepCopy = styled.p`
  font-size: ${rem(15)};
  letter-spacing: ${psLetterSpacing(15, 10)};
  line-height: ${psLineHeight(15, 15)};
  margin-bottom: ${rem(35)};
  
  ${mediaUp.lg`
    line-height: ${psLineHeight(15, 20)};
    max-width: 587px;
  `}
`;

const CompleteStepLabel = styled.p`
  color: ${theme.gray1000};
  font-size: ${rem(10)};
  font-weight: ${theme.avenirWeightHeavy};
  letter-spacing: ${psLetterSpacing(10, 140)};
  margin-bottom: ${rem(16)};
  line-height: 10px;
  text-transform: uppercase;
  display: block;
`;

const CompleteStepOrderID = styled.p`
  color: ${theme.primary};
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, -10)};
  line-height: normal;
  display: inline-block;
  margin-bottom: 0;
  word-break: break-all;
  
  ${mediaUp.lg`
    font-size: ${rem(20)};
    letter-spacing: ${psLetterSpacing(20, -10)};
  `}
`;

function CompleteStep(props) {
  return (
    <StyledCompleteStep active={props.currentStep === 4}>
      <CompleteStepBody>
        <CompleteStepContent>
          <CompleteStepTitle>Your Order is Complete</CompleteStepTitle>
          <CompleteStepCopy>
            {props.orderID &&
              <React.Fragment>
                <CompleteStepLabel>Order ID</CompleteStepLabel>
                <CompleteStepOrderID>{props.orderID}</CompleteStepOrderID>
              </React.Fragment>
            }<br /><br />
            Thank you for using Swapcoins to swap {props.incomingCurrency} to {props.outgoingCurrency}. See you again soon!
          </CompleteStepCopy>

        </CompleteStepContent>
      </CompleteStepBody>
    </StyledCompleteStep>
  )
}

export default CompleteStep;
