import React from 'react';
import Header from '../Header/Header';
import SwapCardGroup from '../SwapCardGroup/SwapCardGroup';
import Container from '../Container/Container';
import HelpHero from '../HelpHero/HelpHero';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import CountCard from '../CountCard/CountCard';
import RatesMobileToggle from './RatesMobileToggle';
import RatesCard from './RatesCard';
import Loading from '../Loading/Loading';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown } from '../../globalStyle';
import CompareRates from './CompareRates';
import Modal from '../Modal/Report';
import axios from 'axios';

const RatesSwapCardContainer = styled.div`
  background-color: ${theme.white};
  
  ${mediaDown.lg`
    top: ${theme.headerMobileHeight};
    left: 0;
    right: 0;
    
    ${props => props.pastThreshold 
      ?
      css`
        transition: 0.3s transform ease, 0.3s z-index ease;
        transform-origin: top;
        position: fixed;
        opacity: ${props => props.animating ? '0' : '1'};
        
        ${props => props.mobileMenuToggled
          ?
          css`
            transform: translateY(0);
            z-index: 99;
          `
          :
          css`
            transform: translateY(-100%);
            z-index: 75;
          `
        }
      `
      :
      css`
        position: absolute;
      `
    }
  `}
  
  ${mediaUp.lg`
    margin-top: ${theme.headerDesktopHeight};
  `}
`;

const RatesContainer = styled(Container)`
  padding-top: ${rem(4)};
  padding-bottom: ${rem(8)};
  
  ${mediaDown.lg`
    margin-top: 500px;
  `}
  
  ${mediaUp.lg`
    padding: ${rem(36)} 0 ${rem(51)};
  `}
`;

const RatesColumn = styled(GridColumn)`
  margin-bottom: ${rem(30)};
`;

const RatesCardColumn = styled(GridColumn)`
  margin-bottom: ${rem(30)};
    
  ${mediaDown.lg`
    display: none;
  `}
`;

const RatesHistoryButton = styled.button`
  background-color: ${theme.white};
  border: none;
  font-size: ${rem(12)};
  font-family: ${theme.fontFamilyComfortaa};
  &:hover {
    color: ${theme.secondary};
  };

  ${mediaDown.sm`
    margin-left: ${rem(8)};
  `}
`;

class Rates extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pastThreshold: false,
      mobileMenuToggled: false,
      atTop: true,
      animating: false,
      modalActive: false,
      historicalRates: [["Time", "Rate"],[0,0]]
    };

    this.onWindowScroll = this.onWindowScroll.bind(this);
    this.handleMobileMenuToggle = this.handleMobileMenuToggle.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  onWindowScroll() {
    const headerMobileHeight = 80;
    const currentPastThreshold = this.ratesMobileToggleRef.getBoundingClientRect().top < headerMobileHeight;
    const pastBodyOffset = this.ratesContainerRef.getBoundingClientRect().top > this.ratesMobileToggleRef.getBoundingClientRect().bottom;

    if (currentPastThreshold && !pastBodyOffset) {
      this.setState({
        pastThreshold: true,
        mobileMenuToggled: false,
        atTop: false,
        animating: true
      });

      setTimeout(() => {
        this.setState({
          animating: false
        })
      }, 300);
    } else if (pastBodyOffset && this.state.pastThreshold) {
      this.setState({
        pastThreshold: false,
        mobileMenuToggled: false
      });
    } else if (window.scrollY === 0) {
      this.setState({
        pastThreshold: false,
        mobileMenuToggled: false,
        atTop: true
      });
    }
  }

  handleMobileMenuToggle() {
    this.setState({
      mobileMenuToggled: !this.state.mobileMenuToggled
    });
  }

  handleModalOpen() {
    axios.get(`${process.env.REACT_APP_API_URL}/charts/rate/${this.props.incomingCurrency}/${this.props.outgoingCurrency}/1`)
      .then((historicalData) => {
        this.setState({historicalRates: [["Time", "Rate"]].concat(historicalData.data)});
      })
      .catch((error) => {
      });

    this.setState({
      modalActive: true,
    }, () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    });
  }

  handleModalClose() {
    this.setState({
      modalActive: false,
      historicalRates: [["Time", "Rate"],[0,0]]
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Rates" />

        <Header />

        <main>
          <RatesSwapCardContainer pastThreshold={this.state.pastThreshold}
                                  mobileMenuToggled={this.state.mobileMenuToggled}
                                  animating={this.state.animating}
          >
            <Container>
              <SwapCardGroup ratesPage={true} headerOffset={!this.state.mobileMenuToggled} from={this.props.from} to={this.props.to} />
              <RatesHistoryButton onClick={() => {this.handleModalOpen()}}>
                Historical Rates {this.props.incomingCurrency} to {this.props.outgoingCurrency} Past 24 Hours
              </RatesHistoryButton>
            </Container>
          </RatesSwapCardContainer>

          <RatesMobileToggle count={this.props.rates.length}
                             pastThreshold={this.state.pastThreshold}
                             innerRef={ratesMobileToggleRef => {this.ratesMobileToggleRef = ratesMobileToggleRef}}
                             incomingAmount={this.props.incomingAmount}
                             incomingCurrency={this.props.incomingCurrency}
                             outgoingAmount={this.props.outgoingAmount}
                             outgoingCurrency={this.props.outgoingCurrency}
                             mobileMenuToggled={this.state.mobileMenuToggled}
                             handleMobileMenuToggle={this.handleMobileMenuToggle}
                             atTop={this.state.atTop}
          />

          <Loading loading={this.props.ratesLoading}>
            <RatesContainer _ref={ratesContainerRef => {this.ratesContainerRef = ratesContainerRef}}
                            hasMobileGutters={true}
            >
              <GridRow gutterLg="15px">
                <RatesCardColumn lgColumns={4}>
                  <CountCard count={this.props.rates.length < 10 ? `0${this.props.rates.length}` : this.props.rates.length}
                             title="Rates<br /> Found"
                             minHeight="497px"
                  />
                </RatesCardColumn>

                {this.props.rates.map((rate, index) => {
                  return (
                    <RatesColumn lgColumns={4} key={index}>
                      <RatesCard
                        exchangeName={rate.exchange}
                        exchangeLogo={rate.logo_exchange}
                        currencyAmount={rate.rate * this.props.incomingAmount}
                        currencySymbol={rate.to}
                        bestDeal={index === 0}
                      />
                    </RatesColumn>
                  )
                })}
              </GridRow>
            </RatesContainer>
          </Loading>

          <HelpHero overlayColor={theme.bodyBg} />
          <Modal isActive={this.state.modalActive} handleModalClose={this.handleModalClose}>
            <CompareRates incomingCurrency={this.props.from} outgoingCurrency={this.props.to} historicalRates={this.state.historicalRates}/>
          </Modal>
        </main>
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({ rates,
          incomingAmount,
          incomingCurrency,
          incomingLoading,
          outgoingAmount,
          outgoingCurrency,
          outgoingLoading,
          getExchangeAmount,
      }) => (
        <Rates {...props}
               rates={rates}
               incomingAmount={incomingAmount}
               incomingCurrency={incomingCurrency}
               outgoingAmount={outgoingAmount}
               outgoingCurrency={outgoingCurrency}
               ratesLoading={incomingLoading || outgoingLoading}
               getExchangeAmount={getExchangeAmount}
               from={props.match.params.from}
               to={props.match.params.to}
        />
      )}
    </CoinContext.Consumer>
  )
}