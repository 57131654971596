import React from 'react';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp } from '../../globalStyle';

const AboutPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(75)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(172)};
  `}
`;

const AboutContent = styled.div`
  ${mediaUp.lg`
    width: 60%;
    margin: 0 auto;
  `}
`;

const AboutCopy = styled.p`
  strong {
    color: ${theme.tertiary};
    font-weight: ${theme.avenirWeightHeavy};
  }
`;

// const AboutLink = styled.a`
//   color: ${theme.bodyColor};
//
//   &:hover {
//     color: ${theme.bodyColor};
//   }
// `;

function About() {
  return (
    <React.Fragment>
      <React.Fragment>
        <Header />
        <AboutPageContainer backgroundLight={true} headerOffset={true}>
          <PageTitle>About Us</PageTitle>

          <AboutContent>
            <AboutCopy>
              <strong>Swapcoins</strong> is an easy to use cryptocurrency price aggregator that allows customers to trade their coins at some of the most competitive exchange rates available. 
              <br />
              When trading in a volatile market like crypto, ensuring that you’re always getting the best rates in a fast, simple and secure manner can greatly improve your profitability. Our platform scours the market to provide you with live rates from some of the top exchanges allowing you to pick and choose the rates that you want. Over 180 crypto pair combinations to choose from. 
              <br />
              When you’re ready to swap just click the button and you’re done. No need to create accounts at every exchange. No need to question your rates. 
              <br />
              <br />
              <strong>About the Team</strong>
              <br />Swapcoins.com was created by Hexbite Labs Inc, a full-service blockchain development agency established in 2015.  Hexbite specializes in customized, peer-to-peer decentralized apps and full cryptocurrency network solutions.
            </AboutCopy>
          </AboutContent>
        </AboutPageContainer>
      </React.Fragment>
    </React.Fragment>
  )
};

export default About;
