import React from 'react';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import Accordion from '../Accordion/Accordion';
import FAQHero from './FAQHero';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import { rem } from 'polished';
import { mediaUp } from '../../globalStyle';
import destinationTag from '../../assets/images/faq/faq_destination_tag.jpg';
import memo from '../../assets/images/faq/faq_memo.jpg';
import message from '../../assets/images/faq/faq_message.jpg';

const FAQContent = [
  {
    id: 'aboutSwapcoins',
    title: 'About Swapcoins',
    navigationTitle: 'About Swapcoins',
    items: [
      {
        title: 'What is Swapcoins?',
        copy: 'Swapcoins is a cryptocurrency exchange providing the best way to interchange cryptocurrencies by ' +
        'aggregating all leading exchanges and comparing cryptocurrency prices in real time. We support the most popular ' +
        'cryptocurrencies at more than 4 exchanges.'
      },
      {
        title: 'Why should I trust you?',
        copy: 'Swapcoins is built on the most trusted exchanges in the world. We provide a seamless experience to ' +
        'trade and convert cryptocurrency, by integrating with reliable and secure exchanges in the cryptocurrency ' +
        'market and helping our customers get the most competitive rates while ensuring their security.'
      },
      {
        title: 'Can the rates change during the transaction?',
        copy: 'Unlike regular money, cryptocurrency prices are highly volatile, so rates may fluctuate during trade. ' +
        'It may happen that the cryptocurrency you are swapping, exchange rate which you see before the transaction ' +
        'is different (high or low) after transaction gets completed. In that case, the exchanged cryptocurrency ' +
        'quantity which you receive may differ from what you see during the comparison.'
      },
    ]
  },
  {
    id: 'wallets',
    title: 'About Wallets',
    navigationTitle: 'Wallets',
    items: [
      {
        title: 'What’s the cryptocurrency wallet address?',
        copy: 'To exchange cryptocurrencies, you need to have a cryptocurrency wallet address.\n' +
        'A cryptocurrency wallet is a digital place for your coins to be stored. Each coin has a certain wallet ' +
        'provider. That could be official GUI-wallets, web wallets and other applications. The wallet address ' +
        'represents a randomly generated combination of digits and letters. Swapcoins does not provide wallet ' +
        'addresses and does not store your user deposits.'
      },
      {
        title: 'How do I get a cryptocurrency wallet address?',
        copy: 'Figure out what cryptocurrency you would like to buy and seek a stable wallet version. As a rule, ' +
        'each cryptocurrency has its official wallet client. Note that each wallet has its unique address or a tag ' +
        'with a private key that is required to restore your wallet if lost. Swapcoins will never ask for your ' +
        'private keys. Store them in a safe place and do not share with anyone. Once private keys are lost or ' +
        'stolen, your cryptocurrency wallet will be lost forever.'
      },
      {
        title: 'What\'s the recipient’s address?',
        copy: 'In each transaction, there is a sender and a recipient. The recipient’s address is the ' +
        'cryptocurrency wallet address to receive the funds you want to buy. For example, if you buy XRP, in the ' +
        'field, you should specify the XRP wallet address. In general, the recipient’s wallet address is the address ' +
        'we send purchased coins, once a transaction is finished.'
      },
      {
        title: 'What is a hash?',
        copy: 'A crypto hash (or the tx/transaction ID) is a unique address of your transaction in a blockchain. ' +
        'Mostly, the hash is a combination of digits and lower case (upper in XRP) letters that represents a proof ' +
        'that money is sent. Whenever you make a payment, you receive a hash displayed in your wallet. Hash should ' +
        'be located in a blockchain. Our support team may ask you to provide a hash. Sometimes it’s required to find ' +
        'your payment and resolve your issues if any.'
      },
      {
        title: 'Why is my cryptocurrency wallet address recognized as invalid?',
        copy: 'Swapcoins supports most wallets, if your wallet address is recognized as invalid, there is always a ' +
        'reason: Confusion between Dash (DASH) with Dashcoin (DSH). They are two different coins with two different ' +
        'amounts or confusion between Factom (FCT) with Fantomcoin (FCN) which are different coins as well. There ' +
        'could be a typo or character missing. Make sure that the address you specify matches the actual address ' +
        'of your wallet.'
      },
    ]
  },
  {
    id: 'blockchains',
    title: 'About Blockchains',
    navigationTitle: 'Blockchains',
    items: [
      {
        title: 'What is a STEEM memo?',
        copy: '<p>A memo is a combination of digits and letters required to assign STEEM to a certain Steem wallet user. ' +
        'Memos are used in Steem wallets and indicated right here.' +
        'In the wallet, a memo should look like this\n' +
        'If you want to buy Steem, make sure that you\'ve specified the correct memo while putting in a wallet address.\n' +
        'Note: that you have a nickname on steemit.com, you may use it as your wallet address that doesn\'t require a memo.</p>' +
        `<img src="${memo}" />` +
        '<p>If you want to buy Steem, make sure that you\'ve specified the correct memo while putting in a wallet address.</p>' +
        '<p><strong>Note:</strong> that you have a nickname on steemit.com, you may use it as your wallet address that doesn\'t require a memo.</p>'
      },
      {
        title: 'What is a Stellar (XLM) memo?',
        copy: 'When you create a Stellar Lumens (XLM) transaction, a unique ‘Memo’ is also required. ' + 
        'This Memo is an extra piece of information that is included when sending a Lumens transaction.' +
        'The Memo is used to determine what account a given transaction should be assigned and credited to when a single Lumens deposit address is used.' +
        '<p>GA5XIGA5C7QTPTWXQHY6MCJRMTRZDOSHR6EFIBNDQTCQHG262N4GGKTM</p>' +
        'The Memo is numerical and has to be included with all XLM transactions. Example:' +
        '<p>01234567</p>' +
        'Always include the correct Memo before sending your XLM deposit. Leaving out this information will lead to significant delays, and can be irretrievable in certain cases.'
      },
      {
        title: 'What is a destination tag?',
        copy: '<p>A destination tag is a short complementary combination of characters required for certain XRP wallets. ' +
        'While buying XRP, make sure that you have specified a destination tag if required. Likewise, when you send ' +
        'XRP, don\'t forget about the destination tag provided near the address to send to.</p>' +
        `<img src="${destinationTag}" />`
      },
      {
        title: 'What is a message?',
        copy: '<p>The message is an additional tag required for XEM transaction. Whenever you send or receive XEM, make ' +
        'sure that you\'ve input a message. You may find it in your wallet, and it should look like this.</p>' +
        `<img src="${message}" />"`
      },
    ]
  },
  {
    id: 'exchangingCryptocurrency',
    title: 'About Exchanging Cryptocurrency',
    navigationTitle: 'Exchanging Crypto',
    items: [
      {
        title: 'How do I exchange cryptocurrencies on Swapcoins?',
        copy: 'For all available cryptocurrencies on Swapcoins, the exchange process is similar.'
      },
      {
        title: 'What is the minimum/maximum amount?',
        copy: 'Swapcoins does not have transaction amount restrictions. However, there are some nuances.\n' +
        'Amounts need to be large enough to make sure that they cover all the network fees a blockchain takes. ' +
        'Meaning the amount you are going to send and the amount you are going to receive should be enough to cover ' +
        'input and output network fees taken by a blockchain.\n' +
        'If your funds to be exchanged are insufficient, you will see a message.\n' +
        'Please use these recommendations. Otherwise, there is a high risk that your funds will be lost.\n' +
        'The exchanges that partner with Swapcoins may set their own minimum and maximum limits.'
      },
      {
        title: 'What are the network fees?',
        copy: 'Each cryptocurrency is based on a blockchain. The transaction process requires a network fee, which is ' +
        'a commission taken from the amount you and Swapcoins send.  This is why it is important to include the network ' +
        'fees in the amount you send and are going to receive. If the amount is insufficient to cover those fees, the ' +
        'transaction will fail.'
      },
      {
        title: 'When should I receive my money?',
        copy: 'Swapcoins transactions take 15-30 minutes to be processed. If a transaction is large (over 1 BTC), ' +
        'processing may take longer depending on the size of your transaction and blockchain capacity.'
      },
    ]
  },
  {
    id: 'transactions',
    title: 'About Transactions',
    navigationTitle: 'Transactions',
    items: [
      {
        title: 'What is our transaction fees?',
        copy: 'Swapcoins charges a fee of 0% to 0.75% depending on the exchange you select for your transaction. ' +
        'The conversion rate you see is inclusive of the fees. Note: This fees does not include any amount charged by ' +
        'the exchange itself and the network fee.'
      },
      {
        title: 'How do I cancel my transaction?',
        copy: 'Blockchain transactions are irreversible. Once the money is sent, it cannot be rolled back. So if ' +
        'you are going to swap cryptocurrencies, think twice and double check all payment details prior to sending money.'
      },
      {
        title: 'Why does my transaction take so long?',
        copy: 'Our transactions generally take between 15-30 minutes to be processed. If your transaction takes longer, this might be due to a wide range of factors.\n' +
        '1. Congestion on the blockchain, there are numerous transactions be processed including yours. This type of issue takes place on all blockchains. All you have to do is to wait. Swapcoins does not control processing delays.\n' +
        '2. DDoS attacks. Any platform may experience such issues. In this case, all you have to do is wait.'
      },
      {
        title: 'Why is my transaction still waiting for payment if I\'ve already paid?',
        copy: 'There could be several reasons.\n' +
        'The transaction has not been included to a block. Cryptocurrencies are unstable at times, so minor errors might occur. We can either refund money or push a payment through if you provide the hash (transaction ID) of your transaction.\n' +
        'ETC and ETH confusion. The addresses of Ethereum Classic (ETC)  and Ethereum (ETH) are of the same structure. If you send ETC or ETH, make sure that you have created the appropriate transaction on Swapcoins. Example, if you create an ETH to BTC transaction, make sure that you send ETH, not ETC. Otherwise, your transaction will get stuck.\n' +
        'A wrong XEM message. When sending XEM, make sure that you put in a correct message. It\'s indicated here and looks like a combination of digits and letters. Worded messages such as “I love crypto” do not work.\n' +
        'Internal errors. If you suppose that this is the case, please report it to support@swapcoins.com'
      },
      {
        title: 'What will happen if I close the browser tab?',
        copy: 'If you have completed the transfer process, then you can surely close the browser tab. Our system ' +
        'automatically completes the transaction, and the amount is deposited into the recipient wallet address. ' +
        'Please keep the order-id in your records if you require a follow up'
      },
      {
        title: 'Sent funds to the wrong address. Can I recover my funds?',
        copy: 'Unfortunately not. Once funds are sent to a wrong address, there is nothing can be done to recover ' +
        'those funds. This is why it is extremely important to double check you have the correct addresses for ' +
        'the cryptocurrencies you are swapping when completing your transaction.'
      },
    ]
  },
  {
    id: 'referrals',
    title: 'About Referrals',
    navigationTitle: 'Referrals',
    items: [
      {
        title: 'How do I participate in the referral program?',
        copy: 'First, you must create an account. All account hodlers are automatically enrolled.'
      },
      {
        title: 'How do I refer a friend?',
        copy: 'In your account, there is a referral link. ' + 
        'You simply send your friends the referral link. When they sign up via the referral link, you are automatically tagged with a referral.'
      },
      {
        title: 'How do I accumulate my referral fee?',
        copy: 'You will accumulate $5 worth of BTC the first time the person you refer swaps coins worth $100 or more.'
      },
      {
        title: 'When will I receive my referral fee?',
        copy: 'Referral fees are paid out after you have accumulated 5 referrals. ' + 
        'Our customer service department will contact you for an address to deposit the equivalent of $25 worth of BTC.' +
        'They usually will take 1 week to confirm all the necessary information.'
      },
      {
        title: 'Where can I see the number of people I have referred?',
        copy: 'Within your account, you can see your number of referrals under the Referrals heading.'
      },
    ]
  }
];

const FAQPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(79)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(139)};
  `}
`;

function FAQ() {
  return (
    <React.Fragment>
      <Meta title="FAQ & Support" />
      
      <Header />

      <FAQPageContainer backgroundLight={true} headerOffset={true}>
        <PageTitle>FAQ &amp; Support</PageTitle>

        <Accordion content={FAQContent} />
      </FAQPageContainer>

      <FAQHero />
    </React.Fragment>
  )
}

export default FAQ;