import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing, unstyledButton } from "../../globalStyle";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import clipboard from '../../assets/images/clipboard_secondary.png';
import QRCode from 'qrcode.react';

const StyledSendStep = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
`;

const SendStepBody = styled.div`
  background-color: ${theme.white};
  padding: ${rem(32)} ${rem(27)} ${rem(26)};

  ${mediaUp.lg`
    padding: ${rem(54)} ${rem(54)} ${rem(32)};
  `}
`;

const SendStepRow = styled.div`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const SendStepContent = styled.div`
  ${mediaUp.lg`
    flex: 1;
  `}

  ${mediaDown.lg`
    margin-bottom: ${rem(32)};
  `}
`;

const SendStepQRWrapper = styled.div`
  width: 274px;
  max-width: 100%;
  text-align: center;

  ${mediaDown.lg`
    margin: 0 auto;
  `}

  ${mediaUp.lg`
    flex: 0 0 190px;
    max-width: 190px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  `}
`;

const SendStepStatus = styled.p`
  @keyframes status-color-switch {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }

  color: ${theme.primary};
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(13)};
  letter-spacing: ${psLetterSpacing(13, -10)};
  margin-bottom: 0;
  line-height: normal;
  margin-top: ${rem(15)};
  animation: status-color-switch 2s infinite;

  ${mediaUp.lg`
    margin-top: auto;
  `}
`;

const SendStepTitle = styled.h2`
  color: ${theme.gray1000};
  margin-bottom: ${rem(10)};

  ${mediaUp.lg`
    font-size: ${rem(38)};
    letter-spacing: ${psLetterSpacing(38, -40)};
    line-height: 0.75;
    margin-bottom: ${rem(20)};
  `}
`;

const SendStepCopy = styled.p`
  font-size: ${rem(15)};
  letter-spacing: ${psLetterSpacing(15, 10)};
  line-height: 15px;
  margin-bottom: ${rem(35)};

  ${mediaUp.lg`
    line-height: 20px;
  `}
`;

const SendStepLabel = styled.p`
  color: ${theme.gray1000};
  font-size: ${rem(10)};
  font-weight: ${theme.avenirWeightHeavy};
  letter-spacing: ${psLetterSpacing(10, 140)};
  margin-bottom: ${rem(16)};
  line-height: 10px;
  text-transform: uppercase;
  display: block;
`;

const SendStepAddressWrapper = styled.div`
  display: flex;
  margin-bottom: ${rem(25)};

  ${mediaUp.lg`
    align-items: center;
    margin-bottom: ${rem(60)};
  `}
`;

const SendStepAddress = styled.p`
  color: ${theme.primary};
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, -10)};
  line-height: normal;
  display: inline-block;
  margin-bottom: 0;
  word-break: break-all;


  ${mediaUp.lg`
    font-size: ${rem(20)};
    letter-spacing: ${psLetterSpacing(20, -10)};
  `}
`;

const SendStepClipboardButton = styled.button`
  flex: 0 0 20px;
  max-width: 20px;
  margin-left: ${rem(45)};

  ${mediaUp.lg`
    margin-left: ${rem(24)};
  `}

  ${mediaDown.lg`
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  `}

  ${unstyledButton}
`;

const SendStepClipboardIcon = styled.img`
  width: 100%;
`;

const SendStepNote = styled.p`
  font-size: ${rem(15)};
  letter-spacing: ${psLetterSpacing(15, 0)};
  color: ${theme.gray700};
  margin-bottom: 0;
  line-height: normal;
`;

const SendStepFooter = styled.div`
  padding: ${rem(35)} 0 0;

  ${mediaUp.lg`
    padding: ${rem(30)} ${rem(54)} 0;
  `}
`;

const SendStepFooterCopy = styled.p`
  font-size: ${rem(15)};
  letter-spacing: ${psLetterSpacing(15, 0)};
  color: ${theme.gray700};
  line-height: 18px;
  margin-bottom: 0;
`;

let resize;

class SendStep extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      qrWidth: 0
    };

    this.handleResize = this.handleResize.bind(this);
    this.throttleResize = this.throttleResize.bind(this);
  }

  componentDidMount() {
    this.setState({
      qrWidth: this.qrWrapperRef.offsetWidth
    });

    window.addEventListener('resize', this.throttleResize);
  }

  componentDidUpdate() {
    this.setState({
      qrWidth: this.qrWrapperRef.offsetWidth
    });

    if (this.props.orderID) {
      this.props.handleOrderStatus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttleResize);
  }

  throttleResize() {
    clearTimeout(resize);
    resize = setTimeout(this.handleResize, 100);
  }

  handleResize() {
    this.setState({
      qrWidth: this.qrWrapperRef.offsetWidth
    });
  }

  render() {
    let extraTagName = '';
    let extraTagQRName = ''
    if (this.props.incomingCurrency === 'XRP' || this.props.incomingCurrency === 'STEEM') {
      extraTagName = 'Destination Tag';
      extraTagQRName = '&dt=' + this.props.incomingAddressExtra;
    } else if (this.props.incomingCurrency === 'XLM' || this.props.incomingCurrency === 'EOS') {
      extraTagName = 'Memo ID';
      extraTagQRName = '&memo=' + this.props.incomingAddressExtra;
    } else if (this.props.incomingCurrency === 'XMR') {
      extraTagName = 'Payment ID';
      extraTagQRName = '&payment=' + this.props.incomingAddressExtra;
    }

    return (
      <StyledSendStep active={this.props.currentStep === 2}>
        <SendStepBody>
          <SendStepRow>
            <SendStepContent>
              {(this.props.incomingAmount && this.props.incomingCurrency) &&
              <SendStepTitle>Send {this.props.incomingAmount} {this.props.incomingCurrency} to the Address Below</SendStepTitle>
              }

              <SendStepCopy>Thank you for using Swapcoins.com.<br />
              You are almost done... Exchange will automatically initiate once deposit is detected at the below address.
              </SendStepCopy>

              {(this.props.incomingCurrency || this.props.incomingName) &&
              <SendStepLabel>{this.props.incomingName ? this.props.incomingName : this.props.incomingCurrency} Address</SendStepLabel>
              }

              {this.props.incomingAddress &&
              <SendStepAddressWrapper>
                <SendStepAddress>{this.props.incomingAddress}</SendStepAddress>
                <CopyToClipboard text={this.props.incomingAddress}>
                  <SendStepClipboardButton>
                    <SendStepClipboardIcon src={clipboard} alt="Copy to clipboard" />
                  </SendStepClipboardButton>
                </CopyToClipboard>
              </SendStepAddressWrapper>
              }
              {extraTagName && this.props.incomingAddressExtra && <SendStepLabel>{extraTagName}</SendStepLabel>}
              {this.props.incomingAddressExtra &&
                <SendStepAddressWrapper>
                  <SendStepAddress>{this.props.incomingAddressExtra}</SendStepAddress>
                  <CopyToClipboard text={this.props.incomingAddressExtra}>
                    <SendStepClipboardButton>
                      <SendStepClipboardIcon src={clipboard} alt="Copy to clipboard" />
                    </SendStepClipboardButton>
                  </CopyToClipboard>
                </SendStepAddressWrapper>
              }
              {extraTagName && this.props.incomingAddressExtra && <SendStepCopy>It is critical to include the {extraTagName} when sending your {this.props.incomingCurrency}. Failure to do so may result in a failed transaction or lost/held funds.</SendStepCopy>}

              <SendStepNote>Copy &amp; paste this address to your wallet or scan the QR code via your wallet app.</SendStepNote>
            </SendStepContent>

            <SendStepQRWrapper innerRef={ref => { this.qrWrapperRef = ref}}>
              <QRCode value={`${this.props.incomingCurrency.toLowerCase()}:${this.props.incomingAddress}?amount=${this.props.incomingAmount}${extraTagQRName}`} size={this.state.qrWidth} />

              <SendStepStatus>Ready to Receive Transfer</SendStepStatus>
              <SendStepStatus>
              {this.props.authenticated && <a href="/account/order-history">Order History</a>}
              {!this.props.authenticated && <a href="/track-order">Track Order</a>}
              </SendStepStatus>
                
            </SendStepQRWrapper>

          </SendStepRow>
        </SendStepBody>

        {this.props.orderID && this.props.outgoingCurrency && this.props.outgoingAddress &&
        <SendStepFooter>
          <SendStepFooterCopy>
            Order Id: {this.props.orderID}<br />
            Recipient Address ({this.props.outgoingCurrency}): {this.props.outgoingAddress}
          </SendStepFooterCopy>
        </SendStepFooter>
        }
      </StyledSendStep>
    )
  }
}

export default SendStep;
