import React from 'react';
import styled, { css } from 'styled-components';
import { theme, mediaUp } from '../../globalStyle';

const Row = styled.div`
  display: flex;
  flex-wrap: ${props => props.flexWrap ? `${props.flexWrap}` : 'wrap'};
  
  ${props => props.flexDirection && css`
    flex-direction: ${props.flexDirection};
  `}
  
  ${props => props.alignItems && css`
    align-items: ${props.alignItems};
  `}
  
  ${props => props.justifyContent && css`
    justify-content: ${props.justifyContent};
  `}
  
  ${props => !props.noGutters && css`
    ${props.gutter
      ? css`
        margin-left: -${props.gutter};
        margin-right: -${props.gutter};
        
        > div {
          padding-left: ${props.gutter};
          padding-right: ${props.gutter};
        }
      `
      : css`
        margin-left: -${theme.gridGutter};
        margin-right: -${theme.gridGutter};
        
        > div {
          padding-left: ${theme.gridGutter};
          padding-right: ${theme.gridGutter};
        }
      `
    }
    
    ${props.gutterSm && mediaUp.sm`
      margin-left: -${props.gutterSm};
      margin-right: -${props.gutterSm};
      
      > div {
        padding-left: ${props.gutterSm};
        padding-right: ${props.gutterSm};
      }
    `}
    
    ${props.gutterMd && mediaUp.md`
      margin-left: -${props.gutterMd};
      margin-right: -${props.gutterMd};
      
      > div {
        padding-left: ${props.gutterMd};
        padding-right: ${props.gutterMd};
      }
    `}
    
    ${props.gutterLg && mediaUp.lg`
      margin-left: -${props.gutterLg};
      margin-right: -${props.gutterLg};
      
      > div {
        padding-left: ${props.gutterLg};
        padding-right: ${props.gutterLg};
      }
    `}
    
    ${props.gutterXl && mediaUp.xl`
      margin-left: -${props.gutterXl};
      margin-right: -${props.gutterXl};
      
      > div {
        padding-left: ${props.gutterXl};
        padding-right: ${props.gutterXl};
      }
    `}
  `}
`;

const GridRow = (props) => {
  return (
      <Row flexWrap={props.flexWrap}
           flexDirection={props.flexDirection}
           alignItems={props.alignItems}
           justifyContent={props.justifyContent}
           gutter={props.gutter}
           gutterSm={props.gutterSm}
           gutterMd={props.gutterMd}
           gutterLg={props.gutterLg}
           gutterXl={props.gutterXl}
           noGutters={props.noGutters}
      >
        {props.children}
      </Row>
  )
};

export default GridRow;
