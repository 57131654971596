import React from 'react';
import styled, { css } from 'styled-components';
import { rem, placeholder } from 'polished';
import { theme, mediaDown, mediaUp, psLetterSpacing, psLineHeight } from "../../globalStyle";
import CoinCard from './CoinCard';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import ScreenReaderText from '../ScreenReaderText/ScreenReaderText';
import ReactLoading from 'react-loading';
import close from '../../assets/images/close.svg';

// rates page utilizes transforms for animations and any child element that has a transform
// parent changes the functionality of position: fixed and makes it act as
// an absolutely position element rather than a fixed element.
const StyledCoinDropdown = styled.div`
  position: fixed;
  top: ${props => !props.headerOffset && props.ratesPage ? `-${theme.headerMobileHeight}` : '0'};
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: ${theme.white};
  z-index: ${theme.dropdownZIndex};
  transition: 0.3s opacity ease;
  overflow: auto;
  
  ${mediaDown.lg`
    -webkit-overflow-scrolling: touch;
  `}
  
  ${mediaUp.lg`
    top: 0;
    height: 100%;
  `}
  
  ${props => props.dropdownActive
    ? css`
      pointer-events: auto;
      opacity: 1;
    `
    : css`
      pointer-events: none;
      opacity: 0;
    `
  }
`;

const CoinDropdownBody = styled.div`
  padding: ${rem(102)} ${rem(32)} ${rem(32)} ${rem(32)};
  opacity: ${props => props.loading ? '0' : '1'};
  
  ${mediaDown.lg`
    position: relative;
  `}
    
  ${mediaUp.lg`
    padding: ${rem(54)};
  `}
`;

const CoinDropdownHeader = styled.div`
  background-color: ${theme.primary};
`;

const CoinDropdownDesktopHeader = CoinDropdownHeader.extend`
  padding: ${rem(20)} ${rem(54)};
  position: relative;
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const CoinDropdownMobileHeader = CoinDropdownHeader.extend`
  padding: ${rem(20)} ${rem(25)};
  position: fixed;
  top: ${props => !props.headerOffset && props.ratesPage ? `-${theme.headerMobileHeight}` : '0'};
  left: 0;
  right: 0;
  z-index: 1;
  
  ${mediaUp.lg`
    display: none;
  `}
`;

const CoinDropdownClose = styled.button`
  background-color: ${theme.tertiary};
  border: none;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 85px;
  transition: 0.3s opacity ease;
  
  &:hover {
    opacity: 0.7;
  }
  
  &:focus {
    outline: none;
  }
`;

const CoinDropdownCloseIcon = styled.img``;

const CoinDropdownSearch = styled.input`
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(18)};
  line-height: ${psLineHeight(18, 20)};
  letter-spacing: ${psLetterSpacing(18, -10)};
  background-color: transparent;
  border: none;
  color: ${theme.white};
  display: block;
  width: 100%;
  ${placeholder({'color': theme.muted})}
  
  &:focus {
    outline: none;
  }
`;

const CoinDropdownDesktopSearch = CoinDropdownSearch.extend`
  ${mediaDown.lg`
    display: none;
  `}
`;

const CoinDropdownMobileSearch = CoinDropdownSearch.extend`
  ${mediaUp.lg`
    display: none;
  `}
`;

const CoinDropdownColumn = styled(GridColumn)`
  margin-bottom: ${rem(12)};
  
  &:last-of-type,
  &:nth-last-of-type(2) {
    margin-bottom: 0;
  }
  
  ${mediaUp.lg`
    margin-bottom: ${rem(30)};
    
    &:nth-last-of-type(3)
    &:nth-last-of-type(4) {
      margin-bottom: 0;
    }
  `}
`;

const CoinDropdownLoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.3s opacity ease;
  
  ${props => props.loading
  ? css`
      opacity: 1;
    `
  : css`
      pointer-events: none;
    `
  }
`;

const CoinDropdownTitle = styled.h1`
  color: ${theme.tertiary};
  
  span {
    color: ${theme.white};
  }
`;

class CoinDropdown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dropdownSearch: '',
      loading: false,
      filteredCoinsList: []
    };

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleSearchInputClear = this.handleSearchInputClear.bind(this);
    this.dropdown = React.createRef();
    this.timer = null;
  }

  componentDidMount() {
    let _clientY = null; // remember Y position on touch start
    const overlay = this.dropdown;

    function isOverlayTotallyScrolled() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
      return overlay.scrollHeight - overlay.scrollTop <= overlay.clientHeight;
    }

    function disableRubberBand(event) {
      const clientY = event.targetTouches[0].clientY - _clientY;

      if (overlay.scrollTop === 0 && clientY > 0) {
        // element is at the top of its scroll
        event.preventDefault();
      }

      if (isOverlayTotallyScrolled() && clientY < 0) {
        //element is at the top of its scroll
        event.preventDefault();
      }
    }

    overlay.addEventListener('touchstart', event => {
      if (event.targetTouches.length === 1) {
        // detect single touch
        _clientY = event.targetTouches[0].clientY;
      }
    }, false);

    overlay.addEventListener('touchmove', event => {
      if (event.targetTouches.length === 1) {
        // detect single touch
        disableRubberBand(event);
      }
    }, false);
  }

  handleSearchInputChange(event) {
    const searchValue = event.target.value;
    const searchName = event.target.name;

    clearTimeout(this.timer);

    this.setState({
      [searchName]: searchValue,
      loading: true
    });

    this.timer = setTimeout(() => {
      if (searchValue.length) {
        let filteredCoinsList = [...this.props.coinsList];

        filteredCoinsList = filteredCoinsList.filter((coin) => {
          // if search input contains name or currency symbol
          if (coin.name) {
            return coin.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              || coin.coin.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
          }

          return coin.coin.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
        });

        this.setState({
          loading: false,
          filteredCoinsList: filteredCoinsList
        });
      } else {
        this.setState({
          loading: false,
          filteredCoinsList: []
        });
      }
    }, 500);
  }

  handleSearchInputClear() {
    this.setState({
      dropdownSearch: '',
      loading: false,
      filteredCoinsList: []
    });
  }

  render() {
    return (
      <StyledCoinDropdown dropdownActive={this.props.dropdownActive}
                          headerOffset={this.props.headerOffset}
                          ratesPage={this.props.ratesPage}
                          innerRef={dropdown => {this.dropdown = dropdown}}
      >
        <CoinDropdownDesktopHeader>
          <CoinDropdownDesktopSearch
            name="dropdownSearch"
            type="text"
            placeholder={`Search ${this.props.coinsList.length} coins by asset name or symbol`}
            value={this.state.dropdownSearch}
            onChange={this.handleSearchInputChange}
            innerRef={desktopSearchRef => { this.desktopSearchRef = desktopSearchRef }}
          />

          <CoinDropdownClose
            type="button"
            onClick={() => {this.props.handleDropdownToggle(); this.handleSearchInputClear();}}
          >
            <CoinDropdownCloseIcon src={close} aria-hidden="true" />

            <ScreenReaderText>Close Coin Select</ScreenReaderText>
          </CoinDropdownClose>
        </CoinDropdownDesktopHeader>

        <CoinDropdownBody loading={this.props.loading || this.state.loading}>
          <CoinDropdownMobileHeader headerOffset={this.props.headerOffset} ratesPage={this.props.ratesPage}>
            <CoinDropdownMobileSearch
              name="dropdownSearch"
              type="text"
              placeholder={`Search ${this.props.coinsList.length} coins`}
              value={this.state.dropdownSearch}
              onChange={this.handleSearchInputChange}
              innerRef={mobileSearchRef => { this.mobileSearchRef = mobileSearchRef }}
            />

            <CoinDropdownClose
              type="button"
              onClick={() => {this.props.handleDropdownToggle(); this.handleSearchInputClear();}}
            >
              <CoinDropdownCloseIcon src={close} aria-hidden="true" />

              <ScreenReaderText>Close Coin Select</ScreenReaderText>
            </CoinDropdownClose>
          </CoinDropdownMobileHeader>
          <CoinDropdownTitle>Choose a coin to swap</CoinDropdownTitle>
          <GridRow gutter="0px">
            {this.state.filteredCoinsList.length ?
              this.state.filteredCoinsList.map((coin, index) => {
                return (
                  <CoinDropdownColumn columns={3} smColumns={2} mdColumns={2} lgColumns={1.5} xlColumns={1.5} key={index}>
                    <CoinCard
                      currency={coin.coin}
                      logo={process.env.REACT_APP_API_URL + coin.logo}
                      name={coin.name}
                      isActive={coin.coin === this.props.activeCurrency}
                      handleDropdownSelect={this.props.handleDropdownSelect}
                      handleSearchInputClear={this.handleSearchInputClear}
                      mobileLogoWidth={coin.mobileLogoWidth}
                    />
                  </CoinDropdownColumn>
                )
              })
              :
              this.props.coinsList.map((coin, index) => {
                return (
                  <CoinDropdownColumn columns={3} smColumns={2} mdColumns={2} lgColumns={1.5} xlColumns={1.5} key={index}>
                    <CoinCard
                      currency={coin.coin}
                      logo={process.env.REACT_APP_API_URL + coin.logo}
                      name={coin.name}
                      isActive={coin.coin === this.props.activeCurrency}
                      handleDropdownSelect={this.props.handleDropdownSelect}
                      handleSearchInputClear={this.handleSearchInputClear}
                    />
                  </CoinDropdownColumn>
                )
              })
            }
          </GridRow>
        </CoinDropdownBody>

        <CoinDropdownLoadingWrapper loading={this.props.loading || this.state.loading}>
          <ReactLoading type="spin" color={theme.primary} />
        </CoinDropdownLoadingWrapper>
      </StyledCoinDropdown>
    )
  }
}

export default CoinDropdown;
