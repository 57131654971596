import React from 'react';
import Header from '../Header/Header';
import HelpHero from '../HelpHero/HelpHero';
import Button from '../Button/Button';
import FileButton from '../Button/FileButton';
import { TextInput, TextInputLabel } from '../Input/TextInput';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import PageTitle from '../Page/PageTitle';
import Container from '../Container/Container';
import AccountHeader from './AccountHeader';
import Loading from '../Loading/Loading';
import Alert from '../Alert/Alert';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import axios from 'axios';
import 'formdata-polyfill';
import { rem } from 'polished';
import { inputStyles } from '../../globalStyle';
import { theme, mediaUp, psLetterSpacing, psLineHeight } from "../../globalStyle";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

const AccountWrapper = styled.div`
  background-color: ${theme.white};
  padding-bottom: ${rem(100)};
`;

const AccountTitle = styled(PageTitle)`
  padding: ${rem(52)} 0;
`;

const AccountBody = styled.div`
  ${mediaUp.lg`
    width: 60%;
    margin: 0 auto;
  `}
`;

const AccountButton = styled(Button)`
  margin-top: ${rem(10)};

  ${mediaUp.md`
    width: 270px;
    margin: ${rem(40)} auto 0;
  `}
`;

const AccountUploadHeader = styled.div`
  text-align: center;
  margin: ${rem(55)} 0 ${rem(24)};
`;

const AccountUploadTitle = styled.h2`
  font-size: ${rem(22)};
  margin-bottom: ${rem(4)};
  color: ${theme.gray900};
  letter-spacing: ${psLetterSpacing(22, -40)};
`;

const AccountUploadCopy = styled.p`
  font-size: ${rem(15)};
  line-height: ${psLineHeight(15, 21)};
  margin-bottom: ${rem(30)};

  ${mediaUp.md`
    width: 482px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const AccountUploadButton = styled(FileButton)`
  margin-bottom: ${rem(20)};
`;

const AccountAlert = styled(Alert)`
  text-align: left;
  margin-bottom: 1rem;
`;


const CountryDropdownStyled = styled(CountryDropdown)`
  ${inputStyles}
  background-color:${theme.white};
  -webkit-appearance: none;
`;

const RegionDropdownStyled = styled(RegionDropdown)`
  ${inputStyles}
  background-color:${theme.white};
  -webkit-appearance: none;
`;

class Account extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      formLoading: false,
      firstName: '',
      lastName: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      fileName: null,
      errors: [],
      hasSuccess: false,
      hasError: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
  }

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/user/profile`, { headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
      })
        .then((response) => {
          const user = response.data[0];

          this.setState({
            loading: false,
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
            address: user.address,
            city: user.city,
            state: user.state,
            zip: user.zip,
            country: user.country,
            fileName: user.filename,
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.setState({
              loading: false
            });

            this.props.logoutUser();
            this.props.history.push('/login');
          }
        });
    }
  }

  selectCountry(value) {
    this.setState({
      country: value,
    });
  }

  selectRegion(value) {
    this.setState({
      state: value,
    });
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    let errors = [];
    event.preventDefault();

    const params = {
      firstname: data.get('firstname'),
      lastname: data.get('lastname'),
      email: data.get('email'),
      address: data.get('address'),
      city: data.get('city'),
      state: data.get('state'),
      zip: data.get('zip'),
      country: data.get('country'),
    };

    if (params.firstname.length < 1 || params.firstname.length > 64) {
      errors.push('First name must be between 1 to 64 characters');
    }

    if (/[^a-zA-Z0-9 ]/.test(params.firstname)) {
      errors.push('First name cannot contain symbols.');
    }

    if (params.lastname.length < 1 || params.lastname.length > 64) {
      errors.push('Last name must be between 1 to 64 characters');
    }

    if (/[^a-zA-Z0-9 ]/.test(params.lastname)) {
      errors.push('Last name cannot contain symbols.');
    }

    if (params.address.length < 1 || params.address.length > 255) {
      errors.push('Address must be between 1 to 255 characters');
    }

    if (params.city.length < 1 || params.city.length > 255) {
      errors.push('City must be between 1 to 255 characters');
    }

    if (/[^a-zA-Z0-9 ]/.test(params.city)) {
      errors.push('City cannot contain symbols.');
    }

    if (params.state.length < 1 || params.state.length > 255) {
      errors.push('State must be between 1 to 255 characters');
    }

    if (params.zip.length < 1 || params.zip.length > 16) {
      errors.push('Zip must be between 1 to 16 characters');
    }

    if (/[^a-zA-Z0-9 ]/.test(params.zip)) {
      errors.push('Zip cannot contain symbols.');
    }

    if (params.country.length < 1 || params.country.length > 64) {
      errors.push('Country must be between 1 to 64 characters');
    }

    this.setState({
      hasError: false,
      hasSuccess: false,
      formLoading: true
    });

    if (errors.length) {
      this.setState({
        errors: errors,
        formLoading: false,
        hasError: true
      });
    } else {
      const config = {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      };

      axios.post(`${process.env.REACT_APP_API_URL}/user/profile`, data, config)
        .then(() => {
          this.setState({
            formLoading: false,
            hasSuccess: true,
            hasError: false
          });
        })
        .catch((error) => {
          this.setState({
            formLoading: false,
            errors: [`There was an error with your request (${error.response.data.error}). Please try again.`],
            hasError: true
          });
        });

        window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Profile" />

        <Header />

        <AccountHeader />

          <AccountWrapper>
            <Loading loading={this.state.loading}>
              <Container hasMobileGutters={true}>
                <AccountTitle>My Profile</AccountTitle>

                <AccountBody>
                  <AccountAlert show={this.state.hasSuccess} success={true}>
                    Your profile has been successfully updated.
                  </AccountAlert>
                  <AccountAlert show={this.state.hasError} danger={true}>
                    {this.state.errors.map((error, index) => {
                      return (
                        <React.Fragment key={index}>
                          {error}{index !== (this.state.errors.length - 1)
                          ?
                          <React.Fragment>
                            <br />
                            <br />
                          </React.Fragment>
                          :
                          <React.Fragment />
                        }
                        </React.Fragment>
                      )
                    })}
                  </AccountAlert>

                  <form onSubmit={this.handleSubmit}>
                    <GridRow gutterMd={rem(10)}>
                      <GridColumn mdColumns={6}>
                        <TextInput type="text"
                                   name="firstname"
                                   hasLabel={true}
                                   placeholder="First Name"
                                   initialValue={this.state.firstName}
                                   required={true}
                        />
                      </GridColumn>

                      <GridColumn mdColumns={6}>
                        <TextInput type="text"
                                   name="lastname"
                                   hasLabel={true}
                                   placeholder="Last Name"
                                   initialValue={this.state.lastName}
                                   required={true}
                        />
                      </GridColumn>

                      <GridColumn mdColumns={12}>
                        <TextInput type="text"
                                   name="address"
                                   hasLabel={true}
                                   placeholder="Address"
                                   initialValue={this.state.address}
                                   required={true}
                        />
                      </GridColumn>

                      <GridColumn mdColumns={6}>
                        <TextInput type="text"
                                   name="city"
                                   hasLabel={true}
                                   placeholder="City"
                                   initialValue={this.state.city}
                                   required={true}
                        />
                      </GridColumn>

                      <GridColumn mdColumns={6}>
                        <TextInput type="text"
                                   name="zip"
                                   hasLabel={true}
                                   placeholder="Zip/Postal Code"
                                   initialValue={this.state.zip}
                                   required={true}
                        />
                      </GridColumn>

                      <GridColumn mdColumns ={6}>
                        <TextInputLabel for="country" hasLabel={true}>Country</TextInputLabel>
                        <CountryDropdownStyled
                          name="country"
                          value={this.state.country}
                          onChange={(val) => this.selectCountry(val)}
                        />
                      </GridColumn>

                      <GridColumn mdColumns ={6}>
                        <TextInputLabel for="region" hasLabel={true}>State</TextInputLabel>
                        <RegionDropdownStyled
                          name="state"
                          country={this.state.country}
                          value={this.state.state}
                          defaultOptionLabel="Select State"
                          onChange={(val) => this.selectRegion(val)}
                        />
                      </GridColumn>

                      <GridColumn mdColumns={12}>
                        <TextInput type="email"
                                   name="email"
                                   hasLabel={true}
                                   placeholder="Email"
                                   initialValue={this.state.email}
                                   value={this.state.email}
                                   required={true}
                        />
                      </GridColumn>
                    </GridRow>

                    <AccountUploadHeader>
                      <AccountUploadTitle>Increase Your Swap Limits</AccountUploadTitle>
                      <AccountUploadCopy>
                      For higher Swap limits, please complete this <a
                        href={`${process.env.REACT_APP_API_URL}/images/Swap_limit_increase_application_form.docx`}
                      >form</a> and e-mail it to <a href="mailto:support@swapcoins.com">support@swapcoins.com</a>.
                      </AccountUploadCopy>
                    </AccountUploadHeader>

                    <AccountUploadHeader>
                      <AccountUploadTitle>Identity Verification</AccountUploadTitle>
                      <AccountUploadCopy>
                        Please submit an image of a phone bill, drivers license, or passport to verify
                        your identity and increase your transaction limits.
                      </AccountUploadCopy>
                    </AccountUploadHeader>

                    <AccountUploadButton name="identification" fileName={this.state.fileName} />

                    <AccountButton arrow={true}
                                   secondary={true}
                                   block={true}
                                   loading={this.state.formLoading}
                    >
                      Save
                    </AccountButton>
                  </form>
                </AccountBody>
              </Container>
            </Loading>
          </AccountWrapper>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <Account {...props}
                 authenticated={authenticated}
                 authenticationLoading={authenticationLoading}
                 logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};

