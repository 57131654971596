import React from 'react';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp } from '../../globalStyle';

const SitemapPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(75)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(172)};
  `}
`;

const SitemapContent = styled.div`
  ${mediaUp.lg`
    width: 60%;
    margin: 0 auto;
  `}
`;

const SitemapCopy = styled.p`
  strong {
    color: ${theme.tertiary};
    font-weight: ${theme.avenirWeightHeavy};
  }
`;

const SitemapLink = styled.a`
  color: ${theme.bodyColor};
   &:hover {
     color: ${theme.bodyColor};
   }
`;

function Sitemap() {
  return (
    <React.Fragment>
      <React.Fragment>
        <Header />
        <SitemapPageContainer backgroundLight={true} headerOffset={true}>
          <PageTitle>Sitemap</PageTitle>

          <SitemapContent>
            <SitemapCopy>
              <ul>
                <li><a href={process.env.REACT_APP_API_URL + "/"}>Home</a><br />Cryptocurrency trading made easy <br />Compare prices &amp; swap at the most competitive rates. No wasted or lost opportunities!</li>
                <li><a href={process.env.REACT_APP_API_URL + "/faq"}>FAQ</a><br />Swapcoins is a cryptocurrency exchange providing the best way to interchange cryptocurrencies by aggregating all leading exchanges and comparing cryptocurrency prices in real time. We support the most popular cryptocurrencies at more than 4 exchanges.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/terms-and-conditions"}>Terms and Conditions</a><br />By using the Website and further by accepting to avail the services offered by Swapcoins.com ("Service"), You are hereby agreeing to accept and comply with the terms and conditions of use stated herein below ("Terms &amp; Conditions").</li>
                <li><a href={process.env.REACT_APP_API_URL + "/about"}>About</a><br />Swapcoins is an easy to use cryptocurrency price aggregator that allows customers to trade their coins at some of the most competitive exchange rates available.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/privacy-policy"}>Privacy Policy</a><br />For the purposes of the EU General Data Protection Regulation 2016 (the “GDPR”) Swapcoins is the controller of personal information provided by you on Swapcoins.com (“Swapcoins”) and is responsible for the website swapcoins.com (the “Site”).</li>
                <li><a href={process.env.REACT_APP_API_URL + "/login"}>Login</a><br />Login to enjoy the features of Swapcoins.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/new-user"}>New User</a><br />Account activated. Login to enjoy the features of Swapcoins.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/new-registration"}>New Registration</a><br />To enjoy the benefits of Swapcoins.com, please complete registration by clicking the link sent to your email.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/register"}>Register</a><br />Trading cryptocurrency with Swapcoins.com is easy! All you need to do is register for a free account or login. Are you having issues registering?</li>
                <li><a href={process.env.REACT_APP_API_URL + "/forgot-password"}>Forgot Password</a><br />Having troubles remembering your password? Password Reset.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/track-order"}>Track Order</a><br />Track the status of your order with Swapcoins.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/contact"}>Contact</a><br />Your issue not listed here? If you have a problem or a question about our service, you can mail us at support@swapcoins.com. We usually respond to email requests within 12 hours.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/rates"}>Rates</a><br />Find the most competitive rates across several exchanges. Swap coins now!</li>
                <li><a href={process.env.REACT_APP_API_URL + "/supported-coins"}>Supported Coins</a><br />Swapcoins supports over 180 different coin pairs.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/exchange"}>Exchange</a><br />Make an order with Swapcoins now.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/press"}>News</a><br />The days of searching for the best rates - only to have them change by the time you log in to all your accounts – are over. With one Swapcoins.com account, you can: compare prices; pick the best rates; then, jump on the chance to get the best profit.</li>
                <li><a href={process.env.REACT_APP_API_URL + "/sitemap"}>Sitemap</a><br />Navigate the features of Swapcoins today.</li>
              </ul>
            </SitemapCopy>
          </SitemapContent>
        </SitemapPageContainer>
      </React.Fragment>
    </React.Fragment>
  )
};

export default Sitemap;
