import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import TextInput from '../Input/TextInput';
import CheckboxInput from '../Input/CheckboxInput';
import Button from '../Button/Button';
import HelpHero from '../HelpHero/HelpHero';
import Alert from '../Alert/Alert';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import axios from 'axios';
import 'formdata-polyfill';
import { rem } from 'polished';
import { mediaUp, psLetterSpacing, psLineHeight } from '../../globalStyle';

const ForgotPasswordPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(64)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(92)};
  `}
`;

const ForgotPasswordPageTitle = styled(PageTitle)`
  ${mediaUp.lg`
    padding: ${rem(101)} 0 ${rem(44)};
  `}
`;

const ForgotPasswordContent = styled.div`
  text-align: center;
  
  ${mediaUp.lg`
    width: 40%;
    margin: 0 auto;
  `}
`;

const ForgotPasswordButton = styled(Button)`
  width: 100%;
  text-align: center;
  margin: ${rem(31)} auto 0;
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, 120)};
  line-height: ${psLineHeight(14, 20)};
  
  ${mediaUp.lg`
    max-width: 270px;
    margin: ${rem(33)} auto 0;
  `}
`;

const ForgotPasswordCopy = styled.p`
  margin: ${rem(20)} 0 0;
  font-size: ${rem(12)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
`;

const ForgotPasswordCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 23px;
`;

const ForgotPasswordCheckboxLabel = styled.label`
  font-size: ${rem(12)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
  margin-left: 12px;
`;

const ForgotPasswordAlert = styled(Alert)`
  text-align: left;
  margin-bottom: 1rem;
`;

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      termsCheckboxValue: false,
      errors: [],
      hasError: false,
      hasSuccess: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTermsCheckChange = this.handleTermsCheckChange.bind(this);
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    let errors = [];
    event.preventDefault();

    const params = {
      email: data.get('email')
    };

    this.setState({
      loading: true
    });

    if (!validateEmail(params.email)) {
      errors.push('Invalid email (eg. email@swapcoins.com)');
    }

    if (errors.length) {
      this.setState({
        errors: errors,
        loading: false,
        hasError: true,
        hasSuccess: false
      });

      window.scrollTo(0, 0);
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/authenticate/reset/password`, params)
        .then(() => {
          this.setState({
            loading: false,
            hasError: false,
            hasSuccess: true
          });

          window.scrollTo(0, 0);

          setTimeout(() => {
            this.props.history.push('/');
          }, 5000);

        })
        .catch(() => {
          this.setState({
            loading: false,
            errors: ['There was an error with your request. Please try again.'],
            hasError: true,
            hasSuccess: false
          });

          window.scrollTo(0, 0);
        });
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      errors: [],
      hasError: false
    })
  }

  handleTermsCheckChange() {
    this.setState({
      termsCheckboxValue: !this.state.termsCheckboxValue
    });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Password Reset" />

        <Header />

        <ForgotPasswordPageContainer backgroundLight={true} headerOffset={true}>
          <ForgotPasswordPageTitle>Password Reset</ForgotPasswordPageTitle>

          <ForgotPasswordContent>
            <ForgotPasswordAlert show={this.state.hasSuccess} success={true}>
              An email has been sent to reset your password.
            </ForgotPasswordAlert>

            <ForgotPasswordAlert show={this.state.hasError} danger={true}>
              {this.state.errors.map((error, index) => {
                return (
                  <React.Fragment key={index}>
                    {error}{index !== (this.state.errors.length - 1)
                      ?
                      <React.Fragment>
                        <br />
                        <br />
                      </React.Fragment>
                      :
                      <React.Fragment />
                    }
                  </React.Fragment>
                )
              })}
            </ForgotPasswordAlert>

            <form onSubmit={this.handleSubmit}>
              <TextInput name="email" type="email" placeholder="Email (eg. email@swapcoins.com)" required />

              <ForgotPasswordCheckboxWrapper>
                <CheckboxInput name="termsCheckbox"
                               value={this.state.termsCheckboxValue}
                               handleCheckChange={this.handleTermsCheckChange}
                               isChecked={this.state.termsCheckboxValue}
                />

                <ForgotPasswordCheckboxLabel>
                  I agree to the <Link to="/terms-and-conditions">terms</Link> &amp; <Link to="/privacy-policy">privacy policy</Link>
                </ForgotPasswordCheckboxLabel>
              </ForgotPasswordCheckboxWrapper>

              <ForgotPasswordButton secondary={true} hasArrow={true} loading={this.state.loading} disabled={!this.state.termsCheckboxValue}>Reset Password</ForgotPasswordButton>

              <ForgotPasswordCopy>
                Already registered? <Link to="/login">Login</Link>
              </ForgotPasswordCopy>
            </form>
          </ForgotPasswordContent>
        </ForgotPasswordPageContainer>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default ForgotPassword;

