import React from 'react';
import styled from 'styled-components';
import { hideVisually } from 'polished';
import { inputStyles, labelStyles } from '../../globalStyle';

const StyledTextInput = styled.input`
  ${inputStyles}
`;

export const TextInputLabel = styled.label`
  ${props => props.hasLabel ? labelStyles : hideVisually()};
`;

export class TextInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      initialValueSet: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.setInitialValue = this.setInitialValue.bind(this);
  }

  componentDidMount() {
    this.setInitialValue();
  }

  componentDidUpdate() {
    if (!this.state.setInitialCurrency) {
      this.setInitialValue();
    }
  }

  handleInputChange(event) {
    this.setState({
      value: event.target.value
    });

    if (!this.state.initialValueSet) {
      this.setState({
        initialValueSet: true
      });
    }
  }

  setInitialValue() {
    const initialValue = this.props.initialValue;

    if (initialValue && !this.state.initialValueSet) {
      this.setState({
        value: initialValue,
        initialValueSet: true
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <TextInputLabel for={this.props.name} hasLabel={this.props.hasLabel}>{this.props.placeholder}</TextInputLabel>

        <StyledTextInput
          id={this.props.name}
          name={this.props.name}
          value={this.state.value}
          placeholder={this.props.placeholder}
          type={this.props.type}
          onChange={this.handleInputChange}
          className={this.props.className}
          required={this.props.required}
          initialValue={this.props.initialValue}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
        />
      </React.Fragment>
    )
  }
}

export default TextInput;
