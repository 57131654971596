import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ButtonArrow from './ButtonArrow';
import ButtonLoading from './ButtonLoading';
import { theme, buttonStyles } from "../../globalStyle";

const StyledRouterButton = styled(Link)`
  ${props => buttonStyles(props.primary, props.secondary, props.tertiary, props.block, props.loading)}
  
  &:hover {
    text-decoration: none;
    color: ${theme.white};
  }
`;

function RouterButton(props) {
  return (
    <StyledRouterButton
      className={props.className}
      primary={props.primary}
      secondary={props.secondary}
      tertiary={props.tertiary}
      arrow={props.arrow}
      fontSize={props.fontSize}
      block={props.block}
      onClick={props.onClick}
      to={props.to}
      loading={props.loading}
    >
      {props.children}

      {props.arrow &&
        <ButtonArrow loading={props.loading} />
      }

      <ButtonLoading loading={props.loading} />
    </StyledRouterButton>
  )
}

export default RouterButton;
