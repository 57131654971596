import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import CheckboxInput from '../Input/CheckboxInput';
import Alert from '../Alert/Alert';
import styled from 'styled-components';
import { rem, placeholder } from 'polished';
import { theme, mediaUp, psLetterSpacing } from "../../globalStyle";
import Loading from 'react-loading';
import danger from '../../assets/images/close_danger.png';
import success from '../../assets/images/check_secondary.svg';

const StyledAddressStep = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
`;

const AddressStepBody = styled.div`
  background-color: ${theme.white};
  padding: ${rem(32)} ${rem(27)};

  ${mediaUp.lg`
    padding: ${rem(54)};
  `}
`;

const AddressStepTitle = styled.h2`
  color: ${theme.gray1000};
  margin-bottom: ${rem(10)};

  ${mediaUp.lg`
    font-size: ${rem(38)};
    letter-spacing: ${psLetterSpacing(38, -40)};
    line-height: 0.75;
    margin-bottom: ${rem(20)};
  `}
`;

const AddressStepCopy = styled.p`
  font-size: ${rem(15)};
  letter-spacing: ${psLetterSpacing(15, 10)};
  line-height: 20px;
  margin-bottom: ${rem(35)};

  ${mediaUp.lg`
    line-height: 15px;
  `}
`;

const AddressStepInputLabel = styled.label`
  color: ${theme.gray1000};
  font-size: ${rem(10)};
  font-weight: ${theme.avenirWeightHeavy};
  letter-spacing: ${psLetterSpacing(10, 140)};
  margin-bottom: ${rem(16)};
  line-height: 10px;
  text-transform: uppercase;
  display: block;
`;

const AddressStepInputWrapper = styled.div`
  position: relative;
`;

const AddressStepInput = styled.input`
  display: block;
  margin-bottom: ${rem(16)};
  width: 100%;
  border-radius: 3px;
  border: 1px solid ${theme.muted};
  padding: ${rem(20)} ${rem(23)};
  color: ${props => (props.loading || !props.value) ? theme.primary:
                    (props.valid ? theme.success : theme.danger)};
  font-size: ${rem(14)};
  line-height: 14px;
  letter-spacing: ${psLetterSpacing(14, -10)};
  transition: 0.3s ease;
  border-color: ${props => (props.loading || !props.value) ? theme.muted :
                           (props.valid ? theme.success : theme.danger)};

  ${placeholder({'color': theme.muted})};

  ${mediaUp.lg`
    font-size: ${rem(20)};
    line-height: 20px;
    letter-spacing: ${psLetterSpacing(20, -10)};
    padding: ${rem(25)} ${rem(30)};
  `}

  &:focus {
    border-color: ${props => (props.loading || !props.value) ? theme.gray500 :
                             (props.valid ? theme.success : theme.danger)};
    outline: none;
  }
`;

const AddressStepInputIcon = styled.img`
  position: absolute;
  transition: 0.3s opacity ease;
  opacity: ${props => props.show ? '1' : '0'};
  right: 0;
  top: -12px;
  transform: translateY(-100%);

  ${mediaUp.lg`
    right: 29px;
    top: 50%;
    transform: translateY(-50%);
  `}
`;

const AddressStepInputLoading = styled(Loading)`
  position: absolute;
  transition: 0.3s opacity ease;
  opacity: ${props => props.loading ? '1' : '0'};
  display: flex;
  right: 0;
  top: -12px;
  transform: translateY(-100%);

  svg {
    flex: 1;
  }

  ${mediaUp.lg`
    right: 29px;
    top: 50%;
    transform: translateY(-50%);
  `}
`;

const AddressStepFooter = styled.div`
  margin-top: ${rem(31)};

  ${mediaUp.lg`
    display: flex;
    align-items: center;
    margin-top: ${rem(57)};
  `}
`;

const AddressStepCheckboxGroup = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 0 ${rem(34)};

  ${mediaUp.lg`
    margin: 0 0 0 auto;
    display: inline-flex;
  `}
`;

const AddressStepCheckbox = styled(CheckboxInput)`
  display: inline-block;
  background-color: ${theme.white};
`;

const AddressStepCheckboxLabel = styled.label`
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(12, 20)};
  color: ${theme.gray700};
  margin-left: ${rem(12)};
  line-height: normal;
`;

const AddressStepButton = styled(Button)`
  width: 100%;

  ${mediaUp.lg`
    width: 230px;
    margin-left: ${rem(30)};
  `}
`;

const AddressStepSection = styled.div`
  margin-bottom: ${rem(30)};

  ${mediaUp.lg`
    margin-bottom: ${rem(50)};
  `}

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const AddressAlert = styled(Alert)`
  margin-bottom: 1.5rem;
`;

function AddressStep(props) {
  return (
    <StyledAddressStep active={props.currentStep === 1}>
      <AddressStepBody>
        {props.outgoingCurrency &&
          <React.Fragment>
            <AddressStepSection>
              <AddressAlert danger={true} show={props.hasError}>
                There was an error with your request ({props.errorMessage}). Please try again.
              </AddressAlert>

              <AddressStepTitle>Enter your {props.outgoingName ? props.outgoingName : props.outgoingCurrency} Address</AddressStepTitle>

              {props.outgoingCurrency &&
                <React.Fragment>
                  <AddressStepCopy>
                    Address for the wallet in which {props.outgoingCurrency} will be deposited
                  </AddressStepCopy>

                  <AddressStepInputLabel htmlFor="outgoingAddress">{props.outgoingCurrency} Address</AddressStepInputLabel>
                </React.Fragment>
              }

              <AddressStepInputWrapper>
                <AddressStepInput
                  name="outgoingAddress"
                  id="outgoingAddress"
                  type="text"
                  placeholder="Enter Address Here"
                  value={props.outgoingAddress}
                  onChange={props.handleAddressInputChange}
                  valid={props.outgoingAddressInputValid}
                  loading={props.outgoingAddressInputLoading}
                />

                <AddressStepInputIcon src={danger}
                                            aria-hidden={true}
                                            show={
                                              !props.outgoingAddressInputValid
                                              && !props.outgoingAddressInputLoading
                                              && props.outgoingAddress
                                            }
                />

                <AddressStepInputIcon src={success}
                                             aria-hidden={true}
                                             show={
                                               props.outgoingAddressInputValid
                                               && !props.outgoingAddressInputLoading
                                               && props.outgoingAddress
                                             }
                />

                <AddressStepInputLoading type="spin"
                                         width={20}
                                         height={20}
                                         color={theme.primary}
                                         loading={props.outgoingAddressInputLoading || props.outgoingAddressIDLoading}
                />
              </AddressStepInputWrapper>
              {props.outgoingCurrency === 'XRP' ?
                <React.Fragment>
                  <AddressStepInputLabel htmlFor="outgoingAddressExtra">{props.outgoingCurrency} Destination Tag</AddressStepInputLabel>

                    <AddressStepInputWrapper>

                      <AddressStepInput
                        name="outgoingAddressExtra"
                        id="outgoingAddressExtra"
                        type="text"
                        placeholder="Enter Destination Tag Here"
                        value={props.outgoingAddressExtra}
                        onChange={props.handleAddressIDChange}
                        valid={props.outgoingAddressIDValid}
                        loading={props.outgoingAddressIdLoading}
                      />

                      <AddressStepInputIcon
                        src={danger}
                        aria-hidden={true}
                        show={
                          !props.outgoingAddressIDValid
                          && !props.outgoingAddressIdLoading
                          && props.outgoingAddressExtra
                        }
                      />

                      <AddressStepInputIcon
                        src={success}
                        aria-hidden={true}
                        show={
                          props.outgoingAddressIDValid
                          && !props.outgoingAddressIdLoading
                          && props.outgoingAddressExtra
                        }
                      />

                    </AddressStepInputWrapper>
                  </React.Fragment>
                  :
                  <React.Fragment></React.Fragment>
                }
                {(props.outgoingCurrency === 'XLM' || props.outgoingCurrency === 'EOS') ?
                <React.Fragment>
                  <AddressStepInputLabel htmlFor="outgoingAddressExtra">{props.outgoingCurrency} Memo Id</AddressStepInputLabel>

                    <AddressStepInputWrapper>

                      <AddressStepInput
                        name="outgoingAddressExtra"
                        id="outgoingAddressExtra"
                        type="text"
                        placeholder="Enter Memo Id Here"
                        value={props.outgoingAddressExtra}
                        onChange={props.handleAddressIDChange}
                        valid={props.outgoingAddressIDValid}
                        loading={props.outgoingAddressIdLoading}
                      />


                      <AddressStepInputIcon
                        src={danger}
                        aria-hidden={true}
                        show={
                          !props.outgoingAddressIDValid
                          && !props.outgoingAddressIdLoading
                          && props.outgoingAddressExtra
                        }
                      />

                      <AddressStepInputIcon
                        src={success}
                        aria-hidden={true}
                        show={
                          props.outgoingAddressIDValid
                          && !props.outgoingAddressIdLoading
                          && props.outgoingAddressExtra
                        }
                      />

                    </AddressStepInputWrapper>
                  </React.Fragment>
                  :
                  <React.Fragment></React.Fragment>
                }
                {props.outgoingCurrency === 'XMR' ?
                <React.Fragment>
                  <AddressStepInputLabel htmlFor="outgoingAddressExtra">{props.outgoingCurrency} Payment Id</AddressStepInputLabel>

                    <AddressStepInputWrapper>

                      <AddressStepInput
                        name="outgoingAddressExtra"
                        id="outgoingAddressExtra"
                        type="text"
                        placeholder="Enter Payment Id Here"
                        value={props.outgoingAddressExtra}
                        onChange={props.handleAddressIDChange}
                        valid={props.outgoingAddressIDValid}
                        loading={props.outgoingAddressIdLoading}
                      />

                      <AddressStepInputIcon
                        src={danger}
                        aria-hidden={true}
                        show={
                          !props.outgoingAddressIDValid
                          && !props.outgoingAddressIdLoading
                          && props.outgoingAddressExtra
                        }
                      />

                      <AddressStepInputIcon
                        src={success}
                        aria-hidden={true}
                        show={
                          props.outgoingAddressIDValid
                          && !props.outgoingAddressIdLoading
                          && props.outgoingAddressExtra
                        }
                      />

                    </AddressStepInputWrapper>
                  </React.Fragment>
                  :
                  <React.Fragment></React.Fragment>
                }
            </AddressStepSection>

            <AddressStepSection>
              <AddressStepTitle>Enter your {props.incomingName ? props.incomingName : props.incomingCurrency} Refund Address</AddressStepTitle>


              {props.incomingCurrency &&
                <React.Fragment>
                  <AddressStepCopy>
                    Address for the wallet in which {props.incomingCurrency} will be refunded in case of any issues
                  </AddressStepCopy>

                  <AddressStepInputLabel htmlFor="refundAddress">{props.incomingCurrency} Address</AddressStepInputLabel>
                </React.Fragment>
              }

              <AddressStepInputWrapper>
                <AddressStepInput
                  name="refundAddress"
                  id="refundAddress"
                  type="text"
                  placeholder="Enter Refund Address Here"
                  value={props.refundAddress}
                  onChange={props.handleAddressInputChange}
                  valid={props.refundAddressInputValid}
                  loading={props.refundAddressInputLoading}
                />

                <AddressStepInputIcon src={danger}
                                      aria-hidden={true}
                                      show={
                                        !props.refundAddressInputValid
                                        && !props.refundAddressInputLoading
                                        && props.refundAddress
                                      }
                />
                <AddressStepInputIcon src={success}
                                      aria-hidden={true}
                                      show={
                                        props.refundAddressInputValid
                                        && !props.refundAddressInputLoading
                                        && props.refundAddress
                                      }
                />

                <AddressStepInputLoading type="spin"
                                         width={20}
                                         height={20}
                                         color={theme.primary}
                                         loading={props.refundAddressInputLoading || props.refundAddressIDLoading}
                />
              </AddressStepInputWrapper>
              {props.incomingCurrency === 'XRP' ?
                <React.Fragment>
                  <AddressStepInputLabel htmlFor="refundAddressExtra">{props.incomingCurrency} Destination Tag</AddressStepInputLabel>

                  <AddressStepInputWrapper>

                    <AddressStepInput
                      name="refundAddressExtra"
                      id="refundAddressExtra"
                      type="text"
                      placeholder="Enter Destination Tag Here"
                      value={props.refundAddressExtra}
                      onChange={props.handleAddressIDChange}
                      valid={props.refundAddressIDValid}
                      loading={props.refundAddressIDLoading}
                    />

                    <AddressStepInputIcon
                      src={danger}
                      aria-hidden={true}
                      show={
                        !props.refundAddressIDValid
                        && !props.refundAddressIDLoading
                        && props.refundAddressExtra
                      }
                    />

                    <AddressStepInputIcon
                      src={success}
                      aria-hidden={true}
                      show={
                        props.refundAddressIDValid
                        && !props.refundAddressIDLoading
                        && props.refundAddressExtra
                      }
                    />
                  </AddressStepInputWrapper>
                </React.Fragment>
                :
                <React.Fragment></React.Fragment>
              }
              {(props.incomingCurrency === 'XLM' || props.incomingCurrency === 'EOS') ?
                <React.Fragment>
                  <AddressStepInputLabel htmlFor="refundAddressExtra">{props.incomingCurrency} Memo Id</AddressStepInputLabel>

                  <AddressStepInputWrapper>

                    <AddressStepInput
                      name="refundAddressExtra"
                      id="refundAddressExtra"
                      type="text"
                      placeholder="Enter Memo Id Here"
                      value={props.refundAddressExtra}
                      onChange={props.handleAddressIDChange}
                      valid={props.refundAddressIDValid}
                      loading={props.refundAddressIDLoading}
                    />

                    <AddressStepInputIcon
                      src={danger}
                      aria-hidden={true}
                      show={
                        !props.refundAddressIDValid
                        && !props.refundAddressIDLoading
                        && props.refundAddressExtra
                      }
                    />

                    <AddressStepInputIcon
                      src={success}
                      aria-hidden={true}
                      show={
                        props.refundAddressIDValid
                        && !props.refundAddressIDLoading
                        && props.refundAddressExtra
                      }
                    />
                  </AddressStepInputWrapper>
                </React.Fragment>
                :
                <React.Fragment></React.Fragment>
              }
              {props.incomingCurrency === 'XMR' ?
                <React.Fragment>
                  <AddressStepInputLabel htmlFor="refundAddressExtra">{props.incomingCurrency} Payment Id</AddressStepInputLabel>

                  <AddressStepInputWrapper>

                    <AddressStepInput
                      name="refundAddressExtra"
                      id="refundAddressExtra"
                      type="text"
                      placeholder="Enter Payment Id Here"
                      value={props.refundAddressExtra}
                      onChange={props.handleAddressIDChange}
                      valid={props.refundAddressIDValid}
                      loading={props.refundAddressIDLoading}
                    />

                    <AddressStepInputIcon
                      src={danger}
                      aria-hidden={true}
                      show={
                        !props.refundAddressIDValid
                        && !props.refundAddressIDLoading
                        && props.refundAddressExtra
                      }
                    />

                    <AddressStepInputIcon
                      src={success}
                      aria-hidden={true}
                      show={
                        props.refundAddressIDValid
                        && !props.refundAddressIDLoading
                        && props.refundAddressExtra
                      }
                    />
                  </AddressStepInputWrapper>
                </React.Fragment>
                :
                <React.Fragment></React.Fragment>
              }
            </AddressStepSection>
          </React.Fragment>
        }
      </AddressStepBody>

      <AddressStepFooter>
        <AddressStepCheckboxGroup>
          <AddressStepCheckbox
            name="termsCheckbox"
            value={props.termsCheckboxValue}
            handleCheckChange={props.handleTermsCheckChange}
            isChecked={props.termsCheckboxValue}
          />

          <AddressStepCheckboxLabel>
            I agree to the <Link to="/terms-and-conditions">terms</Link> &amp; <Link to="#">privacy policy</Link>
          </AddressStepCheckboxLabel>
        </AddressStepCheckboxGroup>

        <AddressStepButton secondary={true}
                           arrow={true}
                           handleButtonClick={props.handleOrderSubmit}
                           disabled={!props.buttonEnabled}
                           loading={props.orderLoading}
        >
          Complete Order
        </AddressStepButton>
      </AddressStepFooter>
    </StyledAddressStep>
  )
}

export default AddressStep;