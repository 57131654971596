import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css }  from 'styled-components';
import { rem, darken } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import arrow from '../../assets/images/arrow_white_lg.svg';

const StyledHelpHero = styled(Link)`
  background-color: ${theme.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  position: relative;
  transition: 0.3s background-color ease;
  
  &:hover {
    text-decoration: none;
    background-color: ${darken(0.1, theme.primary)};
  }
  
  &::after {
    content: '';
    position: absolute;
    width: 49%;
    top: -1px;
    height: 40px;
    background-color: ${props => props.overlaycolor ? props.overlaycolor : theme.white};
    
    ${props => props.overlayright ? 'right: 0;' : 'left: 0;'};
    
    ${mediaDown.lg`
      ${props => props.mobileoverlaycolor && css`background-color: ${props.mobileoverlaycolor};`}   
      ${props => props.mobileoverlayright ? 'right: 0;' : 'left: 0;'};
    `}  
    
    ${mediaUp.lg`
      width: 51%;
      height: 57px;
    `}
  }
  
  ${mediaUp.lg`
    min-height: 477px;
  `}
`;

const HelpHeroBody = styled.div`
  text-align: center;
  margin-top: 40px;
  
  ${mediaUp.lg`
    margin-top: 57px;
  `}
`;

const HelpHeroTitle = styled.h1`
  color: ${theme.white};
  font-size: ${rem(44)};
  letter-spacing: ${psLetterSpacing(44, -35)};
  margin-bottom: ${rem(28)};
  
  ${mediaUp.lg`
    margin-bottom: ${rem(40)};
    font-size: ${rem(76)};
    letter-spacing: ${psLetterSpacing(76, -35)};
  `}
`;

const HelpHeroArrow = styled.img``;

function HelpHero(props) {
  return (
    <StyledHelpHero overlaycolor={props.overlayColor}
                    mobileoverlaycolor={props.mobileOverlayColor}
                    overlayright={props.overlayRight}
                    mobileoverlayright={props.mobileOverlayRight}
                    to={props.link ? props.link : '/faq'}
                    onClick={props.onClick}
    >
      <HelpHeroBody>
        <HelpHeroTitle>{props.title ? props.title : 'Need help?'}</HelpHeroTitle>

        <HelpHeroArrow src={arrow}
                       alt={(props.title && props.link) ? `Go to ${props.title} page` : 'Go to help page'}
        />
      </HelpHeroBody>
    </StyledHelpHero>
  )
}

export default HelpHero;
