import React from 'react';
import styled, { css } from 'styled-components';
import { mediaUp, mediaDown, theme } from '../../globalStyle';

const StyledContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  
  ${props => props.hasMobileGutters && css`
    ${mediaDown.sm`
      padding: 0 ${theme.mobileGutterLg};
    `}
  `}
  
  ${mediaUp.sm`
    max-width: ${theme.smMaxContainerWidth};
  `}
  
  ${mediaUp.md`
    max-width: ${theme.mdMaxContainerWidth};
  `}
  
  ${mediaUp.lg`
    max-width: ${theme.lgMaxContainerWidth};
  `}
  
  ${mediaUp.xl`
    max-width: ${theme.xlMaxContainerWidth};
  `}
`;

function Container(props) {
  return (
    <StyledContainer className={props.className} hasMobileGutters={props.hasMobileGutters} innerRef={props._ref}>
      {props.children}
    </StyledContainer>
  )
}

export default Container;
