import React from 'react';
import Button from '../Button/Button';
import TextInput from '../Input/TextInput';
import CheckboxInput from '../Input/CheckboxInput';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import arrow from '../../assets/images/order_arrow.png';
import axios from 'axios';
import 'formdata-polyfill';

const exchangeGutter = rem(22);

const ExchangeHeader = styled.div`
  padding: ${rem(22)} ${exchangeGutter};
  
  ${mediaUp.lg`
    padding: ${rem(26)} ${exchangeGutter};
  `}
`;

const ExchangeSettings = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;

const ExchangeName = styled.div`
  padding: ${rem(18)} ${exchangeGutter} ${rem(35)};
  text-align: center;
  
  ${mediaUp.lg`
    padding: ${rem(18)} ${exchangeGutter} ${rem(54)};
  `}
`;

const ExchangeArrow = styled.img`
  margin-bottom: ${rem(18)};
`;

const ExchangeTo = styled.h3`
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(27)};
  color: ${theme.gray1000};
  
  ${mediaUp.lg`
    font-size: ${rem(34)};
  `}
`;

const ExchangeLineItem = styled.div`
  padding: ${rem(15)} ${exchangeGutter};
  border-top: 1px solid ${theme.muted};
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
    padding: ${rem(20)} ${exchangeGutter};
  `}
  
  ${mediaDown.lg`
    text-align: center;
  `}
  
  &:last-of-type {
    border-bottom: 1px solid ${theme.muted};
  }
`;

const ExchangeLineItemCopy = styled.span`
  font-size: ${rem(13)};
  line-height: normal;
  
  ${mediaDown.lg`
    display: block;
  `}
`;

const ExchangeLineItemLabel = ExchangeLineItemCopy.extend`
  color: ${theme.gray500};
  font-weight: ${theme.avenirWeightMedium};
  letter-spacing: ${psLetterSpacing(13, 20)};
  padding-right: 0.75rem;
  
  ${mediaDown.lg`
    margin-bottom: 0.5rem;
  `}
`;

const ExchangeLineItemValue = ExchangeLineItemCopy.extend`
  color: ${theme.tertiary};
  letter-spacing: 0;
  
  ${mediaUp.lg`
    margin-left: auto;
  `}
`;

const ExchangeFooter = styled.div`
  padding: ${rem(20)} ${exchangeGutter};
  text-align: center;

  ${mediaUp.lg`
    padding: ${rem(30)} ${exchangeGutter};
  `}
`;

const ExchangeButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

function Exchange(props) {

  return (
    <React.Fragment>
      {props.name &&
        <ExchangeHeader>
          <ExchangeSettings>
            <strong>Settings</strong>
          </ExchangeSettings>
        </ExchangeHeader>
      }

      <ExchangeName>
        <ExchangeArrow src={arrow} alt="Settings" />
        {props.name &&
          <ExchangeTo>
            {props.name}
          </ExchangeTo>
        }
      </ExchangeName>

      <form onSubmit={(event) => {
        const data = new FormData(event.target);
        event.preventDefault();

        const params = {
          name: data.get('name') || props.name,
          url: data.get('url') || props.url,
          notes: data.get('notes') || props.notes,
          enabled: data.get('enabled') === null ? false : true,
        };
        
        if (props.notes !== params.notes) {
          axios.put(`${process.env.REACT_APP_API_URL}/admin/exchanges/notes`, 
          {
            exchange: params.name || props.name,
            notes: params.notes || props.notes,
          }
          ,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          },
          )
          .then((response) => {
             alert(`Note has been saved.`);
          })
          .catch((error) => {
            alert(`Issue encountered, please try again.`);
          });
        }

        if (props.enabled !== params.enabled) {
          axios.put(`${process.env.REACT_APP_API_URL}/admin/exchanges/enable`, 
          {
            exchange: params.name || props.name,
            enabled: params.enabled,
          }
          ,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          },
          )
          .then((response) => {
            alert(`Exchange has been modified.`);
          })
          .catch((error) => {
            alert(`Issue encountered, please try again.`);
          });
        }
      }}>
        {props.url &&
          <ExchangeLineItem>
            <ExchangeLineItemLabel>URL</ExchangeLineItemLabel>
            <ExchangeLineItemValue>
              {props.url}
            </ExchangeLineItemValue>
          </ExchangeLineItem>
        }

        <ExchangeLineItem>
          <ExchangeLineItemLabel>Notes</ExchangeLineItemLabel>
          <ExchangeLineItemValue>
            <TextInput type="text"
              name="notes"
              hasLabel={false}
              placeholder="Notes"
              initialValue={props.notes}
              required={false}
            />
          </ExchangeLineItemValue>
        </ExchangeLineItem>
        
        <ExchangeLineItem>
          <ExchangeLineItemLabel>Enabled</ExchangeLineItemLabel>
          <ExchangeLineItemValue>
          <CheckboxInput
            name="enabled"
            isChecked={props.enabled}
          />
          </ExchangeLineItemValue>
        </ExchangeLineItem>

        <ExchangeFooter>
          <ExchangeButton
            arrow={true}
            secondary={true}
            block={true}
            >
            Save
          </ExchangeButton>
        </ExchangeFooter>
      </form>
    </React.Fragment>
  )
}

export default Exchange;
