import { Chart } from "react-google-charts";
import styled from 'styled-components';
import React from 'react';
import { rem } from 'polished';
import { mediaDown, mediaUp, theme } from "../../globalStyle";
import Container from '../Container/Container';

const CompareRatesHeader = styled.div`
  padding: ${rem(22)} ${rem(24)};
  text-transform: uppercase;
  color: ${theme.black};
  font-size: ${rem(20)};

  ${mediaUp.lg`
    width: 800px;
  `}

  ${mediaDown.lg`
    width: 300px;
  `}
`;


function CompareRates(props) {
  return (
    <React.Fragment>
      <CompareRatesHeader>{props.incomingCurrency} to {props.outgoingCurrency} Historical Rates (Past 24h)</CompareRatesHeader>
      <Container hasMobileGutters={true}>
        <Chart
          chartType="LineChart"
          data={props.historicalRates}
          options={{
            title: `${props.incomingCurrency} to ${props.outgoingCurrency} Historical Rates (Past 24h)`,
            hAxis: {title: "",
              gridlines: {
                color: 'transparent'
              }
            },
            vAxis: {
              title: "", 
              gridlines: {
                color: 'transparent'
              }
            },
            legend: "none",
            curveType: 'function',
            chartArea: {'width': '100%', 'height': '100%'},
            enableInteractivity: true,
            
          }}
          height={400}
        />
      </Container>
    </React.Fragment>
  )
}

export default CompareRates;