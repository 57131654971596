import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, psLetterSpacing, mediaDown } from "../../globalStyle";
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import { Link } from 'react-scroll';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/minimal-example.css';
import caret from '../../assets/images/caret_down_tertiary.svg';

const AccordionTitle = styled.h3`
  font-size: ${rem(18)};
  letter-spacing: ${psLetterSpacing(18, 0)};
  margin-bottom: ${rem(20)};
`;

const StyledAccordion = styled(Accordion)`
  margin-bottom: ${rem(62)};
  
  &:last-of-type {
    margin-bottom: 0;
  }
  
  .accordion {
    &__item {
      border-top: 1px solid ${theme.muted};
      
      &:last-of-type {
        border-bottom: 1px solid ${theme.muted};
      }
    }
    
    &__title {
      cursor: pointer;
      line-height: normal;
      padding: ${rem(20)} 0;
      position: relative;
      
      ${mediaDown.lg`
        padding-right: ${rem(25)};
      `}
      
      &:focus {
        outline: none;
      }
      
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 60px;
        background: url('${caret}') center no-repeat;
        
        ${mediaDown.lg`
          width: 25px;
          height: 25px;
          top: 20px;
        `}
      }
      
      &[aria-selected="true"] {
        &::after {
          transform: scaleY(-1);
        }
      }
    }
    
    &__title,
    &__body {
      letter-spacing: ${psLetterSpacing(16, 20)};
      
      p,
      img {
        margin-bottom: 1rem;
      }
      
      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    
    &__body {
      margin: ${rem(10)} 0 ${rem(45)};
    }
  }
`;

const AccordionNavColumn = styled(GridColumn)`
  position: relative;
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const AccordionNav = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  position: sticky;
  left: 0;
  right: 0;
  top: ${props => props.isOffset ? theme.headerDesktopHeight : '0'};
  padding-top: ${props => props.isOffset ? rem(20) : '0'};
`;

const AccordionNavItem = styled.li`
  margin-bottom: ${rem(20)};
  
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const AccordionNavLink = styled(Link)`
  font-family: ${theme.fontFamilyCircular};
  text-transform: uppercase;
  color: ${theme.tertiary};
  transition: 0.3s opacity ease;
  letter-spacing: ${psLetterSpacing(12, 200)};
  font-size: ${rem(12)};
  
  &:hover {
    text-decoration: none;
    color: ${theme.primary};
  }
`;

class AccordionWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pastThreshold: false
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.navRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    // 100 is magic number for desktop header height
    // don't need to check for mobile because element is hidden on mobile
    const headerDesktopHeight = 100;
    const pastHeaderOffset = this.navRef.getBoundingClientRect().top <= headerDesktopHeight;

    // don't want to set state every scroll event because of performance, just set when needed
    if (pastHeaderOffset) {
      this.setState({
        pastThreshold: true
      });
    } else if (this.state.pastThreshold && !pastHeaderOffset) {
      this.setState({
        pastThreshold: false
      });
    }
  }

  render() {
    return (
      <React.Fragment>
          <GridRow noGutters={true}>
            <AccordionNavColumn maxColumns={10} lgColumns={2}>
              <AccordionNav innerRef={navRef => {this.navRef = navRef}} isOffset={this.state.pastThreshold}>
                {this.props.content.map((navItem, index) => {
                  return (
                    <AccordionNavItem key={index}>
                      <AccordionNavLink
                        href={`#${navItem.id}`}
                        to={navItem.id}
                        smooth={true}
                        offset={-120}
                        activeClass="active"
                        duration={500}
                      >
                        {navItem.navigationTitle}
                      </AccordionNavLink>
                    </AccordionNavItem>
                  )
                })}
              </AccordionNav>
            </AccordionNavColumn>

            <GridColumn maxColumns={10} lgOffset={1} lgColumns={7}>
              {this.props.content.map((section, index) => {
                return (
                  <StyledAccordion key={index} name={section.id} id={section.id}>
                    {section.title.length &&
                      <AccordionTitle>{section.title}</AccordionTitle>
                    }

                    {section.items.map((item, index) => {
                      return (
                        <AccordionItem key={index}>
                          {item.title.length &&
                          <AccordionItemTitle>{item.title}</AccordionItemTitle>
                          }

                          {item.copy.length &&
                            <AccordionItemBody dangerouslySetInnerHTML={{__html: item.copy}} />
                          }
                        </AccordionItem>
                      )
                    })}
                  </StyledAccordion>
                )
              })}
            </GridColumn>
          </GridRow>
      </React.Fragment>
    )
  }
}

export default AccordionWrapper;
