import React from 'react';
import Loading from 'react-loading';
import styled from 'styled-components';

const ButtonLoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props => props.loading ? '1' : '0'};
  transition: 0.3s opacity ease;
  z-index: 1;
`;

function ButtonLoading(props) {
  return (
    <ButtonLoadingWrapper loading={props.loading}>
      <Loading type="spin"
               width={20}
               height={20}
      />
    </ButtonLoadingWrapper>
  )
}

export default ButtonLoading;
