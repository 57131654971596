import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../globalStyle';
import { rem, darken, rgba } from 'polished';
import close from '../../assets/images/close.svg';

const StyledReport = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${rgba(theme.tertiary, 0.9)};
  transition: 0.3s opacity ease;
  
  ${props => props.isActive
    ? css`
      opacity: 1;
      z-index: 100000;
      pointer-events: auto;
    `
    : css`
      opacity: 0;
      pointer-events: none;
    `
  }
`;

const ReportBody = styled.div`
  width: auto;
  max-width: calc(100% - ${rem(10)});
  background-color: ${theme.white};
  position: relative;
`;

const ReportClose = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  width: 67px;
  height: 60px;
  background-color: ${theme.primary};
  border: none;
  padding: 0;
  transition: 0.3s background-color ease;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    background-color: ${darken(0.1, theme.primary)};
  }
`;

const ReportCloseIcon = styled.img`
  width: 18px;
  height: auto;
`;

class Report extends React.PureComponent {
  constructor(props) {
    super(props);

    this.overlay = React.createRef();
  }

  componentDidMount() {
    let _clientY = null; // remember Y position on touch start
    const overlay = this.overlay;

    function isOverlayTotallyScrolled() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
      return overlay.scrollHeight - overlay.scrollTop <= overlay.clientHeight;
    }

    function disableRubberBand(event) {
      const clientY = event.targetTouches[0].clientY - _clientY;

      if (overlay.scrollTop === 0 && clientY > 0) {
        // element is at the top of its scroll
        event.preventDefault();
      }

      if (isOverlayTotallyScrolled() && clientY < 0) {
        //element is at the top of its scroll
        event.preventDefault();
      }
    }

    overlay.addEventListener('touchstart', function (event) {
      if (event.targetTouches.length === 1) {
        // detect single touch
        _clientY = event.targetTouches[0].clientY;
      }
    }, false);

    overlay.addEventListener('touchmove', function (event) {
      if (event.targetTouches.length === 1) {
        // detect single touch
        disableRubberBand(event);
      }
    }, false);
  }

  render() {
    return (
      <StyledReport isActive={this.props.isActive}
                   innerRef={(overlay) => { this.overlay = overlay}}
      >
        <ReportBody>
          <ReportClose onClick={this.props.handleModalClose}>
            <ReportCloseIcon src={close} alt="Close" />
          </ReportClose>

          {this.props.children}
        </ReportBody>
      </StyledReport>
    )
  }
}

export default Report;
