import React from 'react';
import Header from '../Header/Header';
import HelpHero from '../HelpHero/HelpHero';
import Button from '../Button/Button';
import Container from '../Container/Container';
import AdminHeader from './AdminHeader';
import Report from './Report';
import ReportModal from '../Modal/Report';
import Loading from '../Loading/Loading';
import PageTitle from '../Page/PageTitle';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import axios from 'axios';
import { rem } from 'polished';
import { Scrollbars } from 'react-custom-scrollbars';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";

const ReportsContainer = styled(Container)`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const ReportsOrder = styled.div`
  border-bottom: 1px solid ${theme.muted};

  &:last-of-type {
    border-bottom: none;
  }
`;

const ReportsHeader = styled.div`
  background-color: #fbfbfb;
  padding: ${rem(13)};

  ${mediaDown.lg`
    display: none;
  `}
`;

const ReportsBody = styled.div`
  background-color: ${theme.white};
`;

const ReportsColumn = styled.div`
  ${mediaDown.lg`
    padding: ${rem(15)};
    margin-right: ${rem(4)};

    &:first-of-type {
      padding-top: ${rem(30)};
    }

    &:last-of-type {
      padding-bottom: ${rem(30)};
    }
  `}

  ${mediaUp.lg`
    display: flex;
    align-items: center;
  `}
`;

const ReportsNameColumn = ReportsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 20%;
    max-width: 20%;
  `}
`;

const ReportsDescriptionColumn = ReportsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 40%;
    max-width: 40%;
  `}
`;

const ReportsIDColumn = ReportsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 10%;
    max-width: 10%;
  `}
`;

const ReportsEnabledColumn = ReportsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 10%;
    max-width: 10%;
  `}
`;

const ReportsButtonColumn = ReportsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 20%;
    max-width: 20%;
  `}
`;

const ReportsHeaderTitle = styled.h6`
  text-transform: uppercase;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  margin-bottom: 0;
  color: #888888;
  line-height: normal;
`;

const ReportsHeaderMobileTitle = ReportsHeaderTitle.extend`
  margin-bottom: 0.25rem;

  ${mediaUp.lg`
    display: none;
  `}
`;

const ReportsCell = styled.div`
  ${mediaUp.lg`
    display: flex;
    padding: ${rem(40)} 0;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `}
`;

const ReportsName = styled.p`
  color: ${theme.gray1000};
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(10, 120)};
`;

const ReportsID = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;

  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const ReportsDescription = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;

  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const ReportsButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};

  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

const ReportsTitle = styled(PageTitle)`
  background-color: ${theme.white};
  color: ${theme.muted};
  padding: ${rem(120)} 0;

  ${mediaUp.lg`
    padding: ${rem(182)} 0;
  `}
`;

const ReportsLoading = styled(Loading)`
  background-color: ${theme.white};
`;

class Reports extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Reports: [],
      modalActive: false,
      name: '',
      description: '',
      id: '',
      enabled: false,
      params: '',
      Datas: {cols:'', data: []},
      isOpen: false,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.logoutUser();
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/admin/reports`, { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        let Reports = [];

        response.data.forEach((report) => {
          if (!report.params) {
            Reports.push({
              name: report.name,
              description: report.description,
              id: report.id,
              enabled: report.enabled ? 'Yes' : 'No',
              params: report.params,
            });
          }
        });

        this.setState({
          loading: false,
          Reports: Reports
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });

        this.props.logoutUser();
        this.props.history.push('/login');
      });
    }
  }
  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  handleModalOpen(name, id, description, enabled, params) {
    this.setState({
      modalActive: true,
      name: name,
      id: id,
      description: description,
      enabled: enabled,
      params: params,
    }, () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';

      axios.post(`${process.env.REACT_APP_API_URL}/admin/reports`,
      {
        name: this.state.name,
        id: this.state.id,
      },
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
        }
      },
      ).then((response) => {
        if (response.data[0] && response.data[0].cols) {
          let reportData = {cols: '', data: []};
          reportData.cols = response.data[0].cols;

          response.data.forEach((report) => {
            delete report.cols;
            reportData.data.push(report);
          })

          this.setState({
            loading: false,
            Datas: reportData,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false
        });

        this.props.logoutUser();
        this.props.history.push('/login');
      });
    });
  };

  handleModalClose() {
    this.setState({
      modalActive: false,
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });

  };

  render() {
    return (
      <React.Fragment>
        <Meta title="Reports" />

        <Header />

        <AdminHeader />

        <ReportsLoading loading={this.state.loading}>
          {this.state.Reports ?
            <React.Fragment>
              <ReportsHeader>
                <ReportsContainer hasMobileGutters={true}>
                  <ReportsIDColumn>
                    <ReportsHeaderTitle>ID</ReportsHeaderTitle>
                  </ReportsIDColumn>

                  <ReportsNameColumn>
                    <ReportsHeaderTitle>Name</ReportsHeaderTitle>
                  </ReportsNameColumn>

                  <ReportsDescriptionColumn>
                    <ReportsHeaderTitle>Description</ReportsHeaderTitle>
                  </ReportsDescriptionColumn>

                  <ReportsEnabledColumn>
                    <ReportsHeaderTitle>Enabled</ReportsHeaderTitle>
                  </ReportsEnabledColumn>
                </ReportsContainer>
              </ReportsHeader>

              <ReportsBody>
                <Scrollbars style={{height: 400 }}>
                  {this.state.Reports.map((report, index) => {
                    return (
                      <ReportsOrder key={index}>
                        <ReportsContainer hasMobileGutters={true}>

                          <ReportsIDColumn>
                            <ReportsCell>
                              <ReportsHeaderMobileTitle>ID</ReportsHeaderMobileTitle>
                              <ReportsID>{report.id}</ReportsID>
                            </ReportsCell>
                          </ReportsIDColumn>

                          <ReportsNameColumn>
                            <ReportsCell>
                              <ReportsHeaderMobileTitle>Name</ReportsHeaderMobileTitle>
                              <ReportsName>
                                <strong>{report.name}</strong>
                              </ReportsName>
                            </ReportsCell>
                          </ReportsNameColumn>

                          <ReportsDescriptionColumn>
                            <ReportsCell>
                              <ReportsHeaderMobileTitle>Description</ReportsHeaderMobileTitle>
                              <ReportsDescription>{report.description}</ReportsDescription>
                            </ReportsCell>
                          </ReportsDescriptionColumn>

                          <ReportsEnabledColumn>
                            <ReportsCell>
                              <ReportsHeaderMobileTitle>Active</ReportsHeaderMobileTitle>
                              <ReportsID>
                                <input
                                  type="checkbox"
                                  checked={report.enabled}
                                  value={report.enabled}
                                  name={report.name}
                                  disabled={true}
                                />
                              </ReportsID>
                            </ReportsCell>
                          </ReportsEnabledColumn>

                          <ReportsButtonColumn>
                            <ReportsCell>
                              <ReportsHeaderMobileTitle>Action</ReportsHeaderMobileTitle>
                              <ReportsButton
                                secondary={true}
                                handleButtonClick={() => {
                                  this.handleModalOpen(
                                    report.name,
                                    report.id,
                                    report.description,
                                    report.enabled,
                                    report.params,
                                  )
                                }}
                              >
                                View
                              </ReportsButton>
                            </ReportsCell>
                          </ReportsButtonColumn>

                        </ReportsContainer>
                      </ReportsOrder>
                    )
                  })}
                </Scrollbars>
              </ReportsBody>
            </React.Fragment>
            :
            <ReportsTitle>No Reports Found</ReportsTitle>
          }
        </ReportsLoading>

        <HelpHero />
        <ReportModal isActive={this.state.modalActive} handleModalClose={this.handleModalClose}>
          <Report
            name={this.state.name}
            id={this.state.id}
            description={this.state.description}
            enabled={this.state.enabled}
            params={this.state.params}
            Datas={this.state.Datas}
            isOpen={this.state.isOpen}
            toggleOpen={this.toggleOpen}
          />
        </ReportModal>
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <Reports {...props}
                      authenticated={authenticated}
                      authenticationLoading={authenticationLoading}
                      logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};
