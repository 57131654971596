import React from 'react';
import styled from 'styled-components';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import { rem } from 'polished';
import Container from '../Container/Container';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import bittrex from '../../assets/images/bittrex.png';
import changelly from '../../assets/images/changelly.png';
import changer from '../../assets/images/changer.png';
import cryptopia from '../../assets/images/cryptopia.png';
import kucoin from '../../assets/images/kucoin.png';
import shapeshift from '../../assets/images/shapeshift.png';

const StyledPartners = styled.section`
  background-color: ${theme.gray800};
  padding: ${rem(52)} 0 ${rem(77)};
`;

const PartnersTitle = styled.h6`
  text-transform: none;
  color: ${theme.gray600};
  font-weight: 400;
  text-align: center;
  letter-spacing: ${psLetterSpacing(17, 40)};
  margin-bottom: ${rem(40)};
  line-height: 17px;
`;

const PartnersLogoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -${rem(26)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const PartnersLogo = styled.img`
  margin: 0 auto;
  
  ${mediaUp.lg`
    margin: 0 ${rem(26)};
  `}
`;

const PartnersSlider = styled(Slider)`
  ${mediaUp.lg`
    &.slick-slider {
      display: none;
    }
  `}
`;

const slickSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  centerMode: true,
  centerPadding: '25%',
  slidesToShow: 1,
};

const partners = [
  {
    logo: kucoin,
    alt: 'Kucoin',
    url: 'https://www.kucoin.com/#/?r=ResQ7J',
  },
  {
    logo: cryptopia,
    alt: 'Cryptopia',
    url: 'https://www.cryptopia.co.nz/Register?referrer=HexbitePro',
  },
  {
    logo: changer,
    alt: 'Changer',
    url: 'https://www.changer.com/?refid=237324',
  },
  {
    logo: changelly,
    alt: 'Changelly',
    url: 'https://www.changelly.com',
  },
  {
    logo: bittrex,
    alt: 'Bittrex',
    url: 'https://www.bittrex.com',
  },
];

function Partners() {
  return (
    <StyledPartners>
      <Container>
        <PartnersTitle>Popular Exchanges</PartnersTitle>

        <PartnersLogoRow>
          {partners.map((partner, index) => {
            return (
              <a href={partner.url} key={index} target="_blank" rel="noopener noreferrer">
                <PartnersLogo key={index} src={partner.logo} alt={partner.alt} />
              </a>
            )
          })}
        </PartnersLogoRow>

        <PartnersSlider {...slickSettings}>
          {partners.map((partner, index) => {
            return (
              <a href={partner.url} key={index} target="_blank" rel="noopener noreferrer">
                <PartnersLogo src={partner.logo} alt={partner.alt} />
              </a>
            )
          })}
        </PartnersSlider>
      </Container>
    </StyledPartners>
  )
}

export default Partners;