import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import TextInput from '../Input/TextInput';
import Button from '../Button/Button';
import HelpHero from '../HelpHero/HelpHero';
import Alert from '../Alert/Alert';
import Loading from '../Loading/Loading';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import axios from 'axios';
import 'formdata-polyfill';
import { rem } from 'polished';
import { mediaUp, psLetterSpacing, psLineHeight } from '../../globalStyle';

const LoginPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(64)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(92)};
  `}
`;

const LoginPageTitle = styled(PageTitle)`
  ${mediaUp.lg`
    padding: ${rem(101)} 0 ${rem(44)};
  `}
`;

const LoginContent = styled.div`
  text-align: center;
  
  ${mediaUp.lg`
    width: 40%;
    margin: 0 auto;
  `}
`;

const LoginButton = styled(Button)`
  width: 100%;
  text-align: center;
  margin: ${rem(10)} auto 0;
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, 120)};
  line-height: ${psLineHeight(14, 20)};
  
  ${mediaUp.lg`
    max-width: 230px;
    margin: ${rem(33)} auto 0;
  `}
`;

const LoginCopy = styled.p`
  margin: ${rem(20)} 0 0;
  font-size: ${rem(15)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
`;

const LoginAlert = styled(Alert)`
  text-align: left;
  margin-bottom: 1rem;
`;

class Login extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hasErrors: false,
      errorMessage: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/account/profile');
    }
  }

  componentDidUpdate() {
    if (this.props.authenticated) {
      this.props.history.push('/account/profile');
    }
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    event.preventDefault();

    const params = {
      username: data.get('username'),
      password: data.get('password')
    };

    this.setState({
      loading: true
    });

    axios.post(`${process.env.REACT_APP_API_URL}/authenticate/login`, params)
      .then((response) => {
        this.setState({
          loading: false,
          hasError: false
        });

        localStorage.setItem('jwtToken', response.data.token);

        this.props.loadUserFromToken();
        this.props.history.push('/');
      })
      .catch((error) => {
        this.setState({
          loading: false,
          hasError: true,
          errorMessage: error.response.data.status
        });
      });
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      hasError: false,
      errorMessage: '',
    })
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Login" />

        <Header />

        <Loading loading={this.props.authenticationLoading}>
          <LoginPageContainer backgroundLight={true} headerOffset={true}>
            <LoginPageTitle>Login</LoginPageTitle>

            <LoginContent>
              <LoginAlert danger={true} show={this.state.hasError}>
                There was an error with your request ({this.state.errorMessage}). Please try again.
              </LoginAlert>

              <form onSubmit={this.handleSubmit}>
                <TextInput name="username" type="text" placeholder="Username or Email (eg. email@swapcoins.com)" required />
                <TextInput name="password" type="password" placeholder="Password" required />

                <LoginButton secondary={true} hasArrow={true} loading={this.state.loading}>Login</LoginButton>

                <LoginCopy>
                  Don't have an account? <Link to="/register">Register</Link>
                  <br />
                  <Link to="/forgot-password">Forgot Password?</Link>
                </LoginCopy>
              </form>
            </LoginContent>
          </LoginPageContainer>
        </Loading>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          loadUserFromToken
        }) => (
        <Login {...props}
               authenticated={authenticated}
               authenticationLoading={authenticationLoading}
               loadUserFromToken={loadUserFromToken}
        />
      )}
    </CoinContext.Consumer>
  )
};
