import React from 'react';
import Header from '../Header/Header';
import HelpHero from '../HelpHero/HelpHero';
import Button from '../Button/Button';
import Container from '../Container/Container';
import AccountHeader from './AccountHeader';
import Order from './Order';
import Modal from '../Modal/Modal';
import Loading from '../Loading/Loading';
import PageTitle from '../Page/PageTitle';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";

const OrderHistoryContainer = styled(Container)`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const OrderHistoryOrder = styled.div`
  border-bottom: 1px solid ${theme.muted};

  &:last-of-type {
    border-bottom: none;
  }
`;

const OrderHistoryHeader = styled.div`
  background-color: #fbfbfb;
  padding: ${rem(13)};

  ${mediaDown.lg`
    display: none;
  `}
`;

const OrderHistoryBody = styled.div`
  background-color: ${theme.white};
`;

const OrderHistoryColumn = styled.div`
  ${mediaDown.lg`
    padding: ${rem(15)};

    &:first-of-type {
      padding-top: ${rem(30)};
    }

    &:last-of-type {
      padding-bottom: ${rem(30)};
    }
  `}

  ${mediaUp.lg`
    display: flex;
    align-items: center;
  `}
`;

const OrderHistoryStatusColumn = OrderHistoryColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 84px;
    max-width: 84px;
  `}
`;

const OrderHistoryDateColumn = OrderHistoryColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 113px;
    max-width: 113px;
  `}
`;

const OrderHistoryFromColumn = OrderHistoryColumn.extend`
  ${mediaUp.lg`
    flex: 1;
  `}
`;

const OrderHistoryToColumn = OrderHistoryColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 50%;
    max-width: 50%;
  `}
`;

const OrderHistoryHeaderTitle = styled.h6`
  text-transform: uppercase;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  margin-bottom: 0;
  color: #888888;
  line-height: normal;
`;

const OrderHistoryHeaderMobileTitle = OrderHistoryHeaderTitle.extend`
  margin-bottom: 0.25rem;

  ${mediaUp.lg`
    display: none;
  `}
`;

const OrderHistoryCell = styled.div`
  ${mediaUp.lg`
    display: flex;
    padding: ${rem(40)} 0;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `}
`;

const OrderHistoryDate = styled.p`
  color: ${theme.gray1000};
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
`;

const OrderHistoryAmount = styled.h3`
  color: ${theme.gray1000};
  letter-spacing: 0;
  margin-bottom: 0;

  ${mediaDown.lg`
    line-height: 20px;
    margin-bottom: 0.25rem;
  `}

  ${mediaUp.lg`
    font-size: ${rem(26)};
    flex: 0 0 100%;
    max-width: 100%;
  `}
`;

const OrderHistoryAddress = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;

  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const OrderHistoryButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};

  ${mediaDown.lg`
    display: flex;
    width: 100%;
    margin-top: 1rem;
  `}
`;

const OrderHistoryTitle = styled(PageTitle)`
  background-color: ${theme.white};
  color: ${theme.muted};
  padding: ${rem(120)} 0;

  ${mediaUp.lg`
    padding: ${rem(182)} 0;
  `}
`;

const OrderHistoryLoading = styled(Loading)`
  background-color: ${theme.white};
`;

class OrderHistory extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      orders: [],
      modalActive: false,
      orderDate: '',
      orderIncomingCurrency: '',
      orderIncomingAmount: 0.0,
      orderIncomingAddress: '',
      orderRefundAddress: '',
      orderRate: '',
      orderExchange: '',
      orderStatus: '',
      orderOutgoingCurrency: '',
      orderOutgoingAmount: '',
      orderOutgoingAddress: '',
      currentOutgoingAmount: 0.0,
      currentOutgoingDiff: 0.0,
      currentOutgoingPerformance: 0.0
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/user/history`, { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        let orders = [];

        response.data.forEach((order) => {
          const dateEnd = order.timestamp.indexOf('T');
          const date = order.timestamp.substring(0, dateEnd);

          orders.push({
            date: date,
            incomingAmount: parseFloat(order.depositAmount),
            incomingCurrency: order.depositCoin,
            incomingAddress: order.exchangeAddress,
            exchange: order.exchange,
            status: order.status.toUpperCase(),
            outgoingAmount: parseFloat(order.destinationAmount),
            outgoingCurrency: order.destinationCoin,
            outgoingAddress: order.destinationAddress,
            refundAddress: order.refundAddress
          });
        });

        this.setState({
          loading: false,
          orders: orders
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({
            loading: false
          });

          this.props.logoutUser();
          this.props.history.push('/login');
        }
      });
    }
  }

  handleModalOpen(date, incomingCurrency, incomingAmount, incomingAddress, refundAddress, rate, exchange, status,
                    outgoingCurrency, outgoingAmount, outgoingAddress) {

    axios.get(`${process.env.REACT_APP_API_URL}/market/${exchange}/${incomingCurrency}-${outgoingCurrency}/${incomingAmount}`)
    .then((response) => {
      this.setState({
        currentOutgoingAmount: parseFloat(response.data[0].rate * incomingAmount).toFixed(8),
        currentOutgoingDiff: (response.data[0].rate * incomingAmount - outgoingAmount).toFixed(8),
        currentOutgoingPerformance: (100 * (response.data[0].rate * incomingAmount - outgoingAmount) / outgoingAmount).toFixed(2)
      });
    })
    .catch((error) => {

    });

    this.setState({
      modalActive: true,
      orderDate: date,
      orderIncomingCurrency: incomingCurrency,
      orderIncomingAmount: incomingAmount,
      orderIncomingAddress: incomingAddress,
      orderRefundAddress: refundAddress,
      orderRate: rate,
      orderExchange: exchange,
      orderStatus: status,
      orderOutgoingCurrency: outgoingCurrency,
      orderOutgoingAmount: outgoingAmount,
      orderOutgoingAddress: outgoingAddress
    }, () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    });
  }

  handleModalClose() {
    this.setState({
      modalActive: false,
      currentOutgoingAmount: 0,
      currentOutgoingDiff: 0,
      currentOutgoingPerformance: 0,
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Order History" />

        <Header />

        <AccountHeader />

        <OrderHistoryLoading loading={this.state.loading}>
          {this.state.orders ?
            <React.Fragment>
              <OrderHistoryHeader>
                <OrderHistoryContainer hasMobileGutters={true}>

                  <OrderHistoryStatusColumn>
                    <OrderHistoryHeaderTitle>Status</OrderHistoryHeaderTitle>
                  </OrderHistoryStatusColumn>

                  <OrderHistoryDateColumn>
                    <OrderHistoryHeaderTitle>Date</OrderHistoryHeaderTitle>
                  </OrderHistoryDateColumn>

                  <OrderHistoryFromColumn>
                    <OrderHistoryHeaderTitle>From</OrderHistoryHeaderTitle>
                  </OrderHistoryFromColumn>

                  <OrderHistoryToColumn>
                    <OrderHistoryHeaderTitle>To (Estimate)</OrderHistoryHeaderTitle>
                  </OrderHistoryToColumn>

                </OrderHistoryContainer>
              </OrderHistoryHeader>

              <OrderHistoryBody>
                <Scrollbars style={{ height: 400 }}>
                  {this.state.orders.map((order, index) => {
                    const outgoingAddressSliced = `${order.outgoingAddress.slice(0, 42)} ${order.outgoingAddress.slice(42, 82)} ${order.outgoingAddress.slice(82)}`;
                    const incomingAddressSliced = `${order.incomingAddress.slice(0, 42)} ${order.incomingAddress.slice(42, 82)} ${order.incomingAddress.slice(82)}`;
                    const refundAddressSliced = `${order.refundAddress.slice(0, 42)} ${order.refundAddress.slice(42, 82)} ${order.refundAddress.slice(82)}`;
                    return (
                      <OrderHistoryOrder key={index}>
                        <OrderHistoryContainer hasMobileGutters={true}>
                          <OrderHistoryStatusColumn>
                            <OrderHistoryCell>
                              <OrderHistoryHeaderMobileTitle>Status</OrderHistoryHeaderMobileTitle>

                              <OrderHistoryDate>
                                <strong>{order.status}</strong>
                              </OrderHistoryDate>
                            </OrderHistoryCell>
                          </OrderHistoryStatusColumn>

                          <OrderHistoryDateColumn>
                            <OrderHistoryCell>
                              <OrderHistoryHeaderMobileTitle>Date</OrderHistoryHeaderMobileTitle>

                              <OrderHistoryDate>
                                <strong>{order.date}</strong>
                              </OrderHistoryDate>
                            </OrderHistoryCell>
                          </OrderHistoryDateColumn>

                          <OrderHistoryFromColumn>
                            <OrderHistoryCell>
                              <OrderHistoryHeaderMobileTitle>From</OrderHistoryHeaderMobileTitle>

                              <OrderHistoryAmount>
                                {(order.incomingAmount.length > 8) ? parseFloat(order.incomingAmount.toFixed(8)) : order.incomingAmount}
                                &nbsp;{order.incomingCurrency}
                              </OrderHistoryAmount>
                              <OrderHistoryAddress>{incomingAddressSliced}</OrderHistoryAddress>
                            </OrderHistoryCell>
                          </OrderHistoryFromColumn>

                          <OrderHistoryToColumn>
                            <OrderHistoryCell>
                              <OrderHistoryHeaderMobileTitle>To (Estimate)</OrderHistoryHeaderMobileTitle>

                              <div>
                                <OrderHistoryAmount>
                                  {(order.outgoingAmount.length > 8) ? parseFloat(order.outgoingAmount.toFixed(8)) : order.outgoingAmount}
                                  &nbsp;{order.outgoingCurrency}
                                </OrderHistoryAmount>
                                <OrderHistoryAddress>{outgoingAddressSliced}</OrderHistoryAddress>
                              </div>

                              <OrderHistoryButton secondary={true}
                                                  handleButtonClick={() => {
                                                    this.handleModalOpen(
                                                      order.date,
                                                      order.incomingCurrency,
                                                      order.incomingAmount,
                                                      incomingAddressSliced,
                                                      refundAddressSliced,
                                                      order.rate,
                                                      order.exchange,
                                                      order.status,
                                                      order.outgoingCurrency,
                                                      order.outgoingAmount,
                                                      outgoingAddressSliced,
                                                    )
                                                  }}
                              >
                                View Order
                              </OrderHistoryButton>
                            </OrderHistoryCell>
                          </OrderHistoryToColumn>
                        </OrderHistoryContainer>
                      </OrderHistoryOrder>
                    )
                  })}
                </Scrollbars>
              </OrderHistoryBody>
            </React.Fragment>
            :
            <OrderHistoryTitle>No Orders Found</OrderHistoryTitle>
          }
        </OrderHistoryLoading>

        <HelpHero />

        <Modal isActive={this.state.modalActive} handleModalClose={this.handleModalClose}>
          <Order
            date={this.state.orderDate}
            incomingCurrency={this.state.orderIncomingCurrency}
            incomingAmount={this.state.orderIncomingAmount}
            incomingAddress={this.state.orderIncomingAddress}
            refundAddress={this.state.orderRefundAddress}
            rate={this.state.orderRate}
            exchange={this.state.orderExchange}
            status={this.state.orderStatus}
            outgoingCurrency={this.state.orderOutgoingCurrency}
            outgoingAmount={this.state.orderOutgoingAmount}
            outgoingAddress={this.state.orderOutgoingAddress}
            currentOutgoingAmount={this.state.currentOutgoingAmount}
            currentOutgoingDiff={this.state.currentOutgoingDiff}
            currentOutgoingPerformance={this.state.currentOutgoingPerformance}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <OrderHistory {...props}
                      authenticated={authenticated}
                      authenticationLoading={authenticationLoading}
                      logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};

