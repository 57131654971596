import React from 'react';
import styled, { css } from 'styled-components';
import { theme, mediaUp, flexColumn, offsetColumn } from '../../globalStyle';

const Column = styled.div`
  ${props => props.maxColumns
    ? css`
      ${props => props.columns 
        ? css`
          ${flexColumn(props.columns, props.maxColumns)}
        `
        : css`
          ${flexColumn(props.maxColumns, props.maxColumns)}
        `
      }
  
      ${props => props.smColumns && mediaUp.sm`
        ${flexColumn(props.smColumns, props.maxColumns)}
      `}
      
      ${props => props.mdColumns && mediaUp.md`
        ${flexColumn(props.mdColumns, props.maxColumns)}
      `}
      
      ${props => props.lgColumns && mediaUp.lg`
        ${flexColumn(props.lgColumns, props.maxColumns)}
      `}
      
      ${props => props.xlColumns && mediaUp.xl`
        ${flexColumn(props.xlColumns, props.maxColumns)}
      `}
      
      ${props => props.offset && offsetColumn(props.offset, props.maxColumns)}
  
      ${props => props.smOffset && mediaUp.sm`
        ${offsetColumn(props.smOffset, props.maxColumns)}
      `}
      
      ${props => props.mdOffset && mediaUp.md`
        ${offsetColumn(props.mdOffset, props.maxColumns)}
      `}
      
      ${props => props.lgOffset && mediaUp.lg`
        ${offsetColumn(props.lgOffset, props.maxColumns)}
      `}
      
      ${props => props.xlOffset && mediaUp.xl`
        ${offsetColumn(props.xlOffset, props.maxColumns)}
      `}
    `
    : css`
      ${props => props.columns
        ? css`
          ${flexColumn(props.columns)}
        `
        : css`
          ${flexColumn(theme.gridColumns)}
        `
      }
      
      ${props => props.smColumns && mediaUp.sm`
        ${flexColumn(props.smColumns)}
      `}
      
      ${props => props.mdColumns && mediaUp.md`
        ${flexColumn(props.mdColumns)}
      `}
      
      ${props => props.lgColumns && mediaUp.lg`
        ${flexColumn(props.lgColumns)}
      `}
      
      ${props => props.xlColumns && mediaUp.xl`
        ${flexColumn(props.xlColumns)}
      `}
      
      ${props => props.offset && offsetColumn(props.offset)}
  
      ${props => props.smOffset && mediaUp.sm`
        ${offsetColumn(props.smOffset)}
      `}
      
      ${props => props.mdOffset && mediaUp.md`
        ${offsetColumn(props.mdOffset)}
      `}
      
      ${props => props.lgOffset && mediaUp.lg`
        ${offsetColumn(props.lgOffset)}
      `}
      
      ${props => props.xlOffset && mediaUp.xl`
        ${offsetColumn(props.xlOffset)}
      `}
    `
  }
`;

const GridColumn = (props) => {
  return (
    <Column columns={props.columns}
            smColumns={props.smColumns}
            mdColumns={props.mdColumns}
            lgColumns={props.lgColumns}
            xlColumns={props.xlColumns}
            maxColumns={props.maxColumns}
            offset={props.offset}
            smOffset={props.smOffset}
            mdOffset={props.mdOffset}
            lgOffset={props.lgOffset}
            xlOffset={props.xlOffset}
            className={props.className}
    >
      {props.children}
    </Column>
  )
};

export default GridColumn;