import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, psLetterSpacing, mediaDown } from "../../globalStyle";
import check from '../../assets/images/check_white.png';

function getBackgroundColor(primary, secondary, tertiary, success, danger) {
  if (primary) return theme.primary;
  if (secondary) return theme.secondary;
  if (tertiary) return theme.tertiary;
  if (success) return theme.success;
  if (danger) return theme.danger;

  return theme.gray400;
}

const StyledAlert = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  align-items: center;
  padding: ${rem(20)} ${rem(25)};
  position: relative;
  background-color: ${props => 
    getBackgroundColor(props.primary, props.secondary, props.tertiary, props.success, props.danger)};
`;

const AlertCopy = styled.p`
  margin-bottom: 0;
  line-height: normal;
  text-transform: uppercase;
  font-weight: ${theme.avenirWeightBlack};
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(12, 60)};
  color: ${theme.white};
`;

const AlertNote = styled.span`
  display: inline-block;
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(12, 60)};
  margin-left: auto;
  color: ${theme.white};
  line-height: normal;
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const AlertCheck = styled.img`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  
  ${mediaDown.lg`
    display: none;
  `}
`;

function Alert(props) {
  return (
    <StyledAlert primary={props.primary}
                 secondary={props.secondary}
                 tertiary={props.tertiary}
                 success={props.success}
                 danger={props.danger}
                 className={props.className}
                 show={props.show}
    >
      <AlertCopy>
        {props.children}
      </AlertCopy>

      {props.note &&
        <AlertNote>{props.note}</AlertNote>
      }

      {props.hasCheck && !props.note &&
        <AlertCheck src={check} aria-hidden={true} />
      }
    </StyledAlert>
  )
}

export default Alert;
