import React from 'react';
import styled, { css } from 'styled-components';
import CoinContext from '../../contexts/CoinContext';
import { rem } from 'polished';
import { HashLink as NavLink } from 'react-router-hash-link';
import { theme, psLineHeight, psLetterSpacing, mediaDown, mediaUp } from '../../globalStyle';

const StyledNavigation = styled.nav`
  margin-left: auto;
  margin-right: auto;
  
  ${mediaDown.lg`
    position: fixed;
    top: ${theme.headerMobileHeight};
    left: 0;
    right: 0;
    transition: 0.5s opacity ease;
    background-color: ${theme.primary};
    padding-top: 45px;
    padding-bottom: 45px;
    border-top: 1px solid ${theme.tertiary};
    height: 100vh;
    -webkit-overflow-scrolling: touch;
    
    ${props => props.toggled 
      ? 
      css`
        opacity: 1;
        pointer-events: auto;
      `
      :
      css`
        opacity: 0;
        pointer-events: none;
      `
    }
  `}
  
  ${mediaDown.sm`
    padding-left: ${theme.headerMobileGutter};
    padding-right: ${theme.headerMobileGutter};
  `}
`;

const NavigationList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  
  ${mediaUp.lg`
    display: flex;
    padding-left: 160px;
    padding-right: 40px;
    margin-left: -${theme.navItemDesktopGutter};
    margin-right: -${theme.navItemDesktopGutter};
  `}

  ${mediaDown.lg`
    max-width: ${theme.mdMaxContainerWidth}
    margin: 0 auto;
  `}
  
  ${mediaDown.md`
    max-width: ${theme.smMaxContainerWidth}
  `}
`;

const NavigationListItem = styled.li`
  letter-spacing: 0;
  font-size: ${rem(21)};
  line-height: ${psLineHeight(22, 47)};
  margin-bottom: ${rem(12)};
  
  ${mediaUp.lg`
    font-family: ${theme.fontFamilyComfortaa};
    flex: 0 0 1;
    margin: 0 ${theme.navItemDesktopGutter};
    text-transform: uppercase;
    letter-spacing: ${psLetterSpacing(11, 200)};
    font-size: ${rem(10)};
    line-height: ${psLineHeight(11, 34)};
  `}
`;

const NavigationDesktopListItem = NavigationListItem.extend`
  ${mediaDown.lg`
    display: none;
  `}
`;

const NavigationMobileListItem = NavigationListItem.extend`
  ${mediaUp.lg`
    display: none;
  `}
`;

const NavigationListLink = styled(NavLink)`
  color: ${theme.white};
  transition: 0.3s opacity ease;
  
  &:hover {
    color: ${theme.white};
    opacity: 0.7;
    text-decoration: none;
  }
`;

const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth"
  });
}

class Navigation extends React.PureComponent {
  constructor(props) {
    super(props);

    this.nav = React.createRef();
  }

  componentDidMount() {
    let _clientY = null; // remember Y position on touch start
    const overlay = this.nav;

    function isOverlayTotallyScrolled() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
      return overlay.scrollHeight - overlay.scrollTop <= overlay.clientHeight;
    }

    function disableRubberBand(event) {
      const clientY = event.targetTouches[0].clientY - _clientY;

      if (overlay.scrollTop === 0 && clientY > 0) {
        // element is at the top of its scroll
        event.preventDefault();
      }

      if (isOverlayTotallyScrolled() && clientY < 0) {
        //element is at the top of its scroll
        event.preventDefault();
      }
    }

    overlay.addEventListener('touchstart', event => {
      if (event.targetTouches.length === 1) {
        // detect single touch
        _clientY = event.targetTouches[0].clientY;
      }
    }, false);

    overlay.addEventListener('touchmove', event => {
      if (event.targetTouches.length === 1) {
        // detect single touch
        disableRubberBand(event);
      }
    }, false);
  }

  render() {
    return (
      <StyledNavigation
        toggled={this.props.toggled}
        aria-expanded={this.props.toggled ? 'true' : 'false'}
        innerRef={nav => { this.nav = nav}}
      >
        <NavigationList>
          <CoinContext.Consumer>
          {({ authenticated }) => (
              authenticated
                ?
                <React.Fragment>
                  <NavigationListItem>
                    <NavigationListLink to="/account/referrals">Referral Program</NavigationListLink>
                  </NavigationListItem>
                </React.Fragment>
                :
                <React.Fragment>
                  <NavigationListItem>
                    <NavigationListLink smooth to="/faq#referrals" scroll={el => scrollWithOffset(el, 120)}>Referral Program</NavigationListLink>
                  </NavigationListItem>
                </React.Fragment>
            )}
          </CoinContext.Consumer>
          <NavigationListItem>
            <NavigationListLink to="/supported-coins">Supported Coins</NavigationListLink>
          </NavigationListItem>

          <NavigationListItem>
            <NavigationListLink to="/track-order">Track Order</NavigationListLink>
          </NavigationListItem>

          <NavigationDesktopListItem>
            <NavigationListLink to="/faq">Support</NavigationListLink>
          </NavigationDesktopListItem>

          <NavigationMobileListItem>
            <NavigationListLink to="/faq">FAQ</NavigationListLink>
          </NavigationMobileListItem>

          <NavigationMobileListItem>
            <NavigationListLink to="/contact">Contact Us</NavigationListLink>
          </NavigationMobileListItem>

          <CoinContext.Consumer>
            {({ authenticated, logoutUser, admin }) => (
              authenticated
                ?
                <React.Fragment>
                  <NavigationMobileListItem>
                    <NavigationListLink to="/account/profile">Account</NavigationListLink>
                  </NavigationMobileListItem>

                  { admin && 
                    <NavigationMobileListItem>
                      <NavigationListLink to="/manage/configs">Manage</NavigationListLink>
                    </NavigationMobileListItem>
                  }

                  <NavigationMobileListItem>
                    <NavigationListLink to="/" onClick={logoutUser}>Log Out</NavigationListLink>
                  </NavigationMobileListItem>
                </React.Fragment>
                :
                <React.Fragment>
                  <NavigationMobileListItem>
                    <NavigationListLink to="/login">Login</NavigationListLink>
                  </NavigationMobileListItem>

                  <NavigationMobileListItem>
                    <NavigationListLink to="/register">Register</NavigationListLink>
                  </NavigationMobileListItem>
                </React.Fragment>
            )}
          </CoinContext.Consumer>
        </NavigationList>
      </StyledNavigation>
    );
  }
}

export default Navigation;
