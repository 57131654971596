import React from 'react';
import Button from '../Button/Button';
import TextInput from '../Input/TextInput';
import CheckboxInput from '../Input/CheckboxInput';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import arrow from '../../assets/images/order_arrow.png';
import axios from 'axios';
import 'formdata-polyfill';

const configGutter = rem(22);

const ConfigHeader = styled.div`
  padding: ${rem(22)} ${configGutter};
  
  ${mediaUp.lg`
    padding: ${rem(26)} ${configGutter};
  `}
`;

const ConfigSettings = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;

const ConfigName = styled.div`
  padding: ${rem(18)} ${configGutter} ${rem(35)};
  text-align: center;
  
  ${mediaUp.lg`
    padding: ${rem(18)} ${configGutter} ${rem(54)};
  `}
`;

const ConfigArrow = styled.img`
  margin-bottom: ${rem(18)};
`;

const ConfigTo = styled.h3`
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(27)};
  color: ${theme.gray1000};
  
  ${mediaUp.lg`
    font-size: ${rem(34)};
  `}
`;

const ConfigLineItem = styled.div`
  padding: ${rem(15)} ${configGutter};
  border-top: 1px solid ${theme.muted};
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
    padding: ${rem(20)} ${configGutter};
  `}
  
  ${mediaDown.lg`
    text-align: center;
  `}
  
  &:last-of-type {
    border-bottom: 1px solid ${theme.muted};
  }
`;

const ConfigLineItemCopy = styled.span`
  font-size: ${rem(13)};
  line-height: normal;
  
  ${mediaDown.lg`
    display: block;
  `}
`;

const ConfigLineItemLabel = ConfigLineItemCopy.extend`
  color: ${theme.gray500};
  font-weight: ${theme.avenirWeightMedium};
  letter-spacing: ${psLetterSpacing(13, 20)};
  padding-right: 0.75rem;
  
  ${mediaDown.lg`
    margin-bottom: 0.5rem;
  `}
`;

const ConfigLineItemValue = ConfigLineItemCopy.extend`
  color: ${theme.tertiary};
  letter-spacing: 0;
  
  ${mediaUp.lg`
    margin-left: auto;
  `}
`;

const ConfigFooter = styled.div`
  padding: ${rem(20)} ${configGutter};
  text-align: center;

  ${mediaUp.lg`
    padding: ${rem(30)} ${configGutter};
  `}
`;

const ConfigButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

function Config(props) {

  return (
    <React.Fragment>
      {props.name &&
        <ConfigHeader>
          <ConfigSettings>
            <strong>Settings</strong>
          </ConfigSettings>
        </ConfigHeader>
      }

      <ConfigName>
        <ConfigArrow src={arrow} alt="Settings" />
        {props.name &&
          <ConfigTo>
            {props.name}
          </ConfigTo>
        }
      </ConfigName>

      <form onSubmit={(event) => {
        const data = new FormData(event.target);
        event.preventDefault();

        const params = {
          name: data.get('name') || props.name,
          description: data.get('description') || props.description,
          quantity: data.get('quantity') || props.quantity,
          enabled: data.get('enabled') === null ? false : true,
        };

        if (props.quantity !== params.quantity || props.enabled !== params.enabled) {

          axios.post(`${process.env.REACT_APP_API_URL}/admin/configs`, 
          {
            name: data.get('name') || props.name,
            description: data.get('description') || props.description,
            quantity: data.get('quantity') || props.quantity,
            enabled: data.get('enabled') === null ? false : true,
          }
          ,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          },
          )
          .then((response) => {
            alert(`Config has been saved.`);
          })
          .catch((error) => {
            alert(`Issue encountered, please try again.`);
          });
        }
      }}>
        {props.description &&
          <ConfigLineItem>
            <ConfigLineItemLabel>Description</ConfigLineItemLabel>
            <ConfigLineItemValue>
              {props.description}
            </ConfigLineItemValue>
          </ConfigLineItem>
        }

        {props.quantity &&
          <ConfigLineItem>
            <ConfigLineItemLabel>Quantity</ConfigLineItemLabel>
            <ConfigLineItemValue>
              <TextInput type="number"
                name="quantity"
                hasLabel={false}
                placeholder={props.quantity}
                initialValue={props.quantity}
                required={true}
              />
            </ConfigLineItemValue>
          </ConfigLineItem>
        }

        <ConfigLineItem>
          <ConfigLineItemLabel>Enabled</ConfigLineItemLabel>
          <ConfigLineItemValue>
          <CheckboxInput
            name="enabled"
            isChecked={props.enabled}
          />
          </ConfigLineItemValue>
        </ConfigLineItem>

        <ConfigFooter>
          <ConfigButton
            arrow={true}
            secondary={true}
            block={true}
            >
            Save
          </ConfigButton>
        </ConfigFooter>
      </form>
    </React.Fragment>
  )
}

export default Config;
