import React from 'react';
import styled, { css } from 'styled-components';
import { theme, mediaUp, mediaDown, psLetterSpacing, psLineHeight } from '../../globalStyle';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import arrow from '../../assets/images/arrow_tertiary.svg';

const StyledNewsBlock = styled.div`
  position: relative;

  ${mediaDown.lg`
    margin-bottom: ${rem(29)};
  `}

  ${mediaUp.lg`
    height: 100%;
    padding: ${rem(40)} ${rem(49)};

    ${props => props.first && css`
      padding-bottom: 0;
    `}

    ${props => props.borderRight && css`
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 40px;
        bottom: 10px;
        height: calc(100% - 80px);
        width: 1px;
        background-color: ${theme.gray300};
      }
    `}

    ${props => props.borderBottom && css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 49px;
        right: 49px;
        width: calc(100% - 98px);
        height: 1px;
        background-color: ${theme.gray300};
      }
    `}
  `}
`;

const NewsBlockContent = styled.div`
  text-align: center;
  width: 373px;
  margin: 0 auto;
  ${mediaUp.lg`
    display: flex;
    flex-direction: column;
    height: 100%;
  `}
  ${mediaDown.lg`
    width: 80%
  `}
`;

const NewsBlockTitle = styled.h6`
  text-align: left;
  font-size: ${rem(20)};
  letter-spacing: ${psLetterSpacing(17, 60)};
  margin-bottom: ${rem(9)};
`;

const NewsBlockCopy = styled.p`
  text-align: left;
  line-height: ${psLineHeight(16, 26)};
  color: ${theme.gray800};
  margin-bottom: 0;
`;

const NewsBlockIconWrapper = styled.div`
  width: 172px;
  display: flex;
  flex-direction: column;
  margin: 0 auto ${rem(30)};

  ${mediaUp.lg`
    height: 195px;
  `}
`;

const NewsBlockIcon = styled.img`
  width: 100%;
  height: auto;
  margin-top: auto;

  .info-block--is-last & {
    position: relative;
    top: 6px;
  }
`;

const NewsBlockLink = styled.a`
  padding: ${rem(30)} 0 ${rem(40)};

  margin-top: 0px;
  line-height: 0;
  color: ${theme.gray500}

  &:hover {
  color: ${theme.blue600};
  text-decoration: underline;
  opacity: 0.7;
  }

  ${mediaDown.lg`
    padding-bottom: 0;
  `}
`;

const NewsFeedLinkArrow = styled.img`
margin: 0 auto;
text-align: centre;
  ${mediaDown.lg`
    margin: 0 auto;
  `}
`;

const NewsBlockLinkArrow = styled.img``;

function NewsBlock(props) {
  return (
    <StyledNewsBlock borderRight={props.borderRight}
                     borderBottom={props.borderBottom}
                     first={props.first}
                     className={props.className}
    >
      <NewsBlockContent>
        {props.icon &&
          <NewsBlockIconWrapper>
            <NewsBlockIcon src={props.icon} aria-hidden="true" />
          </NewsBlockIconWrapper>
        }

        {props.copy &&
          <NewsBlockCopy>{props.copy}</NewsBlockCopy>
        }

        {props.title &&
          <NewsBlockTitle>{props.title}</NewsBlockTitle>
        }

        {props.link &&
          <NewsBlockLink href={props.link} target="_blank">
            Learn More
          </NewsBlockLink>
        }
      </NewsBlockContent>
    </StyledNewsBlock>
  )
}

export default NewsBlock;