import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { theme, psLetterSpacing } from '../../globalStyle';

const StyledOutlineText = styled.h2`
  ${props => props.strokeColor && css`
    color: ${props.strokeColor};
    -webkit-text-stroke-color: ${props.strokeColor};
  `}
  
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 2px;
  font-family: ${theme.fontFamilyIBMPlexSans};
  font-weight: 600;
  font-size: ${rem(156)};
  line-height: 120px;
  margin-bottom: 0;
  letter-spacing: ${psLetterSpacing(156, -35)};
`;

function OutlineText(props) {
  return (
    <StyledOutlineText
      className={props.className}
      strokeColor={props.strokeColor}
    >
      {props.children}
    </StyledOutlineText>
  )
}

export default OutlineText;
