import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import TextInput from '../Input/TextInput';
import Button from '../Button/Button';
import HelpHero from '../HelpHero/HelpHero';
import Alert from '../Alert/Alert';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import axios from 'axios';
import 'formdata-polyfill';
import { rem } from 'polished';
import { mediaUp, psLetterSpacing, psLineHeight } from '../../globalStyle';

const TrackOrderPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(64)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(92)};
  `}
`;

const TrackOrderPageTitle = styled(PageTitle)`
  ${mediaUp.lg`
    padding: ${rem(101)} 0 ${rem(44)};
  `}
`;

const TrackOrderContent = styled.div`
  text-align: center;
  
  ${mediaUp.lg`
    width: 40%;
    margin: 0 auto;
  `}
`;

const TrackOrderButton = styled(Button)`
  width: 100%;
  text-align: center;
  margin: ${rem(10)} auto 0;
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, 120)};
  line-height: ${psLineHeight(14, 20)};
  
  ${mediaUp.lg`
    max-width: 230px;
    margin: ${rem(33)} auto 0;
  `}
`;

const TrackOrderCopy = styled.p`
  margin: ${rem(20)} 0 0;
  font-size: ${rem(12)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
`;

const TrackOrderAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

class TrackOrder extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      status: '',
      hasMessage: false,
      hasError: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    event.preventDefault();

    this.setState({
      loading: true
    });

    axios.get(`${process.env.REACT_APP_API_URL}/status/${data.get('transactionId')}`)
      .then((response) => {
        this.setState({
          loading: false,
          status: response.data.status,
          hasMessage: true
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          hasError: true
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Track Order" />

        <Header />

        <TrackOrderPageContainer backgroundLight={true} headerOffset={true}>
          <TrackOrderPageTitle>Track Order</TrackOrderPageTitle>

          <TrackOrderContent>
            <TrackOrderAlert show={this.state.hasMessage}
                             primary={this.state.status === 'pending' || this.state.status === 'open' || this.state.status === 'processing'}
                             success={this.state.status === 'closed' || this.state.status === 'complete'}
                             danger={this.state.status === 'expired' || this.state.status === 'error'}
            >
              Your order is {this.state.status}.
            </TrackOrderAlert>

            <TrackOrderAlert show={this.state.hasError} danger={true}>
              There was an error with your request. Please try again.
            </TrackOrderAlert>

            <form onSubmit={this.handleSubmit}>
              <TextInput name="transactionId" type="text" placeholder="Enter Order ID Number" required={true} />

              <TrackOrderButton secondary={true} hasArrow={true} loading={this.state.loading}>Track Order</TrackOrderButton>

              <TrackOrderCopy>
                <Link to="/">Go back to home</Link>
              </TrackOrderCopy>
            </form>
          </TrackOrderContent>
        </TrackOrderPageContainer>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default TrackOrder;

