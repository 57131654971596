import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, psLetterSpacing, psLineHeight } from "../../globalStyle";


const StyledOrderStep = styled.div`
  display: ${props => props.active ? 'block' : 'none'};
`;

const OrderStepBody = styled.div`
  background-color: ${theme.white};
  padding: ${rem(32)} ${rem(27)} ${rem(57)};
  
  ${mediaUp.lg`
    padding: ${rem(54)} ${rem(54)} ${rem(95)};
  `}
`;

const OrderStepContent = styled.div`
  margin-bottom: ${rem(40)};
`;

const OrderStepTitle = styled.h2`
  color: ${theme.gray1000};
  margin-bottom: ${rem(10)};
  
  ${mediaUp.lg`
    font-size: ${rem(38)};
    letter-spacing: ${psLetterSpacing(38, -40)};
    line-height: 0.75;
    margin-bottom: ${rem(20)};
  `}
`;

const OrderStepCopy = styled.p`
  font-size: ${rem(15)};
  letter-spacing: ${psLetterSpacing(15, 10)};
  line-height: ${psLineHeight(15, 15)};
  margin-bottom: ${rem(35)};
  
  ${mediaUp.lg`
    line-height: ${psLineHeight(15, 20)};
    max-width: 587px;
  `}
`;

const OrderStepLabel = styled.p`
  color: ${theme.gray1000};
  font-size: ${rem(10)};
  font-weight: ${theme.avenirWeightHeavy};
  letter-spacing: ${psLetterSpacing(10, 140)};
  margin-bottom: ${rem(16)};
  line-height: 10px;
  text-transform: uppercase;
  display: block;
`;

const OrderStepOrderID = styled.p`
  color: ${theme.primary};
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, -10)};
  line-height: normal;
  display: inline-block;
  margin-bottom: 0;
  word-break: break-all;
  
  ${mediaUp.lg`
    font-size: ${rem(20)};
    letter-spacing: ${psLetterSpacing(20, -10)};
  `}
`;

function OrderStep(props) {
  return (
    <StyledOrderStep active={props.currentStep === 3}>
      <OrderStepBody>
        <OrderStepContent>
          <OrderStepTitle>Your Order is Being Processed</OrderStepTitle>
          <OrderStepCopy>
            {props.orderID &&
              <React.Fragment>
                <OrderStepLabel>Order ID</OrderStepLabel>
                <OrderStepOrderID>{props.orderID}</OrderStepOrderID>
              </React.Fragment>
            }<br /><br />
            Cryptocurrency exchanges can take some time. You can close the browser window and come back anytime to check the status using your unique order id.
          </OrderStepCopy>

          {props.incomingCurrency && props.outgoingAddress && 
            <OrderStepCopy>
              <strong>The exchange wallet address where you transferred {props.incomingCurrency}?</strong><br />
              <span>{props.incomingAddress ? props.incomingAddress : 'Not Applicable'}</span><br /><br />
              <strong>How can you track the {props.incomingCurrency} deposit?</strong><br />
              <span>We recommend using a blockchain explorer suitable for your deposit coin.</span><br /><br />
              <strong>How long does it take?</strong><br />
              <span>It usually takes 20-30 minutes. Please read <Link to="/faq">here</Link>, if it is taking longer.</span><br /><br />
              <strong>Where your {props.outgoingCurrency} will be Transferred?</strong><br />
              <span>{props.outgoingAddress}</span><br /><br />
              <strong>What is the estimated exchanged {props.outgoingCurrency} amount?</strong><br />
              {props.outgoingSymbol} = {props.outgoingAmount} (network fee is not considered)
            </OrderStepCopy>
          }

        </OrderStepContent>
      </OrderStepBody>
    </StyledOrderStep>
  )
}

export default OrderStep;
