import React from 'react';
import Container from '../Container/Container';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";
import arrow from '../../assets/images/exchange_mobile_arrow.png';

const StyledExchangeHeader = styled.div`
  padding: ${rem(30)} 0;
  background-color: ${theme.white};
  margin-top: ${theme.headerMobileHeight};
  
  ${mediaUp.lg`
    padding: ${rem(28)} 0;
    margin-top: ${theme.headerDesktopHeight};
  `}
`;

const ExchangeHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

const ExchangeHeaderMobileContent = ExchangeHeaderContent.extend`
  ${mediaUp.lg`
    display: none;
  `}
`;

const ExchangeHeaderDesktopContent = ExchangeHeaderContent.extend`
  ${mediaDown.lg`
    display: none;
  `}
`;

const ExchangeHeaderTitle = styled.h3`
  display: inline-block;
  margin-bottom: 0;
  font-size: ${rem(12)};
  font-weight: ${theme.avenirWeightMedium};
  letter-spacing: ${psLetterSpacing(12, 160)};
  color: ${theme.gray1000};
  line-height: normal;
  
  ${mediaUp.lg`
    font-size: ${rem(24)};
    font-weight: ${theme.avenirWeightBook};
    letter-spacing: ${psLetterSpacing(24, -20)};
  `}
  
  strong {
    font-weight: ${theme.avenirWeightHeavy};
  }
`;

const ExchangeHeaderLabel = styled.span`
  display: inline-block;
  font-family: ${theme.fontFamilyNunito};
  font-size: ${rem(11)};
  letter-spacing: ${psLetterSpacing(11, 0)};
  color: ${theme.gray700};
  margin-left: auto;
  line-height: normal;
`;

const ExchangeHeaderLogo = styled.img`
  margin-left: auto;
  max-width: 150px;
  height: auto;
`;

const ExchangeHeaderName = styled.span`
  text-transform: capitalize;
`;

function ExchangeHeader(props) {
  return (
    <StyledExchangeHeader>
      <Container hasMobileGutters={true}>
        <ExchangeHeaderMobileContent>
          <ExchangeHeaderTitle>
            {props.incomingAmount} {props.incomingCurrency}&nbsp;&nbsp;<img src={arrow} alt="to" />&nbsp;&nbsp;{props.outgoingAmount} {props.outgoingCurrency}
          </ExchangeHeaderTitle>

          <ExchangeHeaderLabel>
            via <ExchangeHeaderName>{props.exchangeName}</ExchangeHeaderName>
          </ExchangeHeaderLabel>
        </ExchangeHeaderMobileContent>

        <ExchangeHeaderDesktopContent>
          <ExchangeHeaderTitle>
            Exchanging {props.incomingAmount} {props.incomingCurrency} to <strong>{props.outgoingAmount}</strong> {props.outgoingCurrency} from <ExchangeHeaderName>{props.exchangeName}</ExchangeHeaderName>
          </ExchangeHeaderTitle>

          {props.exchangeLogo &&
            <ExchangeHeaderLogo src={process.env.REACT_APP_API_URL + props.exchangeLogo} aria-hidden={true} />
          }
        </ExchangeHeaderDesktopContent>
      </Container>
    </StyledExchangeHeader>
  )
}

export default ExchangeHeader;
