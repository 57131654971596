import React from 'react';
import Header from '../Header/Header';
import Hero from './Hero';
import HeroCoins from './HeroCoins';
import Info from './Info';
import HowItWorks from './HowItWorks';
import Partners from './Partners';
import Testimonials from './Testimonials';
import BlockLinks from './BlockLinks';
import Meta from '../Meta/Meta';
import NewsFeed from './NewsFeed';
import RssFeed from './RssFeed';

function Home(props) {
  return (
    <main>
      <Meta title="Home" />
      <Header isTransparent={true} />
      {!props.match.params.from && !props.match.params.to && <Hero />}
      {props.match.params.from && props.match.params.to && (
        <HeroCoins
          incomingCurrency={props.match.params.from}
          outgoingCurrency={props.match.params.to}
        />
      )}
      <RssFeed />
      <Info />
      <HowItWorks />
      <Partners />
      <NewsFeed />
      <Testimonials />
      <BlockLinks />
    </main>
  );
}

export default Home;
