import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import Container from '../Container/Container';
import styled from 'styled-components';
import help from '../../assets/images/help_icon.svg';
import swap from '../../assets/images/swap.svg';
import { rem } from 'polished';
import { theme, psLetterSpacing, mediaUp, mediaDown } from "../../globalStyle";

const StyledAccountHeader = styled.div`
  border-bottom: 1px solid ${theme.muted};
  margin-top: ${theme.headerMobileHeight};
  background-color: ${theme.white};
  
  ${mediaUp.lg`
    margin-top: ${theme.headerDesktopHeight};
  `}
`;

const AccountHeaderContainer = styled(Container)`
  display: flex;
  
  ${mediaDown.sm`
    justify-content: space-between;
  `}
`;

const AccountHeaderLink = styled(NavLink)`
  display: inline-block;
  padding: ${rem(20)} 0 ${rem(18)};
  color: ${theme.tertiary};
  font-size: ${rem(13)};
  letter-spacing: ${psLetterSpacing(15, 20)};
  position: relative;
  
  ${mediaUp.sm`
    padding: ${rem(30)} 0 ${rem(28)};
    font-size: ${rem(15)};
  `}
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    width: 100%;
    opacity: 0;
    background-color: ${theme.primary};
    transition: 0.3s opacity ease;
  }
  
  &:hover {
    color: ${theme.tertiary};
    text-decoration: none;
  }
  
  &:hover,
  &.active {
    &::after {
      opacity: 1;
    }
  }
  
  ${mediaUp.sm`
    margin-right: ${rem(38)};
  `}
`;

const AccountHeaderHelpLink = styled(Link)`
  display: none;
  margin-left: auto;
  padding: ${rem(30)} 0 ${rem(28)};
  color: ${theme.gray600};
  font-size: ${rem(15)};
  letter-spacing: ${psLetterSpacing(15, 20)};
  
  ${mediaUp.sm`
    display: inline-flex;
    align-items: center;
  `}
  
  &:hover {
    color: ${theme.blue600};
    text-decoration: none;
  }
  
  span {
    display: inline-block;
  }
  
  img {
    width: 22px;
    height: auto;
    margin-left: ${rem(15)};
  }
`;

const AccountHeaderSwapLink = styled(Link)`
  display: inline-block;
  padding: ${rem(20)} 0 ${rem(18)};
  color: ${theme.gray600};
  font-size: ${rem(13)};
  letter-spacing: ${psLetterSpacing(15, 20)};
  position: relative;

  ${mediaUp.sm`
    padding: ${rem(30)} 0 ${rem(28)};
    font-size: ${rem(15)};
    margin-right: ${rem(38)};
  `}
  
  &:hover {
    color: ${theme.blue600};
    text-decoration: none;
  }
  
  span {
    display: inline-block;
  }
`;

function AccountHeader() {
  return (
    <StyledAccountHeader>
      <AccountHeaderContainer hasMobileGutters={true}>
        <AccountHeaderSwapLink to="/">
          <span>Swap Now</span>
        </AccountHeaderSwapLink>

        <AccountHeaderLink to="/account/profile" activeClassName="active">Profile</AccountHeaderLink>
        <AccountHeaderLink to="/account/limits" activeClassName="active">Swap Limits</AccountHeaderLink>
        <AccountHeaderLink to="/account/order-history" activeClassName="active">Order History</AccountHeaderLink>
        <AccountHeaderLink to="/account/referrals" activeClassName="active">Referrals</AccountHeaderLink>
        
        <AccountHeaderHelpLink to="/faq">
          <span>Need Help</span>
          <img src={help} aria-hidden={true} alt="?" />
        </AccountHeaderHelpLink>
      </AccountHeaderContainer>
    </StyledAccountHeader>
  )
}

export default AccountHeader;