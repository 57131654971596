import React from 'react';
import Container from '../Container/Container';
import styled from 'styled-components';
import { theme } from '../../globalStyle';
import { rem } from 'polished';

const StyledNewsFeed = styled.section`
  background-color: ${theme.white};
  padding: ${rem(52)} 0 ${rem(77)};
`;

function NewsFeed() {
  return (
    <StyledNewsFeed>
      <Container>
        <div id="curator-feed-swapcoincom-social-feed-layout">
          <a href="https://curator.io" target="_blank" class="crt-logo crt-tag">
            Powered by Curator.io
          </a>
        </div>
      </Container>
    </StyledNewsFeed>
  );
}

export default NewsFeed;
