// Download user docs
import React from 'react';
import Header from '../Header/Header';
import HelpHero from '../HelpHero/HelpHero';
import Button from '../Button/Button';
import Container from '../Container/Container';
import AdminHeader from './AdminHeader';
import User from './User';
import UserLimits from './UserLimits';
import Modal from '../Modal/Modal';
import Loading from '../Loading/Loading';
import PageTitle from '../Page/PageTitle';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import axios from 'axios';
import { rem } from 'polished';
import { Scrollbars } from 'react-custom-scrollbars';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";

const UsersContainer = styled(Container)`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const UsersOrder = styled.div`
  border-bottom: 1px solid ${theme.muted};
  
  &:last-of-type {
    border-bottom: none;
  }
`;

const UsersHeader = styled.div`
  background-color: #fbfbfb;
  padding: ${rem(13)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const UsersBody = styled.div`
  background-color: ${theme.white};
`;

const UsersColumn = styled.div`
  ${mediaDown.lg`
    padding: ${rem(15)};
    margin-right: ${rem(4)};
    
    &:first-of-type {
      padding-top: ${rem(30)};
    }
    
    &:last-of-type {
      padding-bottom: ${rem(30)};
    }
  `}
  
  ${mediaUp.lg`
    display: flex;
    align-items: top;
  `}
`;

const UsersNameColumn = UsersColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 15%;
    max-width: 15%;
  `}
`;

const UsersIDColumn = UsersColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 30%;
    max-width: 30%;
  `}
`;

const UsersEmailColumn = UsersColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 20%;
    max-width: 20%;
  `}
`;

const UsersActiveColumn = UsersColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 5%;
    max-width: 5%;
  `}
`;

const UsersButtonColumn = UsersColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 15%;
    max-width: 15%;
  `}
`;

const UsersLimitsColumn = UsersColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 15%;
    max-width: 15%;
  `}
`;

const UsersHeaderTitle = styled.h6`
  text-transform: uppercase;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  margin-bottom: 0;
  color: #888888;
  line-height: normal;
`;

const UsersHeaderMobileTitle = UsersHeaderTitle.extend`
  margin-bottom: 0.25rem;
  
  ${mediaUp.lg`
    display: none;
  `}
`;

const UsersCell = styled.div`
  ${mediaUp.lg`
    display: flex;
    padding: ${rem(40)} 0;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `}
`;

const UsersName = styled.p`
  color: ${theme.gray1000};
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(10, 120)};
`;

const UsersEmail = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;

  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const UsersID = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const UsersActive = styled.h4`
  color: ${theme.gray1000};
  letter-spacing: 0;
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 20px;
  `}
  
  ${mediaUp.lg`
    font-size: ${rem(16)};
    flex: 0 0 100%;
    max-width: 100%;
  `}
`;

const UsersButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

const UsersTitle = styled(PageTitle)`
  background-color: ${theme.white};
  color: ${theme.muted};
  padding: ${rem(120)} 0;
  
  ${mediaUp.lg`
    padding: ${rem(182)} 0;
  `}
`;

const UsersLoading = styled(Loading)`
  background-color: ${theme.white};
`;

class Users extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Users: [],
      modalActive: false,
      username: '',
      id: '',
      email: '',
      active: false,
      limitsActive: false,
      Limits: [],
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleLimitsOpen = this.handleLimitsOpen.bind(this);
    this.handleLimitsClose = this.handleLimitsClose.bind(this);
  };

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.logoutUser();
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/admin/users`, { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        let Users = [];

        response.data.forEach((user) => {

          Users.push({
            username: user.username,
            email: user.email,
            id: user.id,
            active: user.active,
          });
        });

        this.setState({
          loading: false,
          Users: Users
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });

        this.props.logoutUser();
        this.props.history.push('/login');
      });
    }
  };

  handleModalOpen(username, id, email, active) {
    this.setState({
      modalActive: true,
      username: username,
      id: id,
      email: email,
      active: active,
    }, () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    });
  };

  handleModalClose() {
    this.setState({
      modalActive: false,
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });

    window.location.reload();
  };

  handleLimitsOpen(username, id) {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.logoutUser();
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true,
        username: username,
        id: id,
      });

      axios.post(`${process.env.REACT_APP_API_URL}/admin/users/limits/config`, {
        id: id,
      },
      { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        this.setState({
          loading: false,
          limitsActive: true,
          Limits: response.data,
        }, () => {
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });

        this.props.logoutUser();
        this.props.history.push('/login');
      });
    }
  };

  handleLimitsClose() {
    this.setState({
      limitsActive: false,
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });

    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <Meta title="Users" />

        <Header />

        <AdminHeader />

        <UsersLoading loading={this.state.loading}>
          {this.state.Users ?
            <React.Fragment>
              <UsersHeader>
                <UsersContainer hasMobileGutters={true}>
                  <UsersNameColumn>
                    <UsersHeaderTitle>Username</UsersHeaderTitle>
                  </UsersNameColumn>

                  <UsersIDColumn>
                    <UsersHeaderTitle>ID</UsersHeaderTitle>
                  </UsersIDColumn>

                  <UsersEmailColumn>
                    <UsersHeaderTitle>Email</UsersHeaderTitle>
                  </UsersEmailColumn>

                  <UsersActiveColumn>
                    <UsersHeaderTitle>Active</UsersHeaderTitle>
                  </UsersActiveColumn>
                </UsersContainer>
              </UsersHeader>

              <UsersBody>
                <Scrollbars style={{height: 400 }}>
                  {this.state.Users.map((user, index) => {
                    return (
                      <UsersOrder key={index}>
                        <UsersContainer hasMobileGutters={true}>
                          <UsersNameColumn>
                            <UsersCell>
                              <UsersHeaderMobileTitle>Username</UsersHeaderMobileTitle>
                              <UsersName>
                                <strong>{user.username}</strong>
                              </UsersName>
                            </UsersCell>
                          </UsersNameColumn>

                          <UsersIDColumn>
                            <UsersCell>
                              <UsersHeaderMobileTitle>ID</UsersHeaderMobileTitle>
                              <UsersID>{user.id}</UsersID>
                            </UsersCell>
                          </UsersIDColumn>

                          <UsersEmailColumn>
                            <UsersCell>
                              <UsersHeaderMobileTitle>Email</UsersHeaderMobileTitle>
                              <UsersEmail>{user.email}</UsersEmail>
                            </UsersCell>
                          </UsersEmailColumn>

                          <UsersActiveColumn>
                            <UsersCell>
                              <UsersHeaderMobileTitle>Active</UsersHeaderMobileTitle>
                              <UsersActive>
                                <input
                                  type="checkbox"
                                  checked={user.active}
                                  value={user.active}
                                  name={user.username}
                                  disabled={true}
                                />
                              </UsersActive>
                            </UsersCell>
                          </UsersActiveColumn>

                          <UsersButtonColumn>
                            <UsersCell>
                              <UsersHeaderMobileTitle>Enable</UsersHeaderMobileTitle>
                              <UsersButton secondary={true}
                                                handleButtonClick={() => {
                                                  this.handleModalOpen(
                                                    user.username,
                                                    user.id,
                                                    user.email,
                                                    user.active,
                                                  )
                                                }}>
                                Enable
                              </UsersButton>
                            </UsersCell>
                          </UsersButtonColumn>

                          <UsersLimitsColumn>
                            <UsersCell>
                              <UsersHeaderMobileTitle>Limits</UsersHeaderMobileTitle>
                              <UsersButton secondary={true}
                                                handleButtonClick={() => {
                                                  this.handleLimitsOpen(
                                                    user.username,
                                                    user.id,);
                                                }}>
                                Limits
                              </UsersButton>
                            </UsersCell>
                          </UsersLimitsColumn>

                        </UsersContainer>

                      </UsersOrder>
                    )
                  })}
                </Scrollbars>
              </UsersBody>

            </React.Fragment>
            :
            <UsersTitle>No Users Found</UsersTitle>
          }
        </UsersLoading>


        <HelpHero />
        <Modal isActive={this.state.modalActive} handleModalClose={this.handleModalClose}>
          <User
            username={this.state.username}
            id={this.state.id}
            email={this.state.email}
            active={this.state.active}
          />
        </Modal>
        <Modal isActive={this.state.limitsActive} handleModalClose={this.handleLimitsClose}>
          <UserLimits
            username={this.state.username}
            id={this.state.id}
            Limits={this.state.Limits}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <Users {...props}
                      authenticated={authenticated}
                      authenticationLoading={authenticationLoading}
                      logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};
