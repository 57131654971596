import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import TextInput from '../Input/TextInput';
import CheckboxInput from '../Input/CheckboxInput';
import Button from '../Button/Button';
import HelpHero from '../HelpHero/HelpHero';
import Alert from '../Alert/Alert';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import axios from 'axios';
import 'formdata-polyfill';
import { rem } from 'polished';
import { mediaUp, psLetterSpacing, psLineHeight } from '../../globalStyle';

const NewPasswordPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(64)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(92)};
  `}
`;

const NewPasswordPageTitle = styled(PageTitle)`
  ${mediaUp.lg`
    padding: ${rem(101)} 0 ${rem(44)};
  `}
`;

const NewPasswordContent = styled.div`
  text-align: center;
  
  ${mediaUp.lg`
    width: 40%;
    margin: 0 auto;
  `}
`;

const NewPasswordButton = styled(Button)`
  width: 100%;
  text-align: center;
  margin: ${rem(31)} auto 0;
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, 120)};
  line-height: ${psLineHeight(14, 20)};
  
  ${mediaUp.lg`
    max-width: 270px;
    margin: ${rem(33)} auto 0;
  `}
`;

const NewPasswordCopy = styled.p`
  margin: ${rem(20)} 0 0;
  font-size: ${rem(12)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
`;

const NewPasswordCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 23px;
`;

const NewPasswordCheckboxLabel = styled.label`
  font-size: ${rem(12)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
  margin-left: 12px;
`;

const NewPasswordAlert = styled(Alert)`
  text-align: left;
  margin-bottom: 1rem;
`;

function validatePassword(password) {
  var re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/;
  return re.test(String(password));
};

class NewPassword extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      termsCheckboxValue: false,
      errors: [],
      hasError: false,
      hasSuccess: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTermsCheckChange = this.handleTermsCheckChange.bind(this);
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    let errors = [];
    event.preventDefault();

    const params = {
      token: this.props.match.params.token,
      password: '',
      passwordA: data.get('passwordA'),
      passwordB: data.get('passwordB'),
    };

    this.setState({
      loading: true
    });

    if (params.passwordA !== params.passwordB) {
      errors.push('Retyped password does not match');
    }

    if (params.passwordA.length < 8 || params.passwordA.length > 255) {
      errors.push('Password must be between 8 to 255 characters');
    }

    if (!validatePassword(params.passwordA)) {
      errors.push('Password must contain an uppercase, lowercase, number, and special character');
    }

    if (errors.length) {
      this.setState({
        errors: errors,
        loading: false,
        hasError: true,
        hasSuccess: false
      });

      window.scrollTo(0, 0);
    } else {
      params.password = params.passwordB;

      axios.post(`${process.env.REACT_APP_API_URL}/authenticate/new/password`, params)
        .then(() => {
          this.setState({
            loading: false,
            hasError: false,
            hasSuccess: true
          });

          window.scrollTo(0, 0);

          setTimeout(() => {
            this.props.history.push('/login');
          }, 2000);

        })
        .catch(() => {
          this.setState({
            loading: false,
            errors: ['There was an error with your request. Please try again.'],
            hasError: true,
            hasSuccess: false
          });

          window.scrollTo(0, 0);
        });
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      errors: [],
      hasError: false
    })
  }

  handleTermsCheckChange() {
    this.setState({
      termsCheckboxValue: !this.state.termsCheckboxValue
    });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Password Reset" />

        <Header />

        <NewPasswordPageContainer backgroundLight={true} headerOffset={true}>
          <NewPasswordPageTitle>Password Reset</NewPasswordPageTitle>

          <NewPasswordContent>
            <NewPasswordAlert show={this.state.hasSuccess} success={true}>
              Your password has been reset. Please login to access Swapcoins.com user features.
            </NewPasswordAlert>

            <NewPasswordAlert show={this.state.hasError} danger={true}>
              {this.state.errors.map((error, index) => {
                return (
                  <React.Fragment key={index}>
                    {error}{index !== (this.state.errors.length - 1)
                      ?
                      <React.Fragment>
                        <br />
                        <br />
                      </React.Fragment>
                      :
                      <React.Fragment />
                    }
                  </React.Fragment>
                )
              })}
            </NewPasswordAlert>

            <form onSubmit={this.handleSubmit}>
              <TextInput name="passwordA" type="password" placeholder="Password (Uppercase, lowercase, number, and special character)" required />
              <TextInput name="passwordB" type="password" placeholder="Retype Password" required />

              <NewPasswordCheckboxWrapper>
                <CheckboxInput name="termsCheckbox"
                               value={this.state.termsCheckboxValue}
                               handleCheckChange={this.handleTermsCheckChange}
                               isChecked={this.state.termsCheckboxValue}
                />

                <NewPasswordCheckboxLabel>
                  I agree to the <Link to="/terms-and-conditions">terms</Link> &amp; <Link to="/privacy-policy">privacy policy</Link>
                </NewPasswordCheckboxLabel>
              </NewPasswordCheckboxWrapper>

              <NewPasswordButton secondary={true} hasArrow={true} loading={this.state.loading} disabled={!this.state.termsCheckboxValue}>Reset Password</NewPasswordButton>

              <NewPasswordCopy>
                Already registered? <Link to="/login">Login</Link>
              </NewPasswordCopy>
            </form>
          </NewPasswordContent>
        </NewPasswordPageContainer>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default NewPassword;

