import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './globalStyle';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import FAQ from './components/FAQ/FAQ';
import TermsConditions from './components/TermsConditions/TermsConditions';
import About from './components/About/About';
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Login from './components/Login/Login';
import NewUserLogin from './components/NewUserLogin/NewUserLogin';
import NewUserRegistration from './components/NewUserRegistration/NewUserRegistration';
import Register from './components/Register/Register';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import NewPassword from './components/NewPassword/NewPassword';
import TrackOrder from './components/TrackOrder/TrackOrder';
import Contact from './components/Contact/Contact';
import Rates from './components/Rates/Rates';
import SupportedCoins from './components/SupportedCoins/SupportedCoins';
import SupportedCoinPage from './components/SupportedCoins/SupportedCoinPage';
import Exchange from './components/Exchange/Exchange';
import Account from './components/Account/Account';
import OrderHistory from './components/Account/OrderHistory';
import Referrals from './components/Account/Referrals';
import Limits from './components/Account/Limits';
import Configs from './components/Admin/Configs';
import Exchanges from './components/Admin/Exchanges';
import Tools from './components/Admin/Tools';
import Reports from './components/Admin/Reports';
import Users from './components/Admin/Users';
import Press from './components/Press/Press';
import Sitemap from './components/Sitemap/Sitemap';
import HowItWorksGuide from './components/Home/HowItWorksGuide';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import CoinContext from './contexts/CoinContext';
import { withCookies } from 'react-cookie';
import axios from 'axios';
import debounce from 'lodash/debounce';

import { PropTypes } from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    this.sendPageView(this.context.router.history.location);
    this.context.router.history.listen(this.sendPageView);
  }

  sendPageView(location) {

  }

  render() {
    return this.props.children;
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coinsList: [],
      coinsLoading: false,
      coinDropdownActive: false,
      coinDropdownCurrency: '',
      incomingInputName: 'incomingAmount',
      incomingCurrency: 'BTC',
      incomingAmount: 1,
      incomingLoading: false,
      outgoingInputName: 'outgoingAmount',
      outgoingCurrency: 'ETH',
      outgoingAmount: 1,
      outgoingLoading: false,
      rates: [],
      exchange: '',
      numRates: 0,
      authenticationLoading: true,
      authenticated: false,
      admin: false,
      referredId: '',
    };

    this.handleSwapChange = this.handleSwapChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.getExchangeAmount = debounce(this.getExchangeAmount.bind(this), 500);
    this.handleCoinDropdownToggle = this.handleCoinDropdownToggle.bind(this);
    this.handleCoinDropdownSelect = this.handleCoinDropdownSelect.bind(this);
    this.getAllCoins = this.getAllCoins.bind(this);
    this.setInitialCurrency = this.setInitialCurrency.bind(this);
    this.setExchange = this.setExchange.bind(this);
    this.loadInitialSwapForm = this.loadInitialSwapForm.bind(this);
    this.loadUserFromToken = this.loadUserFromToken.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
  }

  componentDidMount() {
    this.loadUserFromToken();
  }

  handleSwapChange() {
    const tempAmount = this.state.incomingAmount;
    const tempCurrency = this.state.incomingCurrency;

    this.setState({
      incomingAmount: this.state.outgoingAmount,
      incomingCurrency: this.state.outgoingCurrency,
      outgoingAmount: tempAmount,
      outgoingCurrency: tempCurrency,
      outgoingLoading: true
    });

    this.getExchangeAmount('incomingAmount');
  }

  getExchangeAmount(inputName) {
    const incomingAmount = this.state.incomingAmount;
    const incomingCurrency = this.state.incomingCurrency;
    const outgoingCurrency = this.state.outgoingCurrency;
    const outgoingAmountKey = 'outgoingAmount';
    const outgoingLoadingKey = 'outgoingLoading';

    this.setState({
      [outgoingLoadingKey]: true
    });

    axios.get(`${process.env.REACT_APP_API_URL}/market/all/${incomingCurrency}-${outgoingCurrency}/${incomingAmount}`)
      .then((response) => {
        const exchanges = response.data;
        let maxExchangeRate;

        if (exchanges && exchanges.length > 0) {
          this.setState({
            rates: exchanges.sort((a, b) => b.rate - a.rate)
          });

          exchanges.forEach((exchange, index) => {
            if (index === 0) {
              maxExchangeRate = exchange.rate;
              this.setExchange(exchange.exchange, exchange.logo_exchange);
            }

            if (exchange.rate > maxExchangeRate) {
              maxExchangeRate = exchange.rate;
              this.setExchange(exchange.exchange, exchange.logo_exchange);
            }
          });

          if (maxExchangeRate) {
            this.setState({
              [outgoingAmountKey]: (maxExchangeRate * incomingAmount).toPrecision(8),
              [outgoingLoadingKey]: false,
              numRates: exchanges.length
            }, () => {
              const { cookies } = this.props;

              cookies.set('incomingAmount', this.state.incomingAmount, { path: '/' });
              cookies.set('incomingCurrency', this.state.incomingCurrency, { path: '/' });
            });
          }
        } else {
          this.setState({
            [outgoingAmountKey]: 0,
            [outgoingLoadingKey]: false,
            numRates: exchanges.length,
            rates: exchanges,
            exchange: 'No Exchanges Available'
          });
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  getAllCoins() {
    this.setState({
      coinsLoading: true
    });

    axios.get(`${process.env.REACT_APP_API_URL}/coins`)
      .then((response) => {
        this.setState({
          coinsLoading: false,
          coinsList: response.data
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  handleCurrencyInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    }, this.getExchangeAmount(event.target.name));
  }

  handleCoinDropdownToggle(currency, incoming) {
    const toggleDropdownActive = !this.state.coinDropdownActive;

    if (!this.state.coinsList.length) {
      this.getAllCoins();
    }

    this.setState({
      coinDropdownIncoming: incoming,
      coinDropdownActiveCurrency: currency,
      coinDropdownActive: toggleDropdownActive
    }, () => {
      document.documentElement.style.overflow = this.state.coinDropdownActive ? 'hidden' : 'auto';
      document.body.style.overflow = this.state.coinDropdownActive ? 'hidden' : 'auto';
    });
  }

  handleCoinDropdownSelect(currency) {
    this.setInitialCurrency(currency, 1, this.state.coinDropdownIncoming);

    document.documentElement.style.overflow = 'auto';
    document.body.style.overflow = 'auto';

    this.setState({
      coinDropdownActive: false
    });
  }

  setInitialCurrency(currency, amount = 1, incoming = true) {
    let currencyKey;
    let amountKey;

    if (incoming) {
      const { cookies } = this.props;
      currencyKey = 'incomingCurrency';
      amountKey = 'incomingAmount';

      cookies.set(amountKey, amount, { path: '/' });
      cookies.set(currencyKey, currency, { path: '/' });
    } else {
      currencyKey = 'outgoingCurrency';
      amountKey = 'outgoingAmount';
    }

    const newCoinDropdownActiveCurrency = this.state.coinDropdownActiveCurrency !== currency ? currency : this.state.coinDropdownActiveCurrency;

    this.setState({
      coinDropdownActiveCurrency: newCoinDropdownActiveCurrency,
      [amountKey]: amount,
      [currencyKey]: currency
    }, () => {
      this.getExchangeAmount(amountKey);
    });
  }

  setExchange(exchange, logo, outgoingAmount) {
    this.setState({
      exchange: exchange,
      exchangeLogo: logo
    });
    if (outgoingAmount) {
      this.setState({
        outgoingAmount: outgoingAmount
      });
    }
  }

  loadInitialSwapForm(from, to) {
    const { cookies } = this.props;
    const incomingAmountCookie = parseFloat(cookies.get('incomingAmount'));
    const incomingCurrencyCookie = cookies.get('incomingCurrency');

    if (from && to) {
      this.setInitialCurrency(from, (incomingAmountCookie > 0? incomingAmountCookie : 1));
      this.setInitialCurrency(to, 0, false);
    } else if (incomingAmountCookie > 0 && incomingCurrencyCookie) {
      this.setInitialCurrency(incomingCurrencyCookie, incomingAmountCookie);
    } else {
      this.setInitialCurrency('BTC');
    }
  }

  loadUserFromToken() {
    const token = localStorage.getItem('jwtToken');

    if (!token || token === '') {
      this.setState({
        authenticated: false,
        authenticationLoading: false,
        admin: false,
      });

      return;
    }

    this.setState({
      authenticationLoading: true
    });

    axios.get(`${process.env.REACT_APP_API_URL}/user`, { headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
    })
      .then(() => {
        this.setState({
          authenticated: true,
          authenticationLoading: false,
          admin: false,
        });
        
        axios.get(`${process.env.REACT_APP_API_URL}/admin`, { headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
        })
          .then(() => {
            this.setState({ admin: true });
          })
          .catch(() => {
            return;
          });
      })
      .catch(() => {
        this.logoutUser();
      });
  }

  logoutUser() {
    axios.get(`${process.env.REACT_APP_API_URL}/authenticate/logout`, { headers: {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}` }
    })
      .then(() => {
        this.setState({
          authenticated: false,
          authenticationLoading: false,
          admin: false,
        });
      })
      .catch(() => {
        this.setState({
          authenticated: false,
          authenticationLoading: false,
          admin: false,
        });
      });

    localStorage.removeItem('jwtToken');
  }

  render() {
    return (
      <Router>
        <GAListener>
          <ScrollToTop>
            <ThemeProvider theme={theme}>
              <CoinContext.Provider value={
                {
                  ...this.state,
                  handleSwapChange: this.handleSwapChange,
                  handleCurrencyInputChange: this.handleCurrencyInputChange,
                  handleCoinDropdownSelect: this.handleCoinDropdownSelect,
                  handleCoinDropdownToggle: this.handleCoinDropdownToggle,
                  getExchangeAmount: this.getExchangeAmount,
                  getAllCoins: this.getAllCoins,
                  setInitialCurrency: this.setInitialCurrency,
                  setExchange: this.setExchange,
                  loadInitialSwapForm: this.loadInitialSwapForm,
                  loadUserFromToken: this.loadUserFromToken,
                  logoutUser: this.logoutUser
                }
              }>
                <main>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/swap/:from/:to" component={Home} />
                    <Route path="/faq" component={FAQ} />
                    <Route path="/terms-and-conditions" component={TermsConditions} />
                    <Route path="/about" component={About} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/login" component={Login} />
                    <Route path="/new-user" component={NewUserLogin} />
                    <Route path="/new-registration" component={NewUserRegistration} />
                    <Route exact path="/register/:referredId" component={Register} />
                    <Route path="/register" component={Register} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/new-password/:token" component={NewPassword} />
                    <Route path="/track-order" component={TrackOrder} />
                    <Route path="/contact" component={Contact} />
                    <Route exact path="/rates/:from/:to" component={Rates} />
                    <Route path="/rates" component={Rates} />
                    <Route path="/supported-coins" exact component={SupportedCoins} />
                    <Route path="/supported-coins/:slug" component={SupportedCoinPage} />
                    <Route exact path="/exchange" component={Exchange} />
                    <Route path="/exchange/order-complete" component={Exchange} />
                    <Route path="/exchange/order-status" component={Exchange} />
                    <Route path="/exchange/order-final" component={Exchange} />
                    <Route path="/account/profile" exact component={Account} />
                    <Route path="/account/order-history" exact component={OrderHistory} />
                    <Route path="/account/referrals" exact component={Referrals} />
                    <Route path="/account/limits" exact component={Limits} />
                    <Route exact path="/manage/configs" exact component={Configs} />
                    <Route exact path="/manage/exchanges" exact component={Exchanges} />
                    <Route exact path="/manage/reports" exact component={Reports} />
                    <Route exact path="/manage/users" exact component={Users} />
                    <Route exact path="/manage/tools" exact component={Tools} />
                    <Route exact path="/press" exact component={Press} />
                    <Route exact path="/sitemap" exact component={Sitemap} />
                    <Route exact path="/how-it-works" exact component={HowItWorksGuide} />
                    <Redirect to="/" />
                  </Switch>

                  <Footer />
                </main>
              </CoinContext.Provider>
            </ThemeProvider>
          </ScrollToTop>
        </GAListener>
      </Router>
    );
  }
}

export default withCookies(App);
