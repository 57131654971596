import React from 'react';
import styled from 'styled-components';
import { rem, hideVisually } from 'polished';
import { theme, psLineHeight, psLetterSpacing } from '../../globalStyle';
import file from '../../assets/images/file_icon.png';

const StyledFileButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  border-radius: 3px;
  border: 1px solid ${theme.muted};
  padding: ${rem(30)};
  margin-bottom: ${rem(10)};
  color: ${props => props.hasFile ? theme.primary : theme.gray600};
  font-size: ${rem(17)};
  line-height: ${psLineHeight(17, 20)};
  letter-spacing: ${psLetterSpacing(17, -10)};
  transition: 0.3s border-color ease;
  
  &:focus {
    outline: none;
  }
`;

const FileIcon = styled.img`
  margin-right: ${rem(9)};
  display: ${props => props.hasFile ? 'none' : 'block'};
`;

class FileButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fileName: ''
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fileInputRef = React.createRef();
  }

  handleButtonClick(event) {
    event.preventDefault();

    this.fileInputRef.current.click();
  }

  handleChange(event) {
    const files = event.target.files;

    if (files) {
      this.setState({
        fileName: files[0].name
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <StyledFileButton onClick={this.handleButtonClick}
                          type="button"
                          className={this.props.className}
                          hasFile={this.state.fileName.length || this.props.fileName}
        >
          <FileIcon src={file} aria-hidden={true} hasFile={this.state.fileName.length || this.props.fileName} />
          {this.state.fileName.length ? this.state.fileName : (this.props.fileName ? this.props.fileName : 'Attach File')}
        </StyledFileButton>

        <label style={hideVisually()} for="file">Attach file</label>
        <input type="file"
               style={hideVisually()}
               id="file"
               ref={this.fileInputRef}
               name={this.props.name}
               onChange={this.handleChange}
        />
      </React.Fragment>
    )
  }
}

export default FileButton;