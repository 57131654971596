import React from 'react';
import Header from '../Header/Header';
import HelpHero from '../HelpHero/HelpHero';
import Button from '../Button/Button';
import Container from '../Container/Container';
import AdminHeader from './AdminHeader';
import Tool from './Tool';
import ToolModal from '../Modal/Report';
import Loading from '../Loading/Loading';
import PageTitle from '../Page/PageTitle';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";

const ToolsContainer = styled(Container)`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const ToolsOrder = styled.div`
  border-bottom: 1px solid ${theme.muted};
  
  &:last-of-type {
    border-bottom: none;
  }
`;

const ToolsHeader = styled.div`
  background-color: #fbfbfb;
  padding: ${rem(13)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const ToolsBody = styled.div`
  background-color: ${theme.white};
`;

const ToolsColumn = styled.div`
  ${mediaDown.lg`
    padding: ${rem(15)};
    margin-right: ${rem(4)};
    
    &:first-of-type {
      padding-top: ${rem(30)};
    }
    
    &:last-of-type {
      padding-bottom: ${rem(30)};
    }
  `}
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
  `}
`;

const ToolsNameColumn = ToolsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 20%;
    max-width: 20%;
  `}
`;

const ToolsDescriptionColumn = ToolsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 40%;
    max-width: 40%;
  `}
`;

const ToolsIDColumn = ToolsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 10%;
    max-width: 10%;
  `}
`;

const ToolsEnabledColumn = ToolsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 10%;
    max-width: 10%;
  `}
`;

const ToolsButtonColumn = ToolsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 20%;
    max-width: 20%;
  `}
`;

const ToolsHeaderTitle = styled.h6`
  text-transform: uppercase;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  margin-bottom: 0;
  color: #888888;
  line-height: normal;
`;

const ToolsHeaderMobileTitle = ToolsHeaderTitle.extend`
  margin-bottom: 0.25rem;
  
  ${mediaUp.lg`
    display: none;
  `}
`;

const ToolsCell = styled.div`
  ${mediaUp.lg`
    display: flex;
    padding: ${rem(40)} 0;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `}
`;

const ToolsName = styled.p`
  color: ${theme.gray1000};
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(10, 120)};
`;

const ToolsID = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const ToolsDescription = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const ToolsButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

const ToolsTitle = styled(PageTitle)`
  background-color: ${theme.white};
  color: ${theme.muted};
  padding: ${rem(120)} 0;
  
  ${mediaUp.lg`
    padding: ${rem(182)} 0;
  `}
`;

const ToolsLoading = styled(Loading)`
  background-color: ${theme.white};
`;

class Tools extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Tools: [],
      modalActive: false,
      name: '',
      description: '',
      id: '',
      enabled: false,
      params: [],
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.logoutUser();
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/admin/reports`, { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        let Tools = [];

        response.data.forEach((tool) => {
          if (tool.params) {
            let params = tool.params.split(',');

            Tools.push({
              name: tool.name,
              description: tool.description,
              id: tool.id,
              enabled: tool.enabled ? 'Yes' : 'No',
              params: params,
            });
          }
        });

        this.setState({
          loading: false,
          Tools: Tools
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });

        this.props.logoutUser();
        this.props.history.push('/login');
      });
    }
  }

  handleModalOpen(name, id, description, enabled, params) {
    this.setState({
      modalActive: true,
      name: name,
      id: id,
      description: description,
      enabled: enabled,
      params: params,
    }, () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    });
  };

  handleModalClose() {
    this.setState({
      modalActive: false,
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });

  };

  render() {
    return (
      <React.Fragment>
        <Meta title="Tools" />

        <Header />

        <AdminHeader />

        <ToolsLoading loading={this.state.loading}>
          {this.state.Tools ?
            <React.Fragment>
              <ToolsHeader>
                <ToolsContainer hasMobileGutters={true}>
                  <ToolsIDColumn>
                    <ToolsHeaderTitle>ID</ToolsHeaderTitle>
                  </ToolsIDColumn>

                  <ToolsNameColumn>
                    <ToolsHeaderTitle>Name</ToolsHeaderTitle>
                  </ToolsNameColumn>

                  <ToolsDescriptionColumn>
                    <ToolsHeaderTitle>Description</ToolsHeaderTitle>
                  </ToolsDescriptionColumn>

                  <ToolsEnabledColumn>
                    <ToolsHeaderTitle>Enabled</ToolsHeaderTitle>
                  </ToolsEnabledColumn>
                </ToolsContainer>
              </ToolsHeader>

              <ToolsBody>
                <Scrollbars style={{height: 400 }}>
                  {this.state.Tools.map((tool, index) => {
                    return (
                      <ToolsOrder key={index}>
                        <ToolsContainer hasMobileGutters={true}>

                          <ToolsIDColumn>
                            <ToolsCell>
                              <ToolsHeaderMobileTitle>ID</ToolsHeaderMobileTitle>
                              <ToolsID>{tool.id}</ToolsID>
                            </ToolsCell>
                          </ToolsIDColumn>

                          <ToolsNameColumn>
                            <ToolsCell>
                              <ToolsHeaderMobileTitle>Name</ToolsHeaderMobileTitle>
                              <ToolsName>
                                <strong>{tool.name}</strong>
                              </ToolsName>
                            </ToolsCell>
                          </ToolsNameColumn>

                          <ToolsDescriptionColumn>
                            <ToolsCell>
                              <ToolsHeaderMobileTitle>Description</ToolsHeaderMobileTitle>
                              <ToolsDescription>{tool.description}</ToolsDescription>
                            </ToolsCell>
                          </ToolsDescriptionColumn>

                          <ToolsEnabledColumn>
                            <ToolsCell>
                              <ToolsHeaderMobileTitle>Active</ToolsHeaderMobileTitle>
                              <ToolsID>
                                <input
                                  type="checkbox"
                                  checked={tool.enabled}
                                  value={tool.enabled}
                                  name={tool.name}
                                  disabled={true}
                                />
                              </ToolsID>
                            </ToolsCell>
                          </ToolsEnabledColumn>

                          <ToolsButtonColumn>
                            <ToolsCell>
                              <ToolsHeaderMobileTitle>Action</ToolsHeaderMobileTitle>
                              <ToolsButton
                                secondary={true}
                                handleButtonClick={() => {
                                  this.handleModalOpen(
                                    tool.name,
                                    tool.id,
                                    tool.description,
                                    tool.enabled,
                                    tool.params,
                                  )
                                }}
                              >
                                Launch
                              </ToolsButton>
                            </ToolsCell>
                          </ToolsButtonColumn>

                        </ToolsContainer>
                      </ToolsOrder>
                    )
                  })}
                </Scrollbars>
              </ToolsBody>
            </React.Fragment>
            :
            <ToolsTitle>No Tools Found</ToolsTitle>
          }
        </ToolsLoading>

        <HelpHero />
        <ToolModal isActive={this.state.modalActive} handleModalClose={this.handleModalClose}>
          <Tool
            name={this.state.name}
            id={this.state.id}
            description={this.state.description}
            enabled={this.state.enabled}
            params={this.state.params}
          />
        </ToolModal>
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <Tools {...props}
                      authenticated={authenticated}
                      authenticationLoading={authenticationLoading}
                      logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};
