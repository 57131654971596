import React from 'react';
import styled from 'styled-components';
import { theme, mediaUp, mediaDown, psLetterSpacing, psLineHeight } from '../../globalStyle';
import { rem } from 'polished';
import Container from '../Container/Container';
import SwapForm from './SwapForm';
import heroBg from '../../assets/images/home_hero_bg.jpg';
import Meta from '../Meta/Meta';

const StyledHeroCoins = styled.section`
  background-image: url('${heroBg}');
  background-size: cover;
  background-position: bottom right;
  
  ${mediaUp.lg`
    height: 100vh;
    padding-top: ${theme.headerDesktopHeight};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
  
  ${mediaDown.lg`
    padding: ${rem(93)} ${theme.mobileGutter} ${rem(75)};
  `}
`;

const HeroCoinsTitle = styled.h1`
  color: ${theme.tertiary};
  
  span {
    color: ${theme.white};
  }
`;

const HeroCoinsDesktopTitle = HeroCoinsTitle.extend`
  font-size: ${rem(58)};
  letter-spacing: ${psLetterSpacing(58, -40)};
  line-height: ${psLineHeight(58, 40)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const HeroCoinsMobileTitle = HeroCoinsTitle.extend`
  font-size: ${rem(28)};
  letter-spacing: ${psLetterSpacing(28, -40)};
  line-height: ${psLineHeight(28, 26)};
  text-align: center;
  
  ${mediaUp.lg`
    display: none;
  `}
`;

const HeroCoinsSubDesktopTitle = HeroCoinsDesktopTitle.extend`
  font-size: ${rem(22)};
  letter-spacing: ${psLetterSpacing(22, -40)};
  line-height: ${psLineHeight(22, 20)};
  color: ${theme.white};
  
  span {
    color: ${theme.white};
  }

  ${mediaDown.lg`
    display: none;
  `}
`;

const HeroCoinsSubMobileTitle = HeroCoinsMobileTitle.extend`
  font-size: ${rem(20)};
  letter-spacing: ${psLetterSpacing(20, -40)};
  line-height: ${psLineHeight(20, 18)};
  text-align: center;
  color: ${theme.white};
  
  span {
    color: ${theme.white};
  }

  ${mediaUp.lg`
    display: none;
  `}
`;

function HeroCoins(props) {
  return (
    <React.Fragment>       
      <Meta title={`Swap ${props.incomingCurrency} to ${props.outgoingCurrency}`} />
      <StyledHeroCoins>
        <Container>
          <HeroCoinsDesktopTitle>
            <span>Swap {props.incomingCurrency} to {props.outgoingCurrency}</span><br />
            <HeroCoinsSubDesktopTitle>
              <span>Compare prices &amp; swap at the most competitive rates. No wasted or lost opportunities!</span>
            </HeroCoinsSubDesktopTitle>
          </HeroCoinsDesktopTitle>

          <HeroCoinsMobileTitle>
            <span>Swap {props.incomingCurrency} to {props.outgoingCurrency}</span><br />
            <HeroCoinsSubMobileTitle>
              <span>Compare prices &amp; swap at the most competitive rates. No wasted or lost opportunities!</span>
            </HeroCoinsSubMobileTitle>
          </HeroCoinsMobileTitle>
          <SwapForm incomingCurrency={props.incomingCurrency} outgoingCurrency={props.outgoingCurrency}/>
        </Container>
      </StyledHeroCoins>
    </React.Fragment>
  )
}

export default HeroCoins;
