import React from 'react';
import styled, { css } from 'styled-components';
import { mediaUp, mediaDown, theme, psLetterSpacing } from '../../globalStyle';
import { rem } from 'polished';
import AutosizeInput from 'react-input-autosize';
import ReactLoading from 'react-loading';

const StyledSwapCard = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: ${rem(19)} ${rem(19)} ${rem(24)} ${rem(27)};
  
  ${mediaUp.lg`
    padding: ${rem(30)} ${rem(46)} ${rem(30)} ${rem(37)};
  `}
  
  ${props => props.ratesPage && css`
    ${mediaDown.lg`
      padding-left: 0;
      padding-right: 0;
    `}
    
    ${mediaDown.sm`
      padding-left: ${theme.mobileGutterLg};
      padding-right: ${theme.mobileGutterLg};
    `}
    
    ${mediaUp.lg`
      ${props.incoming ? 'padding-left: 0;' : 'padding-right: 0;'}
    `}
  `}
`;

const SwapCardTitle = styled.h6`
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 200)};
  text-transform: uppercase;
  margin-bottom: ${rem(20)};
  
  ${mediaUp.lg`
    margin-bottom: ${rem(40)};
  `}
  
  ${props => props.ratesPage && css`
    line-height: normal;
    
    ${mediaUp.lg`
      margin-bottom: ${rem(32)};
    `}
  `}
`;

const SwapCardInputGroup = styled.div`
  overflow: hidden;
  border-bottom: 1px solid ${theme.gray400};
  flex: 1;
  position: relative; 
  
  ${mediaDown.lg`
    padding-bottom: ${rem(7)};
    margin-bottom: ${rem(12)};
  `}
`;

const SwapCardInputLabel = styled.label`
  font-size: ${rem(28)};
  color: ${theme.gray300};
  background-color: ${theme.white};
    
  ${mediaUp.lg`
    font-size: ${rem(38)};
  `}
`;

const SwapCardInput = styled(AutosizeInput)`
  max-width: calc(100% - ${props => props.labelWidth + 1}px);
  
  input {
    box-sizing: border-box !important;
    border: none;
    font-size: ${rem(28)};
    padding: 0;
    display: block;
    max-width: 100%;
    
    ${mediaUp.lg`
      font-size: ${rem(38)};
    `}
    
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
    
    &:focus {
      outline: none;
    }
  }
`;

const SwapCardCurrencySelect = styled.div`
  margin-left: ${rem(17)};
  flex: 0 0 90px;
  max-width: 90px;
  position: relative;
  
  ${mediaUp.lg`
    flex: 0 0 115px;
    max-width: 115px;
  `}
  
  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    border-radius: 100%;
    background: linear-gradient(to bottom, ${theme.secondary} 0%, ${theme.blue300} 100%);
  }
  
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
    border-radius: 100%;
    background: linear-gradient(to bottom, ${theme.blue300} 0%, ${theme.secondary} 100%);
    transition: 0.3s opacity ease;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  
  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;

const SwapCardCurrencyButton = styled.button`
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 4px;
  top: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  outline: none;
  border: none;
  background-color: ${theme.white};
  border-radius: 100%;
  padding: 0;
  font-family: ${theme.fontFamilySpaceMono};
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, 60)};
  z-index: 1;
  
  &:focus {
    outline: none;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 18px;
    left: 0;
    right: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 4px solid ${theme.blue300};
  }
`;

const SwapCardLoadingWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.white};
  opacity: 0;
  transition: 0.3s opacity ease;
  
  ${mediaUp.lg`
    left: ${props => props.incoming ? '0' : '1px'};
  `}
  
  ${props => props.loading
  ? css`
      z-index: 5;
      opacity: 1;
    `
  : css`
      pointer-events: none;
    `
  }
`;

class SwapCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      labelWidth: 0
    };

    this.inputRef = React.createRef();
    this.labelRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.copyInputStyles();

    this.setLabelWidth();
  }

  componentDidUpdate() {
    this.setLabelWidth();
  }

  setLabelWidth() {
    const labelWidth = this.labelRef.clientWidth;

    if (labelWidth !== this.state.labelWidth) {
      this.setState({
        labelWidth: labelWidth
      });
    }
  }

  render() {
    return (
      <StyledSwapCard ratesPage={this.props.ratesPage} incoming={this.props.incoming}>
        <SwapCardLoadingWrapper loading={this.props.loading} incoming={this.props.incoming}>
          <ReactLoading type="spin" color={theme.primary} />
        </SwapCardLoadingWrapper>

        <SwapCardInputGroup>
          <SwapCardTitle ratesPage={this.props.ratesPage}>{this.props.title}</SwapCardTitle>

          <SwapCardInput
            type="number"
            step="any"
            name={this.props.name}
            value={this.props.amount}
            innerRef={inputRef => { this.inputRef = inputRef }}
            onChange={this.props.handleInputChange}
            labelWidth={this.state.labelWidth}
          />

          <SwapCardInputLabel innerRef={labelRef => { this.labelRef = labelRef }}>
            {this.props.currency}
          </SwapCardInputLabel>
        </SwapCardInputGroup>

        <SwapCardCurrencySelect>
          <SwapCardCurrencyButton
            type="button"
            onClick={() => {this.props.handleDropdownToggle(this.props.currency, this.props.incoming)}}
          >
            {this.props.currency}
          </SwapCardCurrencyButton>
        </SwapCardCurrencySelect>
      </StyledSwapCard>
    )
  }
}

export default SwapCard;
