import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Header from '../Header/Header';
import Container from '../Container/Container';
import HelpHero from '../HelpHero/HelpHero';
import Loading from '../Loading/Loading';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import { rem } from 'polished';
import axios from 'axios';
import { theme, mediaUp, psLetterSpacing } from '../../globalStyle';
import arrow from '../../assets/images/supported_coins_page_arrow.svg';

const SupportedCoinPageMain = styled.main`
  margin-top: ${theme.headerMobileHeight};
  position: relative;
  
  ${mediaUp.lg`
    margin-top: ${theme.headerDesktopHeight};
    background-color: ${theme.white};
  `}
`;

const SupportedCoinPageBody = styled.div`
  padding: ${rem(54)} 0 ${rem(57)};
  
  ${mediaUp.lg`
    width: 60%;
    margin: 0 auto;
    padding: ${rem(71)} 0 ${rem(155)};
  `}
`;

const SupportedCoinPageHeader = styled.div`
  text-align: center;
  border-bottom: 1px solid ${theme.muted};
  margin-bottom: ${rem(43)};
`;

const SupportedCoinPageTitle = styled.h1`
  font-size: ${rem(34)};
  letter-spacing: ${psLetterSpacing(34, -20)};
  text-align: center;
  margin-bottom: ${rem(40)};
  
  ${mediaUp.lg`
    font-size: ${rem(40)};
    letter-spacing: ${psLetterSpacing(40, -20)};
    margin-bottom: ${rem(44)};
  `}
`;

const SupportedCoinPageLogo = styled.img`
  margin-bottom: ${rem(22)};
  width: 28px;
  
  ${mediaUp.lg`
    width: 35px;
  `}
`;

const SupportedCoinPageContent = styled.div`
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const SupportedCoinPageButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.primary};
  width: 90px;
  height: 80px;
  position: absolute;
  left: 0;
  top: 0;
  
  ${mediaUp.lg`
    top: 112px;
  `}
`;

class SupportedCoinPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      name: '',
      currency: '',
      logo: null,
      description: ''
    };
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;

    this.setState({
      currency: slug
    });

    axios.get(`${process.env.REACT_APP_API_URL}/coins/${slug}`)
      .then((response) => {
        this.setState({
          loading: false,
          name: response.data.name,
          currency: response.data.coin,
          logo: response.data.logo,
          description: response.data.description
        });
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  componentWillUnmount() {
    this.setState({
      name: '',
      logo: '',
      description: ''
    });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title={this.state.currency} />

        <Header />

        <Loading loading={this.state.loading}>
          <SupportedCoinPageMain>
            <SupportedCoinPageButton to="/supported-coins">
              <img src={arrow} alt="Go to all supported coins page" />
            </SupportedCoinPageButton>

            <Container hasMobileGutters={true}>
              <SupportedCoinPageBody>
                <SupportedCoinPageHeader>
                  {this.state.logo &&
                  <SupportedCoinPageLogo src={process.env.REACT_APP_API_URL + this.state.logo} aria-hidden={true} />
                  }

                  {(this.state.name || this.state.currency) &&
                  <SupportedCoinPageTitle>{this.state.name ? this.state.name : this.state.currency}</SupportedCoinPageTitle>
                  }
                </SupportedCoinPageHeader>

                {this.state.description &&
                <SupportedCoinPageContent>
                  {this.state.description}
                </SupportedCoinPageContent>
                }
              </SupportedCoinPageBody>
            </Container>
          </SupportedCoinPageMain>

          {(this.state.name || this.state.currency) &&
            <CoinContext.Consumer>
              {({ setInitialCurrency }) => (
                <HelpHero title={`Swap ${this.state.name ? this.state.name : this.state.currency}`}
                          link={`/rates`}
                          overlayRight={true}
                          mobileOverlayColor={theme.bodyBg}
                          onClick={() => { setInitialCurrency(this.state.currency)}}
                />
              )}
            </CoinContext.Consumer>
          }
        </Loading>
      </React.Fragment>
    )
  }
}

export default withRouter(SupportedCoinPage);