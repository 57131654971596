import React from 'react';
import styled, { css } from 'styled-components';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import { rem, darken } from 'polished';
import { Link } from 'react-router-dom';
import arrow from '../../assets/images/arrow_white_lg.svg';

const tileStyles = css`
  background-color: ${theme.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${rem(49)};
  
  ${mediaUp.lg`
    padding: 0 ${rem(96)};
  `}
`;

const StyledTile = styled.div`
  ${tileStyles}
`;

const StyledTileLink = styled(Link)`
  ${tileStyles}
  transition: 0.3s background-color ease;
  
  &:hover {
    text-decoration: none;
    background-color: ${darken(0.1, theme.primary)};
  }
`;

const TileTitle = styled.h2`
  color: ${theme.white};
  margin-bottom: 0;
  
  ${props => props.smFontSize
    ?
    css`
      font-size: ${rem(props.smFontSize)};
      letter-spacing: ${psLetterSpacing(props.smFontSize, -35)};
      line-height: 52px;
    `
    :
    css`
      font-size: ${rem(28)};
      letter-spacing: ${psLetterSpacing(28, -35)};
      line-height: 28px;
    `
  }
  
  ${mediaUp.lg`
    ${props => props.lgFontSize 
      ?
      css`
        font-size: ${rem(props.lgFontSize)};
        letter-spacing: ${psLetterSpacing(props.lgFontSize, -35)};
        line-height: 72px;
      `
      :
      css`
        font-size: ${rem(74)};
        letter-spacing: ${psLetterSpacing(74, -35)};
        line-height: 72px;
      `
    }
  `}
  
  span {
    color: ${theme.tertiary};
  }
`;

const TileLinkArrowWrapper = styled.div`
 margin-top: ${rem(20)};
 line-height: 0;

 ${mediaUp.lg`
   margin-top: ${rem(54)};
 `}
`;

const TileLinkArrow = styled.img`
  ${mediaDown.lg`
    width: 40px;
    height: auto;
  `}
`;

function Tile(props) {
  return (
    <React.Fragment>
      {props.link
        ?
        <StyledTileLink className={props.className} to={props.link}>
          <TileTitle lgFontSize={props.lgFontSize} smFontSize={props.smFontSize}>{props.children}</TileTitle>

          <TileLinkArrowWrapper>
            <TileLinkArrow src={arrow} alt={`Go to ${props.children}`} />
          </TileLinkArrowWrapper>
        </StyledTileLink>
        :
        <StyledTile className={props.className}>
          <TileTitle lgFontSize={props.lgFontSize} smFontSize={props.smFontSize}>{props.children}</TileTitle>
        </StyledTile>
      }
    </React.Fragment>
  )
}

export default Tile;