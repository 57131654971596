import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Container from '../Container/Container';
import { theme, psLetterSpacing, mediaUp, unstyledButton } from "../../globalStyle";
import arrow from '../../assets/images/mobile_rates_arrow.png';
import close from '../../assets/images/mobile_rates_close.png';

const StyledRatesMobileToggle = styled.div`
  background-color: ${theme.gray800};
  padding: ${rem(17)} 0;
  color: ${theme.white};
  z-index: 50;
  position: absolute;
  top: 398px;
  left: 0;
  right: 0;
  transition: ${props => props.atTop ? 'none' : '0.3s transform ease'};
  transform: ${props => props.mobileMenuToggled ? 'translateY(266px)' : 'translateY(0)'};
  
  ${props => props.pastThreshold && css`
    position: fixed;
    top: ${theme.headerMobileHeight};
  `}
  
  ${mediaUp.lg`
    display: none;
  `}
    
    &::after {
      content: '';
      position: fixed;
      width: 100%;
      height: 100vh;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: #2e2f33;
      transition: 0.3s opacity ease;
      z-index: -1;
      opacity: ${props => props.mobileMenuToggled ? '0.78' : '0'};
      pointer-events: ${props => props.mobileMenuToggled ? 'auto' : 'none'};
    }
`;

const RatesMobileToggleWrapper = styled.div`
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;
`;

const RatesMobileToggleCopy = styled.p`
  text-transform: uppercase;
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(12, 160)};
  margin-bottom: 0;
`;

const RatesMobileToggleCount = RatesMobileToggleCopy.extend`
  transition: 0.3s opacity ease;
  opacity: ${props => props.pastThreshold ? '0' : '1'};
`;

const RatesMobileToggleCurrency = RatesMobileToggleCopy.extend`
  display: flex;
  align-items: center;
  flex: 1;
`;

const RatesMobileToggleButton = styled.button`
  background-color: transparent;
  border: 1px solid ${theme.white};
  border-radius: 3px;
  flex: 0 0 80px;
  max-width: 80px;
  color: ${theme.white};
  font-size: ${rem(10)};
  font-weight: ${theme.avenirWeightBlack};
  letter-spacing: ${psLetterSpacing(10, 200)};
  text-transform: uppercase;
  padding: ${rem(12)} ${rem(23)};
  line-height: normal;
  
  &:focus {
    outline: none;
  }
`;

const RatesMobileToggleMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s opacity ease;
  
  ${props => props.pastThreshold && !props.mobileMenuToggled
    ?
    css`
      pointer-events: auto;
      opacity: 1;
    `
    :
    css`
      pointer-events: none;
      opacity: 0;
    `
  }
`;

const RatesMobileToggleArrow = styled.img``;

const RatesMobileToggleCloseButton = styled.button`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  transition: 0.3s opacity ease;
  ${props => props.pastThreshold && props.mobileMenuToggled
  ?
  css`
      pointer-events: auto;
      opacity: 1;
    `
  :
  css`
      pointer-events: none;
      opacity: 0;
    `
  }
  
  ${unstyledButton}
`;

const RatesMobileToggleClose = styled.img``;

function RatesMobileToggle(props) {
  return (
    <StyledRatesMobileToggle pastThreshold={props.pastThreshold}
                             innerRef={props.innerRef}
                             mobileMenuToggled={props.mobileMenuToggled}
                             atTop={props.atTop}
    >
      <Container hasMobileGutters={true}>
        <RatesMobileToggleWrapper>
          <RatesMobileToggleCount pastThreshold={props.pastThreshold}
                                  mobileMenuToggled={props.mobileMenuToggled}
          >
            {props.count} Rates Found
          </RatesMobileToggleCount>

          <RatesMobileToggleMenu pastThreshold={props.pastThreshold}
                                 mobileMenuToggled={props.mobileMenuToggled}
          >
            <RatesMobileToggleCurrency>
              {props.incomingAmount} {props.incomingCurrency}
              &nbsp;&nbsp;
              <RatesMobileToggleArrow src={arrow} alt="to" />
              &nbsp;&nbsp;
              {props.outgoingAmount} {props.outgoingCurrency}
            </RatesMobileToggleCurrency>

            <RatesMobileToggleButton onClick={props.handleMobileMenuToggle}>Edit</RatesMobileToggleButton>
          </RatesMobileToggleMenu>
        </RatesMobileToggleWrapper>

        <RatesMobileToggleCloseButton onClick={props.handleMobileMenuToggle}
                                      pastThreshold={props.pastThreshold}
                                      mobileMenuToggled={props.mobileMenuToggled}
        >
          <RatesMobileToggleClose src={close} alt="Close" />
        </RatesMobileToggleCloseButton>
      </Container>
    </StyledRatesMobileToggle>
  )
}

export default RatesMobileToggle;
