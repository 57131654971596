import React from 'react';
import Header from '../Header/Header';
import Container from '../Container/Container';
import AccountHeader from './AccountHeader';
import HelpHero from '../HelpHero/HelpHero';
import PageTitle from '../Page/PageTitle';
import Loading from '../Loading/Loading';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import axios from 'axios';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing, psLineHeight } from "../../globalStyle";

const ReferralsWrapper = styled.div`
  background-color: ${theme.white};
  padding-bottom: ${rem(100)};
`;

const ReferralsTitle = styled(PageTitle)`
  padding: ${rem(52)} 0;
`;

const ReferralsLoading = styled(Loading)`
  background-color: ${theme.white};
`;

const ReferralsInstructionHeader = styled.div`
  text-align: center;
  margin: ${rem(55)} 0 ${rem(24)};
`;

const ReferralsInstructionTitle = styled.h2`
  font-size: ${rem(22)};
  margin-bottom: ${rem(4)};
  color: ${theme.gray900};
  letter-spacing: ${psLetterSpacing(22, -40)};
`;

const ReferralsInstructionCopy = styled.p`
  font-size: ${rem(15)};
  line-height: ${psLineHeight(15, 21)};
  margin-bottom: ${rem(30)};
  
  ${mediaUp.md`
    width: 482px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const ReferralsContainer = styled(Container)`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const ReferralsColumn = styled.div`
  ${mediaDown.lg`
    padding: ${rem(15)};
    
    &:first-of-type {
      padding-top: ${rem(30)};
    }
    
    &:last-of-type {
      padding-bottom: ${rem(30)};
    }
  `}
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
  `}
`;

const ReferralsInfoColumn = ReferralsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 113px;
    max-width: 113px;
  `}
`;

class Referrals extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      referrals: {},
      link: ''
    };
  }

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/user/referrals`, { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        this.setState({
          loading: false,
          referrals: response.data,
          link: process.env.REACT_APP_API_URL + "/register/" + response.data.referralId
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });

        this.props.logoutUser();
        this.props.history.push('/login');
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Referrals" />

        <Header />

        <AccountHeader />

        <ReferralsWrapper>
          <Container hasMobileGutters={true}>
            <ReferralsLoading loading={this.state.loading}>
              {this.state.referrals ? 
              <React.Fragment>
                <ReferralsTitle>Referral Counter: {this.state.referrals.count}</ReferralsTitle>
                <ReferralsInstructionCopy>
                  Refer people you know to Swapcoins.com and receive bonus coins!
                  You are credited $5 worth of BTC when your referral makes their first swap valued over $100.
                  After 5 referrals, we'll send you your outstanding BTC balance.
                  Simply send them your referral link below, have them sign up, and use Swapcoins.com! 
                  </ReferralsInstructionCopy>
                <ReferralsInstructionHeader>
                  <ReferralsInstructionTitle><a href={this.state.link} target="_blank" rel="noopener noreferrer">{this.state.link}</a></ReferralsInstructionTitle>
                </ReferralsInstructionHeader>
                
              </React.Fragment> :
              <ReferralsTitle>Please contact support@swapcoins.com</ReferralsTitle>
              }
            </ReferralsLoading>
          </Container>
        </ReferralsWrapper>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <Referrals {...props}
                      authenticated={authenticated}
                      authenticationLoading={authenticationLoading}
                      logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};
