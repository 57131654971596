import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import {
  theme,
  mediaUp,
  mediaDown,
  psLineHeight,
  psLetterSpacing,
  unstyledList,
  linkColorWithHoverColor
} from '../../globalStyle';
import { Link } from 'react-router-dom';
import Container from '../Container/Container';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import logo from '../../assets/images/logo.png';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/instagram.svg';
import youtube from '../../assets/images/yt.svg';
import twitter from '../../assets/images/twitter.svg';
import CookieConsent from "react-cookie-consent";

const footerCopyTypography = css`
  font-size: ${rem(12)};
  line-height: ${psLineHeight(12)};
  letter-spacing: ${psLetterSpacing(12, 60)};
`;

const footerCopyMargin = css`
  margin-bottom: ${rem(8)};
`;

const StyledFooter = styled.footer`
  background-color: ${theme.footerBg};
  font-family: ${theme.fontFamilyComfortaa};
  padding: ${rem(54)} 0 ${rem(39)};
  
  ${mediaUp.lg`
    padding: ${rem(90)} 0 ${rem(46)};
  `}
  
  ${mediaDown.lg`
    text-align: center;
  `}
`;

const FooterLogoLink = styled(Link)`
  display: block;
  margin-bottom: ${rem(35)};
`;

const FooterLogo = styled.img`
  transition: 0.3s opacity ease;
  width: 187px;
  
  &:hover {
    opacity: 0.7;
  }
`;

const FooterContactCopy = styled.p`
  ${footerCopyMargin}
  ${footerCopyTypography}
`;

const FooterContactLink = styled.a`
  ${linkColorWithHoverColor(theme.footerColor)}
`;

const FooterCredit = styled.p`
  color: ${theme.footerColor};
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  line-height: ${psLineHeight(10, 30)};
  margin-bottom: 0;
`;

const FooterBottom = styled.div`
  ${mediaUp.lg`
    margin-top: ${rem(64)};
  `}
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const FooterNavHeading = styled.h6`
  color: ${theme.white};
  font-size: ${rem(13)};
  line-height: ${psLineHeight(13)};
  letter-spacing: ${psLetterSpacing(13, 60)};
  text-transform: none;
  
  ${footerCopyMargin}
  
  ${mediaDown.lg`
    margin-top: ${rem(30)};
  `}
`;

const FooterNavList = styled.ul`
  ${unstyledList}
`;

const FooterNavListItem = styled.li`
  ${footerCopyMargin}
  ${footerCopyTypography}
  
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const FooterNavLink = styled(Link)`
  ${linkColorWithHoverColor(theme.footerColor)}
`;

const FooterSocialRow = styled.div`
  display: flex;
  margin-left: -${rem(20)};
  margin-right: -${rem(20)};
  
  ${mediaDown.lg`
    justify-content: center;
  `}
`;

const FooterSocialLink = styled.a`
  display: inline-flex;
  flex: 0 0 18px;
  max-width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  margin-left: ${rem(20)};
  margin-right: ${rem(20)};
`;

const FooterSocialIcon= styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`;

function Footer() {
  return (
    <StyledFooter>
      <Container>
        <GridRow>
          <GridColumn lgColumns={3}>
            <FooterLogoLink to="/">
              <FooterLogo src={logo} alt={theme.siteName} />
            </FooterLogoLink>

            <FooterContactCopy>
              <FooterContactLink href="mailto: support@swapcoins.com">
                support@swapcoins.com
              </FooterContactLink>
            </FooterContactCopy>
          </GridColumn>

          <GridColumn lgColumns={3}>
            <FooterNavHeading>Company</FooterNavHeading>

            <FooterNavList>
              <FooterNavListItem>
                <FooterNavLink to="/about">About Us</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/privacy-policy">Privacy Policy</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/terms-and-conditions">Terms and Conditions</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/sitemap">Site Map</FooterNavLink>
              </FooterNavListItem>
            </FooterNavList>
          </GridColumn>

          <GridColumn lgColumns={3}>
            <FooterNavHeading>Popular Pairs</FooterNavHeading>

            <FooterNavList>
              <FooterNavListItem>
                
                <FooterNavLink to="/swap/BTC/ETH" target="_top">Bitcoin to Ethereum</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/swap/BTC/XRP" target="_top">Bitcoin to Ripple</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/swap/ETH/BTC" target="_top">Ethereum to Bitcoin</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/swap/BTC/XMR" target="_top">Bitcoin to Monero</FooterNavLink>
              </FooterNavListItem>
            </FooterNavList>
          </GridColumn>

          <GridColumn lgColumns={3}>
            <FooterNavHeading>Get in Touch</FooterNavHeading>

            <FooterNavList>
              <FooterNavListItem>
                <FooterNavLink to="/contact">Contact Us</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/press">News Releases</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/contact">Partnership</FooterNavLink>
              </FooterNavListItem>

              <FooterNavListItem>
                <FooterNavLink to="/faq">Support</FooterNavLink>
              </FooterNavListItem>
            </FooterNavList>
          </GridColumn>
        </GridRow>

        <FooterBottom>
          <GridRow justifyContent="space-between">
            <GridColumn lgColumns={3}>
              <FooterCredit>
                &copy;2019 {theme.siteName}
              </FooterCredit>
            </GridColumn>

            <GridColumn lgColumns={3}>
              <FooterSocialRow>
                <FooterSocialLink href="https://www.facebook.com/SwapCoins-491695867987791/" target="_blank">
                  <FooterSocialIcon src={facebook} alt="Facebook" />
                </FooterSocialLink>

                <FooterSocialLink href="https://www.instagram.com/swapcoins/" target="_blank">
                  <FooterSocialIcon src={instagram} alt="Instagram" />
                </FooterSocialLink>

                <FooterSocialLink href="https://twitter.com/SwapCoins_com" target="_blank">
                  <FooterSocialIcon src={twitter} alt="Twitter" />
                </FooterSocialLink>

                <FooterSocialLink href="https://www.youtube.com/channel/UCuLHG5hH3FsP7Dn5VwkRetA/featured?view_as=subscriber" target="_blank">
                  <FooterSocialIcon src={youtube} alt="YouTube" />
                </FooterSocialLink>
              </FooterSocialRow>
            </GridColumn>
          </GridRow>
        </FooterBottom>
      </Container>
      <CookieConsent style={{fontSize: theme.fontSizeBase, background: theme.white, color: theme.black}} buttonStyle={{fontSize: theme.fontSizeBase, background: theme.white}} buttonText="CLOSE">
        Please know that cookies are required to operate and enhance our services as well as for advertising purposes. We value your privacy. If you are not comfortable with us using this information, please review your settings before continuing your visit.
      </CookieConsent>
    </StyledFooter>
  )
}

export default Footer;