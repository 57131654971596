import React from 'react';
import styled from 'styled-components';
import { theme, mediaUp, mediaDown, psLetterSpacing, psLineHeight } from '../../globalStyle';
import { rem } from 'polished';
import Container from '../Container/Container';
import SwapForm from './SwapForm';
import heroBg from '../../assets/images/home_hero_bg.jpg';

const StyledHero = styled.section`
  background-image: url('${heroBg}');
  background-size: cover;
  background-position: bottom right;
  
  ${mediaUp.lg`
    height: 100vh;
    padding-top: ${theme.headerDesktopHeight};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
  
  ${mediaDown.lg`
    padding: ${rem(93)} ${theme.mobileGutter} ${rem(75)};
  `}
`;

const HeroTitle = styled.h1`
  color: ${theme.tertiary};
  
  span {
    color: ${theme.white};
  }
`;

const HeroDesktopTitle = HeroTitle.extend`
  font-size: ${rem(58)};
  letter-spacing: ${psLetterSpacing(58, -40)};
  line-height: ${psLineHeight(58, 40)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const HeroMobileTitle = HeroTitle.extend`
  font-size: ${rem(28)};
  letter-spacing: ${psLetterSpacing(28, -40)};
  line-height: ${psLineHeight(28, 26)};
  text-align: center;
  
  ${mediaUp.lg`
    display: none;
  `}
`;

const HeroSubDesktopTitle = HeroDesktopTitle.extend`
  font-size: ${rem(22)};
  letter-spacing: ${psLetterSpacing(22, -40)};
  line-height: ${psLineHeight(22, 20)};
  color: ${theme.white};
  
  span {
    color: ${theme.white};
  }

  ${mediaDown.lg`
    display: none;
  `}
`;

const HeroSubMobileTitle = HeroMobileTitle.extend`
  font-size: ${rem(20)};
  letter-spacing: ${psLetterSpacing(20, -40)};
  line-height: ${psLineHeight(20, 18)};
  text-align: center;
  color: ${theme.white};
  
  span {
    color: ${theme.white};
  }

  ${mediaUp.lg`
    display: none;
  `}
`;

function Hero() {
  return (
    <StyledHero>
      <Container>
        <HeroDesktopTitle>
        <span>Cryptocurrency trading made easy</span><br />
        <HeroSubDesktopTitle>
          <span>Compare prices &amp; swap at the most competitive rates. No wasted or lost opportunities!</span>
          </HeroSubDesktopTitle>
        </HeroDesktopTitle>
        <HeroMobileTitle>
          <span>Cryptocurrency trading made easy</span>
          <HeroSubMobileTitle>
            <span>Compare prices &amp; swap at the most competitive rates. No wasted or lost opportunities!</span>
          </HeroSubMobileTitle>
        </HeroMobileTitle>
        
        <SwapForm />
      </Container>
    </StyledHero>
  )
}

export default Hero;
