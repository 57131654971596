import React from 'react';
import styled from 'styled-components';
import { mediaUp, psLetterSpacing } from '../../globalStyle';
import { rem } from 'polished';

const StyledPageTitle = styled.h1`
  text-align: center;
  margin-bottom: 0;
  padding: ${rem(44)} 0;
  font-size: ${rem(28)};
  letter-spacing: ${psLetterSpacing(28, -20)};
  
  ${mediaUp.lg`
    font-size: ${rem(48)};
    letter-spacing: ${psLetterSpacing(48, -20)};
    padding: ${rem(105)} 0 ${rem(97)};
  `}
`;

function PageTitle(props) {
  return (
    <StyledPageTitle className={props.className}>{props.children}</StyledPageTitle>
  )
}

export default PageTitle;
