import React from 'react';
import Button from '../Button/Button';
import CheckboxInput from '../Input/CheckboxInput';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import arrow from '../../assets/images/order_arrow.png';
import axios from 'axios';
import 'formdata-polyfill';

const userLimitsGutter = rem(22);

const UserLimitsHeader = styled.div`
  padding: ${rem(22)} ${userLimitsGutter};
  
  ${mediaUp.lg`
    padding: ${rem(26)} ${userLimitsGutter};
  `}
`;

const UserLimitsSettings = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;

const UserLimitsName = styled.div`
  padding: ${rem(18)} ${userLimitsGutter} ${rem(35)};
  text-align: center;
  
  ${mediaUp.lg`
    padding: ${rem(18)} ${userLimitsGutter} ${rem(54)};
  `}
`;

const UserLimitsArrow = styled.img`
  margin-bottom: ${rem(18)};
`;

const UserLimitsTo = styled.h3`
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(27)};
  color: ${theme.gray1000};
  
  ${mediaUp.lg`
    font-size: ${rem(34)};
  `}
`;

const UserLimitsLineItem = styled.div`
  padding: ${rem(15)} ${userLimitsGutter};
  border-top: 1px solid ${theme.muted};
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
    padding: ${rem(20)} ${userLimitsGutter};
  `}
  
  ${mediaDown.lg`
    text-align: center;
  `}
  
  &:last-of-type {
    border-bottom: 1px solid ${theme.muted};
  }
`;

const UserLimitsLineItemCopy = styled.span`
  font-size: ${rem(13)};
  line-height: normal;
  
  ${mediaDown.lg`
    display: block;
  `}
`;

const UserLimitsLineItemLabel = UserLimitsLineItemCopy.extend`
  color: ${theme.gray500};
  font-weight: ${theme.avenirWeightMedium};
  letter-spacing: ${psLetterSpacing(13, 20)};
  padding-right: 0.75rem;
  
  ${mediaDown.lg`
    margin-bottom: 0.5rem;
  `}
`;

const UserLimitsLineItemValue = UserLimitsLineItemCopy.extend`
  color: ${theme.tertiary};
  letter-spacing: 0;
  
  ${mediaUp.lg`
    margin-left: auto;
  `}
`;

const UserLimitsFooter = styled.div`
  padding: ${rem(20)} ${userLimitsGutter};
  text-align: center;

  ${mediaUp.lg`
    padding: ${rem(30)} ${userLimitsGutter};
  `}
`;

const UserLimitsButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

function UserLimits(props) {

  return (
    <React.Fragment>
      {props.username &&
        <UserLimitsHeader>
          <UserLimitsSettings>
            <strong>Limits</strong>
          </UserLimitsSettings>
        </UserLimitsHeader>
      }

      <UserLimitsName>
        <UserLimitsArrow src={arrow} alt="Limits" />
        {props.username &&
          <UserLimitsTo>
            {props.username}
          </UserLimitsTo>
        }
      </UserLimitsName>

      <form onSubmit={(event) => {
        const data = new FormData(event.target);
        event.preventDefault();

        const params = {
          enabledHour: data.get('enabledHOUR') === null || "" ? false : true,
          enabledDay: data.get('enabledDAY') === null || "" ? false : true,
          enabledWeek: data.get('enabledWEEK') === null || "" ? false : true,
          enabledMonth: data.get('enabledMONTH') === null || "" ? false : true,
          enabledYear: data.get('enabledYEAR') === null || "" ? false : true,
          limitHour: data.get('limitHOUR'),
          limitDay: data.get('limitDAY'),
          limitWeek: data.get('limitWEEK'),
          limitMonth: data.get('limitMONTH'),
          limitYear: data.get('limitYEAR'),
        };

        if (props.id && (params.limitHour || params.enabledHour)) {
          axios.post(`${process.env.REACT_APP_API_URL}/admin/users/limits`, 
          {
            id: props.id,
            name: 'LIMIT_HOUR',
            enabled: params.enabledHour,
            limit: params.limitHour,
          }
          ,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`}},
          )
        }
        if (props.id && (params.limitDay || params.enabledDay)) {
          axios.post(`${process.env.REACT_APP_API_URL}/admin/users/limits`, 
          {
            id: props.id,
            name: 'LIMIT_DAILY',
            enabled: params.enabledDay,
            limit: params.limitDay,
          }
          ,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`}},
          )
        }
        if (props.id && (params.limitWeek || params.enabledWeek)) {
          axios.post(`${process.env.REACT_APP_API_URL}/admin/users/limits`, 
          {
            id: props.id,
            name: 'LIMIT_WEEKLY',
            enabled: params.enabledWeek,
            limit: params.limitWeek,
          }
          ,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`}},
          )
        }
        if (props.id && (params.limitMonth || params.enabledMonth)) {
          axios.post(`${process.env.REACT_APP_API_URL}/admin/users/limits`, 
          {
            id: props.id,
            name: 'LIMIT_MONTHLY',
            enabled: params.enabledMonth,
            limit: params.limitMonth,
          }
          ,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`}},
          )
        }
        if (props.id && (params.limitYear || params.enabledYear)) {
          axios.post(`${process.env.REACT_APP_API_URL}/admin/users/limits`, 
          {
            id: props.id,
            name: 'LIMIT_YEARLY',
            enabled: params.enabledYear,
            limit: params.limitYear,
          }
          ,
          {headers: {'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`}},
          )
        }
      }}>
        {props.Limits.map((limit, index) => {
          return (
            <UserLimitsLineItem>
              <UserLimitsLineItemLabel>
                {limit.granularity}
              </UserLimitsLineItemLabel>
              <UserLimitsLineItemValue>
                <input type="number" name={"limit" + limit.granularity} placeholder={limit.limit} value={limit.limit}/>
              </UserLimitsLineItemValue>
              <UserLimitsLineItemValue>
                <CheckboxInput
                  name={"enabled" + limit.granularity}
                  isChecked={limit.enabled}
                />
              </UserLimitsLineItemValue>
            </UserLimitsLineItem>
          )
        })}

        <UserLimitsFooter>
          <UserLimitsButton
            arrow={true}
            secondary={true}
            block={true}
            >
            Save
          </UserLimitsButton>
        </UserLimitsFooter>
      </form>
    </React.Fragment>
  )
}

export default UserLimits;
