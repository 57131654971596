import React from 'react';
import Header from '../Header/Header';
import Container from '../Container/Container';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import CountCard from '../CountCard/CountCard';
import CoinCard from './CoinCard';
import HelpHero from '../HelpHero/HelpHero';
import Loading from '../Loading/Loading';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import CheckboxInput from '../Input/CheckboxInput';
import { rem, placeholder } from 'polished';
import { theme, psLetterSpacing, psLineHeight, mediaUp, mediaDown } from "../../globalStyle";

const SupportedCoinsContainer = styled(Container)`
  ${mediaUp.lg`
    margin-top: ${theme.headerDesktopHeight};
    padding-top: ${rem(30)};
    padding-bottom: ${rem(100)}
  `}
`;

const SupportedCoinsCountColumn = styled(GridColumn)`
  margin-bottom: ${rem(30)};

  ${mediaDown.lg`
    display: none;
  `}
`;

const SupportedCoinsColumn = styled(GridColumn)`
  margin-bottom: ${rem(30)};
`;

const SupportedCoinsSearch = styled.input`
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(18)};
  line-height: ${psLineHeight(18, 20)};
  letter-spacing: ${psLetterSpacing(18, -10)};
  background-color: ${theme.white};
  border: none;
  display: block;
  width: 100%;
  margin-bottom: ${rem(30)};
  border-radius: 3px;
  height: 76px;
  ${placeholder({'color': theme.muted})}

  ${mediaUp.lg`
    padding: 0 ${rem(21)};
  `}

  &:focus {
    outline: none;
  }
`;

const SupportedCoinsDesktopSearch = SupportedCoinsSearch.extend`
  ${mediaDown.lg`
    display: none;
  `}
`;

const SupportedCoinsMobileSearchWrapper = styled.div`
  margin-top: ${theme.headerMobileHeight};
  background-color: ${theme.white};

  ${mediaUp.lg`
    display: none;
  `}
`;

const SupportedCoinsCountCard = styled(CountCard)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 277px;
`;

const SortByCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 23px;
  justify-content: flex-end;
  align-items: flex-end;
`;

const SortByCheckboxLabel = styled.label`
  font-size: ${rem(12)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
  margin-bottom: 0;
  margin-left: 6px;
  margin-right: 12px;
`;


class SupportedCoins extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      coinSearch: '',
      filteredCoinsList: [],
      unSortedCoinsList: [],
      sortBy: false,
    };

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleSearchInputClear = this.handleSearchInputClear.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);

  };

  componentDidMount() {
    if (!this.props.coinsList.length) {
      this.props.getAllCoins();
    }
  }

  handleSortBy (sortBy, list) {
    let sortedList;
    list.length? sortedList = list: sortedList = [...this.props.coinsList];

    if (sortBy) {
        sortedList =  sortedList.sort((a, b) => (a.name > b.name) ? 1 : -1);

    } else {
      sortedList = sortedList;
    }

    this.setState({
      filteredCoinsList: sortedList,
    });
  }

  handleSorting() {
    this.setState({
      sortBy: !this.state.sortBy,
      loading: true,
    });

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (this.state.unSortedCoinsList.length) {
        this.handleSortBy(this.state.sortBy, this.state.unSortedCoinsList);
      } else {
        this.handleSortBy(this.state.sortBy, [...this.props.coinsList]);
      }

      this.setState({
        loading: false,
      });
    }, 500);

  }

  handleSearchInputChange(event) {
    const searchValue = event.target.value;
    const searchName = event.target.name;

    clearTimeout(this.timer);

    this.setState({
      [searchName]: searchValue,
      loading: true
    });

    this.timer = setTimeout(() => {
      if (searchValue.length) {
        let filteredCoinsList = [...this.props.coinsList];

        filteredCoinsList = filteredCoinsList.filter((coin) => {
          // if search input contains name or currency symbol
          if (coin.name) {
            return coin.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
              || coin.coin.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
          }

          return coin.coin.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
        });

        this.handleSortBy(this.state.sortBy, [...filteredCoinsList]);

        this.setState({
          loading: false,
          unSortedCoinsList: filteredCoinsList,
        });
      } else {
        this.handleSortBy(this.state.sortBy, [...this.props.coinsList]);

        this.setState({
          loading: false,
          unSortedCoinsList: [],
        });
      }
    }, 500);
  }

  handleSearchInputClear() {
    this.setState({
      coinSearch: '',
      filteredCoinsList: []
    });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Supported Coins" />

        <Header />

          <main>
            <SupportedCoinsMobileSearchWrapper>
              <Container hasMobileGutters={true}>
                <SupportedCoinsSearch
                  name="coinSearch"
                  type="text"
                  placeholder={`Search ${this.props.coinsList.length} coins`}
                  value={this.state.coinSearch}
                  onChange={this.handleSearchInputChange}
                  innerRef={mobileSearchRef => { this.mobileSearchRef = mobileSearchRef }}
                />
              </Container>
            </SupportedCoinsMobileSearchWrapper>

            <SupportedCoinsContainer hasMobileGutters={true}>
              <SupportedCoinsDesktopSearch
                name="coinSearch"
                type="text"
                placeholder={`Search ${this.props.coinsList.length} coins by asset name or symbol`}
                value={this.state.coinSearch}
                onChange={this.handleSearchInputChange}
                innerRef={desktopSearchRef => { this.desktopSearchRef = desktopSearchRef }}
              />

              <SupportedCoinsColumn>
                <SortByCheckboxWrapper>
                  <SortByCheckboxLabel>
                    Sort by:
                  </SortByCheckboxLabel>
                  <CheckboxInput name="checkbox"
                              value={!this.state.sortBy}
                              handleCheckChange={this.handleSorting}
                              isChecked={!this.state.sortBy}
                  />
                  <SortByCheckboxLabel>
                    Most popular
                  </SortByCheckboxLabel>

                  <CheckboxInput name="checkbox"
                              value={this.state.sortBy}
                              handleCheckChange={this.handleSorting}
                              isChecked={this.state.sortBy}
                  />
                  <SortByCheckboxLabel>
                    Ascending: A to Z
                  </SortByCheckboxLabel>

                </SortByCheckboxWrapper>
              </SupportedCoinsColumn>

              <Loading loading={this.props.coinsLoading || this.state.loading}>

                <GridRow gutterLg="15px">
                  <SupportedCoinsCountColumn lgColumns={4}>
                    <SupportedCoinsCountCard count={this.props.coinsList.length}
                               countFontSize={124}
                               title="Supported<br />Coins"
                               titleFontSize={50}
                    />
                  </SupportedCoinsCountColumn>

                  {this.state.filteredCoinsList.length
                    ?
                    this.state.filteredCoinsList.map((coin, index) => {
                      return (
                        <SupportedCoinsColumn key={index} lgColumns={4}>
                          <CoinCard
                            name={coin.name}
                            logo={coin.logo}
                            buttonName={coin.coin}
                            slug={coin.coin}
                          />
                        </SupportedCoinsColumn>
                      )
                    })
                    :
                    this.props.coinsList.map((coin, index) => {
                      return (
                        <SupportedCoinsColumn key={index} lgColumns={4}>
                          <CoinCard
                            name={coin.name}
                            logo={coin.logo}
                            buttonName={coin.coin}
                            coin={coin.coin}
                          />
                        </SupportedCoinsColumn>
                      )
                    })
                  }
                </GridRow>
              </Loading>
            </SupportedCoinsContainer>


            <HelpHero overlayColor={theme.bodyBg} />
          </main>
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({ coinsLoading, coinsList, getAllCoins }) => (
        <SupportedCoins {...props} coinsLoading={coinsLoading} getAllCoins={getAllCoins} coinsList={coinsList} />
      )}
    </CoinContext.Consumer>
  )
}
