import React from 'react';
import styled from 'styled-components';
import { hideVisually } from 'polished';
import { mediaUp, inputStyles } from '../../globalStyle';

const StyledTextAreaInput = styled.textarea`
  ${inputStyles}
  height: 220px;
  
  ${mediaUp.lg`
    height: 226px;
  `}
`;

const TextAreaLabel = styled.label`
  ${hideVisually()};
`;

class TextAreaInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: ''
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  render() {
    return (
      <React.Fragment>
        <TextAreaLabel for={this.props.name}>{this.props.placeholder}</TextAreaLabel>

        <StyledTextAreaInput
          id={this.props.name}
          name={this.props.name}
          value={this.state.value}
          placeholder={this.props.placeholder}
          type={this.props.type}
          onChange={this.handleInputChange}
          className={this.props.className}
          required={this.props.required}
        />
      </React.Fragment>
    )
  }
}

export default TextAreaInput;
