import React from 'react';
import styled from 'styled-components';
import { theme, mediaUp, mediaDown } from '../../globalStyle';
import { rem } from 'polished';
import Container from '../Container/Container';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import Tile from './Tile';
import InfoBlock from './InfoBlock';
import swaps from '../../assets/images/swaps_icon.svg';
import rates from '../../assets/images/rates_icon.svg';
import fanatical from '../../assets/images/fanatical_icon.svg';

const StyledInfo = styled.section`
  background-color: ${theme.white};
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: -141px;
    width: 50%;
    height: 141px;
    z-index: 5;
    background-color: ${theme.white};
  }
  
  ${mediaDown.lg`
    padding: 0 ${theme.mobileGutter};
    
    &::after {
      height: 40px;
      bottom: -40px;
    }
  `}
`;

const InfoTile = styled(Tile)`
  ${mediaUp.lg`
    height: calc(100% + 32px);
    margin-top: -${rem(32)};
  `}
  
  ${mediaDown.lg`
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};
    margin-top: -${rem(52)};
    margin-bottom: ${rem(60)};
  `}
`;

const swapsBlockContent = {
  icon: swaps,
  title: 'The Most Popular Currencies',
  copy: 'Swap the most popular cryptocurrencies, altcoins, tokens like Bitcoin (BTC), Ethereum (ETH), Ripple (XRP), Litecoin(LTC) etc. Check list of all cryptocurrencies we support.',
  link: '/supported-coins'
};

const ratesBlockContent = {
  icon: rates,
  title: 'Most Competitive Rates - Fast',
  copy: 'We partner with the top exchanges to save you the legwork of searching for the most competitive rate.',
  link: '/rates'
};

const fanaticalBlockContent = {
  icon: fanatical,
  title: 'One Account',
  copy: 'Through one Swapcoins account, you can exchange 180+ crypto pairs. Sign up today and explore all of Swapcoins\' features.',
  link: '/register'
};

function Info() {
  return (
    <StyledInfo>
      <Container>
        <GridRow noGutters={true}>
          <GridColumn lgColumns={6}>
            <InfoTile smFontSize={44}>
              Why choose Swapcoins?
            </InfoTile>
          </GridColumn>

          <GridColumn lgColumns={6}>
            <InfoBlock
              borderRight={true}
              icon={ratesBlockContent.icon}
              title={ratesBlockContent.title}
              copy={ratesBlockContent.copy}
              link={ratesBlockContent.link}
            />
          </GridColumn>

          <GridColumn lgColumns={6}>
            <InfoBlock
              borderBottom={true}
              first={true}
              icon={swapsBlockContent.icon}
              title={swapsBlockContent.title}
              copy={swapsBlockContent.copy}
              link={swapsBlockContent.link}
            />
          </GridColumn>

          <GridColumn lgColumns={6}>
            <InfoBlock
              icon={fanaticalBlockContent.icon}
              title={fanaticalBlockContent.title}
              copy={fanaticalBlockContent.copy}
              link={fanaticalBlockContent.link}
              className="info-block--is-last"
            />
          </GridColumn>
        </GridRow>
      </Container>
    </StyledInfo>
  )
}

export default Info;
