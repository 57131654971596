import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing, psLineHeight } from '../../globalStyle';
import OutlineText from '../OutlineText/OutlineText';

const TestimonialHeader = styled.div`
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    transform: translateY(-50%);
    background-color: ${theme.gray400};
  }
  
  ${mediaUp.lg`
    &::after {
      width: 87%;
    }
  `}
`;

const TestimonialApostrophe = styled(OutlineText)`
  position: absolute;
  font-size: ${rem(310)};
  letter-spacing: ${psLetterSpacing(310, -80)};
  top: 91px;
  left: -19px;
  
  ${mediaDown.lg`
    display: none;
  `}
  
  &::after {
    content: '';
    position: absolute;
    height: 35px;
    width: 100%;
    left: 19px;
    right: 0;
    top: 1px;
    background-color: ${theme.white};
  }
`;

const TestimonialImageWrapper = styled.div`
  width: 187px;
  height: 187px;
  margin: 0 auto;
  background-color: ${theme.white};
  position: relative;
  padding: ${rem(25)};
`;

const TestimonialImageInner = styled.div`
  border: 1px solid #5689ce;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
  
  &::before,
  &::after {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    background-color: ${theme.white};
  }
  
  &::before {
    bottom: 0;
    left: 0;
  }
  
  &::after {
    top: 0;
    right: 0;
  }
`;

const TestimonialImage = styled.img`
  border: 11px solid ${theme.white};
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
`;

const TestimonialQuote = styled.h3`
  font-family: ${theme.fontFamilyIBMPlexSerif};
  font-size: ${rem(26)};
  line-height: ${psLineHeight(26, 40)};
  letter-spacing: ${psLetterSpacing(26, 20)};
  font-style: italic;
  font-weight: 300;
  margin-bottom: ${rem(41)};
  
  ${mediaUp.lg`
    font-size: ${rem(40)};
    line-height: ${psLineHeight(40, 58)};
    letter-spacing: ${psLetterSpacing(40, 20)};
    margin-bottom: ${rem(62)};
  `}
`;

const TestimonialBody = styled.div`
  margin: 0 auto;
  text-align: center;
  max-width: 82%;
  
  ${mediaUp.lg`
    max-width: 863px;
  `}
`;

const TestimonialName = styled.span`
  font-family: ${theme.fontFamilyComfortaa};
  font-weight: 700;
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(12, 80)};
  padding-left: 44px;
  position: relative;
  
  &::before {
    content: '';
    background-color: ${theme.primary};
    width: 30px;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

function Testimonial(props) {
  return (
    <div>
      <TestimonialHeader>
        <TestimonialApostrophe strokeColor={theme.muted}>‘‘</TestimonialApostrophe>

        {props.image &&
          <TestimonialImageWrapper>
            <TestimonialImageInner>
              <TestimonialImage src={props.image} alt={props.name} />
            </TestimonialImageInner>
          </TestimonialImageWrapper>
        }
      </TestimonialHeader>

      <TestimonialBody>
        {props.quote &&
          <TestimonialQuote>{props.quote}</TestimonialQuote>
        }

        {props.name &&
          <TestimonialName>{props.name}</TestimonialName>
        }
      </TestimonialBody>
    </div>
  )
}

export default Testimonial;
