import React from 'react';
import Header from '../Header/Header';
import HelpHero from '../HelpHero/HelpHero';
import Button from '../Button/Button';
import Container from '../Container/Container';
import AdminHeader from './AdminHeader';
import Exchange from './Exchange';
import Modal from '../Modal/Modal';
import Loading from '../Loading/Loading';
import PageTitle from '../Page/PageTitle';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";

const ExchangesContainer = styled(Container)`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const ExchangesOrder = styled.div`
  border-bottom: 1px solid ${theme.muted};
  
  &:last-of-type {
    border-bottom: none;
  }
`;

const ExchangesHeader = styled.div`
  background-color: #fbfbfb;
  padding: ${rem(13)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const ExchangesBody = styled.div`
  background-color: ${theme.white};
`;

const ExchangesColumn = styled.div`
  ${mediaDown.lg`
    padding: ${rem(15)};
    margin-right: ${rem(15)};
    
    &:first-of-type {
      padding-top: ${rem(20)};
    }
    
    &:last-of-type {
      padding-bottom: ${rem(20)};
      padding-right: ${rem(15)};
    }
  `}
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
  `}
`;

const ExchangesNameColumn = ExchangesColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 15%;
    max-width: 15%;
  `}
`;

const ExchangesURLColumn = ExchangesColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 20%;
    max-width: 20%;    
  `}
`;

const ExchangesNotesColumn = ExchangesColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 40%;
    max-width: 40%;
  `}
`;

const ExchangesEnabledColumn = ExchangesColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 10%;
    max-width: 10%;
  `}
`;

const ExchangesButtonColumn = ExchangesColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 15%;
    max-width: 15%;
  `}
`;

const ExchangesHeaderTitle = styled.h6`
  text-transform: uppercase;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  margin-bottom: 0;
  color: #888888;
  line-height: normal;
`;

const ExchangesHeaderMobileTitle = ExchangesHeaderTitle.extend`
  margin-bottom: 0.25rem;
  
  ${mediaUp.lg`
    display: none;
  `}
`;

const ExchangesCell = styled.div`
  ${mediaUp.lg`
    display: flex;
    padding: ${rem(40)} 0;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `}
`;

const ExchangesName = styled.p`
  color: ${theme.gray1000};
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(10, 120)};
`;

const ExchangesURL = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;

  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const ExchangesNotes = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const ExchangesEnabled = styled.h4`
  color: ${theme.gray1000};
  letter-spacing: 0;
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 20px;
  `}
  
  ${mediaUp.lg`
    font-size: ${rem(16)};
    flex: 0 0 100%;
    max-width: 100%;
  `}
`;

const ExchangesButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

const ExchangesTitle = styled(PageTitle)`
  background-color: ${theme.white};
  color: ${theme.muted};
  padding: ${rem(120)} 0;
  
  ${mediaUp.lg`
    padding: ${rem(182)} 0;
  `}
`;

const ExchangesLoading = styled(Loading)`
  background-color: ${theme.white};
`;

class Exchanges extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      Exchanges: [],
      modalActive: false,
      name: '',
      url: '',
      notes: '',
      enabled: false,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  };

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.logoutUser();
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/admin/exchanges`, { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        let Exchanges = [];

        response.data.forEach((exchange) => {

          Exchanges.push({
            name: exchange.name,
            url: exchange.url,
            enabled: exchange.enabled,
            notes: exchange.notes,
          });
        });

        this.setState({
          loading: false,
          Exchanges: Exchanges
        });
      })
      .catch((error) => {
        this.setState({
          loading: false
        });

        this.props.logoutUser();
        this.props.history.push('/login');
      });
    }
  };

  handleModalOpen(name, url, notes, enabled) {
    this.setState({
      modalActive: true,
      name: name,
      url: url,
      notes: notes,
      enabled: enabled,
    }, () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    });
  };

  handleModalClose() {
    this.setState({
      modalActive: false,
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });

    window.location.reload();
  };

  render() {
    return (
      <React.Fragment>
        <Meta title="Exchanges" />

        <Header />

        <AdminHeader />

        <ExchangesLoading loading={this.state.loading}>
          {this.state.Exchanges ?
            <React.Fragment>
              <ExchangesHeader>
                <ExchangesContainer hasMobileGutters={true}>
                  <ExchangesNameColumn>
                    <ExchangesHeaderTitle>Name</ExchangesHeaderTitle>
                  </ExchangesNameColumn>

                  <ExchangesURLColumn>
                    <ExchangesHeaderTitle>URL</ExchangesHeaderTitle>
                  </ExchangesURLColumn>

                  <ExchangesNotesColumn>
                    <ExchangesHeaderTitle>Notes</ExchangesHeaderTitle>
                  </ExchangesNotesColumn>

                  <ExchangesEnabledColumn>
                    <ExchangesHeaderTitle>Enabled</ExchangesHeaderTitle>
                  </ExchangesEnabledColumn>
                </ExchangesContainer>
              </ExchangesHeader>

              <ExchangesBody>
                <Scrollbars style={{height: 400 }}>
                  {this.state.Exchanges.map((exchange, index) => {
                    return (
                      <ExchangesOrder key={index}>
                        <ExchangesContainer hasMobileGutters={true}>
                          <ExchangesNameColumn>
                            <ExchangesCell>
                              <ExchangesHeaderMobileTitle>Name</ExchangesHeaderMobileTitle>
                              <ExchangesName>
                                <strong>{exchange.name}</strong>
                              </ExchangesName>
                            </ExchangesCell>
                          </ExchangesNameColumn>

                          <ExchangesURLColumn>
                            <ExchangesCell>
                              <ExchangesHeaderMobileTitle>URL</ExchangesHeaderMobileTitle>
                              <ExchangesURL>{exchange.url}</ExchangesURL>
                            </ExchangesCell>
                          </ExchangesURLColumn>

                          <ExchangesNotesColumn>
                            <ExchangesCell>
                              <ExchangesHeaderMobileTitle>Notes</ExchangesHeaderMobileTitle>
                              <ExchangesNotes>{exchange.notes}</ExchangesNotes>
                            </ExchangesCell>
                          </ExchangesNotesColumn>

                          <ExchangesEnabledColumn>
                            <ExchangesCell>
                              <ExchangesHeaderMobileTitle>Enabled</ExchangesHeaderMobileTitle>
                                <ExchangesEnabled>
                                  <input
                                    type="checkbox"
                                    checked={exchange.enabled}
                                    value={exchange.enabled}
                                    name={exchange.name}
                                    disabled={true}
                                  />
                                </ExchangesEnabled>
                            </ExchangesCell>
                          </ExchangesEnabledColumn>

                          <ExchangesButtonColumn>
                            <ExchangesCell>
                              <ExchangesHeaderMobileTitle>Action</ExchangesHeaderMobileTitle>
                              <ExchangesButton secondary={true}
                                                handleButtonClick={() => {
                                                  this.handleModalOpen(
                                                    exchange.name,
                                                    exchange.url,
                                                    exchange.notes,
                                                    exchange.enabled,
                                                  )
                                                }}>
                                Configure
                              </ExchangesButton>
                            </ExchangesCell>
                          </ExchangesButtonColumn>

                        </ExchangesContainer>
                      </ExchangesOrder>
                    )
                  })}
                </Scrollbars>
              </ExchangesBody>
            </React.Fragment>
            :
            <ExchangesTitle>No Exchanges Found</ExchangesTitle>
          }
        </ExchangesLoading>

        <HelpHero />

        <Modal isActive={this.state.modalActive} handleModalClose={this.handleModalClose}>
          <Exchange
            name={this.state.name}
            url={this.state.url}
            notes={this.state.notes}
            enabled={this.state.enabled}
          />
        </Modal>

      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <Exchanges {...props}
                      authenticated={authenticated}
                      authenticationLoading={authenticationLoading}
                      logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};

