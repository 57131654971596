import React from 'react';
import { Link } from 'react-router-dom';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import { mediaUp, mediaDown, theme } from '../../globalStyle';
import { rem, rgba } from 'polished';
import logo from '../../assets/images/logo.png';
import hamburger from '../../assets/images/hamburger.svg';
import close from '../../assets/images/close.svg';
import user from '../../assets/images/user.svg';
import Container from '../Container/Container'
import Navigation from './Navigation';
import ScreenReaderText from '../ScreenReaderText/ScreenReaderText';

const StyledHeader = styled.header`
  height: ${theme.headerMobileHeight};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${theme.headerZIndex};
  background: transparent;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: -1;
    opacity: ${props => props.isTransparent && !props.scrolled && !props.navToggled ? '0' : '1'};
    background: ${theme.primary};
    transition: 0.5s opacity ease;
  }

  ${mediaUp.lg`
    height: ${theme.headerDesktopHeight};

    &::before {
      background: linear-gradient(to right, ${theme.headerBgStart} 0%, ${theme.headerBgEnd} 83%);
    }
  `}
`;

const HeaderContainer = styled(Container)`
  display: flex;
  height: 100%;
  align-items: center;
  
  ${mediaDown.sm`
    padding-left: ${theme.mobileGutterLg};
    padding-right: ${theme.mobileGutterLg};
  `}
  
  ${mediaUp.lg`
    position: relative;
  `}
`;

const HeaderLogoLink = styled(Link)`
  ${mediaUp.lg`
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
  `}
`;

const HeaderLogo = styled.img`
  transition: 0.3s opacity ease;
  width: 187px;
  
  &:hover {
    opacity: 0.7;
  }
`;

const HeaderNavToggle = styled.button`
  background: transparent;
  margin-left: auto;
  border: none;
  width: 28px;
  height: 28px;
  position: relative;
  padding: 0;
  
  ${mediaUp.lg`
    display: none;
  `}
  
  &:focus {
    outline: none;
  }
`;

const HeaderNavToggleIcon = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s opacity ease;
  cursor: pointer;
`;

const HeaderNavToggleHamburger = HeaderNavToggleIcon.extend`
  opacity: ${props => props.toggled ? '0' : '1'};
`;

const HeaderNavToggleClose = HeaderNavToggleIcon.extend`
  opacity: ${props => props.toggled ? '1' : '0'};
`;

const HeaderUserLink = styled(Link)`
  display: none;
  line-height: 0;
  transition: 0.3s opacity ease;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  
  ${mediaUp.lg`
    display: block;
  `}
  
  &:hover {
    opacity: 0.7;
  }
`;

const HeaderUserLogoutLink = HeaderUserLink.extend`
  right: 56px;
  font-weight: 300;
  font-size: ${rem(12)};
  letter-spacing: 0.5px;
  color: ${theme.white};
  transition: 0.3s color ease;
  
  &:hover {
    color: ${rgba(theme.white, 0.7)};
    text-decoration: none;
    opacity: 1;
  }
  
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 16px;
    background-color: ${theme.white};
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const HeaderUserConfigLink = HeaderUserLink.extend`
  right: 133px;
  font-weight: 300;
  font-size: ${rem(12)};
  letter-spacing: 0.5px;
  color: ${theme.white};
  transition: 0.3s color ease;
  
  &:hover {
    color: ${rgba(theme.white, 0.7)};
    text-decoration: none;
    opacity: 1;
  }
  
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 16px;
    background-color: ${theme.white};
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const HeaderUserRegisterLink = HeaderUserLink.extend`
  right: -23px;
  font-weight: 300;
  font-size: ${rem(12)};
  letter-spacing: 0.5px;
  color: ${theme.white};
  transition: 0.3s color ease;
  
  &:hover {
    color: ${rgba(theme.white, 0.7)};
    text-decoration: none;
    opacity: 1;
  }
`;

const HeaderUserLinkIcon = styled.img`
  width: 22px;
  height: auto;
`;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      navToggled: false,
      scrolled: false
    };

    this.onNavToggle = this.onNavToggle.bind(this);
    this.onWindowScroll = this.onWindowScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  onNavToggle() {
    this.setState({
      navToggled: !this.state.navToggled
    }, () => {
      document.documentElement.style.overflow = this.state.navToggled ? 'hidden' : 'auto';
      document.body.style.overflow = this.state.navToggled ? 'hidden' : 'auto';
    });

  }

  onWindowScroll() {
    this.setState({
      scrolled: window.scrollY > 0
    });
  }

  render() {
    return (
      <StyledHeader
        isTransparent={this.props.isTransparent}
        scrolled={this.state.scrolled}
        navToggled={this.state.navToggled}
      >
        <HeaderContainer>
          <HeaderLogoLink to="/">
            <HeaderLogo src={logo} alt={theme.siteName} />
          </HeaderLogoLink>

          <Navigation toggled={this.state.navToggled} />


          <CoinContext.Consumer>
            {({ authenticated, logoutUser, admin }) => (
              authenticated
                ? 
                <React.Fragment>
                  <HeaderUserLogoutLink to="/" onClick={logoutUser}>Log Out</HeaderUserLogoutLink>
                  { admin && 
                    <HeaderUserConfigLink to="/manage/configs">Manage</HeaderUserConfigLink>
                  }
                  <HeaderUserLink to="/account/profile">
                    <HeaderUserLinkIcon src={user} alt="Account" />
                  </HeaderUserLink>
                </React.Fragment> 
                : 
                <React.Fragment>
                  <HeaderUserLogoutLink to="/login">Login</HeaderUserLogoutLink>
                  <HeaderUserRegisterLink to="/register">Register</HeaderUserRegisterLink>
                </React.Fragment>
            )}
          </CoinContext.Consumer>

          <HeaderNavToggle onClick={this.onNavToggle}>
            <HeaderNavToggleHamburger src={hamburger} aria-hidden="true" toggled={this.state.navToggled} />
            <HeaderNavToggleClose src={close} aria-hidden="true" toggled={this.state.navToggled} />

            <ScreenReaderText>
              {this.state.navToggled ? 'Close Navigation' : 'Open Navigation'}
            </ScreenReaderText>
          </HeaderNavToggle>
        </HeaderContainer>
      </StyledHeader>
    );
  }
}

export default Header;