import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import TextInput from '../Input/TextInput';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import CheckboxInput from '../Input/CheckboxInput';
import Button from '../Button/Button';
import HelpHero from '../HelpHero/HelpHero';
import Alert from '../Alert/Alert';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import axios from 'axios';
import 'formdata-polyfill';
import { rem } from 'polished';
import { inputStyles } from '../../globalStyle';
import { theme, mediaUp, psLetterSpacing, psLineHeight } from "../../globalStyle";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

const RegisterPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(64)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(92)};
  `}
`;

const RegisterPageTitle = styled(PageTitle)`
  ${mediaUp.lg`
    padding: ${rem(101)} 0 ${rem(44)};
  `}
`;

const RegisterContent = styled.div`
  text-align: center;
  
  ${mediaUp.lg`
    width: 50%;
    margin: 0 auto;
  `}
`;

const RegisterButton = styled(Button)`
  width: 100%;
  text-align: center;
  margin: ${rem(31)} auto 0;
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, 120)};
  line-height: ${psLineHeight(14, 20)};
  
  ${mediaUp.lg`
    max-width: 270px;
    margin: ${rem(33)} auto 0;
  `}
`;

const RegisterCopy = styled.p`
  font-size: ${rem(15)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
  margin: ${rem(20)} 0 ${rem(10)};
`;

const RegisterCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 23px;
`;

const RegisterCheckboxLabel = styled.label`
  font-size: ${rem(12)};
  line-height: ${psLineHeight(12, 20)};
  letter-spacing: ${psLetterSpacing(12, 20)};
  margin-left: 12px;
`;

const RegisterAlert = styled(Alert)`
  text-align: left;
  margin-bottom: 1rem;
`;

const CountryDropdownStyled = styled(CountryDropdown)`
  ${inputStyles}
  background-color:${theme.white};
  -webkit-appearance: none;
`;

const RegionDropdownStyled = styled(RegionDropdown)`
  ${inputStyles}
  background-color:${theme.white};
  -webkit-appearance: none;
`;

function validatePassword(password) {
  var re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/;
  return re.test(String(password));
};

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

function validateReferral(referralId) {
  var re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/;
  return re.test(String(referralId));
};

class Register extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      termsCheckboxValue: false,
      errors: [],
      hasError: false,
      hasSuccess: false,
      state: 'State',
      country: 'Country',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectCountry = this.selectCountry.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
    this.handleTermsCheckChange = this.handleTermsCheckChange.bind(this);
  }

  selectCountry(value) {
    this.setState({
      country: value,
    });
  }

  selectRegion(value) {
    this.setState({
      state: value,
    });
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    let errors = [];
    event.preventDefault();

    const params = {
      username: data.get('username'),
      firstname: data.get('firstname'),
      lastname: data.get('lastname'),
      address: data.get('address'),
      city: data.get('city'),
      state: data.get('state'),
      zip: data.get('zip'),
      country: data.get('country'),
      email: data.get('email'),
      password: data.get('password'),
      passwordB: data.get('passwordB'),
    };

    if (this.props.match.params.referredId) {
      if (validateReferral(this.props.match.params.referredId) && this.props.match.params.referredId.length === 12) {
        params.referredId = this.props.match.params.referredId;
      }
    }

    this.setState({
      loading: true
    });

    if (params.username.length < 5 || params.username.length > 64) {
      errors.push('Username must be between 5 to 64 characters');
    }

    if (/[^a-zA-Z0-9]/.test(params.username)) {
      errors.push('Username can be letters and numbers only');
    }

    if (params.firstname.length < 1 || params.firstname.length > 64) {
      errors.push('First name must be between 1 to 64 characters');
    }

    if (/[^a-zA-Z0-9 ]/.test(params.firstname)) {
      errors.push('First name cannot contain symbols.');
    }

    if (params.lastname.length < 1 || params.lastname.length > 64) {
      errors.push('Last name must be between 1 to 64 characters');
    }

    if (/[^a-zA-Z0-9 ]/.test(params.lastname)) {
      errors.push('Last name cannot contain symbols.');
    }

    if (params.address.length < 1 || params.address.length > 255) {
      errors.push('Address must be between 1 to 255 characters');
    }

    if (params.city.length < 1 || params.city.length > 255) {
      errors.push('City must be between 1 to 255 characters');
    }

    if (/[^a-zA-Z0-9 ]/.test(params.city)) {
      errors.push('City cannot contain symbols.');
    }

    if (params.state.length < 1 || params.state.length > 255) {
      errors.push('State must be between 1 to 255 characters');
    }

    if (params.zip.length < 1 || params.zip.length > 16) {
      errors.push('Zip must be between 1 to 16 characters');
    }

    if (/[^a-zA-Z0-9 ]/.test(params.zip)) {
      errors.push('Zip cannot contain symbols.');
    }

    if (params.country.length < 1 || params.country.length > 64) {
      errors.push('Country must be between 1 to 64 characters');
    }

    if (!validateEmail(params.email)) {
      errors.push('Invalid email (eg. email@swapcoins.com)');
    }

    if (params.password !== params.passwordB) {
      errors.push('Retyped password does not match.');
    }

    if (params.password.length < 8 || params.password.length > 255) {
      errors.push('Password must be between 8 to 255 characters');
    }

    if (!validatePassword(params.password)) {
      errors.push('Password must contain an uppercase letter, a lowercase letter, and a number.');
    }

    if (!this.state.termsCheckboxValue) {
      errors.push('You must agree to the terms and privacy policy to register.')
    }

    if (errors.length) {
      this.setState({
        errors: errors,
        loading: false,
        hasError: true,
        hasSuccess: false
      });

      window.scrollTo(0, 0);
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/authenticate/register`, params)
        .then(() => {
          this.setState({
            loading: false,
            hasError: false,
            hasSuccess: true
          });

          this.props.history.push('/new-registration');
        })
        .catch((error) => {
          this.setState({
            loading: false,
            errors: [`There was an error with your request (${error.response.data.error}). Please try again.`],
            hasError: true,
            hasSuccess: false,
          });

          window.scrollTo(0, 0);
        });
    }
  }
  
  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/account/profile');
    } 
  }

  componentDidUpdate() {
    if (this.props.authenticated) {
      this.props.history.push('/account/profile');
    }
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      errors: [],
      hasError: false,
    })
  }

  handleTermsCheckChange() {
    this.setState({
      termsCheckboxValue: !this.state.termsCheckboxValue
    });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Register" />

        <Header />

        <RegisterPageContainer backgroundLight={true} headerOffset={true}>
          <RegisterPageTitle>Register</RegisterPageTitle>

          <RegisterContent>
            <RegisterAlert show={this.state.hasSuccess} success={true}>
              An email has been sent to complete your registration.
            </RegisterAlert>

            <RegisterAlert show={this.state.hasError} danger={true}>
              {this.state.errors.map((error, index) => {
                return (
                  <React.Fragment key={index}>
                    {error}{index !== (this.state.errors.length - 1)
                      ?
                      <React.Fragment>
                        <br />
                        <br />
                      </React.Fragment>
                      :
                      <React.Fragment />
                    }
                  </React.Fragment>
                )
              })}
            </RegisterAlert>

            <RegisterCopy>
              Trading cryptocurrency with Swapcoins.com is easy! <br />
              All you need to do is register for a free account or <Link to="/login">login</Link>. <br />
              Are you <a href="mailto:support@swapcoins.com">having issues</a> registering?
            </RegisterCopy>

            <form onSubmit={this.handleSubmit}>
              <GridRow gutterMd={rem(10)}>
                <GridColumn mdColumns={12}><TextInput name="username" type="text" maxLength="64" placeholder="Username (eg. Swapcoins; 5-64 chars)" required /></GridColumn>
                <GridColumn mdColumns={12}><TextInput name="email" type="email" placeholder="Email (eg. email@swapcoins.com)" required /></GridColumn>
                <GridColumn mdColumns={6}><TextInput name="firstname" type="text" maxLength="255" placeholder="First Name" required /></GridColumn>
                <GridColumn mdColumns={6}><TextInput name="lastname" type="text" maxLength="255" placeholder="Last Name" required /></GridColumn>
                <GridColumn mdColumns={12}><TextInput name="address" type="text" maxLength="255" placeholder="Address" required /></GridColumn>
                <GridColumn mdColumns={6}><TextInput name="city" type="text" maxLength="255" placeholder="City" required /></GridColumn>
                <GridColumn mdColumns={6}><TextInput name="zip" type="text" maxLength="16" placeholder="Zip/Postal Code" required /></GridColumn>
                <GridColumn mdColumns={6}>
                  <CountryDropdownStyled
                    name="country"
                    value={this.state.country}
                    defaultOptionLabel="Country"
                    onChange={(val) => this.selectCountry(val)}
                  />
                </GridColumn>
                <GridColumn mdColumns={6}>
                  <RegionDropdownStyled
                    name="state"
                    country={this.state.country}
                    value={this.state.state}
                    defaultOptionLabel="State"
                    onChange={(val) => this.selectRegion(val)}
                  />
                </GridColumn>
                <GridColumn mdColumns={12}><TextInput name="password" type="password" maxLength="255" placeholder="Create Password (Upper, lowercase, and number; 8-255 chars)" required /></GridColumn>
                <GridColumn mdColumns={12}><TextInput name="passwordB" type="password" maxLength="255" placeholder="Retype Password" required /></GridColumn>
              </GridRow>

              <RegisterCheckboxWrapper>
                <CheckboxInput name="termsCheckbox"
                               value={this.state.termsCheckboxValue}
                               handleCheckChange={this.handleTermsCheckChange}
                               isChecked={this.state.termsCheckboxValue}
                />

                <RegisterCheckboxLabel>
                  I agree to the <Link to="/terms-and-conditions">terms</Link> &amp; <Link to="/privacy-policy">privacy policy</Link>
                </RegisterCheckboxLabel>
              </RegisterCheckboxWrapper>

              <RegisterButton secondary={true} hasArrow={true} loading={this.state.loading} disabled={!this.state.termsCheckboxValue}>Create Account</RegisterButton>

              <RegisterCopy>
                Already registered? <Link to="/login">Login</Link>
              </RegisterCopy>
            </form>
          </RegisterContent>
        </RegisterPageContainer>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default Register;

