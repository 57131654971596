import React from 'react';
import Header from '../Header/Header';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import Container from '../Container/Container';
import { rem } from 'polished';
import { theme, mediaUp  } from '../../globalStyle';
import guide from '../../assets/images/swapcoins_infographic.png';

const HowItWorksContainer = styled(Container)`
  margin-top: ${theme.headerDesktopHeight};
  text-align: center;
  ${mediaUp.lg`
    padding-top: ${rem(30)};
    padding-bottom: ${rem(100)}
  `}
`;

function HowItWorksGuide(props) {
  return (
    <React.Fragment>
    <Meta title="How It Works" />

    <Header />
      <main>
        <HowItWorksContainer hasMobileGutters={true}>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/hz1WXbB-nuw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <br />Download the <a href={guide} download>how it works guide</a> for more info.
        </HowItWorksContainer>
     </main>
    </React.Fragment>
  )
}
export default HowItWorksGuide;