import React from 'react';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp } from '../../globalStyle';

const TermsConditionsPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(75)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(172)};
  `}
`;

const TermsConditionsContent = styled.div`
  ${mediaUp.lg`
    width: 60%;
    margin: 0 auto;
  `}
`;

const TermsConditionsTitle = styled.h3`
  margin-bottom: 0.5rem;
`;

const TermsConditionsCopy = styled.p`
  strong {
    color: ${theme.tertiary};
    font-weight: ${theme.avenirWeightHeavy};
  }
`;

function TermsConditions() {
  return (
    <React.Fragment>
      <Meta title="Terms & Conditions" />

      <Header />

      <TermsConditionsPageContainer backgroundLight={true} headerOffset={true}>
        <PageTitle>Terms &amp; Conditions</PageTitle>

        <TermsConditionsContent>
          <TermsConditionsCopy>
            As used herein, "<strong>Company</strong>", "<strong>Swapcoins</strong>", "<strong>Ourselves</strong>",
            "<strong>Website</strong>", "<strong>We</strong>", and "<strong>Us</strong>" collectively refers to
            the internet website "Swapcoins.com" ("Website") and to its owners, operators, employees, directors,
            officers, employees, agents, insurers, suppliers, and attorneys. "<strong>Client</strong>",
            "<strong>Customer</strong>", "<strong>User</strong>", "<strong>You</strong>" and
            "<strong>Your</strong>" refers to you, the person accessing "<strong>Swapcoins.com</strong>" by
            accepting the Terms of Use. "<strong>Party</strong>" refers
            to either You or Us and "<strong>Parties</strong>" refers to You and Us collectively.
            "<strong>Crypto Currency</strong>" or "<strong>CCs</strong>" refers
            to crypto currencies, i.e., unregulated digital money, which is issued and usually controlled by its
            developers, and used and accepted among the members of a specific Crypto community, including but not
            limited to Bitcoins, NEO, Ether, Litecoin, Ripple, Dash, etc.
            <br />
            <br />
            Depending upon context, "<strong>Swapcoins</strong>" may also refer to the services, products, website,
            content or other materials (collectively, "<strong>Materials</strong>") provided by the Company.
            <br />
            <br />
            By using the Website and further by accepting to avail the services offered by
            Swapcoins.com ("<strong>Service</strong>"), You are hereby agreeing to accept and comply with the terms
            and conditions of use stated herein below ("<strong>Terms & Conditions</strong>").
            <br />
            <br />
            You should read, agree, and accept the entire Terms & Conditions prior to your use of Swapcoins.com or any of the Service provided by Us. You are hereby expressly barred from accessing the Website or availing the Service unless You agree and accept these Terms & Conditions.
            <br />
            <br />
            As long as you agree to and comply with these Terms & Conditions, Swapcoins grants to You a personal, non-exclusive, non-transferable, non-sub-licensable, and limited right to enter and use the Website and its Services.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Terms & Conditions:</TermsConditionsTitle>
          <TermsConditionsCopy>
            <ol>
              <li>Swapcoins is neither the creator nor the administrator of any Crypto Currency. It merely acts as an aggregator and intermediary between the User and the various Crypto currency exchanges across the globe through External Websites.</li>
              <li>The Service operated by the Company in the name of Swapcoins allows users to exchange CCs on Crypto currency exchanges by accessing External Website via the Website. The Service provided by Swapcoins allows all users of the Website, to exchange CCs directly with other CCs from various Crypto currency exchanges.</li>
              <li>All kinds of fees are included in the quantities shown to You during the comparison of rates across the CC exchanges. All payments towards fee shall be made by way of CCs only.</li>
              <li>Swapcoins has no control over the generation, distribution, retention or termination of CCs. Swapcoins does not control market volatility of CCs. It merely acts as an intermediary for the services offered, which are fully set out herein</li>
              <li>Swapcoins does not provide a warranty of any kind, either express or implied. Services and the Website and any information or content contained therein is provided on an “as-is” and “as available” basis. User avails of the Services and / or dealing in CCs does so at their own risk and with full disclosure of possible wiping out of the entire holding or valuation, in the event of the occurrence of any of the risk factors including but not limited to changes in regulatory or legal positions or restrictions that may be placed on Swapcoins, it is hereby expressly declared that Swapcoins offers no warranty of any kind regarding the Website or the Services.</li>
              <li>All Services are provided without warranty of any kind, either express or implied. While we strive to provide you with the uninterrupted Service, we do not guarantee that the access to this Website will not be interrupted, or that there will be no delays, failures, errors, omissions or loss of transmitted information. We will use reasonable endeavors to ensure that the Website can normally be accessed by You in accordance with these Terms & Conditions. We may suspend use of the Website for maintenance and will make every reasonable effort to give You reasonable notice. You acknowledge that such notice, however, may not be possible in every situation.</li>
              <li>Swapcoins does not encourage the violation of any laws and cannot be held responsible for violation of such laws in any jurisdiction of the world. It is Your responsibility to follow the laws of Your country of residence and/or country from which You access this Website.</li>
            </ol>
          </TermsConditionsCopy>

          <TermsConditionsTitle>Taxes:</TermsConditionsTitle>

          <TermsConditionsCopy>
            It is Your responsibility to determine, collect, report, and submit any taxes applicable to the payments You make or receive in connection with the use of the Website and the Service to the appropriate tax authority. Swapcoins is not responsible for determining whether taxes apply to Your transaction, or for collecting, reporting or remitting any taxes arising from any transaction undertaken via the Website.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Privacy Policy:</TermsConditionsTitle>
          <TermsConditionsCopy>
            Swapcoins collects information with respect to Your CC wallet addresses and Your IP address. Swapcoins does not in any way obscure the information that it does request or obtain. Due to the inherent transparency of blockchains of CCs, transactions to and from the Website are public, and may be disclosed by Us, to any third party, including but not limited to government regulators.
            <br />
            <br />
            We may also place cookies on Your device while You access the Website. Cookies collect User information including Website usage, visits to the Website and other User generated information. Cookies allow Users to navigate from page to page without having to re-login each time, count visits, and see which areas and features of the Website are popular.
            <br />
            <br />
            Any sensitive information in these cookies are encoded so that only Swapcoins can interpret the information stored on them. The cookies are placed for the following purposes:
            <ul>
              <li>Recognize You as a Swapcoins User</li>
              <li>Collect information about Your computer to mitigate risk and help prevent fraud,</li>
              <li>Customize Your experience, content, and advertising, and</li>
              <li>Measure promotional effectiveness.</li>
            </ul>
            Further, use of cookies and other technologies may allow Swapcoins and third parties to collect information about User browsing activities over time and across different Websites following use of Service.
            <br />
            <br />
            Swapcoins may also ask for mobile/phone verification from user while creating transactions for some exchanges and store this information to provide a better experience to our users. Phone numbers that end users provide for authentication will be sent and stored by third party phone verification services to improve their spam and abuse prevention.
            <br />
            <br />
            Swapcoins may also uses third party analytical tools to collect data about usage of Website. The information collected identifies the types and timing of actions taken, including installation, registration, uploading, and certain types of navigation. All of this information is used internally for the purpose of understanding how the Website is being used and to improve the same.
            <br />
            <br />
            You are advised to not utilize Swapcoins to obscure transactions or assets in any way. Law enforcement has full access to blockchain information with respect to CCs that go in or out of Swapcoins’s system. You accept that Swapcoins will comply with all legal requests for information and reserves the right to share the information shared by You in its sole discretion.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Risks:</TermsConditionsTitle>
          <TermsConditionsCopy>
            The use of the Website and the Service may entail the following risks:
            <ol>
              <li>Crypto Currency transactions are irreversible after they hit the network and once a transaction is initiated by You, it can neither be cancelled, modified nor reversed by Us.</li>
              <li>The values of CCs are highly volatile and tends to fluctuate, due to which there may be increase or decrease in the value of the CC You receive after conversion. CCs are subject to large swings in value and may even become worthless. There is always an inherent risk of losses associated with buying, selling or trading in CCs. It is also possible that the valuation and price of CC changes after the transaction has been initiated by You or during the course of the transaction, and You are hereby advised of the same. Under no circumstance shall Swapcoins be liable for any such changes or fluctuations.</li>
              <li>CCs are an as-yet autonomous and largely unregulated worldwide system of value transfer between private parties. It is not backed by any government or commodities and hence may be susceptible to risks not generally associated with other forms of legal tenders.</li>
              <li>As CC is digital cash managed by a peer-to-peer system of networks, there is also the risk of loss of confidence in CC trading. The valuation of CCs is based on a supply and demand economy model.</li>
              <li>There may be additional risks that we have not foreseen or identified in these Terms & Conditions.</li>
            </ol>
            You should carefully assess whether Your financial situation and tolerance for risk is suitable for buying, selling or trading CCs.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Limited Right of Use:</TermsConditionsTitle>
          <TermsConditionsCopy>
            Unless otherwise specified, all Materials on this Website are the property of the Company and are
            protected by copyright, trademark, and other applicable laws. You may view, print, and/or download a
            copy of such Materials on any single computer solely for Your personal, informational, non-commercial
            use, provided You keep intact all copyright and other proprietary notices. The trademarks, service
            marks, and logos of Swapcoins and others used in this Website ("<strong>Trademarks</strong>") are the
            property of the Company and their respective owners. The software, text, images, graphics, data,
            prices, trades, charts, graphs, video, and audio used on this Website belong to Company. The
            Trademarks and Materials should not be copied, reproduced, modified, republished, uploaded, posted,
            transmitted, scraped, collected or distributed in any form or by any means, whether manual or
            automated. The use of any such Materials on any other website or networked computer environment
            for any other purpose is strictly prohibited; any such unauthorized use may violate copyright,
            trademark, and other applicable laws and could result in criminal or civil legal actions and penalties.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Usage:</TermsConditionsTitle>
          <TermsConditionsCopy>
            Each User shall ensure compliance to the following terms while availing or accessing the Website or the Service:
            <ol>
              <li>The Website is solely for personal and non-commercial use.</li>
              <li>By accessing the Website, You agree to provide Company with current, accurate, and complete information about Yourself as prompted by our Privacy Policy, and to keep such information updated. You further agree that You will not use or trade in CCs using CCs other than Your own, and obtained through legal channels and for legal purposes.</li>
              <li>If there is any suspicious activity related to the transaction undertaken by You via the Website, We may, but are not obligated, to request additional information from You, including authenticating documents, and to freeze any transactions pending Our review.</li>
              <li>You are required to notify Swapcoins, immediately, of any unauthorized use of the Website or Service that You may become aware off, by way of an email addressed to support@swapcoins.com. The User who violates these Terms & Conditions may be the concerned authorities or restricted from accessing the Website, and further shall also be held liable for losses incurred by Company or any User of the Website.</li>
              <li>You hereby agree that You shall not use the Website or our Service, in connection with any activity violating any law, statute, ordinance, or regulation of any jurisdiction. Further, You shall not exchange CCs via using the Website that are obtained through illegal activities and You will also not use the Service to perform any illegal activity of any sort, in any jurisdiction, including but not limited to, money laundering, illegal gambling operations, terrorist financing, or malicious hacking. Source of CCs exchanged by You is not known to Swapcoins and You hereby agree to ensure that it always comes from legitimate sources.</li>
              <li>Swapcoins only acts as an information intermediary and an aggregator of various Crypto currency exchanges. In case of any technical or manual error that happens by Swapcoins, the Company will, on a best effort basis, take the steps undertake the requisite corrections including the involved transactions and/or records and will intimate You about the correction in a timely manner. If such correction creates a liability on You, You will be required to incur that liability.</li>
              <li>You need to understand that You agree to the above and authorise Us to suspend Your access if You are found to be in violation of these Terms & Conditions.</li>
            </ol>
          </TermsConditionsCopy>

          <TermsConditionsTitle>External Websites:</TermsConditionsTitle>
          <TermsConditionsCopy>
            The Website may provide links or other forms of reference to other websites
            ("<strong>External Websites</strong>") or resources over which We have no control. You acknowledge that
            Swapcoins is providing any such links or references of External Websites or resources to You, including
            APIs to various Crypto currency exchanges, only as a convenience to You and that Swapcoins makes no
            representations whatsoever about any External Websites which You may access through or via this Website,
            including such External Websites being free of such items as viruses, worms, trojan horses, and other
            items of a destructive nature. Swapcoins is not responsible for the availability of, and content
            provided on any External Websites. You are requested to review the terms & conditions and policies
            posted by such External Websites regarding privacy and other topics before use. Swapcoins is not
            responsible for third party content accessible through the Website, including opinions, advice,
            statements, prices, activities, and advertisements, and You shall bear all risks associated with the
            use of such content or trading CCs on such External Website.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Prohibition on Money Laundering / Illegal Activities:</TermsConditionsTitle>
          <TermsConditionsCopy>
            Swapcoins has zero tolerance towards use of its Services for any illegal purpose. Any such abuse by any User shall result in forthwith restriction of access and reporting of such activities to concerned regulatory and statutory authorities. Swapcoins shall report all suspicious activities to the concerned authorities to ensure initiation of investigation and prosecution against You, if required.
            <ol>
              <li>Users shall not use circumvention or obfuscating technologies to mask their IP addresses or to hide transaction details.</li>
              <li>Users shall not create or circulate any technologies which violate or which would facilitate other users to violate the terms hereof including for masking IP addresses or to obfuscate transaction details.</li>
              <li>Users shall not misrepresent, misinform or misguide Swapcoins in any manner. Users are hereby duly informed that We shall share all details with regulatory or legal authorities and the User shall be solely responsible for the correctness of the contents provided or for legal action for false or incorrect information furnished.</li>
              <li>Users are cautioned against undertaking any suspicious activity on or through the Website. In the event of any suspicious or large transactions being undertaken by You, Swapcoins shall have the right but not the obligation to verify such activity by sharing Your IP address with the relevant authority, and if required, suspend the transactions or freeze Your access.</li>
              <li>Notwithstanding the foregoing, if in Swapcoins’s sole discretion, the source of any CC is illegal or suspicious, Swapcoins reserves the right to retain and confiscate all CCs related to such illegal or suspicious activity.</li>
            </ol>
          </TermsConditionsCopy>

          <TermsConditionsTitle>Limitation of Liability:</TermsConditionsTitle>
          <TermsConditionsCopy>
            <ol>
              <li>Users of this Website are responsible for their actions in connection with the use of the Website and Service.</li>
              <li>Swapcoins is not responsible for the outcome, whether positive or negative, of any action performed by any of its Users within or related to the Website / Service.</li>
              <li>The Parties agree that in no event shall Swapcoins or its affiliates, officers, directors, agents, employees, and suppliers be liable for lost profits or any special, incidental or consequential damages arising out of or in connection with the use of our Website, Service, or these Terms & Conditions.</li>
              <li>User shall not invest in CCs under any assumption of high earnings or profits. CCs being digital assets issued by private entrepreneurs are subject to market risks and volatile pricing, which may even render them worthless. Users are therefore cautioned of the inherent risks involved in procuring CCs.</li>
              <li>Use of Swapcoins may carry risks as also detailed in the Risk section above, and is to be used as an experimental software only. In no event shall Swapcoins be liable or responsible for any damages, claims, applications, losses, injuries, delays, accidents, costs, business interruption costs, or other expenses (including, without limitation, attorneys’ fees or the costs of any claim or suit), nor for any incidental, direct, indirect, general, special, punitive, exemplary, or consequential damages, loss of goodwill or business profits, loss of CCs or digital assets, work stoppage, data loss, computer failure or malfunction, or any other commercial or other losses directly or indirectly arising out of or related to these Terms of Use; the Privacy Policy, any service of Swapcoins, the use of Swapcoins, exchange of Your digital assets or CCs by any other party not authorized by You (collectively, all of the foregoing items shall be referred to herein as "<strong>Losses</strong>"). Swapcoins is hereby released by You from liability for any and all Losses. The foregoing limitations of liability shall apply whether the alleged liability or Losses are based on contract, negligence, tort, strict liability, or any other basis, even if Swapcoins has been advised of or should have known of the possibility of such losses and damages, and without regard to the success or effectiveness of other remedies. Notwithstanding anything else in these Terms & Conditions, in no event shall the combined aggregate liability of Swapcoins for any Loss exceed $25 USD. In the event that any transfers of CCs are erroneously credited, the User is bound to report the same and to arrange to refund or transfer it to relevant CC exchange or to the rightful owner, as confirmed by the relevant CC exchange. Failure to do so would amount to a material breach and in addition to remedies available through court processes.</li>
              <li>Services are strictly available for personal use only within jurisdictions that permit transactions or trading in CCs or where such transactions have not been curtailed. In the event that a User resides in any such jurisdiction where transactions in CCs is prohibited, Users shall not use the Website or the Services to conduct any transaction in CCs. In the event that such transgression is brought to the notice of Swapcoins, the Company shall forthwith restrict Your access to the Website. Users are therefore duly warned against availing Website and Services, where there is a specific ban against the transactions offered by Swapcoins. The Parties agree that the Users shall be solely responsible for ensuring compliance with the above duty and responsibility to follow the laws of the country of residence.</li>
              <li>If We are unable to perform the Service outlined in the Terms & Conditions due to factors beyond our control including but not limited to an event of force majeure, change of law or change in sanctions policy We will not have any liability to You with respect to the Service provided under these Terms & Conditions and for a time period coincident with the event.</li>
            </ol>
          </TermsConditionsCopy>

          <TermsConditionsTitle>Financial Regulation and Advice:</TermsConditionsTitle>
          <TermsConditionsCopy>
            We do not provide any investment advice in connection with the Service contemplated by these Terms & Conditions. While We may provide information on the value, range, volatility of CCs, and events that may affect/have affected the value of CCs directly on the Website or through other social media channels, this is not considered investment advice and should not be construed as such. Swapcoins will not be liable for any loss suffered by You in connection with any transaction involving Swapcoins / the Service or the Website.
            <br />
            <br />
            Our business model, and our Service, consists of facilitating the swapping of CCs in an unregulated yet responsible, international open payment system.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Assignment:</TermsConditionsTitle>
          <TermsConditionsCopy>
            You acknowledge and agree that the Company can freely assign these Terms & Conditions or any of its rights under these Terms & Conditions to any person without Your consent. You shall however, not be entitled to assign these Terms & Conditions, in whole or in part, to another person without the prior written approval of the Company.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Termination:</TermsConditionsTitle>
          <TermsConditionsCopy>
            You may terminate this agreement with Us, at any time, following settlement of any pending transactions.
            <br />
            <br />
            You agree that Company may, by giving notice, in its sole discretion, restrict Your access to the Website; including without limitation, suspending or terminating the Service, prohibiting access to the Website and its content, services and tools, delaying or removing hosted content, and taking technical and legal actions to keep You off the Website if We, in our sole discretion, reasonably perceive that You are creating any problems, including without limitation, possible legal liabilities, infringement of the intellectual property rights, or acting inconsistently with the letter or spirit of these Terms & Conditions.

            We may, in appropriate circumstances and at our sole discretion, initiate the above actions for any reason, including without limitation:
            <ol>
              <li>attempts to gain unauthorized access to the Website or providing assistance to others’ attempting to do so;</li>
              <li>usage of the Service to perform illegal activities such as money laundering, illegal gambling operations, financing terrorism, or other criminal activities;</li>
              <li>violations of these Terms of Use;</li>
              <li>failure to pay or fraudulent payment for transactions;</li>
              <li>unexpected operational difficulties; or</li>
              <li>upon the request of law enforcement or other government agencies.</li>
            </ol>
          </TermsConditionsCopy>

          <TermsConditionsTitle>Consequences of Termination:</TermsConditionsTitle>
          <TermsConditionsCopy>
            Upon termination of this Agreement or earlier determination, the rendering of the Services shall stand immediately terminated by the Company and the User shall not be entitled to access or avail the Website.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Indemnity:</TermsConditionsTitle>
          <TermsConditionsCopy>
            To the full extent permitted by applicable law, You hereby agree to indemnify Swapcoins, and its affiliates, officers, directors, agents, employees, and suppliers against any action, liability, cost, claim, loss, damage, proceeding or expense suffered or incurred if direct or otherwise arising from Your use of the Website, Service, or from Your violation of these Terms of Use.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Modification of Terms:</TermsConditionsTitle>
          <TermsConditionsCopy>
            Swapcoins reserves the right to change, add or remove or modify the Website, the Service, the Terms & Conditions or any content therein, at any time, with or without notice, in its sole discretion.
          </TermsConditionsCopy>

          <TermsConditionsTitle>Contact:</TermsConditionsTitle>
          <TermsConditionsCopy>
            If You have any questions relating to these Terms & Conditions, Your rights and obligations arising from these Terms & Conditions and/or Your use of the Website and the Service, or any other matter, please contact us at support@swapcoins.com .
          </TermsConditionsCopy>
        </TermsConditionsContent>
      </TermsConditionsPageContainer>
    </React.Fragment>
  )
};

export default TermsConditions;
