import React from "react";
import styled from 'styled-components';
import axios from 'axios';
import Button from '../../components/Button/Button';
import { theme, mediaUp, mediaDown, slickDots, psLetterSpacing, psLineHeight } from '../../globalStyle';
import { rem, parseToHsl } from 'polished';
import Container from '../Container/Container';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import NewsBlock from './NewsBlock';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import arrow from '../../assets/images/arrow_black.svg';

const StyledNewsFeed = styled.section`
  background-color: ${theme.white};
  padding: ${rem(52)} 0 ${rem(77)};
`;

const ViewButton = styled(Button)`
  width: 100%;
  text-align: center;
  margin: ${rem(10)} auto 0;
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, 120)};
  line-height: ${psLineHeight(14, 20)};

  ${mediaUp.lg`
    max-width: 280px;
    margin: ${rem(33)} auto 0;
  `}
`;


const NewsContent = styled.div`
  text-align: center;

  ${mediaUp.lg`
    width: 60%;
    margin: 0 auto;
  `}
  ${mediaDown.lg`
    width: 60%;
    margin: 0 auto;
  `}
`;


const NewsTitle = styled.h1`
  color: ${theme.tertiary};

  span {
    color: ${theme.tertiary};
  }
`;

const NewsDesktopTitle = NewsTitle.extend`
  font-size: ${rem(48)};
  letter-spacing: ${psLetterSpacing(58, -40)};
  line-height: ${psLineHeight(58, 40)};

  ${mediaDown.lg`
    text-align: center;
  `}
`;
const NewsDesktopSubTitle = NewsTitle.extend`
  span {
    color: ${theme.black};
  }
  font-size: ${rem(28)};
  letter-spacing: ${psLetterSpacing(58, -40)};
  line-height: ${psLineHeight(48, 30)};
  ${mediaDown.lg`
   margin-left: 10px;
  `}
`;
const NewsBlockCopy = styled.p`
  line-height: ${psLineHeight(20, 36)};
  color: ${theme.gray800};
  margin-bottom: 0;

  ${mediaDown.lg`
    padding: 10px;
  `}
`;

const NewsBlockCopyLink = styled.a`
  color: ${theme.gray800};
  text-decoration: none;
  transition: 0.3s opacity ease;
  &:hover {
    color: ${theme.gray800};
    opacity: 0.7;
  }

`;

const NewsFeedImage = styled.img`
  border: 10px solid ${theme.white};
  max-width: 560px;
  max-height: 315px;
  object-fit: cover;
  object-position: center;
  min-height: 315px;
  width: 90%;
  height: 90%;

  ${mediaUp.lg`
    margin-left: 70px;
  `}

  ${mediaDown.lg`
    margin-left: 20px;
  `}
`;


const NewsFeedSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    width: 49px;
    height: 9px;
    background-color: ${theme.white};
    padding: 1rem;
    z-index: 1;

    &::before {
      width: 49px;
      height: 9px;
      font-size: 0;
      display: block;
      background-size: cover;
      opacity: 1;
    }
  }

  .slick-prev {
    left: 0;
    transition: 0.3s left ease;

    &::before {
      background-image: url('${arrow}');
    }

    &:hover {
      left: -10px;
    }
  }

  .slick-next {
    right: 0;
    transition: 0.3s right ease;

    &::before {
      background-image: url('${arrow}');
      transform: rotate(180deg);
    }

    &:hover {
      right: -10px;
    }
  }

  ${slickDots(theme.gray600, theme.primary, '-43px')};
`;

const slickSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  autoplay: false,
  fade: true,
  responsive:
  [
    {
      breakpoint: 992,
      settings: {
        dots: true,
        arrows: false,
      }
    }
  ]
};

class RssFeed extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rssUrl: 'https://coinchapter.com/news/feed/',
      items: [],
      slideIndex1: 1
    };

    this.handleSlideChange = this.handleSlideChange.bind(this);
  }

  handleSlideChange(i) {
    this.setState({
      slideIndex1: i + 1
    });
  }
  componentDidMount() {
    axios.get(`https://api.allorigins.win/get?url=${this.state.rssUrl}`)
    .then((res) => {
      const { contents } = res.data;
      const feed = new window.DOMParser().parseFromString(contents, "text/xml");
      const items = feed.querySelectorAll("item");

      const feedItems = [...items].map((el) => ({
        link: el.querySelector("link").innerHTML,
        title: el.querySelector("title").innerHTML,
        pubDate: el.querySelector("pubDate").innerHTML,
        image: el.getElementsByTagNameNS("*", "encoded").item(0).textContent,
      }));

      const getImgSrc = (imgStr) => {
        const div = document.createElement('div');
        div.innerHTML = imgStr;
        const img = div.querySelector('img');
        return img.src;
      }

      feedItems.forEach((item) => {
        item.image = getImgSrc(item.image);

        let dt = new Date(item.pubDate);
        item.pubDate = dt.toDateString();
      })

      this.setState({items: feedItems}) ;
    })
    .catch((error) => {
      console.log(error)
    });
  };

  render() {
    return (
      <StyledNewsFeed>
        <Container>
          <NewsDesktopTitle>
            <span>In The News</span><br />
          </NewsDesktopTitle>
          {this.state.items.length &&
          <NewsFeedSlider {...slickSettings} afterChange={i => this.handleSlideChange(i)}>
            {this.state.items.slice(0,10).map((item, i) => {
              return (
               <div key={i}>
                <GridRow noGutters={true}>
                  <GridColumn lgColumns={7}>
                    <NewsFeedImage src={item.image} alt="coinchapter" />
                  </GridColumn>
                  <GridColumn lgColumns={5}>
                    <NewsBlock
                      title={item.title}
                      copy={item.pubDate}
                      link={item.link}
                      className="info-block--is-last"
                    />
                  </GridColumn>
                </GridRow>
               </div>
              );
            })}
          </NewsFeedSlider>
          }
          <NewsDesktopSubTitle>
            <span>Latest Stories</span>
          </NewsDesktopSubTitle>

          {this.state.items.slice(0,5).map((item) => {
            return (
              <NewsBlockCopy><NewsBlockCopyLink href={item.link} target="_blank">{item.title}</NewsBlockCopyLink><hr /></NewsBlockCopy>
            );
          })}
          <NewsContent>
            <a href="https://www.coinchapter.com/" target="_blank"><ViewButton secondary={true} hasArrow={true}>Click here for the latest news</ViewButton></a>
          </NewsContent>

        </Container>
      </StyledNewsFeed>
    );
  }
}
export default RssFeed;

