import React from 'react';
import Container from '../Container/Container';
import Header from '../Header/Header';
import ExchangeHeader from './ExchangeHeader';
import ExchangeSteps from './ExchangeSteps';
import AddressStep from './AddressStep';
import SendStep from './SendStep';
import OrderStep from './OrderStep';
import CompleteStep from './CompleteStep';
import HelpHero from '../HelpHero/HelpHero'
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import axios from 'axios';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";

import Modal from '../Modal/Modal';
import arrow from '../../assets/images/order_arrow.png';
import { TextInput } from '../Input/TextInput';
import Button from '../Button/Button';
import Alert from '../Alert/Alert';

const ExchangeBody = styled.div`
  padding: ${rem(26)} 0 ${rem(44)};

  ${mediaUp.lg`
    padding: ${rem(37)} 0 ${rem(99)};
  `}
`;

const emailCaptureGutter = rem(22);

const EmailCaptureHeader = styled.div`
  padding: ${rem(22)} ${emailCaptureGutter};

  ${mediaUp.lg`
    padding: ${rem(26)} ${emailCaptureGutter};
  `}
`;

const EmailCaptureDate = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;

const EmailCaptureExchange = styled.div`
  padding: ${rem(18)} ${emailCaptureGutter} ${rem(35)};
  text-align: center;

  ${mediaUp.lg`
    padding: ${rem(18)} ${emailCaptureGutter} ${rem(54)};
  `}
`;

const EmailCaptureCopy = styled.p`
  color: ${theme.gray600};
  font-size: ${rem(16)};
  margin-bottom: ${rem(14)};
  line-height: normal;

  ${mediaUp.lg`
    font-size: ${rem(20)};
  `}
`;

const EmailCaptureArrow = styled.img`
  margin-bottom: ${rem(18)};
`;

const EmailCaptureReceipt = styled.h3`
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(27)};
  color: ${theme.gray1000};

  ${mediaUp.lg`
    font-size: ${rem(34)};
  `}
`;

const EmailCaptureFooter = styled.div`
  padding: ${rem(10)} ${emailCaptureGutter};

  ${mediaUp.lg`
    padding: ${rem(10)} ${emailCaptureGutter};
  `}
`;

const EmailCaptureButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(12)};

  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;
const EmailCaptureAlert = styled(Alert)`
  text-align: left;
  margin-bottom: 1rem;
`;

const WAIT_INTERVAL = 500;

// TODO: Remove unnecessary states/props
class Exchange extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      orderID: '',
      incomingAmount: 0,
      incomingSymbol: '',
      incomingCurrency: '',
      incomingAddress: '',
      incomingAddressExtra: '',
      outgoingAmount: 0,
      outgoingSymbol: '',
      outgoingCurrency: '',
      outgoingAddress: '',
      outgoingAddressExtra: '',
      refundAddress: '',
      refundAddressExtra: '',
      exchangeName: '',
      exchangeLogo: '',
      outgoingAddressInputValid: false,
      outgoingAddressIDValid: false,
      outgoingAddressInputLoading: false,
      outgoingAddressIDLoading: false,
      refundAddressInputValid: false,
      refundAddressIDValid: false,
      refundAddressInputLoading: false,
      refundAddressIDLoading: false,
      termsCheckboxValue: false,
      orderLoading: false,
      currentConfirmations: 0,
      hasError: false,
      totalConfirmations: 0,
      errorMessage: '',
      modalActive: false,
      email: '',
      hasEmailError: false,
      emailErrors: [],
      intervalID: 0,
    };

    this.handleAddressInputChange = this.handleAddressInputChange.bind(this);
    this.handleAddressIDChange = this.handleAddressIDChange.bind(this);
    this.triggerValidate = this.triggerValidate.bind(this);
    this.triggerIDValidate = this.triggerIDValidate.bind(this);
    this.handleOrderSubmit = this.handleOrderSubmit.bind(this);
    this.handleTermsCheckChange = this.handleTermsCheckChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
    this.handleOrderStatus = this.handleOrderStatus.bind(this);

    this.timer = null;
  }

  componentDidMount() {
    const { incomingAmount, incomingCurrency, outgoingAmount, outgoingCurrency, exchange, exchangeLogo } = this.props;

    if (!(incomingAmount && incomingCurrency && outgoingAmount && outgoingCurrency && exchange && exchangeLogo)) {
      this.props.history.push('/rates');
    }

    if (!this.props.authenticated && !this.props.authenticationLoading) {
      if (!this.props.email && this.state.email === '') {
        this.setState({
          modalActive: true
        });
      } else {
        this.props.history.push('/login');
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  handleAddressInputChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    clearTimeout(this.timer);

    this.setState({
      [`${inputName}InputLoading`]: true,
      [inputName]: inputValue
    });

    this.timer = setTimeout(() => { this.triggerValidate(inputValue, inputName) }, WAIT_INTERVAL);
  }

  handleAddressIDChange(event) {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    clearTimeout(this.timer);

    this.setState({
      [`${inputName}InputLoading`]: true,
      [inputName]: inputValue
    });

    this.timer = setTimeout(() => { this.triggerIDValidate(inputValue, inputName) }, WAIT_INTERVAL);
  }

  triggerIDValidate(extraId, inputName) {
    if (extraId) {
      const currency = (inputName === 'outgoingAddress' || inputName === 'outgoingAddressExtra') ? this.props.outgoingCurrency : this.props.incomingCurrency;
      const address = (inputName === 'outgoingAddress' || inputName === 'outgoingAddressExtra') ? this.props.outgoingAddress : this.props.refundAddress;

      axios.get(`${process.env.REACT_APP_API_URL}/validate/${currency}/${address}/dt/${extraId}`)
        .then((response) => {
          const isValid = response.data.status === 'valid';
          const name = inputName === 'outgoingAddressExtra'? 'outgoingAddress': 'refundAddress';
          this.setState({
            [`${name}IDValid`]: isValid,
            [`${name}IDLoading`]: false
          });
        })
        .catch((error) => {
          //console.log(error);
        });
        
      } else {
        this.setState({
          [`${inputName}InputLoading`]: false
      });
    }
  }

  triggerValidate(address, inputName) {
    if (address) {
      const currency = (inputName === 'outgoingAddress' || inputName === 'outgoingAddressExtra') ? this.props.outgoingCurrency : this.props.incomingCurrency;
        axios.get(`${process.env.REACT_APP_API_URL}/validate/${currency}/${address}`)
          .then((response) => {
            const isValid = response.data.status === 'valid';

            this.setState({
              [`${inputName}InputValid`]: isValid,
              [`${inputName}InputLoading`]: false
            });
          })
          .catch((error) => {
            //console.log(error);
          });
      } else {
        this.setState({
          [`${inputName}InputLoading`]: false
      });
    }
  }

  handleOrderSubmit() {
    let params = {
      exchange: this.props.exchange,
      depositCoin: this.props.incomingCurrency,
      refundAddress: this.state.refundAddress,
      refAddrExtra: this.state.refundAddressExtra,
      destinationCoin: this.props.outgoingCurrency,
      destinationAddress: this.state.outgoingAddress,
      destAddrExtra: this.state.outgoingAddressExtra,
      depositAmount: this.props.incomingAmount,
      destinationAmount: ""
    };

    let config = {};
    if (this.props.authenticated) {
      config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
        }
      };

      this.setState({
        orderLoading: true
      });

      axios.post(`${process.env.REACT_APP_API_URL}/user/order`, params, config)
        .then((response) => {
          this.setState({
            currentStep: 2,
            orderLoading: false,
            incomingAddress: response.data.address,
            incomingAddressExtra: response.data.addrExtra,
            orderID: response.data.orderId
          }, () => {
            this.props.history.push('/exchange/order-complete');
            window.scrollTo(0, 0);
          });
        })
        .catch((error) => {
          this.setState({
            hasError: true,
            orderLoading: false,
            errorMessage: error.response.data.error
          });
        });
    } else if (this.state.email !== '') {
      params.email = this.state.email;

      this.setState({
        orderLoading: true
      });

      axios.post(`${process.env.REACT_APP_API_URL}/order`, params, config)
        .then((response) => {
          this.setState({
            currentStep: 2,
            orderLoading: false,
            incomingAddress: response.data.address,
            incomingAddressExtra: response.data.addrExtra,
            orderID: response.data.orderId
          }, () => {
            this.props.history.push('/exchange/order-complete');
            window.scrollTo(0, 0);
          });
        })
        .catch((error) => {
          this.setState({
            hasError: true,
            orderLoading: false,
            errorMessage: error.response.data.error
          });
        });
    }
    else { // user must log in to continue
      this.props.history.push('/login');
    }
  }

  handleTermsCheckChange() {
    this.setState({
      termsCheckboxValue: !this.state.termsCheckboxValue
    });
  }

  handleModalOpen() {
    this.setState({
      modalActive: true,
    }, () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    });
  }

  handleModalClose() {
    this.setState({
      modalActive: false,
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });
    if (this.state.email === '') {
      this.props.history.push('/login');
    }
  }

  handleEmailSubmit(event) {
    const data = new FormData(event.target);
    event.preventDefault();

    const params = {
      email: data.get('email'),
    };

    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(params.email,).toLowerCase())) {
      this.state.emailErrors.push('Your email is invalid.')
      this.setState({
        hasEmailError: true,
      });
    } else {
      this.setState({
        hasEmailError: false,
        modalActive: false,
        email: params.email,
      });
    }
  }

  handleOrderStatus() {
    if (this.state.currentStep === 4) {
      clearInterval(this.state.intervalID);
    } else if (this.state.currentStep === 2 || this.state.currentStep === 3 ){
      clearInterval(this.state.intervalID);

      var intervalID = setInterval(
        function() {
          axios.get(`${process.env.REACT_APP_API_URL}/status/${this.state.orderID}`)
          .then((response) => {
            if(response.data.status && response.data.status ==='processing') {
              this.setState({
                currentStep: 3, 
                orderLoading: false,
              }, () => {
                this.props.history.push('/exchange/order-status');
                window.scrollTo(0, 0);
              });
            } else if(response.data.status && response.data.status ==='complete') {
              this.setState({
                currentStep: 4, 
                orderLoading: false,
              }, () => {
                this.props.history.push('/exchange/order-final');
                window.scrollTo(0, 0);
              });
            }
          })
          .catch((error) => {
            this.setState({
              hasError: true,
              orderLoading: false,
              errorMessage: error.response.data.error
            });
          });
        }
        .bind(this), 
        60000
      );

      this.setState({intervalID: intervalID});
    }
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Exchange" />

        <Header />

        <main>
          <ExchangeHeader
            incomingAmount={this.props.incomingAmount}
            incomingCurrency={this.props.incomingCurrency}
            outgoingAmount={this.props.outgoingAmount}
            outgoingCurrency={this.props.outgoingCurrency}
            exchangeName={this.props.exchange}
            exchangeLogo={this.props.exchangeLogo}
          />

          <ExchangeBody>
            <Container hasMobileGutters={true}>
              <ExchangeSteps currentStep={this.state.currentStep}
                             incomingCurrency={this.props.incomingCurrency}
              />

              <AddressStep handleAddressInputChange={this.handleAddressInputChange}
                           currentStep={this.state.currentStep}
                           incomingCurrency={this.props.incomingCurrency}
                           incomingName={this.state.incomingName}
                           outgoingCurrency={this.props.outgoingCurrency}
                           outgoingName={this.state.outgoingName}
                           outgoingAddress={this.state.outgoingAddress}
                           outgoingAddressExtra={this.state.outgoingAddressExtra}
                           outgoingAddressInputValid={this.state.outgoingAddressInputValid}
                           outgoingAddressInputLoading={this.state.outgoingAddressInputLoading}
                           refundAddress={this.state.refundAddress}
                           refundAddressExtra={this.state.refundAddressExtra}
                           refundAddressInputValid={this.state.refundAddressInputValid}
                           refundAddressInputLoading={this.state.refundAddressInputLoading}
                           handleOrderSubmit={this.handleOrderSubmit}
                           handleTermsCheckChange={this.handleTermsCheckChange}
                           termsCheckboxValue={this.state.termsCheckboxValue}
                           orderLoading={this.state.orderLoading}
                           hasError={this.state.hasError}
                           buttonEnabled={
                             this.state.refundAddressInputValid
                             && this.state.outgoingAddressInputValid
                             && this.state.termsCheckboxValue
                           }
                           errorMessage={this.state.errorMessage}
                           handleAddressIDChange={this.handleAddressIDChange}
                           outgoingAddressIDValid={this.state.outgoingAddressIDValid}
                           outgoingAddressIDLoading={this.state.outgoingAddressIDLoading}
                           refundAddressIDValid={this.state.refundAddressIDValid}
                           refundAddressIDLoading={this.state.refundAddressIDLoading}
              />

              <SendStep
                currentStep={this.state.currentStep}
                incomingCurrency={this.props.incomingCurrency}
                incomingAmount={this.props.incomingAmount}
                incomingAddress={this.state.incomingAddress}
                incomingAddressExtra={this.state.incomingAddressExtra}
                incomingName={this.state.incomingName}
                outgoingCurrency={this.props.outgoingCurrency}
                outgoingAddress={this.state.outgoingAddress}
                outgoingAddressExtra={this.state.outgoingAddressExtra}
                outgoingName={this.state.outgoingName}
                orderID={this.state.orderID}
                authenticated={this.props.authenticated}
                handleOrderStatus={this.handleOrderStatus}
              />

              <OrderStep 
                currentStep={this.state.currentStep}
                orderID={this.state.orderID}
                totalConfirmations={this.state.totalConfirmations}
                currentConfirmations={this.state.currentConfirmations}
                incomingCurrency={this.props.incomingCurrency}
                incomingAddress={this.state.incomingAddress}
                outgoingCurrency={this.props.outgoingCurrency}
                outgoingAddress={this.state.outgoingAddress}
                outgoingSymbol={this.state.outgoingName}
                outgoingAmount={this.props.outgoingAmount}
              />

              <CompleteStep 
                currentStep={this.state.currentStep}
                orderID={this.state.orderID}
                totalConfirmations={this.state.totalConfirmations}
                currentConfirmations={this.state.currentConfirmations}
                incomingCurrency={this.props.incomingCurrency}
                incomingAddress={this.state.incomingAddress}
                outgoingCurrency={this.props.outgoingCurrency}
                outgoingAddress={this.state.outgoingAddress}
                outgoingSymbol={this.state.outgoingName}
                outgoingAmount={this.props.outgoingAmount}
              />
            </Container>
          </ExchangeBody>

          <HelpHero overlayColor={theme.bodyBg} />
          <Modal isActive={this.state.modalActive} handleModalClose={this.handleModalClose}>
            <EmailCaptureHeader>
              <EmailCaptureDate></EmailCaptureDate>
            </EmailCaptureHeader>

            <EmailCaptureExchange>
              <EmailCaptureReceipt>
                Email Receipt
              </EmailCaptureReceipt>
              <EmailCaptureArrow src={arrow} alt="Email" />
              <EmailCaptureAlert show={this.state.hasEmailError} danger={true}>
                {this.state.emailErrors.map((error, index) => {
                  return (
                    <React.Fragment key={index}>
                      {error}{index !== (this.state.emailErrors.length - 1)
                      ?
                      <React.Fragment>
                        <br />
                        <br />
                      </React.Fragment>
                      :
                      <React.Fragment />
                    }
                    </React.Fragment>
                  )
                })}
              </EmailCaptureAlert>
              <EmailCaptureCopy>
                Please enter your email address below so that we can send you your swap transaction receipt and order ID number.
              </EmailCaptureCopy>
              <EmailCaptureCopy>
                You will be able to track your swap by entering the order ID number in our Track Order service.
              </EmailCaptureCopy>
              <form onSubmit={this.handleEmailSubmit}>
                <TextInput  type="text"
                            name="email"
                            hasLabel={false}
                            placeholder="Email (eg. support@swapcoins.com)"
                            initialValue=''
                            required={true}
                  />
                  <EmailCaptureFooter>
                    <EmailCaptureButton arrow={true} secondary={true} block={true}>
                      Submit
                    </EmailCaptureButton>
                  </EmailCaptureFooter>
                </form>
            </EmailCaptureExchange>
          </Modal>
        </main>
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          incomingAmount,
          incomingCurrency,
          outgoingAmount,
          outgoingCurrency,
          exchange,
          exchangeLogo,
          authenticated,
          email
        }) => (
          <Exchange {...props}
                    incomingAmount={incomingAmount}
                    incomingCurrency={incomingCurrency}
                    outgoingAmount={outgoingAmount}
                    outgoingCurrency={outgoingCurrency}
                    exchange={exchange}
                    exchangeLogo={exchangeLogo}
                    authenticated={authenticated}
                    email={email}
          />
      )}
    </CoinContext.Consumer>
  )
};
