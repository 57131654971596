import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown } from '../../globalStyle';
import Container from '../Container/Container';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import Tile from './Tile';

const StyledBlockLinks = styled.section`
  background-color: ${theme.white};
  padding: 0 ${theme.mobileGutter} ${rem(51)};
  
  ${mediaUp.lg`
    padding: 0 0 ${rem(132)};
  `}
`;

const BlockLinksContainer = styled(Container)`
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${theme.gray400};
    left: 0;
    right: 0;
    top: 38px;
  }
  
  ${mediaUp.lg`
    &::before {
      top: 98px;
    }
  `}
`;

const BlockLinksWrapper = styled.div`
  ${mediaUp.lg`
    padding: 0 ${rem(66)};
  `}
`;

const BlockLinkTileWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 50%;
  
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const BlockLinkTile = styled(Tile)`
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  
  ${mediaDown.lg`
    padding: 0;
  `}
`;

const BlockLinkLeftTile = BlockLinkTile.extend`
  top: 0;
`;

const BlockLinkRightTile = BlockLinkTile.extend`
  top: 50%;
  height: 100%;
`;

function BlockLinks() {
  return (
    <StyledBlockLinks>
      <BlockLinksContainer>
        <BlockLinksWrapper>
          <GridRow noGutters={true}>
            <GridColumn columns={6}>
              <BlockLinkTileWrapper>
                <BlockLinkLeftTile link="/faq" lgFontSize="76px">FAQ</BlockLinkLeftTile>
              </BlockLinkTileWrapper>
            </GridColumn>

            <GridColumn columns={6}>
              <BlockLinkTileWrapper>
                <BlockLinkRightTile link="/faq" lgFontSize="64px">Support</BlockLinkRightTile>
              </BlockLinkTileWrapper>
            </GridColumn>
          </GridRow>
        </BlockLinksWrapper>
      </BlockLinksContainer>
    </StyledBlockLinks>
  )
}

export default BlockLinks;