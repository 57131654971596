import React from 'react';
import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import { rem } from 'polished';
import { theme, mediaUp, psLetterSpacing } from '../../globalStyle';
import PageContainer from '../Page/PageContainer';
import 'formdata-polyfill';
import { CSVLink } from "react-csv";
import { Scrollbars } from 'react-custom-scrollbars';
import ToolkitProvider, { Search, CSVExport, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import filterFactory, { dateFilter } from 'react-bootstrap-table2-filter';

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const { ToggleList } = ColumnToggle;

const reportGutter = rem(22);

const TableContainer = styled(PageContainer)`
  padding-bottom: ${rem(10)};

  ${mediaUp.lg`
    padding-bottom: ${rem(10)};
  `}
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  justify-content: flex-end;
  align-items: flex-end;
`;

const DropdownDiv = styled.div`
  font-size: ${rem(10)};
`
const ReportHeader = styled.div`
  padding: ${rem(10)} ${reportGutter};

  ${mediaUp.lg`
    padding: ${rem(10)} ${reportGutter};
  `}
`;

const ReportDescription = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;
const ColSelect= styled.div`
  font-size: ${rem(10)};
`;

const ReportName = styled.div`
  padding: ${rem(10)} ${reportGutter} ${rem(35)};
  text-align: center;

  ${mediaUp.lg`
    padding: ${rem(18)} ${reportGutter} ${rem(18)};
  `}
`;

const ReportTo = styled.h3`
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(18)};
  color: ${theme.gray1000};

  ${mediaUp.lg`
    font-size: ${rem(18)};
  `}
`;

const ReportFooter = styled.div`
  padding: ${rem(20)} ${reportGutter};
  text-align: center;

  ${mediaUp.lg`
    padding: ${rem(30)} ${reportGutter};
  `}
`;



function Report(props) {
  const headers = props.Datas.cols.split(',');

  const options = {
    sizePerPage: 4,
    sizePerPageList: [4, 8],
  };

  let columns =[];
  const menuClass = `dropdown-menu${props.isOpen ? " show" : ""}`;

  headers.map(header =>{
    if (header === 'timestamp' || header === 'dateTime') {
      columns.push({
        dataField: header,
        text: header.toUpperCase(),
        // filter: dateFilter(),
        sort: true,
        style: {
          fontSize: '12px',
          'white-space' : 'nowrap',
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
        },
        headerStyle: {
          fontSize: '12px',
          'white-space' : 'nowrap',
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
        }
      });
    } else {
      columns.push({
        dataField: header,
        text: header.toUpperCase(),
        sort: true,
        toggle: true,
        style: {
          fontSize: '12px',
          'white-space' : 'nowrap',
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
        },
        headerStyle: {
          fontSize: '12px',
          'white-space' : 'nowrap',
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
        }
      });
    }
  });

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <SearchWrapper>
        <button className="btn btn-success" onClick={ handleClick }>Download</button>
      </SearchWrapper>
    );
  };

  const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles,
  }) => (
    <div className="btn-group dropright" onClick={props.toggleOpen}>
      <button
        className="btn btn-secondary btn-sm dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
      >
        Show Columns
      </button>
      <div className={menuClass} aria-labelledby="dropdownMenuButton">
        <Scrollbars autoHeight={true} autoHeightMax={400}>
          <button
            type="button"
            key={1}
            class={ `btn btn-light btn-sm dropdown-item` }
            data-toggle="button"
            onClick={ () =>
              columns.map(column => (
                toggles[column.dataField] = true
              ))
            }
          >
            SHOW ALL COLUMNS
          </button>
          <div class="dropdown-divider"></div>
          {
            columns
              .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
              }))
              .map(column => (
                <button
                  type="button"
                  key={ column.dataField }
                  class={ `btn btn-light btn-sm dropdown-item ${column.toggle ? 'active' : ''}` }
                  data-toggle="button"
                  aria-pressed={ column.toggle ? 'false' : 'true' }
                  onClick={ () => onColumnToggle(column.dataField) }
                  value={column.text}
                >
                  { column.text }
                </button>
              ))
          }
          <div class="dropdown-divider"></div>
          <button
            type="button"
            key={1}
            class={ `btn btn-light btn-sm dropdown-item` }
            data-toggle="button"
            onClick={ () =>
              columns.map(column => (
                toggles[column.dataField] = false
              ))
            }
          >
            HIDE ALL COLUMNS
          </button>
        </Scrollbars>
      </div>
    </div>
  );

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="id"
        columns={ columns }
        data={ props.Datas.data }
        search={true}
        columnToggle={true}
        exportCSV={{
          fileName: `${props.name}.csv`
        }}
      >
        {
          toolkitprops => (
            <div>
              <CustomToggleList { ...toolkitprops.columnToggleProps } />
              <SearchWrapper>
                <SearchBar
                  { ...toolkitprops.searchProps }
                />
              </SearchWrapper>
              <BootstrapTable
                bodyStyle={{ overflow: 'overlay' }}
                wrapperClasses="table-sm"
                striped={false}
                bordered={false}
                hover={true}
                // filter={ filterFactory() }
                condensed={true}
                { ...toolkitprops.baseProps }
                { ...paginationTableProps }
              />
              <MyExportCSV { ...toolkitprops.csvProps } />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );

  return (
    <React.Fragment>
      <TableContainer>
        <ReportHeader>
          <ReportName>
            {props.name &&
              <ReportTo>
                {props.name}
              </ReportTo>
            }
            <ReportDescription>
              {props.description}
            </ReportDescription>
          </ReportName>
          <PaginationProvider
            pagination={
              paginationFactory(options)
            }
          >
            { contentTable }
          </PaginationProvider>
        </ReportHeader>
      </TableContainer>
    </React.Fragment>
  )
}

export default Report;