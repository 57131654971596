import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, slickDots } from '../../globalStyle';
import Container from '../Container/Container';
import Testimonial from './Testimonial';
import OutlineText from '../OutlineText/OutlineText';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testimonialImage from '../../assets/images/testimonial_image.jpg';
import testimonialImage2 from '../../assets/images/coinchapter.jpg';
import arrow from '../../assets/images/arrow_black.svg';

const StyledTestimonials = styled.section`
  background-color: ${theme.white};
  padding: ${rem(80)} ${theme.mobileGutter} ${rem(47)};
  
  ${mediaUp.lg`
    padding: ${rem(113)} 0 ${rem(83)};
  `}
`;

const TestimonialsContainer = styled(Container)`
  ${mediaUp.lg`
    position: relative;
  `}
`;

const TestimonialsSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    width: 49px;
    height: 9px;
    background-color: ${theme.white};
    padding: 1rem;
    z-index: 1;
    
    &::before {
      width: 49px;
      height: 9px;
      font-size: 0;
      display: block;
      background-size: cover;
      opacity: 1;
    }
  }
  
  .slick-prev {
    left: 0;
    transition: 0.3s left ease;
    
    &::before {
      background-image: url('${arrow}');
    }
    
    &:hover {
      left: -10px;
    }
  }
  
  .slick-next {
    right: 0;
    transition: 0.3s right ease;
    
    &::before {
      background-image: url('${arrow}');
      transform: rotate(180deg);
    }
    
    &:hover {
      right: -10px;
    }
  }
  
  ${slickDots(theme.gray600, theme.primary, '-43px')};
`;

const TestimonialsIndex = styled(OutlineText)`
  position: absolute;
  right: 0;
  bottom: -83px;
  margin-bottom: -35px;
  transform: translateY(100%);
  background-color: ${theme.white};
  padding-left: ${rem(27)};
  z-index: 1;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 57%;
    height: 50%;
    background-color: ${theme.white};
  }
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const slickSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  autoplay: false,
  fade: true,
  responsive:
  [
    {
      breakpoint: 992,
      settings: {
        dots: true,
        arrows: false,
      }
    }
  ]
};

const testimonials = [
  {
    image: testimonialImage2,
    name: 'Coinchapter.com',
    quote: 'Swapcoins.com, is a cryptocurrency aggregator bringing the crypto trader all the best pricing to swap between different cryptocurrencies in one place.'
  },
  {
    image: testimonialImage,
    name: 'Mike G',
    quote: 'Swapcoins.com finds the most competitive rates for exchanging one cryptocurrency to another it is simple, secure, and fast! Supporting many different cryptocurrencies.'
  }
];

class Testimonials extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: 1
    };

    this.handleSlideChange = this.handleSlideChange.bind(this);
  }

  handleSlideChange(index) {
    this.setState({
      slideIndex: index + 1
    });
  }

  render() {
    return (
      <StyledTestimonials>
        <TestimonialsContainer>
          {testimonials.length &&
          <TestimonialsSlider {...slickSettings} afterChange={index => this.handleSlideChange(index)}>
            {testimonials.map((testimonial, index) => {
              return (
                <div key={index}>
                  <Testimonial
                    image={testimonial.image}
                    quote={testimonial.quote}
                    name={testimonial.name}
                  />
                </div>
              )
            })}
          </TestimonialsSlider>
          }

          <TestimonialsIndex strokeColor={theme.muted}>0{this.state.slideIndex}</TestimonialsIndex>
        </TestimonialsContainer>
      </StyledTestimonials>
    )
  }
}

export default Testimonials;
