import React from 'react';
import Button from '../Button/Button';
import CheckboxInput from '../Input/CheckboxInput';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import arrow from '../../assets/images/order_arrow.png';
import axios from 'axios';
import 'formdata-polyfill';

const userGutter = rem(22);

const UserHeader = styled.div`
  padding: ${rem(22)} ${userGutter};
  
  ${mediaUp.lg`
    padding: ${rem(26)} ${userGutter};
  `}
`;

const UserSettings = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;

const UserName = styled.div`
  padding: ${rem(18)} ${userGutter} ${rem(35)};
  text-align: center;
  
  ${mediaUp.lg`
    padding: ${rem(18)} ${userGutter} ${rem(54)};
  `}
`;

const UserArrow = styled.img`
  margin-bottom: ${rem(18)};
`;

const UserTo = styled.h3`
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(27)};
  color: ${theme.gray1000};
  
  ${mediaUp.lg`
    font-size: ${rem(34)};
  `}
`;

const UserLineItem = styled.div`
  padding: ${rem(15)} ${userGutter};
  border-top: 1px solid ${theme.muted};
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
    padding: ${rem(20)} ${userGutter};
  `}
  
  ${mediaDown.lg`
    text-align: center;
  `}
  
  &:last-of-type {
    border-bottom: 1px solid ${theme.muted};
  }
`;

const UserLineItemCopy = styled.span`
  font-size: ${rem(13)};
  line-height: normal;
  
  ${mediaDown.lg`
    display: block;
  `}
`;

const UserLineItemLabel = UserLineItemCopy.extend`
  color: ${theme.gray500};
  font-weight: ${theme.avenirWeightMedium};
  letter-spacing: ${psLetterSpacing(13, 20)};
  padding-right: 0.75rem;
  
  ${mediaDown.lg`
    margin-bottom: 0.5rem;
  `}
`;

const UserLineItemValue = UserLineItemCopy.extend`
  color: ${theme.tertiary};
  letter-spacing: 0;
  
  ${mediaUp.lg`
    margin-left: auto;
  `}
`;

const UserFooter = styled.div`
  padding: ${rem(20)} ${userGutter};
  text-align: center;

  ${mediaUp.lg`
    padding: ${rem(30)} ${userGutter};
  `}
`;

const UserButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

function User(props) {

  return (
    <React.Fragment>
      {props.username &&
        <UserHeader>
          <UserSettings>
            <strong>Settings</strong>
          </UserSettings>
        </UserHeader>
      }

      <UserName>
        <UserArrow src={arrow} alt="Settings" />
        {props.username &&
          <UserTo>
            {props.username}
          </UserTo>
        }
      </UserName>

      <form onSubmit={(event) => {
        const data = new FormData(event.target);
        event.preventDefault();

        const params = {
          active: data.get('active') === null ? false : true,
        };
       
        if (props.active !== params.active) {
          axios.put(`${process.env.REACT_APP_API_URL}/admin/users/enable`, 
          {
            id: props.id,
            active: params.active,
          }
          ,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
            }
          },
          )
          .then((response) => {
            alert(`User has been modified.`);
          })
          .catch((error) => {
            alert(`Issue encountered, please try again.`);
          });
        }
      }}>
        {props.username &&
          <UserLineItem>
            <UserLineItemLabel>Username</UserLineItemLabel>
            <UserLineItemValue>
              {props.username}
            </UserLineItemValue>
          </UserLineItem>
        }

        <UserLineItem>
          <UserLineItemLabel>Email</UserLineItemLabel>
          <UserLineItemValue>
            {props.email}
          </UserLineItemValue>
        </UserLineItem>
        
        <UserLineItem>
          <UserLineItemLabel>Active</UserLineItemLabel>
          <UserLineItemValue>
          <CheckboxInput
            name="active"
            isChecked={props.active}
          />
          </UserLineItemValue>
        </UserLineItem>

        <UserFooter>
          <UserButton
            arrow={true}
            secondary={true}
            block={true}
            >
            Save
          </UserButton>
        </UserFooter>
      </form>
    </React.Fragment>
  )
}

export default User;
