import React from 'react';
import styled, { css } from 'styled-components';
import { mediaUp, mediaDown, theme } from '../../globalStyle';
import SwapCard from './SwapCard';
import CoinDropdown from '../CoinDropdown/CoinDropdown';
import GridRow from '../Grid/GridRow';
import GridColumn from '../Grid/GridColumn';
import ScreenReaderText from '../ScreenReaderText/ScreenReaderText';
import CoinContext from '../../contexts/CoinContext';
import swap from '../../assets/images/swap.svg';

const StyledSwapCardGroup = styled.div`
  background-color: ${theme.white};
  position: relative;
  border-radius: 3px;
`;

const SwapCardGroupDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.gray400};
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  
  ${mediaUp.lg`
    height: 100%;
    width: 1px;
    margin: 0 auto;
    top: 0;
    transform: none;
  `}
`;

const SwapCardSwapButton = styled.button`
  width: 46px;
  height: 46px;
  border-radius: 100%;
  position: absolute;
  margin: 0 auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid ${theme.gray400};
  background-color: ${theme.white};
  background-image: url('${swap}');
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  z-index: 5;
  
  ${mediaDown.lg`
    transform: translate(-50%, -50%) rotate(90deg);
  `}
  
  ${props => props.disabled && css`
    cursor: no-drop;
  `}
  
  &:focus {
    outline: none;
  }
`;

class SwapCardGroup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.coinDropdownRef = React.createRef();
  }

  componentDidMount() {
    this.props.loadInitialSwapForm(this.props.from, this.props.to);
  }

  render() {
    return (
      <StyledSwapCardGroup className={this.props.className}>
        <GridRow noGutters={true}>
          <GridColumn lgColumns={6}>
            <SwapCard
              title="You have"
              name={this.props.incomingInputName}
              amount={this.props.incomingAmount}
              currency={this.props.incomingCurrency}
              loading={this.props.incomingLoading}
              handleInputChange={this.props.handleCurrencyInputChange}
              handleDropdownToggle={this.props.handleCoinDropdownToggle}
              incoming={true}
              ratesPage={this.props.ratesPage}
            />
          </GridColumn>

          <GridColumn lgColumns={6}>
            <SwapCard
              title={this.props.outgoingAmount && ("You get (Top Rate by " + this.props.topExchange + ")") || this.props.topExchange}
              name={this.props.outgoingInputName}
              amount={this.props.outgoingAmount}
              currency={this.props.outgoingCurrency}
              loading={this.props.outgoingLoading}
              handleInputChange={this.props.handleCurrencyInputChange}
              handleDropdownToggle={this.props.handleCoinDropdownToggle}
              incoming={false}
              ratesPage={this.props.ratesPage}
            />
          </GridColumn>
        </GridRow>

        <SwapCardGroupDivider />

        <SwapCardSwapButton
          type="button"
          onClick={this.props.handleSwapChange}
          disabled={this.props.incomingLoading || this.props.outgoingLoading}
        >
          <ScreenReaderText>Switch sides of amounts and currencies</ScreenReaderText>
        </SwapCardSwapButton>

        <CoinDropdown
          activeCurrency={this.props.coinDropdownActiveCurrency}
          dropdownActive={this.props.coinDropdownActive}
          handleDropdownSelect={this.props.handleCoinDropdownSelect}
          handleDropdownToggle={this.props.handleCoinDropdownToggle}
          ratesPage={this.props.ratesPage}
          headerOffset={this.props.headerOffset}
          loading={this.props.coinsLoading}
          coinsList={this.props.coinsList}
          ref={this.coinDropdownRef}
        />
      </StyledSwapCardGroup>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
        incomingInputName,
        incomingAmount,
        incomingCurrency,
        incomingLoading,
        outgoingInputName,
        outgoingAmount,
        outgoingCurrency,
        outgoingLoading,
        handleCurrencyInputChange,
        handleCoinDropdownToggle,
        coinsList,
        coinsLoading,
        coinDropdownActiveCurrency,
        coinDropdownActive,
        handleCoinDropdownSelect,
        handleSwapChange,
        loadInitialSwapForm,
        exchange,
      }) => (
        <SwapCardGroup {...props}
                       incomingInputName={incomingInputName}
                       incomingAmount={incomingAmount}
                       incomingCurrency={incomingCurrency}
                       incomingLoading={incomingLoading}
                       outgoingInputName={outgoingInputName}
                       outgoingAmount={outgoingAmount}
                       outgoingCurrency={outgoingCurrency}
                       outgoingLoading={outgoingLoading}
                       handleCurrencyInputChange={handleCurrencyInputChange}
                       handleCoinDropdownToggle={handleCoinDropdownToggle}
                       coinsList={coinsList}
                       coinsLoading={coinsLoading}
                       coinDropdownActiveCurrency={coinDropdownActiveCurrency}
                       coinDropdownActive={coinDropdownActive}
                       handleCoinDropdownSelect={handleCoinDropdownSelect}
                       handleSwapChange={handleSwapChange}
                       loadInitialSwapForm={loadInitialSwapForm}
                       topExchange={exchange}
        />
      )}
    </CoinContext.Consumer>
  )
}
