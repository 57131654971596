import React from 'react';
import styled, { css } from 'styled-components';
import { theme, mediaUp } from '../../globalStyle';
import Container from '../Container/Container';

const StyledPageContainer = styled.div`
  background-color: ${props => props.backgroundLight ? theme.bodyMainBg : theme.bodyBg};
  
  ${props => props.headerOffset && css`
    margin-top: ${theme.headerMobileHeight};
    
    ${mediaUp.lg`
      margin-top: ${theme.headerDesktopHeight};
    `}
  `}
`;

function PageContainer(props) {
  return (
    <StyledPageContainer
      backgroundLight={props.backgroundLight}
      headerOffset={props.headerOffset}
      className={props.className}
    >
      <Container hasMobileGutters={true}>
        {props.children}
      </Container>
    </StyledPageContainer>
  )
}

export default PageContainer;
