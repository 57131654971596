import React from 'react';
import { Helmet } from 'react-helmet';

function Meta(props) {
  return (
    <Helmet>
      {props.title &&
        <title>Swap Crypto at Competitive Rates | {props.title} | Swapcoins</title>
      }

      {props.description &&
        <meta name="description" content={props.description} />
      }
    </Helmet>
  )
}

export default Meta;
