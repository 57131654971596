import React from 'react';
import styled from 'styled-components';
import { hideVisually } from 'polished';
import { theme } from '../../globalStyle';

const CheckboxBox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid ${theme.gray400};
  position: relative;
  top: -4px;
  
  &:hover {
    cursor: pointer;
  }
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 4px;
    border: 2px solid ${theme.white};
    background-color: ${props => props.isChecked ? theme.primary : 'transparent'};
  }
`;

class CheckboxInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: false
    };

    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  handleCheckChange() {
    this.setState({
      isChecked: !this.state.isChecked
    });
    
    if (this.props.isChecked !== undefined) {
      this.props.isChecked = !this.props.isChecked;
    }
  }

  render() {
    return (
      <React.Fragment>
        <CheckboxBox  
          aria-hidden={true}
          isChecked={this.props.isChecked === undefined ? this.state.isChecked : this.props.isChecked}
          onClick={this.props.handleCheckChange ? this.props.handleCheckChange : this.handleCheckChange}
          className={this.props.className}
        />

        <input
          type="checkbox"
          checked={this.props.isChecked === undefined ? this.state.isChecked : this.props.isChecked}
          value={this.props.value}
          name={this.props.name}
          style={hideVisually()}
        />
      </React.Fragment>
    )
  }
}

export default CheckboxInput;