import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import { theme, mediaUp, mediaDown, psLetterSpacing, slickDots } from "../../globalStyle";
import Container from '../Container/Container';
import HowItWorksStep from './HowItWorksStep';
import Slider from 'react-slick';
import desktopBg from '../../assets/images/howitworks_bg_lg.jpg';
import mobileBg from '../../assets/images/howitworks_bg_sm.jpg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledHowItWorks = styled.section`
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('${mobileBg}');
  background-position: bottom center;
  padding: ${rem(130)} ${theme.mobileGutter} ${rem(144)};
  
  ${mediaUp.lg`
    padding: ${rem(106)} 0 ${rem(94)};
    background-image: url('${desktopBg}');
    background-position: top right;
  `}
  
  ${mediaDown.lg`
    text-align: center;
  `}
`;

const HowItWorksTitle = styled.h1`
  color: ${theme.tertiary};
  font-size: ${rem(73)};
  line-height: 62px;
  
  ${mediaUp.lg`
    font-size: ${rem(154)};
    line-height: 135px;
    margin-bottom: ${rem(90)};
    letter-spacing: ${psLetterSpacing(154, -35)};
  `}
`;

const HowItWorksSteps = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${rem(56)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const HowItWorksSlider = styled(Slider)`
  ${mediaUp.lg`
    &.slick-slider {
      display: none;
    }
  `}
  
  ${slickDots(theme.tertiary, theme.white, '-230px')};
`;

const slickSettings = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const steps = [
  {
    title: 'Compare Rates',
    copy: 'Compare rates across exchanges and find the most competitive exchange rate.',
    link: '/rates',
  },
  {
    title: 'Convert Crypto',
    copy: `Follow simple steps to exchange your cryptocurrency. View our "How it works" guide `,
    link: '/how-it-works',
    guideLinkTag: 'here.',
  },
  {
    title: 'Track Order',
    copy: 'Every order is given an unique ID and is easily tracked through our simple and intuitive UI.',
    link: '/track-order',
  }
];

function HowItWorks() {
  return (
    <StyledHowItWorks>
      <Container>
        <HowItWorksTitle>How<br />It Works</HowItWorksTitle>

        {steps.length &&
          <HowItWorksSteps>
            {steps.map((step, index) => {
              return (
                <HowItWorksStep
                  key={index}
                  index={index + 1}
                  title={step.title}
                  copy={step.copy}
                  link={step.link}
                  guideLinkTag={step.guideLinkTag}
                />
              )
            })}
          </HowItWorksSteps>
        }

        {steps.length &&
          <HowItWorksSlider {...slickSettings}>
            {steps.map((step, index) => {
              // vertical scrolling issues on mobile if component not wrapped in div
              return (
                <div key={index}>
                  <HowItWorksStep
                    index={index + 1}
                    title={step.title}
                    copy={step.copy}
                    link={step.link}
                  />
                </div>
              )
            })}
          </HowItWorksSlider>
        }
      </Container>
    </StyledHowItWorks>
  )
}

export default HowItWorks;