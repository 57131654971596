import React from 'react';
import { Link } from 'react-router-dom';
import RouterButton from '../Button/RouterButton';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, psLetterSpacing } from '../../globalStyle';
import CoinContext from '../../contexts/CoinContext';
import arrow from '../../assets/images/coin_card_arrow.png';

const StyledCoinCard = styled.div`
  background-color: ${theme.white};
  display: flex;
  flex-direction: column;
`;

const CoinCardHeader = styled.div`
  display: flex;
  padding: ${rem(31)} 0 ${rem(26)};
  margin: 0 ${rem(25)};
  border-bottom: 1px solid ${theme.muted};
  align-items: center;
`;

const CoinCardIcon = styled.div`
  flex: 0 0 38px;
  max-width: 38px;
  text-align: center;
`;

const CoinCardLogo = styled.img`
  width: 38px;
`;

const CoinCardName = styled.h5`
  display: inline-block;
  font-size: ${rem(17)};
  letter-spacing: ${psLetterSpacing(17, 40)};
  margin-left: ${rem(12)};
  margin-bottom: 0;
`;

const CoinCardBody = styled.div`
  padding: ${rem(36)} ${rem(25)};
  text-align: center;
`;

const CoinCardButton = styled(RouterButton)`
  margin-bottom: ${rem(30)};
`;

const CoinCardLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.primary};
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(12, 0)};
  line-height: normal;
  
  &:hover {
    color: ${theme.primary};
  }
`;

const CoinCardArrow = styled.img`
  display: inline-block;
  margin-left: ${rem(10)};
`;

function CoinCard(props) {
  return (
    <StyledCoinCard>
      <CoinCardHeader>
        {props.logo &&
          <CoinCardIcon>
            <CoinCardLogo src={process.env.REACT_APP_API_URL + props.logo} />
          </CoinCardIcon>
        }

        {props.name &&
          <CoinCardName>{props.name}</CoinCardName>
        }
      </CoinCardHeader>

      <CoinCardBody>
        {props.name &&
          <CoinContext.Consumer>
            {({ setInitialCurrency }) => (
              <CoinCardButton secondary={true} block={true} to={`/rates`} onClick={() => { setInitialCurrency(props.coin) }}>
                Swap {props.buttonName ? props.buttonName : props.name}
              </CoinCardButton>
            )}
          </CoinContext.Consumer>
        }

        {(props.name && props.coin) &&
          <CoinCardLink to={`/supported-coins/${props.coin}`}>
            Learn more about {props.name}<CoinCardArrow src={arrow} aria-hidden={true} />
          </CoinCardLink>
        }
      </CoinCardBody>
    </StyledCoinCard>
  )
}

export default CoinCard;