import React from 'react';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import HelpHero from '../HelpHero/HelpHero';
import Loading from '../Loading/Loading';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import 'formdata-polyfill';
import { rem } from 'polished';
import { mediaUp, psLetterSpacing, psLineHeight } from '../../globalStyle';

const NewUserRegistrationPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(64)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(92)};
  `}
`;

const NewUserRegistrationPageTitle = styled(PageTitle)`
  ${mediaUp.lg`
    padding: ${rem(101)} 0 ${rem(44)};
  `}
`;

const NewUserRegistrationContent = styled.div`
  text-align: center;
  
  ${mediaUp.lg`
    width: 60%;
    margin: 0 auto;
  `}
`;

const NewUserRegistrationCopy = styled.p`
  margin: ${rem(20)} 0 0;
  font-size: ${rem(15)};
  line-height: ${psLineHeight(15, 21)};
  letter-spacing: ${psLetterSpacing(12, 20)};
`;

class NewUserRegistration extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hasErrors: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/account/profile');
    }
  }

  componentDidUpdate() {
    if (this.props.authenticated) {
      this.props.history.push('/account/profile');
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      loading: true
    });
  }

  componentWillUnmount() {
    this.setState({
      loading: false,
      hasError: false
    })
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Welcome" />

        <Header />

        <Loading loading={this.props.authenticationLoading}>
          <NewUserRegistrationPageContainer backgroundLight={true} headerOffset={true}>
            <NewUserRegistrationPageTitle>Welcome to Swapcoins.com</NewUserRegistrationPageTitle>

            <NewUserRegistrationContent>
             <NewUserRegistrationCopy>
               Thank you for signing up with Swapcoins.com. <br />
               You are almost there! <br /><br />
               To enjoy the benefits of Swapcoins.com, please complete registration by clicking the link sent to your email. <br />
               We are excited to make cryptocurrency trading easier for you!
             </NewUserRegistrationCopy>
            
            </NewUserRegistrationContent>
          </NewUserRegistrationPageContainer>
        </Loading>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          loadUserFromToken
        }) => (
        <NewUserRegistration {...props}
               authenticated={authenticated}
               authenticationLoading={authenticationLoading}
               loadUserFromToken={loadUserFromToken}
        />
      )}
    </CoinContext.Consumer>
  )
};
