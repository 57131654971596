import React from 'react';
import styled, { css } from 'styled-components';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import SwapCardGroup from '../SwapCardGroup/SwapCardGroup';
import RouterButton from '../Button/RouterButton';
import CoinContext from '../../contexts/CoinContext';
import ReactLoading from 'react-loading';
import { rem } from 'polished';

const SwapFormSwapCardGroup = styled(SwapCardGroup)`
  ${mediaDown.lg`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
`;

const SwapFormFooter = styled.div`
  ${mediaUp.lg`
    padding: ${rem(40)} ${rem(30)};
    display: flex;
  `}
`;

const SwapFormOffers = styled.div`
  ${mediaUp.lg`
    display: inline-flex;
    margin-left: auto;
    align-items: center;
  `}
`;

const SwapFormLabel = styled.span`
  color: ${props => (!props.hasRates || props.loading) ? 'transparent' : theme.white};
  font-size: ${rem(13)};
  letter-spacing: ${psLetterSpacing(13, 40)};
  margin-right: ${rem(25)};
  position: relative;
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const SwapFormLabelLoadingWrapper = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.3s opacity ease;
  
  ${props => props.loading
    ? css`
        opacity: 1;
      `
    : css`
        pointer-events: none;
      `
  }
`;

const SwapFormButton = styled(RouterButton)`
  ${mediaDown.lg`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    display: block;
  `}
`;

function SwapForm(props) {
  return (
    <form>
      <SwapFormSwapCardGroup from={props.incomingCurrency} to={props.outgoingCurrency}/>

      <SwapFormFooter>
        <SwapFormOffers>
          <CoinContext.Consumer>
            {({ incomingLoading, incomingCurrency, outgoingCurrency, outgoingLoading, numRates }) => (
              <React.Fragment>
                <SwapFormButton to="/exchange" secondary={true} arrow={false}>Swap Coins</SwapFormButton>&nbsp;
                <SwapFormButton to={"/rates" + "/" + incomingCurrency + "/" + outgoingCurrency} primary={true} arrow={false}>More Rates</SwapFormButton>
              </React.Fragment>
            )}
          </CoinContext.Consumer>
        </SwapFormOffers>
      </SwapFormFooter>
    </form>
  )
}

export default SwapForm;