import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, psLetterSpacing, psLineHeight, mediaUp, mediaDown } from "../../globalStyle";
import Container from '../Container/Container';
import icon from '../../assets/images/FAQIcon.svg';

const StyledPressHero = styled.section`
  background-color: ${theme.primary};
  min-height: 620px;
  position: relative;
  padding: ${rem(102)} 0 ${rem(94)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  ${mediaUp.lg`
    padding: ${rem(166)} 0 ${rem(88)};
  `}
  
  &::before {
    content: '';
    position: absolute;
    background-color: ${theme.white};
    top: 0;
    right: 0;
    width: 49%;
    height: 40px;
    
    ${mediaUp.lg`
      height: 57px;
    `}
  }
  
  ${mediaUp.lg`
    min-height: 548px;
  `}
`;

const PressHeroBody = styled.div`
  text-align: center;
`;

const PressHeroTitle = styled.h1`
  color: ${theme.white};
  font-size: ${rem(46)};
  letter-spacing: ${psLetterSpacing(46, -35)};
  margin-bottom: ${rem(30)};
  
  ${mediaDown.lg`
    line-height: 55px;
    max-width: 334px;
    margin-left: auto;
    margin-right: auto;
  `}
  
  ${mediaUp.lg`
    font-size: ${rem(48)};
    letter-spacing: ${psLetterSpacing(48, -35)};
    margin-bottom: ${rem(34)};
  `}
`;

const PressHeroCopy = styled.p`
  color: ${theme.white};
  margin: 0 auto;
  line-height: ${psLineHeight(16, 24)};
  letter-spacing: ${psLetterSpacing(16, 20)};
  max-width: 311px;
  
  ${mediaUp.lg`
    max-width: 426px;
  `}
`;

const PressHeroLink = styled.a`
  color: ${theme.white};
  text-decoration: underline;
  transition: 0.3s opacity ease;
  
  &:hover {
    color: ${theme.white};
    opacity: 0.7;
  }
`;

const PressIcon = styled.img`
  margin-bottom: 37px;
  
  ${mediaUp.lg`
    margin-bottom: 41px;
  `}
`;

function PressHero() {
  return (
    <StyledPressHero>
      <Container>
        <PressHeroBody>
          <PressIcon src={icon} aria-hidden={true} />

          <PressHeroTitle>Would you like more information?</PressHeroTitle>
          <PressHeroCopy>
            If you have any questions about our service, you can email us at <PressHeroLink href="mailto:support@swapcoins.com">support@swapcoins.com</PressHeroLink>
            <br />
            <br />
            We usually respond to email requests within 12 hours.
          </PressHeroCopy>
        </PressHeroBody>
      </Container>
    </StyledPressHero>
  )
}

export default PressHero;