import { injectGlobal, css } from 'styled-components';
import { darken, rem, placeholder } from 'polished';
import avenirBlack from './assets/fonts/Avenir-Black.ttf';
import avenirBlackOblique from './assets/fonts/Avenir-BlackOblique.ttf';
import avenirBook from './assets/fonts/Avenir-Book.ttf';
import avenirBookOblique from './assets/fonts/Avenir-BookOblique.ttf';
import avenirHeavy from './assets/fonts/Avenir-Heavy.ttf';
import avenirHeavyOblique from './assets/fonts/Avenir-HeavyOblique.ttf';
import avenirLight from './assets/fonts/Avenir-Light.ttf';
import avenirLightOblique from './assets/fonts/Avenir-LightOblique.ttf';
import avenirMedium from './assets/fonts/Avenir-Medium.ttf';
import avenirMediumOblique from './assets/fonts/Avenir-MediumOblique.ttf';
import avenirRoman from './assets/fonts/Avenir-Roman.ttf';
import avenirOblique from './assets/fonts/Avenir-Oblique.ttf';
import circularBold from './assets/fonts/lineto-circular-pro-bold.ttf';
import circularBook from './assets/fonts/lineto-circular-pro-book.ttf';

const fontSizeBaseValue = 16;

// Calculate pixel letter spacing values from Photoshop pt values
export function psLetterSpacing(fontSize = fontSizeBaseValue, letterSpacing = 10) {
  return `${fontSize * letterSpacing / 1000}px`;
}

// Calculate pixel line height values from Photoshop pt values
// doesn't work properly with Circular font
export function psLineHeight(fontSize = fontSizeBaseValue, lineHeight = 30) {
  return `${fontSize + (lineHeight / 2)}px`;
}

const blues = {
  blue700: '#1a1a67',
  blue600: '#2e2ec5',
  blue500: '#2a38c9',
  blue400: '#0f5ce9',
  blue300: '#026fdc',
  blue200: '#00b3fa',
};

const white = '#ffffff';
const green = '#29b075';
const red = '#ca1616';
const black = '#000000';

const grays = {
  gray1000: '#20232f',
  gray900: '#2e2f33',
  gray800: '#3c404e',
  gray700: '#5c5e66',
  gray600: '#9396a1',
  gray500: '#aaadb7',
  gray400: '#c0c0c5',
  gray300: '#dedfe3',
  gray200: '#ebecf0',
  gray100: '#f5f5f7',
};

const maxContainerWidths = {
  sm: '540px',
  md: '720px',
  lg: '960px',
  xl: '1144px'
};

export const theme = {
  primary: blues.blue500,
  secondary: green,
  tertiary: blues.blue700,
  danger: red,
  success: green,
  blue700: blues.blue700,
  blue600: blues.blue600,
  blue500: blues.blue500,
  blue400: blues.blue400,
  blue300: blues.blue300,
  blue200: blues.blue200,
  gray1000: grays.gray1000,
  gray900: grays.gray900,
  gray800: grays.gray800,
  gray700: grays.gray700,
  gray600: grays.gray600,
  gray500: grays.gray500,
  gray400: grays.gray400,
  gray300: grays.gray300,
  gray200: grays.gray200,
  gray100: grays.gray100,
  black: black,
  white: white,
  muted: grays.gray400,
  bodyBg: grays.gray100,
  bodyColor: grays.gray700,
  bodyMainBg: white,
  footerBg: grays.gray800,
  footerColor: grays.gray600,
  footerHeadingColor: white,
  headerBgStart: blues.blue500,
  headerBgEnd: blues.blue400,
  fontFamilyBase: '\'Avenir\', Helvetica, Roboto, Arial, sans-serif',
  fontFamilyComfortaa: '\'Comfortaa\', Helvetica, Roboto, Arial, sans-serif',
  fontFamilyCircular: '\'Circular\', Helvetica, Roboto, Arial, sans-serif',
  fontFamilySpaceMono: '\'Space Mono\', Helvetica, Roboto, Arial, sans-serif',
  fontFamilyIBMPlexSans: '\'IBM Plex Sans\', Helvetica, Roboto, Arial, sans-serif',
  fontFamilyIBMPlexSerif: '\'IBM Plex Serif\', Helvetica, Roboto, Arial, sans-serif',
  fontFamilyNunito: '\'Nunito\', Helvetica, Roboto, Arial, sans-serif',
  fontSizeBase: `${fontSizeBaseValue}px`,
  avenirWeightBlack: '800',
  avenirWeightHeavy: '700',
  avenirWeightMedium: '500',
  avenirWeightRoman: '400',
  avenirWeightBook: '300',
  avenirWeightLight: '200',
  circularWeightBold: '700',
  circularWeightBook: '400',
  lineHeightBase: psLineHeight(),
  fontWeightBase: '400',
  paragraphMarginBottom: rem(45),
  headingMarginBottom: rem(45),
  linkColor: green,
  linkDecoration: 'none',
  linkHoverColor: green,
  linkHoverDecoration: 'underline',
  smMaxContainerWidth: maxContainerWidths.sm,
  mdMaxContainerWidth: maxContainerWidths.md,
  lgMaxContainerWidth: maxContainerWidths.lg,
  xlMaxContainerWidth: maxContainerWidths.xl,
  headerDesktopHeight: '100px',
  headerMobileHeight: '80px',
  headerMobileGutter: rem(35),
  headerZIndex: 100,
  siteName: 'Swapcoins',
  navItemDesktopGutter: rem(30),
  mobileGutter: rem(15),
  mobileGutterLg: rem(30),
  gridGutter: rem(15),
  gridColumns: 12,
  dropdownZIndex: 2000,
};

const breakpoints = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 375
};

// iterate through the breakpoints and create a media template
export const mediaUp = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = breakpoints[label] / fontSizeBaseValue;

    accumulator[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)};
      }
    `;

    return accumulator;
  },
  {}
);

export const mediaDown = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    // use em in breakpoints to work properly cross-browser and support users
    // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
    const emSize = (breakpoints[label] - 0.2) / fontSizeBaseValue;

    accumulator[label] = (...args) => css`
      @media (max-width: ${emSize}em) {
        ${css(...args)};
      }
    `;

    return accumulator;
  },
  {}
);

export const flexColumn = (columnSpan, gridColumns = 12) => {
  const columnPercentage = columnSpan / gridColumns * 100;

  return css`
    flex: 0 0 ${columnPercentage}%;
    max-width: ${columnPercentage}%;
  `;
};

export const offsetColumn = (columnSpan, gridColumns = 12) => {
  const columnPercentage = columnSpan / gridColumns * 100;

  return css`
    margin-left: ${columnPercentage}%;
  `;
};

export const unstyledList = css`
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
`;

export const linkColorWithHoverColor = (linkColor) => {
  return css`
    color: ${linkColor};
    
    &:hover {
      color: ${linkColor};
    }
  `;
};

export const unstyledButton = css`
  background-color: transparent;
  border: none;
  padding: 0;
  
  &:focus {
    outline: none;
  }
`;

export const inputStyles = css`
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 1px solid ${theme.muted};
  padding: ${rem(30)};
  margin-bottom: ${rem(10)};
  color: ${theme.primary};
  font-size: ${rem(17)};
  line-height: ${psLineHeight(17, 20)};
  letter-spacing: ${psLetterSpacing(17, -10)};
  transition: 0.3s border-color ease;
  background-clip: padding-box;
  
  ${placeholder({'color': theme.muted})};
  
  &:focus {
    border-color: ${theme.gray500};
    outline: none;
  }
`;

export const labelStyles = css`
  font-weight: ${theme.avenirWeightMedium};
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, -10)};
  margin-bottom: ${rem(12)};
  color: ${theme.gray400};
  line-height: normal;
`;

export function slickDots(dotColor, activeDotColor, top) {
  return css`
    .slick-dots {
      bottom: auto;
      top: ${top};
      
      li {
        margin: 0 2px;
        
        button {
          &::before {
            color: ${dotColor};
            font-size: 5px;
            opacity: 1;
          }
        }
        
        &.slick-active {
          button {
            &::before {
              opacity: 1;
              color: ${activeDotColor};
            }
          }
        }
      }
    }
  `;
}

export function buttonStyles(primary, secondary, tertiary, block, disabled = false, loading = false) {
  let backgroundColor = theme.gray800;

  if (!disabled) {
    if (primary) backgroundColor = theme.primary;
    if (secondary) backgroundColor = theme.secondary;
    if (tertiary) backgroundColor = theme.tertiary;
  }

  return css`
    background-color: ${backgroundColor};
    border-radius: 2px;
    color: ${theme.white};
    text-transform: uppercase;
    font-weight: ${theme.avenirWeightHeavy};
    font-size: ${rem(12)};
    letter-spacing: ${psLetterSpacing(12, 200)};
    padding: ${rem(27)} ${rem(37)};
    line-height: normal;
    border: none;
    transition: 0.3s background-color ease, 0.3s color ease;
    display: ${block ? 'flex' : 'inline-flex'};
    justify-content: center;
    align-items: center;
    position: relative;
    
    ${block && css`
      width: 100%;
    `}
    
    &:hover {
      background-color: ${darken(0.1, backgroundColor)};
      
      ${disabled && css`
        cursor: no-drop;
      `}
    }
    
    &:focus {
      outline: none;
    }
    
    ${loading && css`
      color: transparent;
    `}
  `
}

// Reboot forked from Bootstrap 4 reboot.scss. Some elements commented out if not being used.
injectGlobal`
  // Reboot
  //
  // Normalization of HTML elements, manually forked from Normalize.css to remove
  // styles targeting irrelevant browsers while applying new styles.
  //
  // Normalize is licensed MIT. https://github.com/necolas/normalize.css


  // Document
  //
  // 1. Change from \`box-sizing: content-box\` so that \`width\` is not affected by \`padding\` or \`border\`.
  // 2. Change the default font family in all browsers.
  // 3. Correct the line height in all browsers.
  // 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
  // 5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
  //    we force a non-overlapping, non-auto-hiding scrollbar to counteract.
  // 6. Change the default tap highlight to be completely transparent in iOS.

  // fonts
  @import url('https://fonts.googleapis.com/css?family=Comfortaa:400,700');
  @import url('https://fonts.googleapis.com/css?family=Space+Mono:400,400i,700,700i');
  @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600');
  @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Serif:300,300i');
  @import url('https://fonts.googleapis.com/css?family=Nunito');
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirBlack}') format('truetype'); 
    font-weight: 800;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirBlackOblique}') format('truetype'); 
    font-weight: 800;
    font-style: italic;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirBook}') format('truetype'); 
    font-weight: 300;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirBookOblique}') format('truetype'); 
    font-weight: 300;
    font-style: italic;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirHeavy}') format('truetype'); 
    font-weight: 700;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirHeavyOblique}') format('truetype'); 
    font-weight: 700;
    font-style: italic;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirLight}') format('truetype'); 
    font-weight: 200;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirLightOblique}') format('truetype'); 
    font-weight: 200;
    font-style: italic;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirMedium}') format('truetype'); 
    font-weight: 500;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirMediumOblique}') format('truetype'); 
    font-weight: 500;
    font-style: italic;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirRoman}') format('truetype'); 
    font-weight: 400;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Avenir';
    src: url('${avenirOblique}') format('truetype'); 
    font-weight: 400;
    font-style: italic;
  } 
  
  @font-face {
    font-family: 'Circular';
    src: url('${circularBold}') format('truetype'); 
    font-weight: 700;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Circular';
    src: url('${circularBook}') format('truetype'); 
    font-weight: 400;
    font-style: normal;
  } 

  *,
  *::before,
  *::after {
    box-sizing: border-box; // 1
  }

  html {
    font-family: sans-serif; // 2
    line-height: 1.15; // 3
    -webkit-text-size-adjust: 100%; // 4
    -ms-text-size-adjust: 100%; // 4
    -ms-overflow-style: scrollbar; // 5
    -webkit-tap-highlight-color: transparent; // 6
  }

  // IE10+ doesn't honor \`<meta name="viewport">\` in some cases.
  @at-root {
    @-ms-viewport {
      width: device-width;
    }
  }

  // Shim for "new" HTML5 structural elements to display correctly (IE10, older browsers)
  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  // Body
  //
  // 1. Remove the margin in all browsers.
  // 2. As a best practice, apply a default \`background-color\`.
  // 3. Set an explicit initial text-align value so that we can later use the
  //    the \`inherit\` value on things like \`<th>\` elements.

  body {
    margin: 0; // 1
    font-family: ${theme.fontFamilyBase};
    font-size: ${theme.fontSizeBase};
    font-weight: ${theme.fontWeightBase};
    line-height: ${theme.lineHeightBase};
    color: ${theme.bodyColor};
    text-align: left; // 3
    background-color: ${theme.bodyBg}; // 2
  }

  // Suppress the focus outline on elements that cannot be accessed via keyboard.
  // This prevents an unwanted focus outline from appearing around elements that
  // might still respond to pointer events.
  //
  // Credit: https://github.com/suitcss/base
  [tabindex="-1"]:focus {
    outline: 0 !important;
  }


  // Content grouping
  //
  // 1. Add the correct box sizing in Firefox.
  // 2. Show the overflow in Edge and IE.

  hr {
    box-sizing: content-box; // 1
    height: 0; // 1
    overflow: visible; // 2
  }


  //
  // Typography
  //

  // Remove top margins from headings
  //
  // By default, \`<h1>\`-\`<h6>\` all receive top and bottom margins. We nuke the top
  // margin for easier control within type scales as it avoids margin collapsing.
  // stylelint-disable selector-list-comma-newline-after
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: ${theme.headingMarginBottom};
    color: ${theme.tertiary};
  }
  
  h1,
  h2 {
    font-family: ${theme.fontFamilyCircular};
    font-weight: 700;
  }
  
  h6 {
    font-size: ${rem(17)};
    text-transform: uppercase;
    letter-spacing: ${psLetterSpacing(17, 100)};
    line-height: ${psLineHeight(17, 30)};
  }

  // Reset margins on paragraphs
  //
  // Similarly, the top margin on \`<p>\`s get reset. However, we also reset the
  // bottom margin to use \`rem\` units instead of \`em\`.
  p {
    margin-top: 0;
    margin-bottom: ${theme.paragraphMarginBottom};
  }

  // Abbreviations
  //
  // 1. Remove the bottom border in Firefox 39-.
  // 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  // 3. Add explicit cursor to indicate changed behavior.
  // 4. Duplicate behavior to the data-* attribute for our tooltip plugin

  abbr[title],
  abbr[data-original-title] { // 4
    text-decoration: underline; // 2
    text-decoration: underline dotted; // 2
    cursor: help; // 3
    border-bottom: 0; // 1
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  /*
  dt {
    font-weight: $dt-font-weight;
  }
  */

  dd {
    margin-bottom: .5rem;
    margin-left: 0; // Undo browser default
  }

  blockquote {
    margin: 0 0 1rem;
  }

  dfn {
    font-style: italic; // Add the correct font style in Android 4.3-
  }

  b,
  strong {
    font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
  }

  small {
    font-size: 80%; // Add the correct font size in all browsers
  }

  //
  // Prevent \`sub\` and \`sup\` elements from affecting the line height in
  // all browsers.
  //

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub { bottom: -.25em; }
  sup { top: -.5em; }


  //
  // Links
  //

  a {
    color: ${theme.linkColor};
    text-decoration: ${theme.linkDecoration};
    background-color: transparent; // Remove the gray background on active links in IE 10.
    -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

    &:hover {
      color: ${theme.linkHoverColor};
      text-decoration: ${theme.linkHoverDecoration};
    }
  }

  // And undo these styles for placeholder links/named anchors (without href)
  // which have not been made explicitly keyboard-focusable (without tabindex).
  // It would be more straightforward to just use a[href] in previous block, but that
  // causes specificity issues in many other styles that are too complex to fix.
  // See https://github.com/twbs/bootstrap/issues/19402

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: none;
    }
    
    &:focus {
      outline: 0;
    }
  }


  //
  // Code
  //

  /*
  pre,
  code,
  kbd,
  samp {
    font-family: $font-family-monospace;
    font-size: 1em; // Correct the odd \`em\` font sizing in all browsers.
  }
  */

  pre {
    // Remove browser default top margin
    margin-top: 0;
    // Reset browser default of \`1em\` to use \`rem\`s
    margin-bottom: 1rem;
    // Don't allow content to break outside
    overflow: auto;
    // We have @viewport set which causes scrollbars to overlap content in IE11 and Edge, so
    // we force a non-overlapping, non-auto-hiding scrollbar to counteract.
    -ms-overflow-style: scrollbar;
  }


  //
  // Figures
  //

  figure {
    // Apply a consistent margin strategy (matches our type styles).
    margin: 0 0 1rem;
  }


  //
  // Images and content
  //

  img {
    vertical-align: middle;
    border-style: none; // Remove the border on images inside links in IE 10-.
  }

  svg {
    // Workaround for the SVG overflow bug in IE10/11 is still required.
    // See https://github.com/twbs/bootstrap/issues/26878
    overflow: hidden;
    vertical-align: middle;
  }


  //
  // Tables
  //

  table {
    border-collapse: collapse; // Prevent double borders
  }

  /*
  caption {
    padding-top: $table-cell-padding;
    padding-bottom: $table-cell-padding;
    color: $table-caption-color;
    text-align: left;
    caption-side: bottom;
  }
  */

  th {
    // Matches default \`<td>\` alignment by inheriting from the \`<body>\`, or the
    // closest parent with a set \`text-align\`.
    text-align: inherit;
  }


  //
  // Forms
  //

  label {
    // Allow labels to use \`margin\` for spacing.
    display: inline-block;
    // margin-bottom: $label-margin-bottom;
  }

  // Remove the default \`border-radius\` that macOS Chrome adds.
  //
  // Details at https://github.com/twbs/bootstrap/issues/24093
  button {
    border-radius: 0;
    cursor: pointer;
  }

  // Work around a Firefox/IE bug where the transparent \`button\` background
  // results in a loss of the default \`button\` focus styles.
  //
  // Credit: https://github.com/suitcss/base/
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0; // Remove the margin in Firefox and Safari
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible; // Show the overflow in Edge
  }

  button,
  select {
    text-transform: none; // Remove the inheritance of text transform in Firefox
  }

  // 1. Prevent a WebKit bug where (2) destroys native \`audio\` and \`video\`
  //    controls in Android 4.
  // 2. Correct the inability to style clickable types in iOS and Safari.
  button,
  html [type="button"], // 1
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button; // 2
  }

  // Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box; // 1. Add the correct box sizing in IE 10-
    padding: 0; // 2. Remove the padding in IE 10-
  }


  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    // Remove the default appearance of temporal inputs to avoid a Mobile Safari
    // bug where setting a custom line-height prevents text from being vertically
    // centered within the input.
    // See https://bugs.webkit.org/show_bug.cgi?id=139848
    // and https://github.com/twbs/bootstrap/issues/11266
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto; // Remove the default vertical scrollbar in IE.
    // Textareas should really only resize vertically so they don't break their (horizontal) containers.
    resize: vertical;
  }

  fieldset {
    // Browsers set a default \`min-width: min-content;\` on fieldsets,
    // unlike e.g. \`<div>\`s, which have \`min-width: 0;\` by default.
    // So we reset that to ensure fieldsets behave more like a standard block element.
    // See https://github.com/twbs/bootstrap/issues/12359
    // and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
    min-width: 0;
    // Reset the default outline behavior of fieldsets so they don't affect page layout.
    padding: 0;
    margin: 0;
    border: 0;
  }

  // 1. Correct the text wrapping in Edge and IE.
  // 2. Correct the color inheritance from \`fieldset\` elements in IE.
  legend {
    display: block;
    width: 100%;
    max-width: 100%; // 1
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit; // 2
    white-space: normal; // 1
  }

  progress {
    vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
  }

  // Correct the cursor style of increment and decrement buttons in Chrome.
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    // This overrides the extra rounded corners on search inputs in iOS so that our
    // \`.form-control\` class can properly style them. Note that this cannot simply
    // be added to \`.form-control\` as it's not specific enough. For details, see
    // https://github.com/twbs/bootstrap/issues/11586.
    outline-offset: -2px; // 2. Correct the outline style in Safari.
    -webkit-appearance: none;
  }

  //
  // Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
  //

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  //
  // 1. Correct the inability to style clickable types in iOS and Safari.
  // 2. Change font properties to \`inherit\` in Safari.
  //

  ::-webkit-file-upload-button {
    font: inherit; // 2
    -webkit-appearance: button; // 1
  }

  //
  // Correct element displays
  //

  output {
    display: inline-block;
  }

  summary {
    display: list-item; // Add the correct display in all browsers
    cursor: pointer;
  }

  template {
    display: none; // Add the correct display in IE
  }

  // Always hide an element with the \`hidden\` HTML attribute (from PureCSS).
  // Needed for proper display in IE 10-.
  [hidden] {
    display: none !important;
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }
`;