import React from 'react';
import { Link } from 'react-router-dom';
import ExchangeStep from './ExchangeStep';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";
import arrow from '../../assets/images/arrow_back_secondary.svg';

const StyledExchangeSteps = styled.div`
  margin-bottom: ${rem(29)};
  
  ${mediaUp.lg`
    margin-bottom: ${rem(77)};
  `}
`;

const ExchangeStepsLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: ${rem(10)};
  font-weight: ${theme.avenirWeightBlack};
  letter-spacing: ${psLetterSpacing(10, 100)};
  color: #20232f;
  text-transform: uppercase;
  visibility: ${props => props.shown ? 'visible' : 'hidden'};
  line-height: normal;
  margin-bottom: ${rem(8)};
  
  &:hover {
    color: #20232f;
  }
  
  img {
    margin-right: ${rem(12)};
  }
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const ExchangeStepsWrapper = styled.div`
  ${mediaUp.md`
    max-width: 376px;
    margin: 0 auto;
  `}
`;

const ExchangeStepsRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-left: -${rem(12)};
  margin-right: -${rem(12)};
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: ${theme.gray300};
  }

  ${mediaUp.lg`
    margin-left: -${rem(24)};
    margin-right: -${rem(24)};
  `}
`;

function ExchangeSteps(props) {
  return (
    <StyledExchangeSteps>
      <ExchangeStepsLink to="/rates" shown={props.currentStep === 1 ? 1 : 0}>
        <img src={arrow} aria-hidden={true} alt="Back to offers" /> Back to Rates Page
      </ExchangeStepsLink>

      <ExchangeStepsWrapper>
        <ExchangeStepsRow>
          <ExchangeStep step={1}
                        isActive={props.currentStep === 1}
                        isCompleted={props.currentStep > 1}
                        title="Enter Address"
          />

          <ExchangeStep step={2}
                        isActive={props.currentStep === 2}
                        isCompleted={props.currentStep > 2}
                        title={`Send ${props.incomingCurrency}`}
          />

          <ExchangeStep step={3}
                        isActive={props.currentStep === 3}
                        isCompleted={props.currentStep > 3}
                        title="Process Order"
          />

          <ExchangeStep step={4}
                        isActive={props.currentStep === 4}
                        isCompleted={props.currentStep > 4}
                        title="Complete Swap"
          />
        </ExchangeStepsRow>
      </ExchangeStepsWrapper>
    </StyledExchangeSteps>
  )
}

export default ExchangeSteps;
