import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import OutlineText from '../OutlineText/OutlineText';
import { theme, mediaUp, mediaDown } from '../../globalStyle';
import arrow from '../../assets/images/arrow_white.svg';

const StyledHowItWorksStep = styled.div`
  position: relative;
  max-width: 225px;
  
  ${mediaUp.lg`
    flex: 0 0 228px;
    max-width: 228px;
    display: flex;
    flex-direction: column;
    
    &::after {
      content: '';
      position: absolute;
      background-color: #59afee;
      width: 46%;
      height: 1px;
      left: 0;
      top: 62px;
      margin-left: 166px;
      pointer-events: none;
    }
    
    &:last-of-type {
      &::after {
        content: none;
      }
    }
  `}
  
  ${mediaUp.xl`
    &::after {
      width: 87%;
    }
  `}
  
  ${mediaDown.lg`
    margin: 0 auto;
  `}
`;

const HowItWorksStepNumber = styled.div`
  overflow: hidden;
  width: 138px;
  
  ${mediaDown.lg`
    margin: 0 auto;
  `}
`;

const HowItWorksStepText = styled(OutlineText)`
  margin-bottom: ${rem(42)};
  margin-left: -47px;
`;

const HowItWorksStepTitle = styled.h6`
  color: ${theme.white};
  margin-bottom: ${rem(3)};
`;

const HowItWorksStepCopy = styled.p`
  color: ${theme.white};
  margin-bottom: 0;
`;

const HowItWorksStepLink = styled(Link)`
  display: block;
  line-height: 0;
  padding-top: ${rem(26)};
  margin-top: auto;
`;

const HowItWorksLink = styled(Link)`
  color: ${theme.white};
  textDecorationLine: underline;
`;

const HowItWorksStepLinkArrow = styled.img`
  ${mediaDown.lg`
    margin: 0 auto;
  `}
`;

function HowItWorksStep(props) {
  return (
    <StyledHowItWorksStep>
      <HowItWorksStepNumber>
        <HowItWorksStepText strokeColor={theme.tertiary}>0{props.index}</HowItWorksStepText>
      </HowItWorksStepNumber>

      <HowItWorksStepTitle>{props.title}</HowItWorksStepTitle>
      <HowItWorksStepCopy>
        {props.copy}
        {props.guideLinkTag && <HowItWorksLink to={props.link}>{props.guideLinkTag}</HowItWorksLink>}
      </HowItWorksStepCopy>

      <HowItWorksStepLink to={props.link}>
        <HowItWorksStepLinkArrow src={arrow} alt={`Go to ${props.title} page`} />
      </HowItWorksStepLink>
    </StyledHowItWorksStep>
  )
}

export default HowItWorksStep;