import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import arrow from '../../assets/images/arrow_white_sm.svg';

const ButtonArrow = styled.img`
  display: inline-block;
  margin-left: ${rem(15)};
  opacity: ${props => props.loading ? '0': '1'};
`;

export default function(props) {
  return (
    <ButtonArrow src={arrow} aria-hidden={true} loading={props.loading} />
  )
}

