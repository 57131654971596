import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, psLetterSpacing, unstyledButton } from "../../globalStyle";

const CoinCardButton = styled.button`
  width: 100%;
  transition: 0.3s opacity ease;
  opacity: 0.6;
  ${unstyledButton}
  
  &:hover {
    opacity: 1.0;
  }
`;

const CoinCardBody = styled.div`
  background-color: ${theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  height: 136px;
  
  ${props => props.isActive && css`
    border: 2px solid ${theme.primary};
  `}
  
  ${mediaUp.lg`
    height: 136px;
  `}
`;

const CoinCardLogo = styled.img`
  margin: auto 0;
  max-width: 55px;
`;

const CoinCardName = styled.p`
  margin-bottom: 0;
  color: #afb0b2;
  position: absolute;
  font-size: ${rem(11)};
  line-height: 13px;
  letter-spacing: ${psLetterSpacing(13, 40)};
  bottom: 6px;

  ${mediaUp.lg`
    bottom: 11px;
    font-size: ${rem(12)};
    line-height: 14px;
    letter-spacing: ${psLetterSpacing(14, 40)};
  `}
`;

function CoinCard(props) {
  return (
    <CoinCardButton
      type="button"
      onClick={() => { props.handleDropdownSelect(props.currency); props.handleSearchInputClear(); }}
      aria-selected={props.isActive}
    >
      <CoinCardBody isActive={props.isActive}>
        {props.logo &&
          <CoinCardLogo
            src={props.logo}
            aria-hidden={true}
          />
        }

        <CoinCardName>{props.name}</CoinCardName>
      </CoinCardBody>
    </CoinCardButton>
  )
}

export default CoinCard;
