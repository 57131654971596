import React from 'react';
import styled, { css } from 'styled-components';
import { theme, mediaUp, mediaDown, psLetterSpacing, psLineHeight } from '../../globalStyle';
import { rem } from 'polished';
import { Link } from 'react-router-dom';
import arrow from '../../assets/images/arrow_tertiary.svg';

const StyledInfoBlock = styled.div`
  position: relative;
  
  ${mediaDown.lg`
    margin-bottom: ${rem(59)};
  `}
  
  ${mediaUp.lg`
    height: 100%;
    padding: ${rem(40)} ${rem(49)};
    
    ${props => props.first && css`
      padding-bottom: 0;
    `}
    
    ${props => props.borderRight && css`
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 40px;
        bottom: 40px;
        height: calc(100% - 80px);
        width: 1px;
        background-color: ${theme.gray300};
      }
    `}
    
    ${props => props.borderBottom && css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 49px;
        right: 49px;
        width: calc(100% - 98px);
        height: 1px;
        background-color: ${theme.gray300};
      }
    `}
  `}
`;

const InfoBlockContent = styled.div`
  text-align: center;
  width: 273px;
  margin: 0 auto;
  
  ${mediaUp.lg`
    display: flex;
    flex-direction: column;
    height: 100%;
  `}
`;

const InfoBlockTitle = styled.h6`
  font-size: ${rem(17)};
  letter-spacing: ${psLetterSpacing(17, 60)};
  margin-bottom: ${rem(9)};
`;

const InfoBlockCopy = styled.p`
  line-height: ${psLineHeight(16, 26)};
  color: ${theme.gray800};
  margin-bottom: 0;
`;

const InfoBlockIconWrapper = styled.div`
  width: 172px;
  display: flex;
  flex-direction: column;
  margin: 0 auto ${rem(30)};
  
  ${mediaUp.lg`
    height: 195px;
  `}
`;

const InfoBlockIcon = styled.img`
  width: 100%;
  height: auto;
  margin-top: auto;
  
  .info-block--is-last & {
    position: relative;
    top: 6px;
  }
`;

const InfoBlockLink = styled(Link)`
 display: block;
 margin-top: auto;
 line-height: 0;
 padding: ${rem(30)} 0 ${rem(40)};
 color: ${theme.gray500}
 ${mediaDown.lg`
  padding-bottom: 0;
 `}

 &:hover {
  text-decoration: none;
  color: ${theme.blue600};
}
`;

const InfoBlockLinkArrow = styled.img``;

function InfoBlock(props) {
  return (
    <StyledInfoBlock borderRight={props.borderRight}
                     borderBottom={props.borderBottom}
                     first={props.first}
                     className={props.className}
    >
      <InfoBlockContent>
        {props.icon &&
          <InfoBlockIconWrapper>
            <InfoBlockIcon src={props.icon} aria-hidden="true" />
          </InfoBlockIconWrapper>
        }

        {props.title &&
          <InfoBlockTitle>{props.title}</InfoBlockTitle>
        }

        {props.copy &&
          <InfoBlockCopy>{props.copy}</InfoBlockCopy>
        }

        {props.link &&
          <InfoBlockLink to={props.link}>
            Learn More
          </InfoBlockLink>
        }
      </InfoBlockContent>
    </StyledInfoBlock>
  )
}

export default InfoBlock;
