import React from 'react';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import Accordion from '../Accordion/Accordion';
import PressHero from './PressHero';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import { rem } from 'polished';
import { mediaUp } from '../../globalStyle';

const PressContent = [
  {
    id: 'latest',
    title: 'Latest',
    navigationTitle: 'Latest',
    items: [
      {
        title: 'March 18, 2019 - Introducing Swapcoins.com.',
        copy: 
        `<a href="` + process.env.REACT_APP_API_URL + `/press/Swapcoins_Press_Release_19_MAR_2019.pdf" target="_blank">Download PDF</a><br>` +
        '<p>FOR IMMEDIATE RELEASE<br><br>' + 
        'Swapcoins.com<br>' +
        'A division of Hexbite Labs<br>' +
        'Vancouver, BC<br>' +
        'Contact: contact@hexbite.com<br><br>' +
        '<strong>INTRODUCING SWAPCOINS.COM,</strong><br>' +
        '<strong>WHERE YOU FIND THE BEST CRYPTO RATES - IN REAL TIME.</strong><br><br>' +
        'Swapcoins.com culls the best live cryptocurrency rates on the most secure sites. Just login and you’re ready to swap.<br><br>' +
        '<strong>Vancouver, BC (March 18, 2019)</strong> The days of searching for the best rates  - only to have them change by the time you log in to all your accounts – are over. With one Swapcoins.com account, you can: compare prices; pick the best rates; then, jump on the chance to get the best profit.<br><br>' +
        'No time wasted. No opportunities lost.<br><br>' +
        'In a volatile market like crypto, every second counts. With Swapcoins.com, you don’t waste time logging into endless accounts, nor do you get held up with, and charged, for multiple transactions.<br><br>' +
        'Swapcoins.com is a one-account, one-stop swap platform for getting the most competitive rates - and, only on the most legitimate exchanges. Swapcoins.com doesn’t take your coins hostage during the process either.<br><br>' +
        'In addition, the simple interface means you can do what you’ve come to do – find the best real time rates, really fast, and be ready to swap.<br><br>' +
        'Swapcoins.com was created by Hexbite Labs, a full-service blockchain development agency established in 2015.  Hexbite specializes in customized, peer-to-peer decentralized apps and full cryptocurrency network solutions.<br><br>' +
        'For more information, contact Steve Mitobe at steve@hexbite.com<br><br>' +
        '“Swapcoins.com is an easy-to-use cryptocurrency price aggregator that simplifies crypto trading. Their software can definitely save you time and money!”  -Coinchapter.com<br><br>' +
        '###</p>'
      },
    ]
  },
  {
    id: 'media',
    title: 'Media',
    navigationTitle: 'Media',
    items: [
    ]
  },
  {
    id: 'archive',
    title: 'Archive',
    navigationTitle: 'Archive',
    items: [
    ]
  },
];

const PressPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(79)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(139)};
  `}
`;

function Press() {
  return (
    <React.Fragment>
      <Meta title="News & Media" />
      
      <Header />

      <PressPageContainer backgroundLight={true} headerOffset={true}>
        <PageTitle>News &amp; Media</PageTitle>

        <Accordion content={PressContent} />
      </PressPageContainer>

      <PressHero />
    </React.Fragment>
  )
}

export default Press;