import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import OutlineText from '../OutlineText/OutlineText';
import { theme, psLetterSpacing } from "../../globalStyle";

const StyledCountCard = styled.div`
  background-color: ${theme.primary};
  height: 100%;
  min-height: ${props => props.minHeight ? props.minHeight : 'auto'};
  padding: ${rem(92)} ${52/360 * 100 + '%'} ${rem(110)};
`;

const CountCardCount = styled(OutlineText)`
  margin-bottom: ${rem(26)};
  font-family: ${theme.fontFamilyCircular};
  line-height: 0.75;
  
  ${props => props.fontSize
  ?
  css`
    font-size: ${rem(props.fontSize)};
    letter-spacing: ${psLetterSpacing(props.fontSize, -10)};
  `
  :
  css`
    font-size: ${rem(145)};
    letter-spacing: ${psLetterSpacing(145, -35)};
  `
  }
`;

const CountCardTitle = styled.h1`
  color: ${theme.white};
  margin-bottom: 0;
  
  ${props => props.fontSize
    ?
    css`
      font-size: ${rem(props.fontSize)};
      letter-spacing: ${psLetterSpacing(props.fontSize, -35)};
      line-height: ${props.fontSize + 'px'};
    `
    :
    css`
      font-size: ${rem(70)};
      letter-spacing: ${psLetterSpacing(70, -35)};
      line-height: 70px;
    `
  }
`;

function CountCard(props) {
  return (
    <StyledCountCard minHeight={props.minHeight} className={props.className}>
      <CountCardCount strokeColor={theme.tertiary} fontSize={props.countFontSize}>
        {props.count}
      </CountCardCount>

      <CountCardTitle dangerouslySetInnerHTML={{__html: props.title}} fontSize={props.titleFontSize} />
    </StyledCountCard>
  )
}

export default CountCard;
