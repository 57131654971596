import React from 'react';
import Header from '../Header/Header';
import PageContainer from '../Page/PageContainer';
import PageTitle from '../Page/PageTitle';
import TextInput from '../Input/TextInput';
import TextAreaInput from '../Input/TextAreaInput';
import Button from '../Button/Button';
import HelpHero from '../HelpHero/HelpHero';
import Alert from '../Alert/Alert';
import Meta from '../Meta/Meta';
import styled from 'styled-components';
import axios from 'axios';
import 'formdata-polyfill';
import { rem } from 'polished';
import { mediaUp, psLetterSpacing, psLineHeight } from '../../globalStyle';

const ContactPageContainer = styled(PageContainer)`
  padding-bottom: ${rem(64)};
  
  ${mediaUp.lg`
    padding-bottom: ${rem(92)};
  `}
`;

const ContactPageTitle = styled(PageTitle)`
  ${mediaUp.lg`
    padding: ${rem(101)} 0 ${rem(44)};
  `}
`;

const ContactContent = styled.div`
  text-align: center;
  
  ${mediaUp.lg`
    width: 60%;
    margin: 0 auto;
  `}
`;

const ContactButton = styled(Button)`
  width: 100%;
  text-align: center;
  margin: ${rem(10)} auto 0;
  font-size: ${rem(14)};
  letter-spacing: ${psLetterSpacing(14, 120)};
  line-height: ${psLineHeight(14, 20)};
  
  ${mediaUp.lg`
    max-width: 230px;
    margin: ${rem(33)} auto 0;
  `}
`;

const ContactTextInput = styled(TextInput)`
  margin-bottom: ${rem(19)};
`;

const ContactTextAreaInput = styled(TextAreaInput)`
  margin-bottom: ${rem(19)};
`;

const ContactAlert = styled(Alert)`
  margin-bottom: 1rem;
`;

class Contact extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hasMessage: false,
      hasError: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const data = new FormData(event.target);
    event.preventDefault();

    const params = {
      email: data.get('email'),
      subject: data.get('subject'),
      message: data.get('message')
    };

    this.setState({
      loading: true
    });

    axios.post(`${process.env.REACT_APP_API_URL}/contact`, params)
      .then((response) => {
        if (response.data.status === 'valid') {
          this.setState({
            loading: false,
            hasMessage: true
          });
        } else {
          this.setState({
            loading: false,
            hasError: true
          });
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          hasError: true
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Contact" />

        <Header />

        <ContactPageContainer backgroundLight={true} headerOffset={true}>
          <ContactPageTitle>Contact Us</ContactPageTitle>

          <ContactContent>
            <ContactAlert show={this.state.hasMessage} success={true}>
              Your message was successfully submitted. We will be in contact with you shortly.
            </ContactAlert>

            <ContactAlert show={this.state.hasError} danger={true}>
              There was an error with your submission. Please try again.
            </ContactAlert>

            <form onSubmit={this.handleSubmit}>
              <ContactTextInput name="email" type="email" placeholder="Your email address" required={true} />
              <ContactTextInput name="subject" type="text" placeholder="Subject" required={true} />
              <ContactTextAreaInput name="message" placeholder="What can we help you with?" required={true} />

              <ContactButton secondary={true} hasArrow={true} loading={this.state.loading}>Submit</ContactButton>
            </form>
          </ContactContent>
        </ContactPageContainer>

        <HelpHero />
      </React.Fragment>
    )
  }
}

export default Contact;

