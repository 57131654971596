// Configs
import React from 'react';
import Header from '../Header/Header';
import HelpHero from '../HelpHero/HelpHero';
import Button from '../Button/Button';
import Container from '../Container/Container';
import AdminHeader from './AdminHeader';
import Config from './Config';
import Modal from '../Modal/Modal';
import Loading from '../Loading/Loading';
import PageTitle from '../Page/PageTitle';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import axios from 'axios';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";

const ConfigsContainer = styled(Container)`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const ConfigsOrder = styled.div`
  border-bottom: 1px solid ${theme.muted};
  
  &:last-of-type {
    border-bottom: none;
  }
`;

const ConfigsHeader = styled.div`
  background-color: #fbfbfb;
  padding: ${rem(13)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const ConfigsBody = styled.div`
  background-color: ${theme.white};
`;

const ConfigsColumn = styled.div`
  ${mediaDown.lg`
    padding: ${rem(15)};
    margin-right: ${rem(15)};
    
    &:first-of-type {
      padding-top: ${rem(20)};
    }
    
    &:last-of-type {
      padding-bottom: ${rem(20)};
      padding-right: ${rem(15)};
    }
  `}
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
  `}
`;

const ConfigsNameColumn = ConfigsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 15%;
    max-width: 15%;
  `}
`;

const ConfigsDescriptionColumn = ConfigsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 40%;
    max-width: 40%;
  `}
`;

const ConfigsQuantityColumn = ConfigsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 20%;
    max-width: 20%;
  `}
`;

const ConfigsEnabledColumn = ConfigsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 10%;
    max-width: 10%;
  `}
`;

const ConfigsButtonColumn = ConfigsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 15%;
    max-width: 15%;
  `}
`;

const ConfigsHeaderTitle = styled.h6`
  text-transform: uppercase;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  margin-bottom: 0;
  color: #888888;
  line-height: normal;
`;

const ConfigsHeaderMobileTitle = ConfigsHeaderTitle.extend`
  margin-bottom: 0.25rem;
  
  ${mediaUp.lg`
    display: none;
  `}
`;

const ConfigsCell = styled.div`
  ${mediaUp.lg`
    display: flex;
    padding: ${rem(40)} 0;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `}
`;

const ConfigsName = styled.p`
  color: ${theme.gray1000};
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(12)};
  letter-spacing: ${psLetterSpacing(10, 120)};
`;

const ConfigsDescription = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const ConfigsQuantity = styled.p`
  font-size: ${rem(12)};
  color: ${theme.gray600};
  margin-bottom: 0;
  text-align: right;

  ${mediaDown.lg`
    line-height: 12px;
  `}
`;

const ConfigsEnabled = styled.h4`
  color: ${theme.gray1000};
  letter-spacing: 0;
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 20px;
  `}
  
  ${mediaUp.lg`
    font-size: ${rem(16)};
    flex: 0 0 100%;
    max-width: 100%;
  `}
`;

const ConfigsButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

const ConfigsTitle = styled(PageTitle)`
  background-color: ${theme.white};
  color: ${theme.muted};
  padding: ${rem(120)} 0;
  
  ${mediaUp.lg`
    padding: ${rem(182)} 0;
  `}
`;

const ConfigsLoading = styled(Loading)`
  background-color: ${theme.white};
`;

class Configs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      configs: [],
      modalActive: false,
      name: '',
      description: '',
      quantity: '',
      enabled: false,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.logoutUser();
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/admin/configs`, { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        let configs = [];

        response.data.forEach((config) => {

          configs.push({
            name: config.name,
            description: config.description,
            quantity: config.quantity,
            enabled: config.enabled,
          });
        });

        this.setState({
          loading: false,
          configs: configs
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({
            loading: false
          });

          this.props.logoutUser();
          this.props.history.push('/login');
        }
      });
    }
  }

  handleModalOpen(name, description, quantity, enabled) {
    this.setState({
      modalActive: true,
      name: name,
      description: description,
      quantity: quantity,
      enabled: enabled,
    }, () => {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    });
  }

  handleModalClose() {
    this.setState({
      modalActive: false,
    }, () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    });

    window.location.reload();
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Configurations" />

        <Header />

        <AdminHeader />

        <ConfigsLoading loading={this.state.loading}>
          {this.state.configs ?
            <React.Fragment>
              <ConfigsHeader>
                <ConfigsContainer hasMobileGutters={true}>
                  <ConfigsNameColumn>
                    <ConfigsHeaderTitle>Name</ConfigsHeaderTitle>
                  </ConfigsNameColumn>

                  <ConfigsDescriptionColumn>
                    <ConfigsHeaderTitle>Description</ConfigsHeaderTitle>
                  </ConfigsDescriptionColumn>

                  <ConfigsQuantityColumn>
                    <ConfigsHeaderTitle>Quantity</ConfigsHeaderTitle>
                  </ConfigsQuantityColumn>

                  <ConfigsEnabledColumn>
                    <ConfigsHeaderTitle>Enabled</ConfigsHeaderTitle>
                  </ConfigsEnabledColumn>

                </ConfigsContainer>
              </ConfigsHeader>

              <ConfigsBody>
                <Scrollbars style={{height: 400 }}>
                  {this.state.configs.map((config, index) => {
                    return (
                      <ConfigsOrder key={index}>
                        <ConfigsContainer hasMobileGutters={true}>
                          <ConfigsNameColumn>
                            <ConfigsCell>
                              <ConfigsHeaderMobileTitle>Name</ConfigsHeaderMobileTitle>
                              <ConfigsName>
                                <strong>{config.name}</strong>
                              </ConfigsName>
                            </ConfigsCell>
                          </ConfigsNameColumn>

                          <ConfigsDescriptionColumn>
                            <ConfigsCell>
                              <ConfigsHeaderMobileTitle>Description</ConfigsHeaderMobileTitle>
                              <ConfigsDescription>{config.description}</ConfigsDescription>
                            </ConfigsCell>
                          </ConfigsDescriptionColumn>

                          <ConfigsQuantityColumn>
                            <ConfigsCell>
                              <ConfigsHeaderMobileTitle>Quantity</ConfigsHeaderMobileTitle>
                              <ConfigsQuantity>{config.quantity}</ConfigsQuantity>
                            </ConfigsCell>
                          </ConfigsQuantityColumn>

                          <ConfigsEnabledColumn>
                            <ConfigsCell>
                              <ConfigsHeaderMobileTitle>Enabled</ConfigsHeaderMobileTitle>
                              <ConfigsEnabled>
                                <input
                                  type="checkbox"
                                  checked={config.enabled}
                                  value={config.enabled}
                                  name={config.name}
                                  disabled={true}
                                />
                              </ConfigsEnabled>
                            </ConfigsCell>
                          </ConfigsEnabledColumn>

                          <ConfigsButtonColumn>
                            <ConfigsCell>
                              <ConfigsHeaderMobileTitle>Action</ConfigsHeaderMobileTitle>
                              <ConfigsButton secondary={true}
                                                handleButtonClick={() => {
                                                  this.handleModalOpen(
                                                    config.name,
                                                    config.description,
                                                    config.quantity,
                                                    config.enabled,
                                                  )
                                                }}>
                                Configure
                              </ConfigsButton>
                            </ConfigsCell>
                          </ConfigsButtonColumn>
                        </ConfigsContainer>
                      </ConfigsOrder>
                    )
                  })}
                </Scrollbars>
              </ConfigsBody>
            </React.Fragment>
            :
            <ConfigsTitle>No Configurations Found</ConfigsTitle>
          }
        </ConfigsLoading>

        <HelpHero />
        <Modal isActive={this.state.modalActive} handleModalClose={this.handleModalClose}>
          <Config
            name={this.state.name}
            description={this.state.description}
            quantity={this.state.quantity}
            enabled={this.state.enabled}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <Configs {...props}
                      authenticated={authenticated}
                      authenticationLoading={authenticationLoading}
                      logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};

