import React from 'react';
import Header from '../Header/Header';
import HelpHero from '../HelpHero/HelpHero';
import Container from '../Container/Container';
import AccountHeader from './AccountHeader';
import Loading from '../Loading/Loading';
import PageTitle from '../Page/PageTitle';
import Meta from '../Meta/Meta';
import CoinContext from '../../contexts/CoinContext';
import styled from 'styled-components';
import axios from 'axios';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing, psLineHeight } from "../../globalStyle";

const LimitsContainer = styled(Container)`
  ${mediaUp.lg`
    display: flex;
  `}
`;

const LimitsOrder = styled.div`
  border-bottom: 1px solid ${theme.muted};
  
  &:last-of-type {
    border-bottom: none;
  }
`;

const LimitsHeader = styled.div`
  background-color: #fbfbfb;
  padding: ${rem(13)};
  
  ${mediaDown.lg`
    display: none;
  `}
`;

const LimitsBody = styled.div`
  background-color: ${theme.white};
`;

const LimitsColumn = styled.div`
  ${mediaDown.lg`
    padding: ${rem(15)};
    
    &:first-of-type {
      padding-top: ${rem(30)};
    }
    
    &:last-of-type {
      padding-bottom: ${rem(30)};
    }
  `}
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
  `}
`;

const LimitsDateColumn = LimitsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 113px;
    max-width: 113px;
  `}
`;

const LimitsFromColumn = LimitsColumn.extend`
  ${mediaUp.lg`
    flex: 1;
  `}
`;

const LimitsToColumn = LimitsColumn.extend`
  ${mediaUp.lg`
    flex: 0 0 30%;
    max-width: 30%;
  `}
`;

const LimitsHeaderTitle = styled.h6`
  text-transform: uppercase;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  margin-bottom: 0;
  color: #888888;
  line-height: normal;
`;

const LimitsHeaderMobileTitle = LimitsHeaderTitle.extend`
  margin-bottom: 0.25rem;
  
  ${mediaUp.lg`
    display: none;
  `}
`;

const LimitsCell = styled.div`
  ${mediaUp.lg`
    display: flex;
    padding: ${rem(40)} 0;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `}
`;

const LimitsDate = styled.p`
  color: ${theme.gray1000};
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
`;

const LimitsAmount = styled.h3`
  color: ${theme.gray1000};
  letter-spacing: 0;
  margin-bottom: 0;
  
  ${mediaDown.lg`
    line-height: 20px;
    margin-bottom: 0.25rem;
  `}
  
  ${mediaUp.lg`
    font-size: ${rem(26)};
    flex: 0 0 100%;
    max-width: 100%;
  `}
`;

const LimitsTitle = styled(PageTitle)`
  background-color: ${theme.white};
  color: ${theme.muted};
  padding: ${rem(120)} 0;
  
  ${mediaUp.lg`
    padding: ${rem(182)} 0;
  `}
`;

const LimitsLoading = styled(Loading)`
  background-color: ${theme.white};
`;

const LimitsUploadCopy = styled.p`
  text-align: center;
  font-size: ${rem(15)};
  line-height: ${psLineHeight(15, 21)};
  margin: ${rem(24)} 0 ${rem(24)};
  
  ${mediaUp.md`
    width: 482px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

class Limits extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      limits: [],
    };
  }

  componentDidMount() {
    if (!this.props.authenticated && !this.props.authenticationLoading) {
      this.props.history.push('/login');
    } else {
      this.setState({
        loading: true
      });

      axios.get(`${process.env.REACT_APP_API_URL}/user/limits`, { headers:
          { 'Authorization': `Bearer ${localStorage.getItem('jwtToken')}` }
      })
      .then((response) => {
        let limits = [];

        response.data.forEach((limit) => {

          limits.push({
            granularity: limit.granularity,
            total: parseFloat(limit.total),
            limit: parseFloat(limit.limit),
            remaining: parseFloat(limit.remaining)
          });
        });

        this.setState({
          loading: false,
          limits: limits
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({
            loading: false
          });

          this.props.logoutUser();
          this.props.history.push('/login');
        }
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Meta title="Swap Limits" />

        <Header />

        <AccountHeader />

        <LimitsLoading loading={this.state.loading}>
          {this.state.limits ?
            <React.Fragment>
              <LimitsHeader>
                <LimitsContainer hasMobileGutters={true}>
                  <LimitsDateColumn>
                    <LimitsHeaderTitle>Swap Limit</LimitsHeaderTitle>
                  </LimitsDateColumn>

                  <LimitsFromColumn>
                    <LimitsHeaderTitle>Swap Volume Used<br /><center>(USD)</center></LimitsHeaderTitle>
                  </LimitsFromColumn>

                  <LimitsFromColumn>
                    <LimitsHeaderTitle>Swap Volume Available<br /><center>(USD)</center></LimitsHeaderTitle>
                  </LimitsFromColumn>

                  <LimitsToColumn>
                    <LimitsHeaderTitle>Maximum Swap Volume<br /><center>(USD)</center></LimitsHeaderTitle>
                  </LimitsToColumn>
                </LimitsContainer>
              </LimitsHeader>

              <LimitsBody>
                {this.state.limits.map((limit, index) => {
                  return (
                    <LimitsOrder key={index}>
                      <LimitsContainer hasMobileGutters={true}>
                        <LimitsDateColumn>
                          <LimitsCell>
                            <LimitsHeaderMobileTitle>Swap Limit</LimitsHeaderMobileTitle>

                            <LimitsDate>
                              <strong>{limit.granularity}</strong>
                            </LimitsDate>
                          </LimitsCell>
                        </LimitsDateColumn>

                        <LimitsFromColumn>
                          <LimitsCell>
                            <LimitsHeaderMobileTitle>Swap Volume Used<br />(USD)</LimitsHeaderMobileTitle>

                            <LimitsAmount>
                              {limit.total.toFixed(2)}
                            </LimitsAmount>
                          </LimitsCell>
                        </LimitsFromColumn>

                        <LimitsFromColumn>
                          <LimitsCell>
                            <LimitsHeaderMobileTitle>Swap Volume Available<br />(USD)</LimitsHeaderMobileTitle>

                            <LimitsAmount>
                              {limit.remaining.toFixed(2)}
                            </LimitsAmount>
                          </LimitsCell>
                        </LimitsFromColumn>

                        <LimitsToColumn>
                          <LimitsCell>
                            <LimitsHeaderMobileTitle>Maximum Swap Volume<br />(USD)</LimitsHeaderMobileTitle>

                            <div>
                              <LimitsAmount>
                                {limit.limit.toFixed(2)}
                              </LimitsAmount>
                            </div>                            
                          </LimitsCell>
                        </LimitsToColumn>
                      </LimitsContainer>
                    </LimitsOrder>
                  )
                })}
              </LimitsBody>
            </React.Fragment>
            :
            <LimitsTitle>No Limit History Found</LimitsTitle>
          }
        </LimitsLoading>
        <LimitsUploadCopy>
          For higher Swap limits, please complete this <a
            href={`${process.env.REACT_APP_API_URL}/images/Swap_limit_increase_application_form.docx`}
            >form
          </a> and e-mail it to
          <a href="mailto:support@swapcoins.com"> support@swapcoins.com</a>.
        </LimitsUploadCopy>
        <HelpHero />

      </React.Fragment>
    )
  }
}

export default function(props) {
  return (
    <CoinContext.Consumer>
      {({
          authenticated,
          authenticationLoading,
          logoutUser
        }) => (
        <Limits {...props}
                      authenticated={authenticated}
                      authenticationLoading={authenticationLoading}
                      logoutUser={logoutUser}
        />
      )}
    </CoinContext.Consumer>
  )
};

