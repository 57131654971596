import React from 'react';
import ReactLoading from 'react-loading';
import styled, { css } from 'styled-components';
import { theme, mediaUp } from '../../globalStyle';

const LoadingWrapper = styled.div`
  position: relative;
`;

const LoadingAnimationWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.3s opacity ease;
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${props => props.loading
  ? css`
      z-index: 1;
      opacity: 1;
    `
  : css`
      z-index: -1;
      opacity: 0;
      pointer-events: none;
      display: none;
    `
  }
`;

const LoadingContent = styled.div`
  transition: 0.3s opacity ease;
  
  ${props => props.loading
    ? css`
      opacity: 0;
      
      & > * {
        height: 0;
      }
      
      ${props => props.height
        ? css`
          height: ${props.height};
        `
        : css`
          height: calc(100vh - ${theme.headerMobileHeight});
          
          ${mediaUp.lg`
            height: calc(100vh - ${theme.headerDesktopHeight});
          `}
        `
      }
    `
    : css`
      opacity: 1;
    `
  }
`;

function Loading(props) {
  return (
    <LoadingWrapper className={props.className}>
      <LoadingAnimationWrapper loading={props.loading}>
        <ReactLoading type="spin" color={theme.primary} />
      </LoadingAnimationWrapper>

      <LoadingContent loading={props.loading} height={props.height}>
        {props.children}
      </LoadingContent>
    </LoadingWrapper>
  )
}

export default Loading;