import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from "../../globalStyle";
import thumbsUp from '../../assets/images/thumbs_up_secondary.svg';

const StyledExchangeStep = styled.div`
  position: relative;
  background-color: ${theme.bodyBg};
`;

const ExchangeStepCircle = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${rem(12)};
  margin-right: ${rem(12)};
  position: relative;
  
  ${mediaUp.lg`
    margin-left: ${rem(24)};
    margin-right: ${rem(24)};
    width: 90px;
    height: 90px;
  `}
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 4px;
    z-index: 2;
    background-color: ${theme.bodyBg};
  }
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 100%;
    transition: 0.3s ease;
    border: ${props => (props.isActive || props.isCompleted) ? '3px' : '1px'} solid ${props => props.isCompleted
  ? theme.secondary : (props.isActive ? theme.primary : theme.gray300)};
  }
`;

const ExchangeStepNumber = styled.h2`
  line-height: 0.75;
  font-size: ${rem(18)};
  letter-spacing: ${psLetterSpacing(18, 0)};
  margin-bottom: 0;
  z-index: 3;
  transition: 0.3s ease;
  color: ${props => props.isCompleted ? theme.secondary : (props.isActive ? theme.primary : theme.gray300)};
  
  ${props => props.isCompleted
    ?
    css`
      opacity: 0;
      transform: scale(0);
    `
    :
    css`
      opacity: 1;
      transform: scale(1);
    `
  }
  
  ${mediaUp.lg`
    font-size: ${rem(30)};
    letter-spacing: ${psLetterSpacing(30, 0)};
  `}
`;

const ExchangeStepSuccess = styled.img`
  transition: 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  
  ${mediaDown.lg`
    width: 17px;
  `}
  
  ${props => props.isCompleted
    ?
    css`
      opacity: 1;
      transform: scale(1) translateY(-50%) translateX(-50%);
    `
    :
    css`
      opacity: 0;
      transform: scale(0) translateY(-50%) translateX(-50%);
    `
  }
`;

const ExchangeStepTitle = styled.h6`
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 140)};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 17px 0 0;
  color: ${props => props.isCompleted ? theme.secondary : (props.isActive ? theme.primary : theme.gray300)};
  text-align: center;
  line-height: normal;
  transition: 0.3s ease;
  
  ${mediaDown.lg`
    display: none;
  `}
`;

function ExchangeStep(props) {
  return (
    <StyledExchangeStep>
      {props.step &&
        <ExchangeStepCircle isActive={props.isActive} isCompleted={props.isCompleted}>
          <ExchangeStepNumber isActive={props.isActive} isCompleted={props.isCompleted}>{props.step}</ExchangeStepNumber>

          <ExchangeStepSuccess src={thumbsUp} aria-hidden={true} isCompleted={props.isCompleted} />
        </ExchangeStepCircle>
      }

      {props.title &&
        <ExchangeStepTitle isActive={props.isActive} isCompleted={props.isCompleted}>{props.title}</ExchangeStepTitle>
      }
    </StyledExchangeStep>
  )
}

export default ExchangeStep;
