import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import arrow from '../../assets/images/order_arrow.png';

const orderGutter = rem(22);

const OrderHeader = styled.div`
  padding: ${rem(22)} ${orderGutter};
  
  ${mediaUp.lg`
    padding: ${rem(26)} ${orderGutter};
  `}
`;

const OrderDate = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;

const OrderExchange = styled.div`
  padding: ${rem(18)} ${orderGutter} ${rem(35)};
  text-align: center;
  
  ${mediaUp.lg`
    padding: ${rem(18)} ${orderGutter} ${rem(54)};
  `}
`;

const OrderFrom = styled.h4`
  color: ${theme.gray600};
  font-size: ${rem(16)};
  margin-bottom: ${rem(14)};
  line-height: normal;
  
  ${mediaUp.lg`
    font-size: ${rem(20)};
  `}
`;

const OrderCurrent = styled.h4`
  font-size: ${rem(16)};
  margin-bottom: ${rem(14)};
  line-height: normal;
  
  ${mediaUp.lg`
    font-size: ${rem(20)};
  `}
`;

const OrderArrow = styled.img`
  margin-bottom: ${rem(18)};
`;

const OrderTo = styled.h3`
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(27)};
  color: ${theme.gray1000};
  
  ${mediaUp.lg`
    font-size: ${rem(34)};
  `}
`;

const OrderLineItem = styled.div`
  padding: ${rem(15)} ${orderGutter};
  border-top: 1px solid ${theme.muted};
  
  ${mediaUp.lg`
    display: flex;
    align-items: center;
    padding: ${rem(20)} ${orderGutter};
  `}
  
  ${mediaDown.lg`
    text-align: center;
  `}
  
  &:last-of-type {
    border-bottom: 1px solid ${theme.muted};
  }
`;

const OrderLineItemCopy = styled.span`
  font-size: ${rem(13)};
  line-height: normal;
  
  ${mediaDown.lg`
    display: block;
  `}
`;

const OrderLineItemLabel = OrderLineItemCopy.extend`
  color: ${theme.gray500};
  font-weight: ${theme.avenirWeightMedium};
  letter-spacing: ${psLetterSpacing(13, 20)};
  padding-right: 0.25rem;
  
  ${mediaDown.lg`
    margin-bottom: 0.5rem;
  `}
`;

const OrderLineItemValue = OrderLineItemCopy.extend`
  color: ${theme.tertiary};
  letter-spacing: 0;
  
  ${mediaUp.lg`
    margin-left: auto;
  `}
`;

const OrderFooter = styled.div`
  padding: ${rem(20)} ${orderGutter};
  
  ${mediaUp.lg`
    padding: ${rem(30)} ${orderGutter};
  `}
`;

const OrderStatus = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 200)};
  text-align: center;
  color: ${props => props.danger ? theme.danger : theme.secondary};
  line-height: normal;
  text-transform: uppercase;
`;

function Order(props) {
  return (
    <React.Fragment>
      {props.date &&
        <OrderHeader>
          <OrderDate>
            <strong>{props.date}</strong>
          </OrderDate>
        </OrderHeader>
      }

      <OrderExchange>
        {props.incomingAmount && props.incomingCurrency &&
          <OrderFrom>
            {(props.incomingAmount.toString().length > 8) ? parseFloat(props.incomingAmount.toFixed(8)) : props.incomingAmount}
            &nbsp;{props.incomingCurrency}
          </OrderFrom>
        }

        <OrderArrow src={arrow} alt="to" />

        {props.outgoingAmount && props.outgoingCurrency &&
          <OrderTo>
            {(props.outgoingAmount.toString().length > 8) ? parseFloat(props.outgoingAmount.toFixed(8)) : props.outgoingAmount}
            &nbsp;{props.outgoingCurrency}
          </OrderTo>
          
        }
        {(props.currentOutgoingAmount > 0 ) &&
          <OrderCurrent>
            Today: {props.currentOutgoingAmount.toString()} ({props.currentOutgoingPerformance.toString()}%)
          </OrderCurrent>
        }
      </OrderExchange>

      <div>
        {props.incomingAddress &&
          <OrderLineItem>
            <OrderLineItemLabel>From</OrderLineItemLabel>
            <OrderLineItemValue>{props.incomingAddress}</OrderLineItemValue>
          </OrderLineItem>
        }

        {props.refundAddress &&
          <OrderLineItem>
            <OrderLineItemLabel>Refund</OrderLineItemLabel>
            <OrderLineItemValue>{props.refundAddress}</OrderLineItemValue>
          </OrderLineItem>
        }

        {props.rate &&
          <OrderLineItem>
            <OrderLineItemLabel>Rate</OrderLineItemLabel>
            <OrderLineItemValue>{props.rate}</OrderLineItemValue>
          </OrderLineItem>
        }

        {props.exchange &&
          <OrderLineItem>
            <OrderLineItemLabel>Exchange</OrderLineItemLabel>
            <OrderLineItemValue>{props.exchange}</OrderLineItemValue>
          </OrderLineItem>
        }

        {props.outgoingAddress &&
          <OrderLineItem>
            <OrderLineItemLabel>Exchange Address</OrderLineItemLabel>
            <OrderLineItemValue>{props.outgoingAddress}</OrderLineItemValue>
          </OrderLineItem>
        }
      </div>

      {props.status &&
        <OrderFooter>
          <OrderStatus danger={props.status === 'expired' || props.status === 'error'}
                       primary={props.status === 'pending' || props.status === 'open' || props.status === 'processing'}
                       success={props.status === 'complete' || props.status === 'closed'}
          >
            <strong>{props.status}</strong>
          </OrderStatus>
        </OrderFooter>
      }
    </React.Fragment>
  )
}

export default Order;
