import React from 'react';
import styled from 'styled-components';
import ButtonArrow from './ButtonArrow';
import ButtonLoading from './ButtonLoading';
import { buttonStyles } from "../../globalStyle";

const StyledButton = styled.button`
  ${props => buttonStyles(props.primary, props.secondary, props.tertiary, props.block, props.disabled, props.loading)}
`;

function Button(props) {
  return (
    <StyledButton
      type={props.type}
      className={props.className}
      primary={props.primary}
      secondary={props.secondary}
      tertiary={props.tertiary}
      arrow={props.arrow}
      fontSize={props.fontSize}
      block={props.block}
      onClick={props.handleButtonClick}
      disabled={props.disabled}
      loading={props.loading}
    >
      {props.children}

      {props.arrow &&
        <ButtonArrow loading={props.loading} />
      }

      <ButtonLoading loading={props.loading} />
    </StyledButton>
  )
}

export default Button;