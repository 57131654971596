import React from 'react';
import Button from '../Button/Button';
import TextInput from '../Input/TextInput';
import styled from 'styled-components';
import { rem } from 'polished';
import { theme, mediaUp, mediaDown, psLetterSpacing } from '../../globalStyle';
import arrow from '../../assets/images/order_arrow.png';
import 'formdata-polyfill';
import axios from 'axios';
import download from 'js-file-download';

const toolGutter = rem(22);

const ToolHeader = styled.div`
  padding: ${rem(22)} ${toolGutter};
  
  ${mediaUp.lg`
    padding: ${rem(26)} ${toolGutter};
  `}
`;

const ToolDescription = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;

const ToolParameters = styled.p`
  margin-bottom: 0;
  font-size: ${rem(10)};
  letter-spacing: ${psLetterSpacing(10, 120)};
  text-align: center;
  color: ${theme.black};
  line-height: normal;
  text-transform: uppercase;
`;

const ToolName = styled.div`
  padding: ${rem(18)} ${toolGutter} ${rem(35)};
  text-align: center;
  
  ${mediaUp.lg`
    padding: ${rem(18)} ${toolGutter} ${rem(54)};
  `}
`;

const ToolArrow = styled.img`
  margin-bottom: ${rem(18)};
`;

const ToolTo = styled.h3`
  margin-bottom: 0;
  line-height: normal;
  font-size: ${rem(27)};
  color: ${theme.gray1000};
  
  ${mediaUp.lg`
    font-size: ${rem(34)};
  `}
`;

const ToolFooter = styled.div`
  padding: ${rem(20)} ${toolGutter};
  text-align: center;

  ${mediaUp.lg`
    padding: ${rem(30)} ${toolGutter};
  `}
`;

const ToolButton = styled(Button)`
  margin-left: auto;
  padding-top: ${rem(14)};
  padding-bottom: ${rem(14)};
  font-size: ${rem(11)};
  
  ${mediaDown.lg`
    display: flex;
    width: 100%;
  `}
`;

function Tool(props) {
  return (
    <React.Fragment>
      <ToolHeader>
        <ToolDescription>
          Tool
        </ToolDescription>

        <ToolName>
          <ToolArrow src={arrow} alt="Tool" />
          {props.name &&
            <ToolTo>
              {props.name}
            </ToolTo>
          }
        </ToolName>

        <ToolDescription>
          {props.description}
        </ToolDescription>
      </ToolHeader>
      
      <ToolParameters>
        <form onSubmit={(event) => {
        event.preventDefault();
        
        const data = new FormData(event.target);
        let params = {
          name: props.name,
          id: props.id,
        };

        let arrayLength = props.params.length;
        for (let i = 0; i < arrayLength; i++) {
          params[props.params[i]] = data.get(props.params[i]);
        }

        let options = {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`
          },
        };

        if (props.name.search(/download/i) >= 0)  {
          options.responseType = 'arraybuffer'
        }

        axios.post(`${process.env.REACT_APP_API_URL}/admin/reports`, 
          params,
          options,
          ).then((response) => {

            if (response.data) {
              let infoColumns = [];

              if (response.data.constructor === Array && response.data[0] && response.data[0].cols) {
                infoColumns = response.data[0].cols.split(',');
              } else if (response.data.cols) {
                infoColumns = response.data.cols.split(',');
              } else {
                download(response.data, (response.headers['content-disposition'].split('"')[1]));

                return;
              }
              
              let infoLength = infoColumns.length;
              var info = 'Information Relating to Query...\n\n';
              let csvContent = '';
              infoColumns.forEach((heading) => {
                csvContent += heading + '\t';
              });
              csvContent += '\r\n';

              if (response.data.constructor === Array) {
                response.data.forEach((item) => {
                  for (let i = 0; i < infoLength; i++) {
                    info += `${infoColumns[i]}: ${item[infoColumns[i]]}\n`;
                    csvContent += item[infoColumns[i]] + '\t';
                  }
                  csvContent += '\r\n';
                });
              } else {
                for (let i = 0; i < infoLength; i++) {
                  info += `${infoColumns[i]}: ${response.data[infoColumns[i]]}\n`;
                  csvContent += response.data[infoColumns[i]] + '\t';
                }
              }

              if (info.length < 640) {
                alert(info);
              }
              else {
                download(csvContent, `${props.name}.csv`);
              }
            }
          })
          .catch((error) => {
            alert(`Issue encountered, please try again. ${error}`);
          });
        }}>
          {props.params.map((param, index) => {
            return (
              <TextInput  type="text"
                        name={param}
                        hasLabel={false}
                        placeholder={param}
                        initialValue=''
                        required={true}
              />
            )
          })}

          <ToolFooter>
            <ToolButton arrow={true} secondary={true} block={true}>
              Go
            </ToolButton>
            
          </ToolFooter>
        </form>
      </ToolParameters>
    </React.Fragment>
  )
}

export default Tool;